import React, { Fragment } from 'react'
import { PortariaStep } from './PortariaStep'

const processSteps = (steps) => {
  const [dateLastStep] = steps[steps.length - 1]

  return steps.map((step, index) => {
    const [dateStep, stepName, stepIcon] = step
    const [dateNextStep] = steps[index + 1] || []
    const [datePrevStep = '_'] = steps[index - 1] || []

    return {
      key: `${stepName}-${index}`,
      separator: index > 0,
      stepName,
      stepIcon,
      dateStep,
      dateLastStep,
      dateNextStep,
      datePrevStep,
    }
  })
}

export const PortariaSteps = ({ steps }) => {
  return(
    <div className="f g3 p3">
      {processSteps(steps).map((step) => (
        <Fragment key={step.key}>
          {step.separator && (
            <hr style={{ height: 0, flex: 1, marginTop: 20 }} />
          )}

          <PortariaStep
            dateLastStep={step.dateLastStep}
            dateNextStep={step.dateNextStep}
            datePrevStep={step.datePrevStep}
            dateStep={step.dateStep}
            stepIcon={step.stepIcon}
            stepName={step.stepName}
          />
        </Fragment>
      ))}
    </div>
  )
}

