import { useState, useContext, useMemo } from "react";
import { AppContext } from '../../../../App';
import * as c from '../../../../c';
import './index.sass';

export function DashboardChart({ title, filters = [], summaries, labelKey, valueKey, chartWidth }) {
    const App = useContext(AppContext);

    const lang = useMemo(() => {
        const { global, freight_controllership } = App.lang;
        return { ...global, ...freight_controllership.travel_records.summary };
      }, [App.lang]);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF4560', '#775DD0', '#00E396', '#FEB019', '#3F51B5', '#4CAF50'];

    const [activeIndex, setActiveIndex] = useState(0);

    const problemsData = summaries.PROBLEMS.map((p) => ({
        name: p[labelKey],
        value: parseInt(p[valueKey]),
    }));

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, midAngle } = props;

        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        const translatedName = lang.card_summary[payload.name] ?? payload.name;

        return (
            <g>
                <c.chart.Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <c.chart.Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 8}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 15 : -20)} y={ey} textAnchor={textAnchor} fill={fill}>
                    {translatedName}
                </text>
                <text x={ex + (cos >= 0 ? 15 : -20)} y={ey + 20} textAnchor={textAnchor} fill="#333">
                    {`${lang.quantidade}: ${summaries.PROBLEMS.find(item => item[labelKey] === payload.name)?.TOTALS}`}
                </text>
                {summaries.PROBLEMS.find(item => item[labelKey] === payload.name)?.PERCENTAGE !== null && (
                <text x={ex + (cos >= 0 ? 15 : -20)} y={ey + 40} textAnchor={textAnchor} fill="#333">
                    {`${lang.porcentagem}: ${summaries.PROBLEMS.find(item => item[labelKey] === payload.name)?.PERCENTAGE}%`}
                </text>
                )}
            </g>
        );
    };

    return (
        <div className="summaries-chart" style={{ width: chartWidth }}>
            {!!summaries.PROBLEMS.length && (
                <c.Frame title={title} className="chart-container">
                    <c.chart.ResponsiveContainer width="100%" minWidth={500} height={400}>
                        <c.chart.PieChart>
                            <c.chart.Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={problemsData}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={100}
                                fill="#8884d8"
                                dataKey="value"
                                onMouseEnter={onPieEnter}
                            >
                                {problemsData.map((entry, index) => (
                                    <c.chart.Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </c.chart.Pie>
                        </c.chart.PieChart>
                    </c.chart.ResponsiveContainer>
                    <div className="legend-container">
                        <ul>
                        {problemsData.map((entry, index) => {
                            const problem = summaries.PROBLEMS[index];
                            const totals = problem.TOTALS;
                            const percentage = problem.PERCENTAGE;
                            const translatedName = lang.card_summary[entry.name] ?? entry.name;

                            if (totals > 0) {
                                return (
                                    <li
                                        key={`legend-${index}`}
                                        className="legend-item"
                                        onMouseEnter={() => setActiveIndex(index)}
                                    >
                                        <div className="legend-item">
                                            <div className="color-box" style={{ backgroundColor: COLORS[index % COLORS.length] }}></div>
                                            <span className="name">{translatedName}</span>
                                            <span className="quantity">{`${totals}`}</span>

                                            {percentage !== null && percentage !== undefined && (
                                                <span className="percentage">{`(${percentage}%)`}</span>
                                            )}
                                        </div>
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        })}
                        </ul>
                    </div>
                </c.Frame>
            )}
        </div>
    );
}
