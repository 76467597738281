export function generateFiltersFromSummaries(summaries, field) {
  const problems = summaries?.[field] || { PROBLEMS: [] };
  if (problems.PROBLEMS.length === 0) return [];

  return problems.PROBLEMS.map(problem => ({
    action: "direct",
    format: "number",
    label: problem.DS_LBL || problem.DS_VLD_CTE || problem.DS_VLD_NFE,
    value: problem.TOTALS,
    ratio: problem.PERCENTAGE,
  }));
}

export function generateFiltersFromSummariesCount(summaries, field, countType) {
  const counts = summaries?.[field]?.COUNTS || {};

  const countMapping = {
    documents: {
      value: counts.TOTALS,
      ratio: counts.PERCENTAGE,
      label: counts.LABEL_NON_CONFORMING
    },
    withoutDocuments: {
      value: counts.WITHOUT_DOCUMENT,
      ratio: counts.WITHOUT_DOCUMENT_PERCENTAGE,
      label: counts.WITHOUT_DOCUMENT_LABEL
    }
  };

  const selectedCount = countMapping[countType];

  if (!selectedCount?.value || selectedCount.ratio === 0) return [];

  return [{
    action: "direct",
    format: "number",
    label: selectedCount.label,
    value: selectedCount.value,
    ratio: selectedCount.ratio,
  }];
}

export function adjustCardFiltersWithSummaries(config, summaries) {
  return config.reduce((acc, card) => {
    const filtersFromProblems = generateFiltersFromSummaries(summaries, card.field);
    const filtersFromCounts = generateFiltersFromSummariesCount(summaries, card.field, 'documents');
    const filtersFromWithoutDocumentsCounts = generateFiltersFromSummariesCount(summaries, card.field, 'withoutDocuments');

    const combinedFilters = [...filtersFromWithoutDocumentsCounts, ...filtersFromCounts, ...filtersFromProblems];

    if (combinedFilters.length > 0) {
      acc.push({ ...card, filters: combinedFilters });
    }

    return acc;
  }, []);
}
