import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as p from '../../p'
import * as f from '../../f'

export default function FreteFechamento({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons

  const [data, setData] = useState([]),
        [openDescargaModal, setOpenDescargaModal] = useState(null),
        [loading, setLoading] = useState(null)

  async function get(){
    setLoading(true)

    const __data = await App.api('portarias::consultaFechar')
    const _data = __data.status ? __data.results : JSON.parse(sessionStorage.getItem('portarias::consultaFechar'))
    sessionStorage.setItem('portarias::consultaFechar', JSON.stringify(_data))
    setData( _data )

    setLoading(false)
  }

  useEffect(() => { get()
    setReloader(()=>get)
  }, [])

  return(<>
    <c.Frame title={lang.veiculos} loading={loading}>
      <c.Table hasSub={'_SUB'}
        // VD-10265 - Samuel - 16/04/2024
        columns={[
          ["#",'ID_UOCC'],
          [lang.data_operacao,'DT_PRV'],
          [lang.operacao,'_DS_OPRPRT'],
          [lang.veiculo,'LST_VCL'],
          [lang.modal, 'DS_VIATRP'], //VD-10297 Por Hugo em 28/03/2024
          [lang.contrato,'ID_CN'],
          [lang.origem,'NM_ORI'],
          [lang.destino,'NM_DST'],
          [lang.produto,'NM_PRO'],
          [lang.peso_origem,'QT_SAI'],
          [lang.situacao,'DS_STSPRT'],
          [lang.nfe,'LST_NFE'],
          [lang.cte,'LST_CTE'],
          [lang.ticket_descarga,'_IN_DOC'],
        ]}
        data={
          data.map(item => ({...item,
            _DS_OPRPRT: item.DS_OPRPRT==='Carga'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
            _IN_DOC: item.TP_OPRPRT !== 'E' || item.IN_NFE !== true || item.IN_CTE !== true
                      ? <icons.MdBlock style={{color: 'var(--danger)'}} />
                      : item.IN_DOC === 'T'
                        ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                        : <c.IconButton title={'Anexar ticket'} icon={icons.MdAttachFile} size={24} onClick={() => setOpenDescargaModal(item)} />,
            _SUB: !!item.DOCS?.length &&
                    <c.Table isSub hasSub={'_SUB'} // dcos
                      columns={[
                        ['','_VALIDATION_ICON',{},'force-fit'],
                        ['Data','DT_DCO'],
                        ['Tipo','_TP_DF'],
                        ['Número/Série','_NUM_NOTA'],
                        ['Chave','NR_NFE'],
                        ['Emitente','NM_PES'],
                        ['Quantidade','_QT_ORG'],
                        ['Valor','_VL_ORG'],
                      ]}
                      data={item.DOCS?.map(dco => {
                        const valid = dco.validacoes?.every(v => v.CD_STS === '-1'),
                              block = dco.validacoes?.some(v => v.CD_STS === '-3'),
                              notif = !valid && !block && dco.validacoes?.some(v => v.CD_STS === '1')

                        return {...dco,
                          _VALIDATION_ICON:
                            valid ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                            : block ? <icons.MdCancel size={16} style={{color: 'var(--danger)'}} />
                            : notif ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} /> : '',
                          _TP_DF: dco.TP_DF==='55'?'NFe':'CTe',
                          _NUM_NOTA: dco.NR_DCO+'/'+dco.CD_DCO,
                          _QT_ORG: parseFloat(dco.QT_ORG??0).toLocaleString() + 'Kg',
                          _VL_ORG: 'R$' + parseFloat(dco.VL_ORG??0).toLocaleString(),
                          _SUB: !!dco.validacoes &&
                                  <c.Table isSub
                                    columns={[
                                      ['','_VALIDATION_ICON',{},'force-fit'],
                                      ['Regra','DS_VLD'],
                                      ['No agendamento','VL_PRT'],
                                      ['No XML','VL_XML'],
                                    ]}
                                    data={dco.validacoes.map(v => ({...v,
                                      _VALIDATION_ICON:
                                        v.CD_STS === '-1' ? <icons.BsCheck2Circle size={16} style={{color: 'var(--success)'}} />
                                        : v.CD_STS === '-3' ? <icons.MdCancel size={16} style={{color: 'var(--danger)'}} />
                                        : v.CD_STS === '1' ? <icons.MdReportProblem size={16} style={{color: 'var(--warning)'}} /> : '',
                                    }))}
                                  />
                        }
                      })}
                    />
          }))
        }
      />
    </c.Frame>
    {/* VD-12707 - Samuel - 21/05/2024 */}
    {!!openDescargaModal && <p.AnexarTicketModal cota={openDescargaModal} onClose={() => {setOpenDescargaModal(null); get()}} />}
  </>)
}
