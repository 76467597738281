export const getColumns = ({ user }) => {
  const { in_uorgadm = 'F' } = user
    let shippingCompanyColumn = [];

    if(in_uorgadm === 'T') {
      shippingCompanyColumn = [['Transportador', 'shippingCompany']];
    }

    return [
    //[label, field, css, className, dataField]
      ['', 'selectable', {}, 'force-fit'],
      ['FIN', 'finished'],
      ['FAT', 'invoiced'],
      ['AFI', 'authorized'],
      ['ACO', 'approved'],
      ['DFe', 'taxDocument'],
      ['OP', 'operation'],
      ['SV', 'service'],
      ['RV', 'ticketId'],
      ['Data', 'scheduleOn'],
      ['Prazo', 'delayed'],
      ['Situação', 'situation'],
      ...shippingCompanyColumn,
      ['Veículo', 'vehicle'],
      ['Fatura', 'invoice'],
      ['Saldo CP', 'mainAmount'],
      ['Saldo CA', 'additionalAmount'],
      ['Saldo RV', 'amount'],
      ['Documentos', 'downloads', {}, 'f g1'],
      ['Ações', 'action', {}, 'force-fit'],
    ]
}
