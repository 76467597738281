import React from 'react';

import { Field } from './Field'

import './index.sass';

export const Details = ({ items }) =>{
  return (
    <div className="details-container">
      {items.map(([groupKey, groupStyle = {}, group]) => (
        <div key={groupKey}className="details-row" style={groupStyle}>
          {group.map(({ label, value}) => (
            <Field key={`${groupKey}-${label}`} label={label} value={value} />
          ))}
        </div>
      ))}
    </div>
  );
};
