import { useState, useContext, useMemo } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import { formatDate } from '../../f'


export default function TablePortalCliente({dados}) {
    const App = useContext(AppContext),
        lang = { ...App.lang.portal_Cliente, ...App.lang.gerencialQualidade, ...App.lang.global },
        icons = App.icons

    const u = App?.user
    const [loading, setLoading] = useState(false)
    const colunas = useMemo(() =>{
        return [
            [lang.ticket, 'ID_UOCC'],
            [lang.data, 'DT_PRV'], // PRT_APR
            [lang.placa, 'ID_VCL'],// PRT_APR
            [lang.modal, 'MODAL'], // VER NA RUMO
            [lang.operacao, 'OPERACAO'], // PRT_APR
            [lang.situacao, 'CD_STS'], // PRT_APR
            [lang.contrato, 'ID_CN'], // PRT_APR OU OPR_CN
            [lang.produto, 'NM_PRORED'], // PRT_APR -> PRT_MTRB -> ...
            [lang.transportador, 'NM_PES'], // PRT_APR -> PRC_PESBSC
            ...(u.in_uorg === 'T' ? [[lang.classificador, 'CLASSIFICADOR']] : []),  // NM_PES FROM SEG_USR
            [lang.peso_liquido, 'QT_ORG'], // QUALIDADE
            ['UMI', 'UMI'], // QUALIDADE
            ['IMP', 'IMP'], // QUALIDADE
            ['AVA', 'AVA'], // QUALIDADE
            ['QBD', 'QBD'], // QUALIDADE
            ['ESV', 'ESV']  // QUALIDADE
        ]
    },[u.in_uorg])

    const formattedData = useMemo(() => {
        return dados.GROUPED?.map((d) => ({
            ...d,
            DT_PRV: formatDate(d.DT_PRV, {
                input: 'YYYY-MM-DD',
                output: 'DD/MM/YYYY'
            }),
            CD_STS: d.CD_STS === 1 ? 'AGUARDANDO NA FILA'
                : d.CD_STS === 2 ? 'LIBERADO OPERAÇÃO'
                : d.CD_STS === 3 ? 'LIBERADO OPERAÇÃO'
                : d.CD_STS === 6 ? 'LIBERADO OPERAÇÃO'
                : d.CD_STS === 20 ? 'LIBERADO OPERAÇÃO'
                : d.CD_STS === -1 ? 'ENCERRADO'
                : d.CD_STS === -3 ? 'CANCELADO'
                : d.CD_STS === -9 ? 'NO SHOW'
                : d.CD_STS === 5 ? 'EM TRÂNSITO'
                : ''
        }));
    }, [dados]);

    return (
        <c.Frame flex >
            <div style={{maxWidth:'100%',width:'100%'}}>
                <c.Table loading={loading}
                    columns={colunas}
                    data={formattedData}
                />
            </div>
        </c.Frame>
    );
}

