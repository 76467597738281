import { useState, useEffect, useContext, useDeferredValue } from "react";
import { AppContext, log } from "../../App";
import * as c from "../../c";
import * as f from "../../f"

export default function AuditoriasModal({ onClose, onFinish, itinerario }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.transportadoras,
      ...App.lang.tarifas
    },
    icons = App.icons;

  const [loading, setLoading] = useState({}),
    [auditoria, setAuditoria] = useState([]);

  function getAuditoria() {
    setLoading(true)
    App.api('tarifas::getAuditoria', {
      ID_EXT: itinerario.ID_EXT,
      ID_ITINERARIO: itinerario.ID_UOCC
    }).then((r) => {
      setAuditoria(r.results);
    })
    setLoading(false);
  }

  useEffect(() => {
    getAuditoria()
  }, []);

  return (
    <c.Modal contentHeight={250} title={lang.auditoria_tarifa_itinerario + itinerario.ID_UOCC + ' ( #ref ' + itinerario.ID_EXT + ' )'}
      loading={loading} onClose={onClose}
    >
      <c.Frame autoScroll={false} >
        <c.Table
          data={auditoria.map((tp) => ({
            ...tp
          }))}
          columns={[
            [lang.usuario, "NM_USR", {}, "f"],
            [lang.data, "DT_AUD", {}, "f"],
            [lang.observacao, "DS_OBS", {}, "f"],
          ]}
          style={{ overflowY: "auto", width: "70vw", maxHeight: "70vh" }}
        />
      </c.Frame>
    </c.Modal >
  );
}

