import { useState, useEffect, useContext, useDeferredValue } from "react";
import { AppContext, log } from "../../App";
import * as c from "../../c";

export default function Natop({ setReloader }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.natop,
    },
    icons = App.icons;

  const [loading, setLoading] = useState({}),
    [busca, setBusca] = useState(""),
    [incluir, setIncluir] = useState(null),
    [Natops, setNatops] = useState([]),
    [tipoNatop, setTipoNatop] = useState(null),
    [tipoPreco, setTipoPreco] = useState(null),
    [lista,setLista] = useState([]),
    [data, setData] = useState();

  function getListas(){
    App.api('natop::getLista').then(r=>{
      setLista(r.results);
      })
  }

  function get() {
    setLoading(true)
    App.api('natop::getAll').then(r=>{
      setNatops(r.results);
      setLoading(false);
      })
  }

  useEffect(() => {
    setLoading(true);
    setReloader(() => get);
    get();
    getListas();
    setLoading(false);
  }, []);

  function submit() {
    return App.api("natop::updateNatop", {
      ...data,
    }).then((r) => {
      get();
      return r.status;
    });
  }

  return (
    <>
      <c.Frame
        title={"NATOP"}
        loading={loading}
        actions={{
          add: () => (setIncluir(true),setData({})),
        }}
        control={
          <>
            <div className="f f-row w100 center">
              <c.Input
                className="w100"
                placeholder={lang.busca}
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                clearable
              />
              <button
                style={{
                  margin: "0px 10px 0px 10px",
                  minWidth: "9%",
                  padding: "9px",
                }}
                onClick={() => setTipoNatop(true)}
              >
                <icons.BsReverseListColumnsReverse />
                {lang.tipo_natop}
              </button>
              <button
                style={{ minWidth: "9%", padding: "9px" }}
                onClick={() => setTipoPreco(true)}
              >
                <icons.FaMoneyCheckAlt />
                {lang.tipo_preco}
              </button>
            </div>
          </>
        }
      >
        {incluir && (
          <>
            <div className="g1 f f-row">
              <c.Input value={data?.DS_NATOP??''} onChange={e => setData({...data, DS_NATOP: e.target.value})} label={lang.descricao_natop} required style={{width: '11%'}}/>
              <c.Input value={data?.DS_NATOPRED??''} onChange={e => setData({...data, DS_NATOPRED: e.target.value})} label={lang.descricao_red} required style={{width: '26.3%'}}/>
              <c.Select value={data?.ID_TNATOP??''} onChange={v=>setData({...data, ID_TNATOP: v.value})}
                options={lista['tipos']}
                label={lang.tipo_natop} searchable style={{width: '22%'}}/>
                <c.Select value={data?.ID_TPCO??''} onChange={v=>setData({...data, ID_TPCO: v.value})}
                options={lista['precos']}
                label={lang.tipo_preco} searchable style={{width: '33.7%'}}/>
              <div className="f center">
              <c.IconButton  style={{fontSize: '20px', color: 'var(--' + (!!data?.DS_NATOP && !!data?.DS_NATOPRED ? 'success' : 'colorAlpha') + ')'}} disabled={!data?.DS_NATOP || !data?.DS_NATOPRED} onClick={()=> (setIncluir(false), submit(), get())}><icons.BsCheckSquareFill /></c.IconButton>
              <c.IconButton  style={{fontSize: '20px', color: 'var(--danger)'}} onClick={()=> (setIncluir(false), setData({}))}><icons.BsXSquareFill /></c.IconButton>

              </div>
            </div>
          </>
        )}
        <c.Table
          data={Natops.map((n) => ({...n,
          actions:<c.IconButton  onClick={() => (setData({...n}), setIncluir(true))}>
          <icons.BsFillPencilFill />
        </c.IconButton>
          }))}
          columns={[
            ["#", "ID_NATOP", {}, "f center"],
            [lang.descricao, "DS_NATOP", {}, "f"],
            [lang.descricao_red, "DS_NATOPRED", {}, "f"],
            [lang.tipo_natop, "DS_TNATOP", {}, "f"], // Select
            [lang.tipo_preco, "DS_TPCO", {}, "f"], // Select
            [lang.acao, "actions", {}, "f center"],
          ]}
          search={busca}
        />
      </c.Frame>
      {tipoNatop && <TipoNatopModal onClose={() => setTipoNatop(null)} />}
      {tipoPreco && <TipoPrecoModal onClose={() => setTipoPreco(null)} />}
    </>
  );
}

function TipoNatopModal({ onClose }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.transportadoras,
      ...App.lang.natop,
    },
    icons = App.icons;

  const [loading, setLoading] = useState({}),
    [busca, setBusca] = useState(""),
    [incluir, setIncluir] = useState(null),
    [editing, setEditing] = useState(null),
    [NatopsTipo, setNatopsTipo] = useState([]),
    [tipoNatopFilter, setTipoNatopFilter] = useState(false),
    [tipoNatopBusca, setTipoNatopBusca] = useState(""),
    [data, setData] = useState({});

  function get() {
    setLoading(true)
    App.api('natop::getTipos').then(r=>{
      setNatopsTipo(r.results);
      setLoading(false);
      })
  }

  useEffect(() => {
    get();
  }, []);

  function submit() {
    return App.api("natop::updateTipos", {
      ...data,
    }).then((r) => {
      get();
      return r.status;
    });
  }

  return (
    <c.Modal contentHeight={150} loading={loading} title={lang.tipo_natop} onClose={onClose}>
      <div className="w100">
        <c.Frame
          autoScroll={false}
          actions={{
            add: () => (setData([]), setIncluir(true)),
            filter: {
              onClick: () => setTipoNatopFilter(!tipoNatopFilter),
              value: tipoNatopFilter,
            },
          }}
          control={
            <c.Input
              placeholder={lang.busca}
              value={tipoNatopBusca}
              onChange={(e) => setTipoNatopBusca(e.target.value)}
            />
          }
        >
          {incluir && (
            <div className="g1 f f-row">
              <c.Input
                className="w50"
                value={data.DS_TNATOP ?? ""}
                onChange={(e) =>
                  setData({ ...data, DS_TNATOP: e.target.value })
                }
                label={lang.descricao_tipoNatop}
                required
              />
              <c.Select
                className="w50"
                value={data.TP_MVT ?? ""}
                options={[
                  { value: "E", label: lang.entrada },
                  { value: "S", label: lang.saida },
                ]}
                onChange={(e) =>
                  setData({ ...data, TP_MVT: e.value })
                }
                label={lang.tipoMovimentacao}
                required
              />
              <div className="f center">
                <c.IconButton  style={{fontSize: '20px', color: 'var(--' + (!!data?.TP_MVT && !!data?.DS_TNATOP ? 'success' : 'colorAlpha') + ')'}} disabled={!data?.TP_MVT || !data?.DS_TNATOP} onClick={()=> (setIncluir(false), submit(), get())}><icons.BsCheckSquareFill /></c.IconButton>
                <c.IconButton  style={{fontSize: '20px', color: 'var(--danger)'}} onClick={()=> (setIncluir(false), setData({}))}><icons.BsXSquareFill /></c.IconButton>
              </div>
            </div>
          )}
          <c.Table
            data={NatopsTipo.map((d) => ({
              ...d,
              actions:<c.IconButton  onClick={() => (setData({...d}), setIncluir(true))}>
          <icons.BsFillPencilFill />
        </c.IconButton>
            }))}
            columns={[
              ["#", "ID_TNATOP", {}, "f center"],
              [lang.descricao, "DS_TNATOP", {}, "f center"],
              [lang.tipo_movimento, "TP_MVT", {}, "f center"],
              [lang.acao, "actions", {}, "f center"],
            ]}
            search={tipoNatopBusca}
            showFilters={tipoNatopFilter}
            style={{ overflowY: "auto", width: "70vw", maxHeight: "70vh" }}
          />
        </c.Frame>
      </div>
    </c.Modal>
  );
}

function TipoPrecoModal({ onClose }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.transportadoras,
      ...App.lang.natop,
    },
    icons = App.icons;

  const [loading, setLoading] = useState({}),
    [busca, setBusca] = useState(""),
    [incluir, setIncluir] = useState(null),
    [editing, setEditing] = useState(null),
    [setPrecos, setTipoPrecos] = useState([]),
    [tipoPrecoFilter, setTipoPrecoFilter] = useState(false),
    [tipoPrecoBusca, setTipoPrecoBusca] = useState(""),
    [data, setData] = useState({});

  function get() {
    setLoading(true)
    App.api('natop::getPrecos').then(r=>{
      setTipoPrecos(r.results);
      setLoading(false);
      })
  }

  useEffect(() => {
    get();
  }, []);

  function submit() {
    return App.api("natop::updatePrecos", {
      ...data,
    }).then((r) => {
      get();
      return r.status;
    });
  }

  return (
    <c.Modal contentHeight={150} title={lang.tipo_preco} loading={loading} onClose={onClose}>
      <div className="w100">
        <c.Frame
          autoScroll={false}
          actions={{
            add: () => (setData([]), setIncluir(true)),
            filter: {
              onClick: () => setTipoPrecoFilter(!tipoPrecoFilter),
              value: tipoPrecoFilter,
            },
          }}
          control={
            <c.Input
              placeholder={lang.busca}
              value={tipoPrecoBusca}
              onChange={(e) => setTipoPrecoBusca(e.target.value)}
            />
          }
        >
          {incluir && (
            <div className="g1 f f-row">
              <c.Input
                className="f1"
                value={data.DS_TPCO ?? ""}
                onChange={(e) => setData({ ...data, DS_TPCO: e.target.value })}
                label={lang.descricao_precoNatop}
                required
              />
              <c.Input
                className="f3"
                value={data.DS_TPCORED ?? ""}
                onChange={(e) =>
                  setData({ ...data, DS_TPCORED: e.target.value })
                }
                label={lang.descricao_precoNatopRed}
                required
              />
              <div className="f f-row">
              <c.IconButton  style={{fontSize: '20px', color: 'var(--' + (!!data?.DS_TPCO && !!data?.DS_TPCORED ? 'success' : 'colorAlpha') + ')'}} disabled={!data?.DS_TPCO || !data?.DS_TPCORED} onClick={()=> (setIncluir(false), submit(), get())}><icons.BsCheckSquareFill /></c.IconButton>
              <c.IconButton  style={{fontSize: '20px', color: 'var(--danger)'}} onClick={()=> (setIncluir(false), setData({}))}><icons.BsXSquareFill /></c.IconButton>

              </div>
            </div>
          )}
          <c.Table
            data={setPrecos.map((tp) => ({
              ...tp,
              actions: (
                <c.IconButton
                  onClick={() => (setData({ ...tp }), setIncluir(true))}
                >
                  <icons.BsFillPencilFill />
                </c.IconButton>
              ),
            }))}
            columns={[
              ["#", "ID_TPCO", {}, "f center"],
              [lang.tipo_moeda, "ID_MOEDA", {}, "f center"],
              [lang.descricao, "DS_TPCO", {}, "f center"],
              [lang.descricao_red, "DS_TPCORED", {}, "f center"],
              [lang.acao, "actions", {}, "f center"],
            ]}
            search={tipoPrecoBusca}
            showFilters={tipoPrecoFilter}
            style={{ overflowY: "auto", width: "70vw", maxHeight: "70vh" }}
          />
        </c.Frame>
      </div>
    </c.Modal>
  );
}
