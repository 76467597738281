import { useCallback, useState } from "react"

import { Modal } from "../../../../../c"
import { ObservacaoPartial } from "../../Partials/ObservacaoPartial"
import { handleSubmitObservacaoModal as handleSubmit } from "./UseCases/handleSubmitObservacaoModal"

export const ObservacaoModal = ({ quotation, onClose, App, onFinish }) => {
  const [data,setData] = useState(quotation)

  const submit = useCallback(() => {
    handleSubmit({
      data,
      api: App.api
    }).then(r => {
      onFinish()
    })
  },[data, App])

  return (
    <Modal
      onClose={onClose}
      onFinish={submit}
      title={`Editar observação - ${data.id}`}
    >
      <ObservacaoPartial data={data} setData={setData}/>
    </Modal>
  )
}
