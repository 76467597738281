import { formatNumber } from "../../../../../../f"

const FIELDS = [
  [
    [ 'invoiceNumber', 'invoice_number', 'number', 'w50' ],
    [ 'dueDate', 'due_date', 'date', 'w50' ],
  ],
  [
    [ 'calculated', 'calculated_amount', 'float', 'w1-3', true ],
    [ 'amount', 'amount', 'float', 'w1-3' ],
    [ 'discount', 'discount', 'float', 'w1-3' ],
  ]
]

export const buildFields = (data, lang, handleChangeField) => {
  return FIELDS.map((group) => {
    const keys = []

    const fields = group.map(([field, translateField, type, className, disabled = false]) => {
      const value = data[field]
      const formattedValue = type === 'float'
                           ? formatNumber(value, 2)
                           : value

      keys.push(field)

      return {
        className,
        disabled,
        error: !(value ?? false),
        key: field,
        label: lang[translateField],
        onChange: handleChangeField(field, type),
        type,
        value: formattedValue,
      }
    })

    return [keys.join('-'), fields]
  })
}
