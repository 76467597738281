import { Checkbox } from "../../../../c"
import { DownloadsTitleColumn } from "../columns/DownloadsTitleColumn"
import { getColumns } from "./getColumns"

export const transformToColumn = (props) => {
  const { filteredRecords, lang, onToggle, selectedRecords, user } = props
  const rawColumns = getColumns({ lang, user})

  const columns = rawColumns.map(([label, field, style, className, dataField]) => {
    let newLabel = label
    let key = field

    if(field === 'downloads') newLabel = <DownloadsTitleColumn label={label} />
    // removendo o checkboxe da coluna de titulos por enquanto, até resolver op probelma de
    // cache do componente Table
    if(field === 'selectable') {
      const allSelected = selectedRecords.length === filteredRecords.length && filteredRecords.length > 0
      key = `${key}-${allSelected}-all${filteredRecords.length}-selected${selectedRecords.length}`
      newLabel = (
        <Checkbox key={key} checked={allSelected} className="column-field" onToggle={onToggle} />
      )
    }

    return [newLabel, field, style, className, dataField, key]
  })

  return columns
}
