export const selectItemInList = ({ item, selected, list }) => {
  const {
    id: {
      dtPrt: rowDtPrt,
      idPrt: rowIdPrt,
      idUprt: rowIdUprt,
      idUocc: rowIdUocc
    }
  } = item

  return list.map((item) => {
    const {
      id: {
        dtPrt: itemDtPrt,
        idPrt: itemIdPrt,
        idUprt: itemIdUprt,
        idUocc: itemIdUocc }
    } = item

    if(
      itemDtPrt === rowDtPrt
      && itemIdPrt === rowIdPrt
      && itemIdUprt === rowIdUprt
      && itemIdUocc === rowIdUocc
    ) {
      return { ...item, selected }
    }

    return item
  })
}
