import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import { AppContext } from '../../../../../App'
import { Modal, Slider, Span, Switch, Table } from "../../../../../c"
import { formatCEP, formatCnpj } from "../../../../../f"

export const DistribuidaModal = ({ cota, onClose }) => {
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.distribuir_cotas}

  const [cotaDet, setCotaDet] = useState({})

  const get = useCallback(async () => {
    const { results } = await App.api(
      'cotas::consultaDistribuida',
      { id_cota: cota.ID_COTA }
    )

    setCotaDet(results)
  }, [App, cota.ID_COTA])

  const lstCota = useMemo(() => {
    return cotaDet.lst_cota?.map((list) => {

      const lst_trp = list.LST_TRP.map(trp=>({...trp,
        SELECTION: (
          <Switch
            checked={trp.IN_TRP === 'T'}
            onChange={async ({ target }) => {
              await App.api('cotas::incluirTransportadora', {
                id_cota: list.ID_COTA,
                nr_cnpjtrp: trp.NR_CNPJTRP,
                in_insert: target.checked
              })

              await get()
            }} />
        ),
        CNPJ: formatCnpj(trp.NR_CNPJTRP),
        ENDERECO: trp.NM_LGD + ', ' + trp.NR_LGD,
        MUNICIPIO_UF: trp.NM_LOC + '/' + trp.ID_UF,
        CEP: formatCEP(trp.CD_CEP)
      }))

      return {
        ...list,
        RECUPERAR: list.TP_COTA === 'FOB'
          && list.QT_FOB!==list.QT_CONSUMO
          && list.QT_FOB!=='0'
          && (
            <button
              onClick={async () => {
                const { results } = await  App.api('cotas::recuperar',{
                  id_cota: list.ID_COTA
                })
                App.toast.success(lang.foram_recuperada.replace('%d', results.qt_reduzida))
              }}
            >
              {lang.recuperar_nao_consumida.replace('%d', list.QT_DISPONIVEL)}
            </button>
          ),
        DISTRIBUIDA:(
          <Slider
            max={parseInt(list.QT_COTA??0) + parseInt(cotaDet.qt_disponivel)}
            min={parseInt(list.QT_MINIMO)}
            onChange={async ({ target }) => {
              await App.api('cotas::alterarDistribuida', {
                id_cota: list.ID_COTA,
                qt_cota: target.value
              })

              await get()
            }}
            value={parseInt(list.QT_COTA??0)}
          />
        ),
        LST_TRP: lst_trp,
        sub: (
          <Table
            isSub={true}
            data={list.TP_COTA !== "FOB" ? lst_trp : []}
            columns={[
              [lang.selection, 'SELECTION'],
              [lang.cnpj, 'CNPJ'],
              [lang.nome, 'NM_TRP'],
              [lang.endereco, 'ENDERECO'],
              [lang.cep, 'CEP'],
              [lang.municipio_uf, 'MUNICIPIO_UF'],
            ]}
          />
        )
      }
    })
  },[App, cotaDet.lst_cota, cotaDet.qt_disponivel, get, lang.cep, lang.cnpj, lang.endereco, lang.foram_recuperada, lang.municipio_uf, lang.nome, lang.recuperar_nao_consumida, lang.selection])

  useEffect(() => {
    get()
  }, [])

  return(
    <Modal
      largeWidth
      cancelText={lang.fechar}
      onClose={onClose}
      title={lang.cota_distribuida}
    >
      <div className="f g1 f-between">
        <Span value={cota.DT_COTA} label={lang.data} />
        <Span value={cota.DS_TRN} label={lang.turno} />
        <Span value={cota.DS_OPRPRT} label={lang.operacao} />
      </div>

      <div className="f g1 f-between">
        <Span value={cota.NM_PRO} label={lang.produto} />
        <Span value={cotaDet.qt_cota} label={lang.cota} />
        <Span value={cotaDet.qt_consumo} label={lang.distribuida} />
        <Span value={cotaDet.qt_disponivel} label={lang.saldo_distribuir} />
        <Span value={cota.QT_CONSUMO} label={lang.consumida_agendada} />
      </div>

      <Table
        data={lstCota}
        selectColumns={false}
        columns={[
          [lang.tipo_cota, 'TP_COTA'],
          [lang.origem, 'NM_PESORI'],
          [lang.distribuida, 'DISTRIBUIDA', {}, 'f g1 center-v'],
          [lang.fob_redist, 'QT_FOB', {}, 'f g1 center'],
          [lang.consumida_agendada, 'QT_CONSUMO', {}, 'f g1 center'],
          ['', 'RECUPERAR']
        ]}
        hasSub='sub'
      />
    </Modal>
  )
}
