import {useCallback } from "react";

import { Modal } from "../../../../../c"

import { handleCancel } from "./UseCases/handleCancel";

export const CancelModal = ({ quotation, onClose, App, onFinish }) => {
  const onFinishCancel = useCallback(() => {
    handleCancel({ data: quotation, api: App.api}).then(r => {
      onFinish()
    })
    onClose()
  },[quotation,App,onClose])

  return (
    <>
      <Modal title={'Deseja cancelar a cotação?'}
        onClose= {() => onClose()}
        onFinish={() => onFinishCancel()}
      >
      </Modal>
    </>
  )

}
