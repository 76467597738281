import { useState, useEffect, useContext } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import * as f from '../../f'

/*
Classificar por produto
*/

export default function CardClassificacao({ dados }) {
    const App = useContext(AppContext),
        lang = { ...App.lang.gerencialQualidade, ...App.lang.global },
        icons = App.icons

    /*
        Diferente do Dashboard Qualidade, as médias de qualidade devem agregar as classificações dos veículos num único card, sem distinção entre Em operação / Encerrado
    */
    return (<>
            { dados?.QUALITY?.length > 0 &&
                <c.Frame className="CardClassificacao" flex >
                    <div className="cards">
                        {dados?.QUALITY?.map((e, i) => {
                            let n = e.nome.toUpperCase()
                            const MIcon = () =>
                                n.includes("UMIDADE") ? <icons.BsDropletHalf size={24} />
                                    : (n.includes("INSETO") ? <icons.MdPestControl size={24} />
                                        : (n.includes("IMPUREZA") ? <icons.MdCoronavirus size={24} />
                                            : (n.includes("ESVERDEADOS") ? <icons.MdGrass size={24} />
                                                : (n.includes("AVARIADO") ? <icons.GiEdgeCrack size={24} />
                                                    : (n.includes("ESTRANHO") ? <icons.GiFragmentedMeteor size={24} />
                                                        : (n.includes("QUEBRADO") ? <icons.GiBrokenPottery size={24} />
                                                            : (n.includes("VEÍCULOS") ? <icons.FaTruck size={24} />
                                                                : (n.includes("VOLUME") || n.includes("RETENÇÃO") || n.includes("DESCONTO") || n.includes("PESO") ? <icons.GiWeight size={24} />
                                                                    : <icons.GiBrokenPottery size={24} />
                                                                ))))))))

                            return <div key={i} className={'card NORMAL'}>
                                <div className="title"><MIcon />{e.nome}</div>
                                <div className="content">
                                    <div className="min"><span>{e.min}</span><span>Min</span></div>
                                    <div className="med"><span>{e.avg}</span><span>Méd</span></div>
                                    <div className="max"><span>{e.max}</span><span>Max</span></div>
                                </div>
                                <div className="footer">Anormalidade <b>{e.anormalidade ?? 0}</b> (<icons.FaTruck /> {e.anormais ?? 0})</div>
                            </div>
                        })}
                    </div>
                </c.Frame>
            }
        </>
    );
}
