import './styles.sass'

export const Icon = ({
  className,
  color,
  icon: IconComponent,
  label: initialLabel = null,
  size = 32,
}) => {
  const label = (initialLabel !== null && initialLabel !== undefined)
              ? `${initialLabel}`
              : initialLabel
  return (
    <div className={`icon-container ${className}`}>
      <IconComponent size={size} style={{ color }} />
      {label && <span className='icon-label'>{label}</span>}
    </div>
  )
}
