import React, { useCallback, useContext, useMemo, useState } from "react"

import { AppContext } from "../../../../../App"
import { Modal } from "../../../../../c"

import { ServiceStep } from "./ServiceStep"
import { InvoiceStep } from "./InvoiceStep"
import { fillDataFields } from "./useCases/fillDataFields"
import { persistInvoice } from "./useCases/persistInvoice"

import './styles.sass'
import { validateInvoice } from "./useCases/validateInvoice"

export const InvoicingModal = ({ visible = false, columns, rows, onClose, onFinish }) => {
  const App = useContext(AppContext)
  const lang = useMemo(() => {
    const {
      errors,
      freight_controllership: { invoicing_modal },
      global
    } = App.lang

    return { ...global, ...errors, ...invoicing_modal }
  }, [App.lang])

  const records = useMemo(() => {
    return rows.map(({ record }) => record)
  }, [rows])

  const [loading, setLoading] = useState(null)
  const [step, setStep] = useState(0)
  const [fields, setFields] = useState(fillDataFields(records))

  const STEPS = useMemo(() => {
    const invoiceValid = validateInvoice({
      fields: { ...fields, user: App.user },
      lang,
      records
    }).length === 0

    return [
      { label: lang.step_service, validate: true },
      { label: lang.step_invoice, validate: invoiceValid },
    ]
  }, [App.user, fields, lang, records])

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const validations = validateInvoice({
        fields: { ...fields, user: App.user },
        lang,
        records
      })
      const { errors: { pending_issues } } = lang

      if(validations.length > 0) {
        App.toast.error(
          <div>
            {pending_issues}
            <ul>
              {validations.map((validation) => <li>{validation}</li>)}
            </ul>
          </div>
        )

        return
      }

      await persistInvoice({ fields, App, records })

      if (onClose) onClose()
      if (onFinish) onFinish()
    } finally {
      setLoading(false)
    }
  }, [App, fields, lang, onClose, onFinish, records])

  const handleChangeStep = useCallback((newStep) => {
    setStep(newStep)
  }, [])

  const handleChangeForm = useCallback((field) => {
    setFields((prevState) => ({ ...prevState, ...field }))
  }, [])

  const validated = useMemo(() =>{
    STEPS.every(({ validate }) => validate)
  }, [STEPS])

  if(!visible) return <></>

  return(
    <Modal
      largeWidth
      title={lang.modal_title}
      steps={STEPS}
      onStepChange={handleChangeStep}
      loading={loading}
      validate={validated}
      contentHight={400}
      onFinish={handleSubmit}
      onClose={onClose}
    >
      {/* Serviços */}
      {step === 0 && <ServiceStep columns={columns} rows={rows} step={step} />}

      {/* Fatura */}
      {step === 1 && (
        <InvoiceStep dataFields={fields} onChange={handleChangeForm} step={step} />
      )}
    </Modal>
  )
}
