export const handleSubmitObservacaoModal = async ({api,data}) =>{
  return api('contratos::alterarObs',{
    sub: '',
    ID_CN: data.id,
    bid:true,
    ID_OBS: data.idObservation,
    DS_OBS: data.observations,
    ID_UND:data.iDUCN,
    DT_CN:data.dTCN ,
    NR_CN:data.nRCN ,
  })
}
