import { checkArrayType } from "../../../f"

export const processColumns = (data, columns) => {
  return columns.map(([label, field, style, klass, dbField, key]) => {
    const dataField = dbField ?? field // Campo usado para ordenação com o valor que vem do banco de dados
    const _typeof = checkArrayType(data, dataField)
    const sortable = [
      'number',
      'string',
      'date'
    ].indexOf(_typeof)>=0 && (klass?.sortable??true)

    return {
      class: klass,
      dataField,
      field,
      key: key ?? dataField ?? field,
      label,
      sortable,
      style,
      typeof: _typeof,
    }
  })
}
