import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as p from './'
import * as f from '../f'

export default function IntegracaoAppa({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.estoques},
        icons = App.icons

  const [data, setData] = useState([]),
        [loading, setLoading] = useState(null),
        [distribuirCotaAppaModal, setDistribuirCotaAppaModal] = useState(null),
        [timer, setTimer] = useState(0)

  async function get(fromAppa = false){
    setLoading( fromAppa ? 'fromAppa' : true )

    const r = (await App.api('IntegracaoAppa::getDisponibilidadesFull', {fromAppa}))
    setData( r.results ?? [] )

    if( fromAppa ) setTimer(30)

    setLoading(false)
  }

  useEffect(() => { get()
    setReloader(() => get)
  }, [])

  useEffect(() => {
    if( timer > 0 ) setTimeout(() => setTimer(timer - 1), 1000)
  }, [timer])

  return(<>
    {!!loading
      ? <App.LoadingComponent msg={
          loading === 'fromAppa' ? <>Comunicando-se com o APPA <br /> Por favor aguarde...</> : ''
        } />
      : <div className="f g2 f-column center w100">
          <button disabled={timer > 0} onClick={()=>get(true)} style={{fontSize: 20}}>Consultar APPA {!!timer && timer + 's'}</button>
        </div>
    }

    {!loading && !!data.length &&
      <div className="f g2 f-wrap">
        {data.map((terminal, terminal_index) =>
          <c.Frame key={terminal_index} title={terminal.name} className="w50">
            {terminal.produtos.map((produto, produto_index) =>
              <div key={produto_index} className="f g2 f-column">
                <c.Table noFooter
                  columns={[
                    [produto.name, 'TIPO'],
                    ['Ontem', 'ONTEM', {}, 'f center-h force-fit'],
                    ['Hoje', 'HOJE', {}, 'f center-h force-fit'],
                    ['Amanhã', 'AMANHA', {}, 'f center-h force-fit'],
                    [App.moment().add(2, 'days').format('DD/MM'), 'DEPOIS', {}, 'f center-h force-fit'],
                  ]}
                  data={
                    produto.disponibilidades.map(disponibilidade => ({...disponibilidade,
                      ONTEM:  disponibilidade.ONTEM ?? disponibilidade[App.moment().subtract(1, 'days').format('YYYY-MM-DD')] ?? 0,
                      HOJE:   disponibilidade[App.moment().format('YYYY-MM-DD')] ?? 0,
                      AMANHA: disponibilidade[App.moment().add(1, 'days').format('YYYY-MM-DD')] ?? 0,
                      DEPOIS: disponibilidade[App.moment().add(2, 'days').format('YYYY-MM-DD')] ?? 0,
                    })).map((disponibilidade, disponibilidade_id) => ({...disponibilidade,
                      HOJE: disponibilidade_id !== 0 ? disponibilidade.HOJE
                              : <button disabled={ Math.max(0, produto.disponibilidades[1][App.moment().format('YYYY-MM-DD')] ) >= disponibilidade.HOJE }
                                  onClick={() => setDistribuirCotaAppaModal({
                                    terminal, produto, date: App.moment(), appa_qt: disponibilidade.HOJE,
                                  })}>
                                  {disponibilidade.HOJE }
                                </button>,
                      AMANHA: disponibilidade_id !== 0 ? disponibilidade.AMANHA
                              : <button disabled={ Math.max(0, produto.disponibilidades[1][App.moment().add(1, 'days').format('YYYY-MM-DD')] ) >= disponibilidade.AMANHA}
                                  onClick={() => setDistribuirCotaAppaModal({
                                    terminal, produto, date: App.moment().add(1, 'days'), appa_qt: disponibilidade.AMANHA,
                                  })}>
                                  {disponibilidade.AMANHA }
                                </button>,
                      DEPOIS: disponibilidade_id !== 0 ? disponibilidade.DEPOIS
                              : <button disabled={ Math.max(0, produto.disponibilidades[1][App.moment().add(2, 'days').format('YYYY-MM-DD')] ) >= disponibilidade.DEPOIS}
                                  onClick={() => setDistribuirCotaAppaModal({
                                    terminal, produto, date: App.moment().add(2, 'days'), appa_qt: disponibilidade.DEPOIS,
                                  })}>
                                  {disponibilidade.DEPOIS }
                                </button>,
                    }))
                  }
                />
              </div>
            )}
          </c.Frame>
        )}
      </div>
    }

    {distribuirCotaAppaModal &&
      <DistribuirCotaAppaModal data={distribuirCotaAppaModal}
        onClose={() => {setDistribuirCotaAppaModal(null); get()}}
      />}
  </>)
}

function DistribuirCotaAppaModal({data, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.distribuicaoFOB}

  const {terminal, produto, date, appa_qt} = data

  const [loading, setLoading] = useState(null),
        [listas, setListas] = useState([]),
        [distribuidos, setDistribuidos] = useState([]),
        [cota, setCota] = useState({
          TP_OPRPRT: 'E',
          ID_PRO: produto.ID_PRO,
          DT_COTA: App.moment().format('YYYY-MM-DD'),
        })

  async function submit(){ setLoading(true)
    return (await App.api('IntegracaoAppa::saveCota', {
      ...cota,
      terminal: terminal.cnpj,
      produto_appa: produto.id,
      date_appa: date.format('YYYY-MM-DD'),
    })).status
  }

  async function get(){
    setLoading(true)

    setListas( (await App.api('fob::getListas')).results )
    setDistribuidos( (await App.api('IntegracaoAppa::getDistribuidos', {
      terminal: terminal.cnpj,
      produto: produto.id,
      date: date.format('YYYY-MM-DD'),
    })).results )

    setLoading(false)
  }

  useEffect(() => {
    get()
  }, [])

  return(
    <c.Modal title={"Distribuir cota APPA - " + terminal.name} onClose={onClose} loading={loading}
      onFinish={submit} validate={!!cota.NR_CNPJUND && !!cota.NR_CNPJPRC && !!cota.ID_PRO && !!cota.ID_TRN && !!cota.QT_COTA}
    >
      <div className="f g1">
        <c.Select label={lang.unidade} searchable value={cota.NR_CNPJUND} error={!cota.NR_CNPJUND}
          onChange={v=>setCota({...cota, NR_CNPJUND: v.value})} options={listas.unidades} className="f1"
        />
        <c.Select label={lang.grupo_comercial} searchable value={cota.NR_CNPJPRC} error={!cota.NR_CNPJPRC}
          onChange={v=>setCota({...cota, NR_CNPJPRC: v.value})} options={listas.parceiros} className="f1"
        />
      </div>

      <div className="f g1 center-v">
        <c.Select label={lang.produto + ' Vertti'} value={cota.ID_PRO} error={!cota.ID_PRO}
          onChange={v=>setCota({...cota, ID_PRO: v.value})} options={listas.produtos} searchable className="f1"
        />
        {'<=>'}
        <c.Span label={lang.produto + ' APPA'} value={produto.name} className="f1 ellipsis" />
      </div>

      <div className="f g1 f-wrap">
        <c.Select label={lang.turno} className="f2" value={cota.ID_TRN} options={listas.turnos}
          onChange={v=>setCota({...cota, ID_TRN: v.value})} error={!cota.ID_TRN}
        />
        <c.Input label={lang.quantidade_cota} type="number" error={!f.validateQuantidade(cota.QT_COTA)} value={f.toFloat(cota.QT_COTA)} className="f2"
          onChange={e => setCota({...cota,
            QT_COTA: Math.min( Math.abs(e.target.value), appa_qt - distribuidos.reduce((acc, v) => acc + parseInt(v.QT_COTA), 0))
          })}
        />
      </div>

      <div className="f g1 center-v">
        {/* <c.Input label={lang.data + ' Vertti'} type='date' value={cota.DT_COTA} className="f1"
          onChange={e => { const dt = App.moment( e.target.value ), hj = App.moment()
            setCota({...cota,
              DT_COTA: (dt.isAfter(date) ? date : dt.isBefore(hj) ? hj : dt).format('YYYY-MM-DD')
            })
          }}
        /> */}
        {/* {'<=>'} */}
        <c.Span label={lang.data + ' APPA'} className="f1" type='date' value={date.format('DD/MM/YYYY')} />
      </div>

      <c.Frame title={"Já distribuídas"}>
        <c.Table data={distribuidos} noFooter
          columns={[
            ['Unidade','UNIDADE'],
            ['Parceiro','PARCEIRO'],
            ['Produto','PRODUTO'],
            ['Turno','TURNO'],
            ['Quantidade','QT_COTA'],
          ]}
        />
      </c.Frame>
    </c.Modal>
  )
}
