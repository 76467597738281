import './assets.sass'

export default function TruckCharging({load=0}){
  const r = Math.max( 0, ( Math.min(100, load) - 20)*220/80 ),
        g = 200 - Math.max( 0, ( Math.min(100, load) - 80)*255/20 ),
        b = 20

  const darkfactor = 0.7,
        grayTone = 220

  const style = {
    farois: {
      width: '58%',
      marginBottom: -3,
      background: 'rgb(20, 110, 150)',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      padding: '2px 10px',
      // zIndex: 1
    },
    farol: {
      background: 'radial-gradient(rgb(250,200,50), yellow)',
      height: 5,
      borderRadius: '50%',
      width: 15,
      border: '1px solid yellow',
    },
    cavalo: {
      width: '100%',
    },
    retrovisor: {
      height: 5,
      width: 15,
      background: 'rgb(20, 110, 150)',
      margin: '10px -10px',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    cabine: {
      zIndex: 1,
      background: 'linear-gradient(90deg, rgb(20, 110, 150), rgb(100, 200, 240) 70%, rgb(100, 200, 240), rgb(20, 110, 150))',
      borderTopLeftRadius: '10px 100%',
      borderTopRightRadius: '10px 100%',
      // border: '1px solid rgb(20, 110, 150)',
      width: '70%',
      height: 80,
      position: 'relative',
      overflow: 'hidden',
    },
    vidro: {
      background: 'linear-gradient( 0deg, rgb(200,255,255), rgb(150,205,205) )',
      width: '70%',
      height: 10,
      margin: '0 auto',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
    vidroLeft: {
      transform: 'rotate(-90deg)',
      left: -21,
      position: 'absolute',
      top: 40,
      width: '50%',
    },
    vidroRight: {
      transform: 'rotate(90deg)',
      left: 74,
      position: 'absolute',
      top: 40,
      width: '50%',
    },
    traseira: {
      width: '100%',
      alignItems: 'center',
    },
    pneus: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '80%',
    },
    pneu: {
      width: 7,
      height: 40,
      background: 'linear-gradient(0deg, #777, #222, #222, #777)',
    },
    cacamba: {
      background: 'linear-gradient( 90deg, rgb('+(grayTone*darkfactor)+','+(grayTone*darkfactor)+','+(grayTone*darkfactor)+'), rgb('+grayTone+','+grayTone+','+grayTone+') 70%, rgb('+grayTone+','+grayTone+','+grayTone+'), rgb('+(grayTone*darkfactor)+','+(grayTone*darkfactor)+','+(grayTone*darkfactor)+') )',
      borderRadius: 10,
      border: '2px solid rgb(20, 110, 150)',
      width: '80%',
      height: 220,
      //overflow: 'hidden',
      position: 'relative',
    },
    carga: {
      background: 'linear-gradient( 90deg, rgb('+(r*darkfactor)+','+(g*darkfactor)+','+(b*darkfactor)+'), rgb('+r+','+g+','+b+') 70%, rgb('+r+','+g+','+b+'), rgb('+(r*darkfactor)+','+(g*darkfactor)+','+(b*darkfactor)+') )',
      width: '100%',
      height: Math.min(100, load) + '%',
      position: 'absolute',
      transition: '.3s ease all',
      borderRadius: 8,
    },
    percent: {
      fontWeight: 'bold',
      fontSize: 20,
      margin: 'auto',
      zIndex: 1,
      mixBlendMode: 'difference',
      color: 'white',
    }
  }

  return(
  <div className="f f-column center-v" style={{width: 150, height: 300}}>
    {/* Faróis */}
    <div className="f f-between" style={style.farois}>
      <div style={style.farol} />
      <div style={style.farol} />
    </div>

    <div style={style.cavalo} className="f center-h">
      <div style={style.retrovisor} />
      <div style={{...style.pneu, margin: '20px -4px 0 0'}} />
      <div style={style.cabine}>
        <div style={{...style.vidro, ...style.vidroLeft}} />
        <div style={style.vidro} />
        <div style={{...style.vidro, ...style.vidroRight}} />
      </div>
      <div style={{...style.pneu, margin: '20px 0 0 -4px'}} />
      <div style={style.retrovisor} />
    </div>

    <div style={style.traseira} className="f center-h">
      <div style={style.pneus}>
        <div style={style.pneu} />
        <div style={style.pneu} />
      </div>
      <div style={style.cacamba} className="f">
        <div style={style.carga} className={parseInt(load)>=100 ? 'truckChargingFull' : ''} />
        <span style={style.percent}>{parseInt(load) + '%'}</span>
      </div>
      <div style={style.pneus}>
        <div style={style.pneu} />
        <div style={style.pneu} />
      </div>
    </div>
  </div>
  )
}
