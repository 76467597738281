import { CSVLink } from "react-csv"

export const CSVDownload = ({ children, columns, data, filename }) => {
  const csvData = [columns, ...data]

  return (
    <CSVLink data={csvData} filename={filename} separator=';'>
      {children}
    </CSVLink>
  )
}
