import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'

export default function ProfileModal({onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.menu},
        icons = App.icons

  const [editing, setEditing] = useState(false),
        [editingPass, setEditingPass] = useState(false),
        [validate, setValidate] = useState(false),
        [termos, setTermos] = useState([]),
        [data, setData] = useState({
          id_login: App.user.id_login,
          id_newlogin: App.user.id_login,
          ds_email: App.user.ds_email,
          ds_newemail: App.user.ds_email,
          nr_telcel: App.user.nr_telcel,
          nr_newtelcel: App.user.nr_telcel,
        })

  useEffect(()=>{
    setValidate( !editingPass || (data.ds_nova===data.ds_nova2 && data.ds_nova?.length >= 6) )
  }, [data])

  useEffect(()=>App.api('users::consultaTermo').then(r=>setTermos(r.results.lst_termo)),[])

  return (
    <c.Modal title={<><c.Avatar alt="logo_cliente" size={48} />{lang.profile}</>} onClose={onClose} validate={validate}
      onFinish={(editing||editingPass) && (()=>editingPass ? App.api('users::setPass',{...data}) : App.api('users::edit',{...data}))}
      extraControls={[
        <button key={1} onClick={()=>setEditing(!editing)}><icons.MdEdit size={16} title={lang.editar} />{lang.editar}</button>,
        <button key={2} onClick={()=>setEditingPass(!editingPass)}><icons.MdPassword size={16} title={lang.senha} />{lang.senha}</button>,
      ]}
    >
      <div className='f f-column g1 w100 f-stretch'>
        <c.Input disabled label={lang.nome} value={App.user.nm_prc} className='w100' />
        <c.Input disabled label={lang.cnpj} value={f.formatCnpj(App.user.nr_cpfcnpj)} className='w100' />

        {!editingPass ? <>
          <c.Input disabled={!editing} label={lang.login} value={data.id_newlogin} onChange={e=>setData({...data, id_newlogin: e.target.value})} className='w100' />
          <c.Input disabled={!editing} label={lang.email} value={data.ds_newemail} onChange={e=>setData({...data, ds_newemail: e.target.value})} className='w100' />
          {editing
          ? <c.Input disabled={!editing} label={lang.telefone} value={f.formatTel(data.nr_newtelcel)} onChange={e=>setData({...data, nr_newtelcel: f.toFloat(e.target.value)})} className='w100' />
          : <c.Span label={lang.telefone} value={<c.TelefoneView value={data.nr_newtelcel} />} />}

          <c.Divider text={'Termos'} />
          {termos?.map((t,i)=><a key={i} href={t.DS_URL} target='_blank' rel="noreferrer">{t.DS_TRM}</a>)}
          </>
        :
        <>
          <c.Input label={lang.senha_atual}    type='password' value={data.ds_atual??''} onChange={e=>setData({...data, ds_atual: e.target.value})} className='w100' />
          <c.Input label={lang.senha_nova}     type='password' value={data.ds_nova??''} className='w100'
            onChange={e=>setData({...data, ds_nova: e.target.value})}  error={data.ds_nova?.length < 6} info={'A senha deve ter ao menos 6 caracteres'} />
          <c.Input label={lang.senha_confirma} type='password' value={data.ds_nova2??''} className='w100'
            onChange={e=>setData({...data, ds_nova2: e.target.value})} error={data.ds_nova!==data.ds_nova2} info={'A senha e a confirmação devem ser iguais'} />
        </>}
      </div>
    </c.Modal>
  )
}
