import { useContext, useState, useEffect } from 'react'
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as p from '../../p'
import * as f from '../../f'

export default function FreteResumo({setReloader}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.transportador},
        icons = App.icons

  const [loading, setLoading] = useState(null),
        [data, setData] = useState([]),
        [openPDFModal, setOpenPDFModal] = useState(null)

  async function get(){
    setLoading(true)

    const __data = await App.api('ResumoFrete::get')
    const _data = __data.status ? __data.results : JSON.parse(sessionStorage.getItem('ResumoFrete::get'))
    setData( _data )
    sessionStorage.setItem('ResumoFrete::get', JSON.stringify(_data))

    setLoading(false)
  }

  useEffect(() => { get()
    setReloader(() => get)
  },[])

  return(<>
    <c.Frame title="Resumo de frete" loading={loading}>
      <c.Table columns={[
        ['Data saída','DT_SAIDA'],
        ['Veículo','ID_VCL'],
        ['NFe','NR_NF'],
        ['Valor','VL_NF'],
        ['Peso','QT_PESO'],
        ['Frete','VL_FLIQ'],
        ['Tarifa','VL_FTRF'],
        ['Pedágio','VL_PDG'],
        ['Ticket','ID_PRT'],
        ['Tarifa','VL_FTRF'],
      ]} data={
        data?.map(item => ({...item,
          _ACTION: <c.IconButton onClick={()=>setOpenPDFModal(item)}><icons.FaEye /></c.IconButton>
        }))
      } />
    </c.Frame>
  </>)
}
