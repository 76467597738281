import { Fragment, useCallback, useContext, useMemo, useState } from "react"
import Skeleton from "react-loading-skeleton"
import {
  MdClear as ClearIcon,
  MdFilterAlt as FilterIcon,
  MdSearch as SearchIcon,
} from "react-icons/md"
import {
  BsEyeFill as ShowPasswordIcon,
  BsEyeSlashFill as HidePasswordIcon,
} from "react-icons/bs"

import { AppContext } from "../../App"
import { t } from "../../f"

import { IconButton } from "../IconButton"

export const  Input = ({
  className,
  clearable,
  disabled,
  error,
  fit,
  icon,
  iconClick,
  info,
  inline,
  inputStyle,
  label,
  loading,
  max,
  maxLength,
  min,
  minLength,
  onBlur,
  onChange,
  onKeyDown,
  onSearch,
  placeholder,
  required,
  style,
  type,
  value,
  ...rest
}) => {
  const { lang } = useContext(AppContext)

  const [showPass, setShowPass] = useState(false)

  const validate = useMemo(() =>{
    return (minLength && (value?.length > minLength)) || !minLength
  }, [minLength, value?.length])

  const labelClassName = useMemo(() => {
    return [
      'text-group',
      className,
      inline ? 'inline' : '',
      !!icon ||!!clearable || type === 'password' || onSearch ? 'icon' : '',
      error || (required && !value) ? 'error' : '',
      fit ? 'fit' : '',
    ].join(' ')
  }, [className, clearable, error, fit, icon, inline, onSearch, required, type, value])

  const inputType = useMemo(() => {
    if(type === 'password' && showPass) return 'text'
    if (type === 'datetime') return 'datetime-local';

    return type || 'text'
  }, [showPass, type])

  const spanClassName = useMemo(() => {
    return [
      'info',
      !validate || error ? 'danger' : '',
    ].join(' ')
  }, [error, validate])

  const minLengthHint = useMemo(() => {
    const langCollection = lang?.components?.input ?? {}

    return t(langCollection, 'minLength', { minLength })
  }, [lang?.components?.input, minLength])

  const handleChange = useCallback((event) => {
    if(onChange) onChange(event)
  }, [onChange])

  const handleIconClickSubmit = useCallback((event) => {
    if(event.key !== 'Enter') return

    if(iconClick) iconClick()
  }, [iconClick])

  const handleClear = useCallback(() => {
    if(onChange) onChange({ target: { value : '' } })
  }, [onChange])

  const handleTogglePassword = useCallback(() =>{
    setShowPass((prevState) => !prevState)
  }, [])

  const [displayIcon, Icon, handleIconClick] = useMemo(() => {
    if(type === 'password') {
      if(showPass) return  [true, HidePasswordIcon, handleTogglePassword]

      return  [true, ShowPasswordIcon, handleTogglePassword]
    }

    if(icon === 'search' || onSearch) {
      const iconVisible = !!value
      const eventHandler = onSearch ?? iconClick

      return  [iconVisible, SearchIcon, eventHandler]
    }

    if(icon === 'filter') return  [true, FilterIcon, iconClick]

    if(clearable) return [!!value, ClearIcon, handleClear]

    return [false, Fragment, () => {}]
  }, [clearable, handleClear, handleTogglePassword, icon, iconClick, onSearch, showPass, type, value])

  return(
    <label className={labelClassName} style={style}>
      {loading
        ? <Skeleton />
        : <>
          <input
            {...rest}
            disabled={disabled}
            max={max}
            maxLength={maxLength}
            min={min}
            minLength={minLength}
            onBlur={onBlur}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onKeyPress={handleIconClickSubmit}
            placeholder={placeholder ?? label}
            style={inputStyle}
            type={inputType}
            value={value}
          />
          {displayIcon && (
            <IconButton onClick={handleIconClick}>
              <Icon size={24} />
            </IconButton>
          )}
        </>
      }

      {label && <span className="label">{label}</span> }

      {(!!info || !!minLength) && (
        <span className={spanClassName}>
          {!!info && (
            <>
              {info}
              <br />
            </>
          )}
          {!!minLength
          ? (
              <>
                <span>
                  {minLengthHint}
                </span>
                <br />
              </>
            )
          : ''}
        </span>
      )}
    </label>
  )
}
