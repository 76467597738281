import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import * as p from '../'

export default function ParceirosModal({tipo=null, onClose}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.menu},
        icons = App.icons

  const [data, setData] = useState({tipo}),
        [loading, setLoading] = useState(false)

  const ds_tipo = {PRC: lang.parceiro, TRP: lang.transportadoras, ORI: lang.origem_destino, CLT: lang.clientes}[tipo??'PRC']

  const buscaCEP = (cep) => validates.cep &&
    App.api('functions::getByCep',{cep}).then(r=>
      setData({...data,
        id_loc: r.results.ID_LOC??0,
        nm_lgd: r.results.NM_LGD??'',
        nm_bro: r.results.NM_BRO??'',
        nm_loc: r.results.NM_LOC??'',
        id_uf: r.results.ID_UFA,
      })
    )

  const validates = {
    cnpj: f.validateCNPJ(data.nr_cnpj),
    nome: data.nm_trp?.length>=5,
    cep: f.validateCEP(data.cd_cep),
    endereco: data.nm_lgd?.length>=5,
    email: !data.ds_eml||f.validateEmail(data.ds_eml),
  }

  function submit(){
    setLoading(true)
    return App.api('parceiros::save', {
      nr_cpfcnpj: data.nr_cnpj,
      nm_prc: data.nm_trp,
      nm_pes: data.nm_trp,
      in_trp: data.tipo === "TRP",
      in_ebc: data.tipo === "ORI",
      in_clt: data.tipo === "CLT",
      in_trd: data.tipo === "CLT" ? true : data.in_trd,
      cd_cep: data.cd_cep,
      id_loc: data.id_loc,
      nm_lgd: data.nm_lgd,
      nm_bro: data.nm_bro,
      nr_lgd: data.nr_lgd,
      nr_telcel: data.nr_tel,
    }).then(r=>{setLoading(false);return r.status})
  }

  return(
    <c.Modal title={lang.cadastro + ' - ' + ds_tipo} onClose={onClose} middleWidth
      validate={Object.keys(validates).every(k=>validates[k])} loading={loading}
      onFinish={submit}
    >
      <div className="f g1 w100">
        <c.Input label={lang.cnpj} error={!validates.cnpj} value={f.formatCnpj(data.nr_cnpj)} onChange={e=>setData({...data, nr_cnpj: f.strNums(e.target.value).substr(0,14)})} required info={!f.validateCNPJ(data.nr_cnpj)?'CNPJ inválido':null} />
        <c.Input label={lang.nome} error={!validates.nome} value={data.nm_trp} onChange={e=>setData({...data, nm_trp: e.target.value.toUpperCase()})} required className='f1' />

        {(!(tipo==="TRP")&&!(tipo==="CLT"))&&
          <label><c.Switch checked={data.in_trd} onChange={e=>setData({...data, in_trd: e.target.checked})} />{lang.distribuidor}</label>}
      </div>

      <div className="f g1 w100">
        <c.Input label={lang.cep} value={f.formatCEP(data.cd_cep)} onChange={e=>setData({...data, cd_cep: f.strNums(e.target.value)})} onBlur={()=>buscaCEP(data.cd_cep)} onSearch={()=>buscaCEP(data.cd_cep)} maxLength={8} required style={{maxWidth: 130}} error={!validates.cep} />
        <c.Input label={lang.endereco} value={data.nm_lgd} onChange={e=>setData({...data, nm_lgd: e.target.value.toUpperCase()})} error={!validates.endereco} required className='f1' />
        <c.Input label={lang.numero} value={data.nr_lgd} onChange={e=>setData({...data, nr_lgd: e.target.value.toUpperCase()})} style={{maxWidth: 130}} />
      </div>

      <div className="f g1 w100">
        <c.Input label={lang.bairro} value={data.nm_bro} onChange={e=>setData({...data, nm_bro: e.target.value.toUpperCase()})} className='f1' />
        <c.Input label={lang.municipio} value={data.nm_loc} disabled={true} style={{flexBasis: 300}} />
        <c.Input label={lang.uf} value={data.id_uf} disabled={true} style={{maxWidth: 80}} />
      </div>

      <div className="f g1 w100">
        <c.Input label={lang.telefone} value={f.formatTel(data.nr_tel)} onChange={e=>setData({...data, nr_tel: f.strNums(e.target.value)})} />
        <c.Input label="E-mail" value={data.ds_eml} onChange={e=>setData({...data, ds_eml: e.target.value.toUpperCase()})} className='f1' error={!validates.email} info={!validates.email?'E-mail inválido':''} />
      </div>
    </c.Modal>
  )
}
