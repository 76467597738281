export const validateInvoice = ({ fields, lang, records }) => {
  const {
    amount,
    discount,
    dueDate,
    invoiceNumber,
    pdf = '',
    user,
  } = fields

  const validations = []

  if(!dueDate) validations.push([lang.errors.due_date_blank])

  if(!user.id_usr) validations.push([lang.errors.user_blank])

  if(records.length === 0) validations.push([lang.errors.records_blank])

  if(!invoiceNumber) validations.push([lang.errors.invoice_number_blank])

  if(!discount && discount !== 0) {
    validations.push([lang.errors.discount_blank])
  } else if(discount < 0) {
    validations.push([lang.errors.negative_discount])
  }

  if(!amount && amount !== 0) {
    validations.push([lang.errors.amount_blank])
  } else if(amount <= 0) {
    validations.push([lang.errors.amount_greater_than_zero])
  }

  const [contentType, doc ] = (pdf ?? '').split(',');

  if (contentType !== 'data:application/pdf;base64' || !doc) {
    validations.push([lang.errors.document_blank])
  }

  return validations
}
