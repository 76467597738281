import React, { useState, useCallback, useMemo, useContext } from 'react';
import { Input, Modal } from '../../../../../c'; // Supondo que você tenha um componente Modal
import { MdClear as ClearIcon, MdAdd as AddIcon } from 'react-icons/md'; // Para usar o ícone de remoção
import { IoMdTrash as ClearAllIcon } from 'react-icons/io'; // Para usar o ícone de remoção
import { AppContext } from '../../../../../App';
import { t } from '../../../../../f';

export const ModalInput = ({
  collection,
  label,
  maxItems,
  onClear,
  onClose,
  onCollectionChange,
}) => {
  const { lang } = useContext(AppContext)

  const [selectedItem, setSelectedItem] = useState(-1);
  const [inputValue, setInputValue] = useState(selectedItem[0]);
  const [errorMessage, setErrorMessage] = useState('');

  const collectionLength = useMemo(() => collection.length, [collection?.length])

  const disabledAddButton = useMemo(() => { // TODO: Revisar regra de habilitar botao
    return collectionLength === maxItems && selectedItem < 0
  }, [collectionLength, maxItems, selectedItem])

  const disabledInput = useMemo(() => { // TODO: Revisar regra de habilitar input
    return collectionLength === maxItems && selectedItem < 0
  }, [collectionLength, maxItems, selectedItem])

  const handleAddItem = useCallback(() => {
    try {
      const position = selectedItem < 0
        ? collectionLength // uso a ultima posição da collection para inserção
        : selectedItem // aqui estou editando um item da collection

      if(onCollectionChange) onCollectionChange(inputValue, position)

      setSelectedItem(-1);
      setInputValue('')
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(error.message)
    }
  }, [collectionLength, inputValue, onCollectionChange, selectedItem]);

  const handleRemoveItem = useCallback((position) => {
    return () => {
      if(onCollectionChange) onCollectionChange('', position)
    }
  }, [onCollectionChange]);

  const handleEditItem = useCallback((value, position) => {
    return() => {
      setSelectedItem(position);
      setInputValue(value)
      setErrorMessage('');
    }
  }, []);

  const handleClearItems = useCallback(() => {
    if(onClear) onClear();
  }, [onClear]);

  const handleInputChange = useCallback(({ target }) => {
    setInputValue(target.value)
  }, [])

  const handleInputEnter = useCallback((event) => {
    if (event.key === 'Enter') {
      handleAddItem();
      event.preventDefault();
    }
  }, [handleAddItem])

  return (
    <Modal
      okText={t(lang.inputMultiFilter.modal.okText, 'title')}
      onFinish={onClose}
      onClose={onClose}
      displayCancel={false}
      extraControls={ (
        <button
          onClick={handleClearItems}
          className='clean danger'
          disabled={collectionLength <= 0}
        >
          <ClearAllIcon size={16} />
          {t(lang.inputMultiFilter.modal.to_clean, 'title')}
        </button>
      )}
      onRequestClose={onClose}
      title={t(lang.inputMultiFilter.modal, 'title', { label, count: collectionLength})}
    >
      <div className="modal-body">
        <span className="hint">
          <strong>
            {t(lang.inputMultiFilter.modal.hint, 'title')}:
          </strong>
          {" "}
          {t(lang.inputMultiFilter.modal.hint, 'description')}
        </span>
        <div className={`f form-group ${errorMessage ? 'error' : ''}`}>
          <Input
            error={errorMessage}
            label={label}
            disabled={disabledInput}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputEnter}
          />
          <button disabled={disabledAddButton} onClick={handleAddItem} className='primary'>
            <AddIcon size={24} />
          </button>
        </div>

        {errorMessage && <span className='error-message'>{errorMessage}</span>}

        <div className="list">
          {collectionLength > 0 && (
            <>
              <h4>{t(lang.inputMultiFilter.modal.list, 'title')}:</h4>
              <div className="filter-items">
                {collection.map((item, index) => (
                  <div key={`${item}-${index}`} className='filter-item' >
                    <span onClick={handleEditItem(item, index)}>{item}</span>
                    <button onClick={handleRemoveItem(index)} >
                      <ClearIcon size={12} />
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
