import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'
export default function DistribuicaoFOB({setReloader}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.distribuicaoFOB}
  const icons = App.icons

  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([]),
        [incluir, setIncluir] = useState(null),
        [busca, setBusca] = useState(''),
        [showFilters, setShowFilters] = useState(null)

  function get(){
    setLoading(true)
    App.api('fob::getFob').then(r=>{
      setData( r.results )
      setLoading(false)
    })
  }

  useEffect(()=>{ get()
    setReloader(()=>get)
  }, [])

  return (<>
    <c.Frame title={lang.distribuicaoFOB} noPadding loading={loading}
      actions={{
        add: ()=>setIncluir(true),
        filter: {onClick:()=>setShowFilters(!showFilters), value: showFilters}
      }}
      control={<c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable />}
    ><c.Table showFilters={showFilters} search={busca}
      columns={[
        [lang.unidade, 'DS_UORG'],
        [lang.data, 'DT_COTA'],
        [lang.contrato, 'ID_CN'],
        [lang.operacao, '_OPRPRT', {}, '', 'DS_OPRPRT'],
        [lang.cliente, 'NM_PESPRC'],
        [lang.origem, 'NM_PESORI'],
        [lang.destino, 'NM_PESDST'],
        [lang.produto, 'NM_PRO'],
        [lang.turno, 'DS_TRN'],
        [lang.cota, 'QT_COTA'],
        [lang.redistribuida, 'QT_FOB'],
        [lang.consumida_agendada, 'QT_CONSUMO'],
      ]} data={data.map(f=>({...f,
        ID_CN: f.ID_CN??'--',
        _OPRPRT: <span className="f center g1 destaque c2">
          {f.TP_OPRPRT==='E'
            ? <>{lang.carga} <icons.MdUndo size={16} /></>
            : <>{lang.descarga} <icons.MdRedo size={16} /></>}
        </span>
      }))} />
    </c.Frame>

    {/* Modal adição de cota FOB */}
    {incluir&&<AddFobModal onFinish={()=>get()} onClose={()=>{setIncluir(false)}} />}
  </>)
}

// Add FOB Modal
function AddFobModal({onFinish, onClose}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.distribuicaoFOB}

  const [listas, setListas] = useState([]),
        [cota, setCota] = useState({}),
        [loading, setLoading] = useState(false)

  function submit(){
    setLoading(true)
    return App.api('fob::distribuir', {cota}).then(r=>{
      if(r.status){
        App.toast.success(lang.cota_gerada_sucesso.replace('%d', r.results))
        setLoading(false)
        onFinish()
        return true
      }
    })
  }

  useEffect(()=>{
    setLoading(true)
    App.api('fob::getListas').then(r=>{
      setListas(r.results)
      setLoading(false)
    })
  }, [])

  return(
    <c.Modal title={lang.distribuirfob_cota_fob} onClose={onClose} loading={loading}
      onFinish={submit}
      validate={!!cota.NR_CNPJUND && !!cota.NR_CNPJPRC && !!cota.ID_PRO && !!cota.ID_TRN && !!cota.TP_OPRPRT && !!f.validateQuantidade(cota.QT_COTA) && !!cota.DT_INI && !!cota.DT_FIM}
    >
      <c.Input type='number' value={cota?.ID_CN??''} label={lang.contrato} icon='search'
        onChange={e=>setCota({ID_CN: e.target.value})}
        iconClick={()=>App.api('fob::getContrato', {ID_CN: cota.ID_CN}).then(r=>setCota(r.results))}
      />

      <c.Divider text='ou' />

      <div className="f f-column g2">
        <c.Select searchable value={cota.NR_CNPJUND} isDisabled={!!cota.ID_CN} onChange={v=>setCota({...cota, NR_CNPJUND: v.value})} options={listas.unidades} label={lang.unidade} />
        <c.Select searchable value={cota.NR_CNPJPRC} isDisabled={!!cota.ID_CN} onChange={v=>setCota({...cota, NR_CNPJPRC: v.value})} options={listas.parceiros} label={lang.grupo_comercial} />
        <c.Select searchable value={cota.ID_PRO}     isDisabled={!!cota.ID_CN} onChange={v=>setCota({...cota, ID_PRO: v.value})}     options={listas.produtos}  label={lang.produto} />
      </div>

      <c.Divider text='Cota' />

      <div className="f g1 f-wrap">
        <c.Select className="f2" value={cota.ID_TRN}    onChange={v=>setCota({...cota, ID_TRN: v.value})}    options={listas.turnos} label={lang.turno} />
        <c.Select className="f2" value={cota.TP_OPRPRT} onChange={v=>setCota({...cota, TP_OPRPRT: v.value})} label={lang.operacao}
          options={[{value: 'R', label: lang.recepcao},{value: 'E', label: lang.expedicao}]} />
        <c.Input  className="f2" type="number" error={!f.validateQuantidade(cota.QT_COTA)} value={f.toFloat(cota.QT_COTA)} onChange={e=>setCota({...cota, QT_COTA: Math.abs(e.target.value)})} label={lang.quantidade_cota} />
        <c.Input  className="f1" type='date'   value={cota.DT_INI}  onChange={e=>setCota({...cota, DT_INI: e.target.value})}  label={lang.data_inicial} />
        <c.Input  className="f1" type='date'   value={cota.DT_FIM}  onChange={e=>setCota({...cota, DT_FIM: e.target.value})}  label={lang.data_final} />
      </div>

    </c.Modal>
  )
}
