import { useContext } from "react";

import { AppContext } from "../../App";

import "./style.sass";

export const NothingHere = ({ message }) => {
  const App = useContext(AppContext);

  return (
    <div className="nothing-here">
      <h2>{message ?? App.lang.global.nothing_here}</h2>
    </div>
  );
};
