import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../../../App';
import { Frame } from '../../../../../c';
import { ObservacaoChat } from '../../Partials/ObservacaoPartial';
import moment from 'moment';

export const ObservationsFrame = ({ bid, quotation }) => {
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [observations,setObservations] = useState([])
  const [newObs, setNewObs] = useState({ descricao: '' })

  const fetchObservation = useCallback(async () => {
    const {
      id: detailId,
      shippingCompany: { id: shippingCompanyId = App.user.id_prc } = {}
    } = bid
    const { id: quotationId } = quotation
    const { results } = await App.api('ListQuotationBidObservations', {
      detailId,
      quotationId,
      shippingCompanyId,
    })

    setObservations(results.map(({ userId, createdAt, description, owner }) => ({
      'ID_OPR': userId,
      'DT_OPR': moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
      'ID_LOGIN': '',
      DS_OBS:{
        'ds_obs': description,
        'id_color': owner === 'mine' ? "success" : "primary"
      }
    })))
  },[App, bid, quotation])

  const handleSubmit = useCallback(async (event) =>{
    event.preventDefault();
    event.stopPropagation();

    try{
      const {
        id: detailId,
        shippingCompany: { id: shippingCompanyId = App.user.id_prc } = {}
      } = bid
      const { id: quotationId } = quotation
      const { descricao: description } = newObs

      if(!description) return

      setLoading(true)

      await App.api('CreateQuotationBidObservation', {
        description,
        detailId,
        quotationId,
        shippingCompanyId,
      })

      setNewObs({ descricao: '' })
      fetchObservation()
   } catch(e){
      console.error(e)
    } finally {
      setLoading(false)
    }
  },[App, bid, fetchObservation, newObs, quotation])

  useEffect(()=>{
    const intervalId = setInterval(fetchObservation, 10000)

    fetchObservation(); // Initial fetch to get data immediately

    return () => clearInterval(intervalId)
  }, [fetchObservation])

  return (
    <Frame title="Observações" className="w50">
      <ObservacaoChat
        App={App}
        data={observations}
        loading={loading}
        newObs={newObs}
        setNewObs={setNewObs}
        submit={handleSubmit}
      />
    </Frame>
  );
};
