import React, { useMemo } from 'react'

export const PortariaStep = ({
  dateLastStep,
  dateNextStep,
  datePrevStep,
  dateStep,
  stepIcon,
  stepName,
}) => {
  const className = useMemo(
    () => {
      let stepCss = 'normal'

      if(!!dateStep || !!dateNextStep) {
        stepCss = 'warnning'

        if(!!dateNextStep || !!dateLastStep) stepCss = 'success'
      }

      return ['destaque p2 circle f center', stepCss].join(' ')
    },
    [dateLastStep, dateNextStep, dateStep]
  )

  return (
    <div className="f f-column g1 center-v">
      <div className={className} style={{ width: 40, height: 40 }}>
        {stepIcon({size:16})}
      </div>

      <b style={{ fontSize: 14 }}>
        {stepName}
      </b>

      <span
        style={{ fontSize: 10, color: 'var(--colorGray)' }}
        className="nowrap"
      >
        {dateStep}
      </span>
    </div>
  )
}
