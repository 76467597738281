import { fetchColumns } from "./fetchColumns";

export const initiateColumnsData = async ({
  api,
  columns: initialColumns = null,
  tableId
}) => {
  const fetchedColumns = await fetchColumns({ api, tableId });

  const columns = (initialColumns ?? []).map(({ field, ...rest }) => {
  const IN_INSERT = fetchedColumns.length > 0 && fetchedColumns.includes(field)
                  ? 'F'
                  : 'T'

    return ({ ...rest, field, IN_INSERT, OLD_IN_INSERT: IN_INSERT })
  });

  return columns;
}
