import { useMemo } from "react";
import { PiClockFill as DelayedIcon } from "react-icons/pi";

import { Icon } from "../../../../c/Icon";

export const DelayedIconColumn = ({ delayed, size = 32 }) => {
  const color = useMemo(() => {
    if (delayed) return 'var(--warning)'

    return 'var(--success)'
  }, [delayed])

  return (
    <Icon icon={DelayedIcon} size={size} color={color} />
  )
}
