import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import { AppContext } from '../../../../../App'
import { Frame, Modal, Select, Slider, Span, Switch, Table } from "../../../../../c"
import { formatCEP, formatCnpj } from "../../../../../f"

export const DistribuirModal = ({ cota, onClose, onFinish }) => {
  const App = useContext(AppContext)

  const [distribuir, setDistribuir] = useState(parseInt(cota.QT_DISPONIVEL))
  const [origem, setOrigem] = useState(cota.NR_CNPJORI)
  const [origemTrader, setOrigemTrader] = useState(cota.IN_TRD === 'T')
  const [origens, setOrigens] = useState([])
  const [transportadoras, setTransportadoras] = useState([])
  const [trader, setTrader] = useState(false)

  const lang = useMemo(() => ({
    ...App.lang.global,
    ...App.lang.distribuir_cotas
  }), [App.lang.distribuir_cotas, App.lang.global])

  const todasTransportadorasSelectionadas = useMemo(() => {
    if(transportadoras.length === 0) return false

    return transportadoras?.every(({ IN_TRP }) => IN_TRP === 'T')
  }, [transportadoras])

  const manipulaSelecaoDeTodasTransportadoras = useCallback((checked) => {
    setTransportadoras((transportadorasAntigas) => {
      const newState = transportadorasAntigas.map((transportadora) => ({
        ...transportadora,
        IN_TRP: checked ? 'T' : 'F'
      }))

      return [...newState]
  })
  }, [])

  const tableColumns = useMemo(() => {
    const { cep, cnpj, endereco, municipio_uf, nome, selecionar_todas } = lang

    return [
      [
        <Switch title={selecionar_todas} checked={todasTransportadorasSelectionadas} onToggle={manipulaSelecaoDeTodasTransportadoras} />,
        'SELECTION',
        undefined,
        undefined,
        undefined,
        `SELECTION-${todasTransportadorasSelectionadas}`
      ],
      [cnpj, 'CNPJ'],
      [nome, 'NM_TRP'],
      [endereco, 'ENDERECO'],
      [cep, 'CEP'],
      [municipio_uf, 'MUNICIPIO_UF'],
    ]
  }, [lang, manipulaSelecaoDeTodasTransportadoras, todasTransportadorasSelectionadas])

  const changeOrigem = useCallback(({ value }) => {
    setOrigem(value)
    setOrigemTrader(
      origens.find(
        ({ NR_CNPJORI }) => NR_CNPJORI === value
      ).IN_TRD === 'T'
    )
    setTrader(false)
  }, [origens])

  const get = useCallback(async () => {
    const { results = {} } = await App.api(
      'transportadoras::consultaDistribuir',
      { id_cota: cota.ID_COTA }
    )

    setTransportadoras(results.lst_transportadora)
    setOrigem(results.lst_origem[0].NR_CNPJORI ?? cota.NR_CNPJORI)
    setOrigens(results.lst_origem)
  }, [App, cota.ID_COTA, cota.NR_CNPJORI])

  const submit = useCallback(async () => {
    const all = transportadoras.every(({ IN_TRP }) => IN_TRP === 'T')

    await App.api('cotas::distribuirFob', {
      id_cota: cota.ID_COTA,
      qt_cota: distribuir,
      nr_cnpjori: origem,
      in_fob: trader,
      lst_trp: all === true
        ? transportadoras.map(t=>t.NR_CNPJTRP)
        : transportadoras.filter(t=>t.IN_TRP === 'T').map(t=>t.NR_CNPJTRP),
      in_alltrp: all,
    })

    if(onFinish) onFinish();

    return true
  }, [App, cota.ID_COTA, distribuir, onFinish, origem, trader, transportadoras])

  const tableData = useMemo(() => {
    const manipulaSelecaoDeTransportadora = (index) => {
      return (checked) => {
        console.log({ checked, index })
        setTransportadoras((transportadorasAntigas) => {
          transportadorasAntigas[index].IN_TRP = checked ? 'T' : 'F'

          return [...transportadorasAntigas]
        })
      }
    }

    return transportadoras.map((transportadora, index) => ({
      ...transportadora,
      key: `${transportadora.NR_CNPJTRP}-${transportadora.IN_TRP}`,
      CNPJ: formatCnpj(transportadora.NR_CNPJTRP),
      ENDERECO: transportadora.NM_LGD + ', ' + transportadora.NR_LGD,
      MUNICIPIO_UF: transportadora.NM_LOC + '/' + transportadora.ID_UF,
      CEP: formatCEP(transportadora.CD_CEP),
      SELECTION: (
        <Switch
          checked={transportadora.IN_TRP === 'T'}
          onToggle={manipulaSelecaoDeTransportadora(index)}
        />
      ),
    }))
  }, [transportadoras])

  useEffect(() => {
    get()
  }, [])

  console.log(tableColumns[0])

  return (
    <Modal
      largeWidth
      onClose={onClose}
      onFinish={submit}
      successMsg={lang.distribuida_com_sucesso}
      title={lang.cota_distribuicao}
      validate={trader || transportadoras.some(t=>t.IN_TRP === 'T')}
    >
      <div className="f g2 w100">
        <Span
          label={lang.cota}
          style={{minWidth: 100}}
          value={cota.QT_COTA}
        />

        <Span
          label={lang.distribuida}
          style={{minWidth: 100}}
          value={cota.QT_DISTRIBUIDA}
        />

        <Span
          label={lang.disponivel}
          style={{minWidth: 100}}
          value={cota.QT_DISPONIVEL}
        />

        <Slider
          className='f1'
          max={parseInt(cota.QT_DISPONIVEL)}
          min={1}
          onChange={({ target }) => setDistribuir(target.value)}
          value={distribuir}
        />

        <Select
          required
          className='f1'
          label={lang.origem}
          onChange={changeOrigem}
          options={origens.map(l=>({ value: l.NR_CNPJORI, label: l.NM_ORI }))}
          value={origem}
        />

        {origemTrader && (
          <Switch
            label={'FOB'}
            checked={trader}
            onChange={({ target }) => setTrader(target.checked)}
          />
        )}
      </div>

    {!trader  && (
      <Frame title={lang.transportadoras} className="w100">
        <Table
          isSub
          selectColumns={false}
          columns={tableColumns}
          data={tableData}
        />
      </Frame>
    )}
  </Modal>
)
}
