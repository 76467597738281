import React, { useState, useContext, useRef, useEffect, useMemo, useCallback, Fragment } from 'react'
import { AppContext } from '../../App'

import * as c from '../'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'

const getLimitedEndDate = ({ limitation, App, startDate, endDate }) => {
  if ((limitation ?? -1) < 0) return endDate; // não houve Limitação

  const limitedDate = App.moment(startDate).add(limitation, 'days')

  return App.moment
    .min([App.moment(endDate), limitedDate])
    .toDate();
};

export default function DaterangePicker({
  label,
  startDate: initialStartDate,
  endDate: initialEndDate,
  ranges,
  onChange = () => {},
  filter = false,
  limitation = null
}) {
  const App = useContext(AppContext)
  const lang = {...App.lang.global}
  const icons = App.icons

  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(getLimitedEndDate({
    App,
    limitation,
    startDate: initialStartDate,
    endDate: initialEndDate
  }))
  const [open, setOpen] = useState(false)

  const popRef = useRef(null)

  const defaultRanges = useMemo(() => [
    [ lang.hoje,           App.moment(),                                       App.moment(),                                      1],
    [ lang.ontem,          App.moment().subtract(1, "days"),                   App.moment().subtract(1, "days"),                  1],
    [ lang.sete_dias,      App.moment().subtract(6, "days"),                   App.moment(),                                      7],
    [ lang.esta_semana,    App.moment().startOf("week"),                       App.moment().endOf("week"),                        7],
    [ lang.semana_passada, App.moment().subtract(1, "week").startOf("week"),   App.moment().subtract(1, "week").endOf("week"),    7],
    [ lang.trinta_dias,    App.moment().subtract(29, "days"),                  App.moment(),                                     30],
    [ lang.este_mes,       App.moment().startOf("month"),                      App.moment().endOf("month"),                      30],
    [ lang.mes_passado,    App.moment().subtract(1, "month").startOf("month"), App.moment().subtract(1, "month").endOf("month"), 30],
  ], [App, lang.esta_semana, lang.este_mes, lang.hoje, lang.mes_passado, lang.ontem, lang.semana_passada, lang.sete_dias, lang.trinta_dias])

  const inputValue = useMemo(() => {
    const formattedStateDate = startDate ? App.moment(startDate).format(lang.formatoData) : ''
    const formatedEndDate = endDate ? App.moment(endDate).format(lang.formatoData) : ''

    return `${formattedStateDate} - ${formatedEndDate}`
  }, [App, endDate, lang.formatoData, startDate])

  const handleDatePreset = useCallback((beginningOfPeriod, endOfPeriod) => {
    return () => {
      const start = beginningOfPeriod.toDate()
      const end = endOfPeriod.toDate()

      setStartDate(start)
      setEndDate(end)
      onChange(start, end)
      setOpen(false)
    }
  }, [onChange])

  const change = useCallback((dates) => {
    const [start, rawEnd] = dates

    setStartDate(start)

    if(!rawEnd) { // caso end não tenha preenchido set ele como nulo
      setEndDate(rawEnd)
      onChange(start, rawEnd)

      return
    }

    const end = getLimitedEndDate({
      App,
      limitation,
      startDate: start,
      endDate: rawEnd
    })

    setEndDate(end)
    onChange(start, end)

    if( !!start && !!end ) setOpen(false)
  }, [App, limitation, onChange])

  const handleInputClick = useCallback(() => {
    setOpen((prevState) => {
      if(!prevState) return true // se não estiver aberto, irá se abeir

      if(!!startDate && !!endDate) return false // se o periodo estiver preenchido ele fecha
    })
  }, [endDate, startDate])

  useEffect(() => {
    function handleClickOutside(e) {
      if (popRef.current && !popRef.current.contains(e.target)) {
        setOpen(false)
        setEndDate((prevState) => {
          const newEndDate = prevState ?? startDate

          onChange(startDate, newEndDate)

          return newEndDate;
        })

      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onChange, popRef, startDate])

  useEffect(() => {
    registerLocale('pt-BR', ptBR)
    registerLocale('en-US', en)
    registerLocale('es', es)
    setDefaultLocale(lang.linguagem)
  }, [lang.linguagem])

  return <div className={['text-group','icon','daterange-picker'].join(' ')}>
    <input
      onClick={handleInputClick}
      type='text'
      value={inputValue}
      onChange={() => null}
    />

    <c.IconButton onClick={() => setOpen(!open)}>
      <icons.MdCalendarToday />
    </c.IconButton>

    {label&&<span className="label">{label}</span>}

    {open && (
      <div className='daterange-picker-selector' ref={popRef}>
        <div className='daterange-picker-presents'>
          {(ranges||defaultRanges).map(([label, beginningOfPeriod, endOfPeriod, quantityOfDays]) => {
            if(limitation !== null && limitation < quantityOfDays) {
              return <Fragment key={label}></Fragment>
            }

            return (
              <button
                key={label}
                onClick={handleDatePreset(beginningOfPeriod, endOfPeriod)}
              >
                {label}
              </button>
            )
          })}
        </div>

        <DatePicker
          inline
          selectsRange
          endDate={endDate}
          filterDate={filter}
          locale={lang.linguagem}
          monthsShown={2}
          onChange={change}
          selected={startDate}
          startDate={startDate}
        />
      </div>
    )}
  </div>
}
