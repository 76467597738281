import React from 'react'
import { Filter } from './Filter'

export const Card = ({ className, label, filters }) => {
  return (
    <div className={`f g1 f1 card-group ${className}`}>
      <div className="card-group-title">{label}</div>

      <div className="card-group-filters">
        {filters.map(({ key, ...filter}) => (
          <Filter key={key} filter={filter} />
        ))}
      </div>
    </div>
  )
}

