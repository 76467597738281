import { useContext, useMemo } from "react"
import { AppContext } from "../../../../App"
import { DropdownMenu } from "../../../../c"

export function DropdownActionMenu({ quotation, control, setEditing }) {
  const { user } = useContext(AppContext)

  const items = useMemo(() => {
    const { in_trp, in_uorg } = user
    const menus = []

    if (in_trp === 'T') {
      if(quotation?.situation?.code === 'started') {
        const { details: [detail = {}] = [] } = quotation

        menus.push({
          action: () => setEditing({ op: 'lance', quotation, bid: detail }),
          disabled: false,
          title: 'Adicionar lance',
        })
      }
    }

    if (in_uorg === 'T') {
      if(!['finished', 'canceled'].includes(quotation?.situation?.code)){
        menus.push({
          action: () => setEditing({ op: 'cancel', quotation }),
          disabled: false,
          title: 'Cancelar',
        })
      }

      menus.push({
        action: () => setEditing({ op: 'observacao', quotation }),
        disabled: false ,
        title: 'Observação',
      })
    }

    return menus
  }, [quotation, setEditing, user])

  if(items.length === 0) return <></>

  return (
    <DropdownMenu title="Editar" itens={items}>
      {control}
    </DropdownMenu>
  )
}
