import { useCallback } from "react"

export const Checkbox = ({
  checked = false,
  disabled = false,
  className = '',
  info = null,
  title,
  label,
  onChange,
  onToggle,
  style = {}
}) => {
  const handleChange = useCallback((event) => {
    if(onChange) onChange(event)
    if(onToggle) onToggle(event.target.checked)
  }, [onChange, onToggle])

  return(
    <label
      className={['checkbox-group', className].join(' ')}
      title={title}
    >
      <div className='f g1'>
        <input
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          style={style}
          type="checkbox"
        />
        <span className='checkbox-anim' />
        {label}
      </div>
      {!!info && <span className='info'>{info}</span>}
    </label>
  )
}
