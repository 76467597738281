import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AppContext } from '../../App'
import CryptoJS from 'crypto-js'

import { toDateType } from '../../f'
import { Input } from '..'

import { extractCacheFromColumns } from './useCases/extractCacheFromColumns'
import { initiateColumnsData } from './useCases/initiateColumnsData'
import { processColumns } from './useCases/processColumns'
import { saveColumns } from './useCases/saveColumns'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


export const DraggableTable = React.memo(({
  allowOrder = true,
  columns,
  data = [],
  filter = {},
  forceFullHeight = null,
  hasSub = null,
  isSub = false,
  noHeader = null,
  pagination = 'client-side', // client-side / no-pagination
  rowClick = null,
  search = '',
  showFilters = false,
  style = {},
  subOpenedDefault = null,
  handleChangeLocal = () => { }
}) => {
  const App = useContext(AppContext);
  const icons = App.icons;
  const columnsRef = useRef(null);

  const [filters, setFilters] = useState(filter);
  const [perPage, setPerPage] = useState(App.prefs?.tableNumber ?? 25);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState({ asc: true, field: null });
  const [columnsEditing, setColumnsEditing] = useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [columnsCache, setColumnsCache] = useState('');
  const [loadingTable, setLoadingTable] = useState(!isSub);
  const [dropdownClass, setDropdownClass] = useState('');

  const tableRef = useRef(null)

  const colunas = useMemo(() => processColumns(data, columns), [columns, data])

  const filtered = useMemo(() => {
    return (data || [])
      .map((item) => {
        return Object.entries(item).reduce((memo, [key, value]) => {
          return { ...memo, [key]: typeof value === 'string' ? value.trim() : value }
        }, {})
      })
      .filter((item) => { // Filtros específicos
        const mostrarItem = colunas.every((coluna) => {
          const { [coluna.dataField]: dataField } = item
          const { [coluna.field]: fieldFilter = '' } = filters || {}

          const possuiDataField = (dataField ?? '')
            .toString()
            .toUpperCase()
            .includes(fieldFilter.toUpperCase())

          return !coluna.sortable || dataField === null || possuiDataField
        })

        if (showFilters && !mostrarItem) return false // verifica se a coluna pode ser mostrada pelo filtro

        const searcheable = Object.values(item).some((value) => {
          if (!['string', 'number'].includes(typeof value)) return false

          const valueUpcased = value.toString().toUpperCase()

          return search
            .toString()
            .toUpperCase()
            .split(' ')
            .every((term) => valueUpcased.includes(term))
        })

        return search === '' || searcheable // verifica se a coluna pode ser pesquisável
      })
      .sort((a, b) => {
        return allowOrder && !!orderBy.field
          ? (
            orderBy?.typeof === 'number'
              ? orderBy.asc
                ? a[orderBy.dataField] - b[orderBy.dataField]
                : b[orderBy.dataField] - a[orderBy.dataField]
              : orderBy?.typeof === 'string'
                ? orderBy.asc
                  ? a[orderBy.dataField]?.localeCompare(b[orderBy.dataField])
                  : b[orderBy.dataField]?.localeCompare(a[orderBy.dataField])
                : orderBy?.typeof === 'date'
                  ? orderBy.asc
                    ? toDateType(a[orderBy.dataField]) - toDateType(b[orderBy.dataField])
                    : toDateType(b[orderBy.dataField]) - toDateType(a[orderBy.dataField])
                  : 0
          ) : 0
      })
  }, [allowOrder, colunas, data, filters, orderBy.asc, orderBy.dataField, orderBy.field, orderBy?.typeof, search, showFilters])

  const columnsShow = useMemo(
    () => columnsData.filter(({ IN_INSERT }) => isSub || IN_INSERT === 'T'),
    [columnsData, isSub],
  )

  const totalPages = useMemo(() => {
    return Math.max(
      Math.ceil((filtered?.length ?? 0) / perPage) - 1,
      0
    )
  }, [filtered?.length, perPage])

  const dataShow = useMemo(() => {
    if (pagination !== 'client-side') return filtered

    const inicioRegistro = (page - 1) * perPage
    const fimRegistro = (page) * perPage

    return filtered.slice(inicioRegistro, fimRegistro)
  }, [perPage, filtered, page, pagination])



  const tableId = useMemo(() => {
    const tableString = colunas.map(({ field }) => field).join('');

    return `table-${CryptoJS.MD5(JSON.stringify(tableString)).toString()}`;
  }, [colunas])

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const originIndex = result.draggableId;
    const destinationIndex = result.destination.index;
    handleChangeLocal(originIndex, destinationIndex)
  }

  useEffect(() => {
    (async () => {
      setLoadingTable(true)

      try {
        const cache = `${extractCacheFromColumns(colunas)}-${tableId}`

        if (cache === columnsCache) return

        setColumnsCache(cache)

        const newColumnsData = await initiateColumnsData({
          api: App.api,
          columns: colunas,
          tableId
        })

        setColumnsData(newColumnsData)
      } finally {
        setLoadingTable(false)
      }
    })()
  }, [App.api, columnsCache, colunas, tableId])

  useEffect(() => {
    setPage(Math.max(Math.min(page, totalPages), 1))
  }, [page, totalPages])

  //VD-12851 - Samuel - 04/06/2024
  useEffect(() => {
    const handleClickOutside = async (event) => {
      // O usuário está editando as colunas, então não faz nada aqui
      if (!columnsRef.current || columnsRef.current.contains(event.target)) return;

      if (columnsEditing) setColumnsEditing(false)

      if (columnsData.length === 0) return

      if (columnsData.every(({ IN_INSERT, OLD_IN_INSERT }) => IN_INSERT === OLD_IN_INSERT)) return

      setLoadingTable(true);

      // Houve alterações, então salva as colunas
      const prefs = columnsData.reduce(
        (hiddenColumns, { IN_INSERT, field }) => {
          if (IN_INSERT === "F") return [...hiddenColumns, field]

          return hiddenColumns
        },
        []
      )

      try {
        await saveColumns({ api: App.api, tableId, prefs })
      } finally {
        setLoadingTable(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [App.api, columnsData, columnsEditing, tableId])

  useEffect(() => {
    if (columnsEditing && tableRef.current) {
      const rowCount = tableRef.current.querySelectorAll('tbody tr').length;
      setDropdownClass(rowCount <= 7 ? 'dropdown-menu-below' : 'dropdown-menu-above');
    }
  }, [columnsEditing]);

  if (loadingTable) return <App.LoadingComponent />



  return (
    <div className='table-default f f-column g2 w100'>
      <div style={{ overflowX: 'auto', ...forceFullHeight && { height: 'calc(100vh - ' + forceFullHeight + 'px)' } }}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-rows">
            {(provided) => (
              <table {...provided.droppableProps} cellSpacing={0} style={style} ref={provided.innerRef}>
                <thead>
                  {!noHeader &&
                    <tr>
                      {!!hasSub && <th key='forsub' width='36' className='force-fit'></th>}
                      {columnsShow.map((c, ci) => (
                        <th key={c.key ?? ci} className={c.sortable ? 'sortable' : ''}
                          onClick={() => allowOrder && c.sortable && setOrderBy({ ...c, asc: orderBy.field === c.field ? !orderBy.asc : orderBy.asc })}
                        >
                          <div style={c.style} className={c.class}>{/* Precisa dessa DIV pq o TH fica distorcido com flex */}
                            {c.label}
                            {allowOrder && orderBy.field === c.field
                              && <span className='table-order-buttons'>
                                <icons.MdArrowDropDown size={24} className={orderBy.asc && 'selected'} />
                                <icons.MdArrowDropUp size={24} className={!orderBy.asc && 'selected'} />
                              </span>
                            }
                          </div>
                        </th>
                      ))}
                    </tr>
                  }
                  {!!showFilters && <tr className='table-filters'>
                    {!!hasSub && <th key='forsub'></th>}
                    {columnsShow.map((col, ci) => (
                      <th key={col.key ?? ci}>
                        {col.sortable &&
                          <Input clearable value={filters[col.field]} onChange={e =>
                            setFilters({ ...filters, [col.field]: e.target.value })
                          } placeholder={col.label} />
                        }
                      </th>
                    ))}
                  </tr>}
                </thead>
                <tbody>
                  {dataShow?.map((r, ri) => (
                    <Draggable key={ri} draggableId={ri.toString()} index={ri}>
                      {(provided, snapshot) => (
                        <tr
                          onClick={() => { if (rowClick) rowClick(r) }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={ri}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: snapshot.isDragging ? '#ddd' : 'white',
                            top: 'auto',
                            left: 'auto'
                          }}
                        >

                          {columnsShow.map((c, ci) =>
                            <td key={ci}>
                              <div style={c.style} className={[c.class].join(' ')}>{r?.[c?.field]}</div>
                            </td>)}
                        </tr>
                      )}
                    </Draggable>
                  ))}


                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>

  );
})

export default DraggableTable
