import { useMemo } from "react"

import { Frame, Table } from "../../../../../../c"
import { formatCnpj, t } from "../../../../../../f"

export const ShippingCompaniesFields = ({ form, lang }) => {
  const columns = useMemo(() => {
    return [
      [t(lang.shippingCompaniesStep, 'cnpj'), 'cnpj'],
      [t(lang.shippingCompaniesStep, 'shippingCompany'), 'name'],
    ]
  },[lang])

  const data = useMemo(() => {
    return form.shippingCompanies.map(({ cnpj, name }) => ({
      cnpj: formatCnpj(cnpj),
      name
    }))
  }, [form.shippingCompanies])

  return (
    <Frame title={t(lang.steps, 'shippingCompaniesStep')} className="w50">
      <Table isSub columns={columns} data={data} fixedPerPage={5} />
    </Frame>
  )
}
