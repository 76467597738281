import { readFile, toFloat } from "../../../../../../f"

const changeFileField = (onChange) => {
  return async (files) => {
    const [{ file } = {}] = files || []
    const { type } = file ?? {}
    let pdf;

    if (type === "application/pdf") {
      try {
        pdf = await readFile(file);
      } catch (error) {
        console.error('Erro ao ler arquivo PDF:', error);
      }
    }

    if(onChange) onChange({ images: files, pdf });
  }
}

const changeFloatField = (field, onChange) => {
  return ({ target: { value: rawValue } }) => {
    const value = toFloat(rawValue, 2)

    if(onChange) onChange({ [field]: value })
  }
}

const changeOtherField = (field, onChange) => {
  return ({ target: { value } }) => {
    if(onChange) onChange({ [field]: value })
  }
}

export const changeField = ({ field, type, onChange }) => {
  if(type === 'file') return changeFileField(onChange)

  if(type === 'float') return changeFloatField(field, onChange)

  return changeOtherField(field, onChange)
}
