import React, { useContext, useState, useCallback, useEffect } from 'react';
import { AppContext } from '../../../App';
import { VIAInfoModal } from '../VIAModal/VIAInfoModal';
import VIAImage from '../../../a/VIA/via-color.svg';
import './styles.sass';

export function VIAIcon({ type, className = '' }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { prefs } = useContext(AppContext);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (prefs?.displayVIAInfoModalAgain === false) {
      setIsModalOpen(true);
    }
  }, [prefs]);

  return (
    <div>
      <img
        src={VIAImage}
        alt="VIA"
        title='VIA (Velog Inteligência Artificial)'
        className={`via-svg ${className}`}
        onClick={handleOpenModal}
      />
      {isModalOpen && (
        <VIAInfoModal
          type={type}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}
