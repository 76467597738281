import { MdCloudDownload as DownloadIcon } from "react-icons/md"

export const DownloadsTitleColumn = ({ label }) => {
  return (
    <>
      <DownloadIcon/>
      {label}
    </>
  )
}
