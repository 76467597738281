import { useContext, useMemo } from "react"
import { AppContext } from '../../App'
import { formatNumber, convertUnit } from '../../f'

export default function CardStatus({dados}) {
    const App = useContext(AppContext),
        lang = { ...App.lang.portal_Cliente },
        icons = App.icons;

    const recepcao = useMemo(() =>{
        return [(
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{formatNumber(convertUnit(dados.VOL_RCP), 2)}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.volume_bruto}
                </div>
            </div>
        ),
        (
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{formatNumber(convertUnit(dados.VOL_RCP), 2)}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.volume_liquido}
                </div>
            </div>
        ),
        (
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }}  >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{dados.NR_RCP}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.veiculo}
                </div>
            </div>
        )
        ]
    },[dados])

    const expedicao = useMemo(() =>{
        return [(
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{formatNumber(convertUnit(dados?.VOL_EXP), 2)}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.volume}
                </div>
            </div>
        ),
        (
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{dados?.NR_EXP}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.veiculo}
                </div>
            </div>
        )];
    },[dados])

    const retencao = useMemo(() =>{
        return [(
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{formatNumber(convertUnit(dados.RETENCAO), 2)}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.volume}
                </div>
            </div>
        ),
        (
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{dados.RETENCAO_VEICULO}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.veiculo}  
                </div>
            </div>
        )];
    },[dados])

    const desconto = useMemo(() =>{
        return [(
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{formatNumber(convertUnit(dados.DESCONTO), 2)}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.volume}
                </div>
            </div>
        ),
        (
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{dados.DESCONTO_VEICULO}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.veiculo}
                </div>
            </div>
        )];
    },[dados])

    const saldo = useMemo(() =>{
        return [(
            <div className={'card'} style={{ maxWidth: '100%', justifyContent: 'center', minWidth: "120px", margin: "0 3px" }} >
                <div className='card-content'>
                    <span className="card-value" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>{formatNumber(convertUnit(dados.SALDO), 2)}</span>
                </div>
                <div className='card-title' style={{ justifyContent: 'center' }}>
                    {lang.volume}
                </div>
            </div>
        )];
    },[dados])

    return (
        <div className="frame f f-column card">
            <div className="frame-content" style={{display:'flex'}}>
                <div className="f g1 f1 card-group" style={{ display: 'flex', flexDirection: "column", borderTop: "5px solid #00c1de", maxWidth: "fit-content" }}>
                    <div className="card-group-title" style={{ maxWidth: '100%', textAlign: 'center', fontSize: 'larger' }}>{lang.recepcao}</div>
                    <div className="" style={{ display: "flex" }}>
                        {recepcao.map((v, i) => {
                            return v
                        })}
                    </div>

                </div>

                <div className="f g1 f1 card-group" style={{ display: 'flex', flexDirection: "column", borderTop: "5px solid #00bca0", maxWidth: "fit-content" }}>
                    <div className="card-group-title" style={{ maxWidth: '100%', textAlign: 'center', fontSize: 'larger' }}>{lang.expedicao}</div>
                    <div className="" style={{ display: "flex" }}>
                        {expedicao.map((v, i) => {
                            return v
                        })}
                    </div>

                </div>

                <div className="f g1 f1 card-group" style={{ display: 'flex', flexDirection: "column", borderTop: "5px solid #e0973d", maxWidth: "fit-content" }}>
                    <div className="card-group-title" style={{ maxWidth: '100%', textAlign: 'center', fontSize: 'larger' }}>{lang.retencao}</div>
                    <div className="" style={{ display: "flex" }}>
                        {retencao.map((v, i) => {
                            return v
                        })}
                    </div>

                </div>

                <div className="f g1 f1 card-group" style={{ display: 'flex', flexDirection: "column", borderTop: "5px solid #d04933", maxWidth: "fit-content" }}>
                    <div className="card-group-title" style={{ maxWidth: '100%', textAlign: 'center', fontSize: 'larger' }}>{lang.desconto}</div>
                    <div className="" style={{ display: "flex" }}>
                        {desconto.map((v, i) => {
                            return v
                        })}
                    </div>

                </div>
                <div className="f g1 f1 card-group" style={{ display: 'flex', flexDirection: "column", borderTop: "5px solid #313131", maxWidth: "fit-content" }}>
                    <div className="card-group-title" style={{ maxWidth: '100%', textAlign: 'center', fontSize: 'larger' }}>{lang.saldo}</div>
                    <div className="" style={{ display: "flex" }}>
                        {saldo.map((v, i) => {
                            return v
                        })}
                    </div>

                </div>

            </div>
        </div>
    )

}

// border-left 5px solid #00bca0
