import { useContext, useState } from 'react'
import { AppContext, log } from '../App'
import * as c from '../c'
import VIAImage from '../a/VIA/via-color.svg';

export default function Login(){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.login},
        icons = App.icons

  const [login, setLogin] = useState(''),
        [loading, setLoading] = useState(false),
        [pass,  setPass]  = useState('')

        const getLangId = () => {
          return (lang.document_langs.map(l => l.toUpperCase()).indexOf(navigator.language.toUpperCase()) + 1 || 1) - 1;
        };

  async function loginAction(e){
    if( login && pass ) {
      setLoading(true)

      let validate = await App.api('users::validate', { login: login, pass: pass })

      if( validate.status ){
        sessionStorage.setItem('token', validate?.results?.token??'e30=')

        App.setApp({...App,
          ...validate?.results,
        })
      }else{
        setLoading(false)
      }
    }else{
      App.toast.error(lang.whithout_user_pass)
    }
  }

  return (
  <div style={{
    height:'100vh',
    //backgroundImage: 'url('+require('../a/login1.png')+')'
  }}
    className={['center', App.prefs?.dark?'dark':''].join(' ')} id='loginArea'>
    <div id='backlayer-login' className='f center'>
      <div id="login-area" className='f g1'>

        <div className="center f f-column p1 g10 f1 f-between">
          <div></div>
          <div className="center f f-column p1 g3 f1 w100" id='logoGrande'>
            <img id='logo' src={ App.prefs?.dark ? require('../a/velog-hub-w.png') : require('../a/velog-hub-b.png') } alt='logo' />
            <h3>{lang.slogan}</h3>
          </div>
          <div className='f g1 center-v' id='version'>
            <span className='version-number'>Version {App.pj.version}</span>
            {App.environment &&
              <span className='version-env destaque danger'>
                {App.environment}
              </span>
            }
          </div>
        </div>

        <div className="center f f-column p1 g10 f1 f-between" id="right-area">
          {loading ? <><div/><App.LoadingComponent /><div/></> : <>
            <div style={{height: 40}} className='w100'>
              <button onClick={()=>App.setPrefs({...App.prefs, dark: !App.prefs?.dark}, false)}
                style={{float: 'right'}} className='p2'
              >
                {App.prefs?.dark
                  ? <>Light mode <icons.BsSunFill size={16} title={'Normal mode'} /></>
                  : <>Dark mode <icons.BsMoonFill size={16} title={'Dark mode'} /></> }
              </button>
            </div>

            <img id='logo' src={ App.prefs?.dark ? require('../a/velog-hub-w.png') : require('../a/velog-hub-b.png') } alt='logo' />

            <div className="center" style={{width: '100%'}}>
              <div className="w100 g5 f f-column">
                <c.Input onChange={(e)=>setLogin(e.target.value)} value={login} type="text" placeholder={lang.user} label={lang.user} className="w100" onKeyDown={e=>e.keyCode===13&&loginAction(e)} />
                <c.Input onChange={(e)=>setPass( e.target.value)} value={pass}  type="password" placeholder={lang.pass_placeholder} label={lang.pass} className="w100" onKeyDown={e=>e.keyCode===13&&loginAction(e)} />

                <div className='f center-v f-between'>
                  <button type='submit' onClick={loginAction}>Login</button>
                  <div className='f center-v g2' id='lang-selector'>
                    {lang?.langs?.map((l,i)=>
                      <c.IconButton key={i} onClick={()=> App.setPrefs({lang: i}, false)} title={l} selected={(Number.isInteger(App?.prefs?.lang) ? App?.prefs?.lang === i : getLangId() === i)} size={36}>
                        <c.Flag code={lang.flagsB[i]} />
                        {/* {lang.flags[i]} */}
                      </c.IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="f f-between center-v" style={{width: '100%'}}>
              <h3 className='f center-v g1 m0'>
                <c.Avatar size='48' />{App.cliente?.name}
              </h3>
              <div className='f g2' style={{alignItems: "center"}} title='VIA (Velog Inteligência Artificial)'>
                <img src={VIAImage} alt="VIA" className='via-svg' style={{ width: "40px"}} />
                <h2>VIA</h2>
              </div>
              <img src={ App.prefs?.dark ? require('../a/vertti-w.png') : require('../a/vertti-b.png')} alt='Vertti' height={40}/>
            </div>
          </>}
        </div>
      </div>
    </div>
  </div>)
}
