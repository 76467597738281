import { useCallback, useContext, useMemo, useState } from "react"
import moment from "moment"
import {
  MdSave as SaveIcon,
  MdDelete as DeleteIcon,
  MdEdit as EditIcon,
} from "react-icons/md"

import { AppContext } from "../../../../../../App"
import { Input, Select, Table } from "../../../../../../c"
import { Badge } from "../../../../../../c/Badge"
import { t } from "../../../../../../f"

const INITIAL_FORM = {
  id: '',
  type: 'primary',
  description: ''
}

export const ObservationsStep = ({ form: initialForm, lang, onFormChange }) => {
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(INITIAL_FORM)
  const [list, setList] = useState(initialForm.observations)

  const handleChange = useCallback((field, type) => {
    return (event) => {
      let value

      if(type === 'text') {
        value = event.target.value
      } else if(type === 'select') {
        value = event?.value
      }

      setForm((prevState) => ({ ...prevState, [field]: value }))
    }
  }, [])

  const handleChangeForm = useCallback((newObservations) => {
    if(onFormChange) {
      onFormChange({ ...initialForm, observations: newObservations })
    }
  }, [initialForm, onFormChange])

  const handleAdd = useCallback(() => {
    if(!form.type || !form.description) return

    setLoading(true)

    const newList = form.id
      ? list.map((item) => item.id === form.id ? form : item)
      : [...list, { ...form, id: `${Math.random()}-${moment().unix()}` }]

    handleChangeForm(newList)
    setList(newList)
    setForm(INITIAL_FORM)
    setLoading(false)
  }, [form, handleChangeForm, list])

  const observationTypeOptions = useMemo(() => [
    { value: 'success', label: t(App.lang.global, 'success'), color: 'var(--success)' },
    { value: 'danger', label: t(App.lang.global, 'danger'), color: 'var(--danger)' },
    { value: 'warning', label: t(App.lang.global, 'warning'), color: 'var(--warning)' },
    { value: 'info', label: t(App.lang.global, 'info'), color: 'var(--info)' },
    { value: 'primary', label: t(App.lang.global, 'primary'), color: 'var(--primary)' },
  ], [App.lang.global])

  const observationTypeStyles = useMemo(() => ({
    option: (s, { data }) => ({
      ...s,
      color: 'var(--white)',
      background: data.color
    })
  }), [])

  const inputStyle = useMemo(() => ({
    borderLeft: `5px solid var(--${form.type ?? 'color'})`
  }), [form.type])

  const columns = useMemo(() => [
    [t(lang, 'type'), 'type', {}, 'force-fit'],
    [t(lang, 'description'), 'description'],
    [t(lang, 'actions'), 'actions',{},'force-fit'],
  ],[lang])

  const data = useMemo(() => {
    const handleRemove = ({ id }) => {
      return () => {
        if(list.length < 1) return

        const newList = list.filter((item) => item.id !== id)

        handleChangeForm(newList)
        setList(newList)
      }
    }
    const handleEdit = (item) => {
      return () => {
        setForm(item)
      }
    }

    return list.map((item) => {
      const { label: type = '' } = observationTypeOptions
        .find(({ value }) => value === item.type) ?? {}

      return ({
        ...item,
        type: <Badge color={item.type ?? 'primary'} text={type} size="md" />,
        key: item.id,
        actions: (
          <div className="f g1 f-row">
            <button className="primary" onClick={handleEdit(item)}>
              <EditIcon size={24} title={t(lang, 'edit')} />
            </button>
            <button className="danger" onClick={handleRemove(item)}>
              <DeleteIcon size={24} title={t(lang, 'remove')} />
            </button>
          </div>
        ),
      })
    })
  }, [handleChangeForm, lang, list, observationTypeOptions])

  return (
    <div className="f g2 f-column w100">
      <div className="f g1 center">
        <Select
          selectable
          className=""
          error={!form.type}
          label={t(lang, 'type')}
          onChange={handleChange('type', 'select')}
          options={observationTypeOptions}
          style={{ flexBasis: 150 }}
          styles={observationTypeStyles}
          value={form.type ?? ''}
        />

        <Input
          className="f4"
          error={!form.description}
          inputStyle={inputStyle}
          label={t(lang, 'description')}
          onChange={handleChange('description', 'text')}
          value={form.description ?? ''}
        />

        <button className="success" onClick={handleAdd} title={t(lang, 'add')}>
          <SaveIcon size={28} title={t(lang, 'add')} />
        </button>
      </div>

      <div className="f g1 f-column f1">
        <Table
          isSub
          columns={columns}
          data={data}
          fixedPerPage={10}
          loading={loading}
        />
      </div>
    </div>
  )
}
