
import { Modal } from "../../../../../c"
import { useCallback, useState } from "react";
import { BidsFrame } from "./BidsFrame";
import { ObservationsFrame } from "./ObservationsFrame";

export const BidModal = ({
  App,
  onClose,
  onFinish,
  quotation,
  bid,
}) => {
  const [dataChanged, setDataChanged] = useState(false)

  const handleChangeData = useCallback(() => {
    setDataChanged(true)
  }, [])

  const handleClose = useCallback(() => {
    if (dataChanged) {
      if(onFinish) onFinish()
    } else {
      if(onClose) onClose()
    }
  }, [dataChanged, onClose, onFinish])

  return (
    <Modal
      largeWidth
      displayCancel={false}
      onClose={handleClose}
      title="Lances"
    >
      <div className="f g1 f3 ">
        <BidsFrame
          quotation={quotation}
          shippingCompanyId={bid?.shippingCompany?.id ?? App.user.id_prc}
          lang={App.lang.bid}
          onItemAdded={handleChangeData}
        />

        <ObservationsFrame quotation={quotation} bid={bid} />
      </div>
    </Modal>
  )

}
