import React, { useState, useEffect, useContext } from "react"
import { uniqueId } from "lodash"
import { filesize } from "filesize"
import Dropzone from "react-dropzone"
import { AppContext, log } from '../App'
import * as c from '../c'

export default function Upload (props){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.components},
        icons = App.icons

  const [uploadedFiles, setUploadedFiles] = useState(props.files);

  function upload(files){
    if( uploadedFiles.length < props.maxFiles) {
      const afiles = files.slice(0, props.maxFiles).map(file => ({
        file, id: uniqueId(), name: file.name, readableSize: filesize(file.size),
        preview: URL.createObjectURL(file),
      }))

      setUploadedFiles( [...uploadedFiles, ...afiles] )
      props.onChange( [...uploadedFiles, ...afiles] )
    }
  }

  useEffect(() => {
    return () => uploadedFiles.forEach(file => URL.revokeObjectURL(file.preview))
  }, [])

  useEffect(() => setUploadedFiles(props.files), [props.files])

  return (<div className={["upload", props.className].join(' ')}>
    <Dropzone maxFiles={props.maxFiles} accept={props.accept} onDropAccepted={upload} >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive
          ? <span className="f g2 center"><icons.MdCloudUpload size={24} />{lang.upload_solte}</span>
            : ( isDragReject ? <span type="error">{lang.upload_nao_suportado}</span>
              : <span className="f g2 center"><icons.MdOutlineCloudUpload size={24} />{lang.upload_clique}</span> )
          }
        </div>
      )}
    </Dropzone>
    <div className="upload-label">{props.label}</div>

    {!!uploadedFiles.length && (
      <ul className="upload-list">
        {uploadedFiles.map(uploadedFile => (
          <li key={uploadedFile.id}>
            <div>
              {/* <img src={uploadedFile.preview} alt='Preview' /> */}
              <strong>{uploadedFile.name}</strong>
              <span>
                {uploadedFile.readableSize}{" "}
              </span>
            </div>

            <div>
              {uploadedFile.preview && <>
                <c.IconButton onClick={()=>window.open(uploadedFile.preview, '_blank')}>
                  <icons.FaEye size={16} />
                </c.IconButton>

                <c.IconButton onClick={()=>{
                  const afiles = uploadedFiles.filter(file => file.id !== uploadedFile.id)
                  setUploadedFiles( afiles )
                  props.onChange( afiles )
                }}>
                  <icons.FaTrash size={16} />
                </c.IconButton>
              </>}
            </div>
          </li>
        ))}
      </ul>
    )}
  </div>)
}
