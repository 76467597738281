import { BsEye as ShowIcon } from "react-icons/bs"

import { IconButton } from "../../../../../c"

export const handleDetatilsActions = (quotation, bid, setEditing) => {

  return (
    <>
      <IconButton onClick={() => setEditing({ op: 'transportadora', quotation, bid })} >
        <ShowIcon />
      </IconButton>
    </>
  )

}
