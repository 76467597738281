export const fillDataFields = (records) => {
  const amount = records.reduce(
    (sum, row) => sum + row.amount,
    0
  )

  return {
    calculated: amount,
    amount,
    discount: 0,
    dueDate: '',
    invoiceNumber: '',
    pdf: null,
    images: []
  }
}
