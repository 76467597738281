import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext, log } from "../App";
import * as c from "../c";
import * as f from "../f";

export function PedidoIncluir({ pedido = null, onFinish, onClose }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.pedidos,
      ...App.lang.produtos,
      ...App.lang.menu,
      ...App.lang.Obs,
    },
    icons = App.icons;

  const colunas_default = [
    ['#', "ID_PRO"],
    [lang.nome, "NM_PRO"],
    [lang.estoque, "STC"],
    [
      lang.pedUndMed,
      "ID_UNDMQTD",
      { display: "flex", justifyContent: "center" },
    ],
    [lang.dt_ult_pedido, "DT_ULTFT"],
    [lang.acao, "actions", { display: "flex" }],
  ];

  const [step, setStep] = useState(0),
    [estoque, setEstoque] = useState({}),
    [listas, setListas] = useState({}),
    [representante, setRepresentante] = useState({}),
    [cliente, setCliente] = useState({}),
    [grupo, setGrupo] = useState([]),
    [filtered, setFiltered] = useState(),
    [colunas, setColunas] = useState([]),
    [produtos, setProdutos] = useState([]),
    [grupos, setGrupos] = useState([]),
    [produtoShowFilters, setProdutoShowFilters] = useState(false),
    [produtoBusca, setProdutoBusca] = useState(""),
    [loading, setLoading] = useState(false),
    [viewCarrinho, setViewCarrinho] = useState([]),
    [cooperante, setCooperante] = useState(false),
    [data, setData] = useState({
      ...pedido,
      lst_transp: [],
      DT_EMS: pedido?.DT_EMS ?? App.moment().format("DD/MM/YYYY"),
      produtos: pedido
        ? pedido.PROS.map((p) => ({
            ...p,
            erro: false,
            qnt: parseInt(p.QT_PRO),
            selected: true,
          }))
        : [],
      OBSS:
        pedido?.OBSS.map((o) => ({ ...o, DS_OBS: JSON.parse(o.DS_OBS) })) ?? [],
      NEWOBS: [],
    });

  function insertObs(pedido, ds_obs, op,tp_obss='S') {
    data.NEWOBS.find((o) => o.op === op) &&
      data.NEWOBS.splice(
        data.NEWOBS.findIndex((o) => o.op === op),
        1
      );

    data.NEWOBS.push({
      ID_OPR: App.user.id_usr,
      DT_CN: pedido?.DT_CN ?? App.user.dt_sistema,
      NR_CN: pedido?.NR_CN,
      ID_LOGIN: App.user.id_login,
      DT_OPR: App.user.dt_sistema,
      DS_OBS: {
        ds_obs: ds_obs,
      },
      op: op,
      TP_OBSS: tp_obss
    });
  }

  async function addProdutosCarrinho(
    id,
    nome_pro,
    tp_pro,
    undmed,
    stc,
    selected = false
  ) {
    let prds = data.produtos;

    estoque[id] =
      estoque[id] ?? stc;

    const tam = prds.length;
    if (selected) {
      prds = prds.map((p) => ({
        ...p,
        qnt: p.ID_PRO === id ? p?.qnt + 1 : p?.qnt,
        new: false,
        OUT_STOCK: parseInt(stc) < parseInt(p.qnt),
      }));
    } else {
      prds.push({
        ID_PRO: id,
        NM_PRO: nome_pro,
        TP_PRO: tp_pro,
        UNDMED: undmed,
        STC: stc,
        qnt: 1,
        new: true,
        selected: true,
        OUT_STOCK: parseInt(stc) < 1,
      });
    }

    pedido &&
      pedido?.NM_PES !== App.user.nm_prc &&
      App.user.in_uorgadm === "T" &&
      data.NEWOBS.push({
        ID_OPR: App.user.id_usr,
        DT_CN: pedido?.DT_CN ?? App.user.dt_sistema,
        NR_CN: pedido?.NR_CN,
        ID_LOGIN: App.user.id_login,
        DT_OPR: App.user.dt_sistema,
        DS_OBS: {
          ds_obs:
            tam === prds.length &&
            data.NEWOBS.find((o) => !o.new && o.op !== id)
              ? lang.alt_produto.replace("%d", App.user.nm_prc) +
                nome_pro +
                lang.qnt_pedido.replace(
                  "%d",
                  prds.find((p) => p.ID_PRO === id).qnt
                ) +
                undmed
              : lang.add_produto.replace("%d", App.user.nm_prc) +
                nome_pro +
                lang.qnt_pedido.replace(
                  "%d",
                  prds.find((p) => p.ID_PRO === id).qnt
                ) +
                undmed,
        },
        new: tam !== prds.length,
        op: id,
      });
    const qtdObjetos = data.NEWOBS.reduce((c, o) => {
      if (o.op === id) {
        return c + 1;
      }
      return c;
    }, 0);

    qtdObjetos &&
      qtdObjetos > 1 &&
      data.NEWOBS.splice(
        data.NEWOBS.findIndex((o) => o.op === id),
        1
      );
    setData({ ...data, produtos: prds });
  }

  function removeProdutosCarrinho(id, exclui = false) {
    let prds = data.produtos;
    const tam = prds.length;
    const prodSelect = prds.find((p) => p.ID_PRO === id);

    if (exclui) {
      prds =
        prodSelect.qnt > 1
          ? prds.map((p) => ({
              ...p,
              qnt: p.ID_PRO === id ? p?.qnt - 1 : p?.qnt,
            }))
          : prds.filter((p) => p.ID_PRO !== id);
    } else {
      prds = prds.filter((p) => p.ID_PRO !== id);
    }

    pedido &&
      pedido?.NM_PES !== App.user.nm_prc &&
      App.user.in_uorgadm === "T" &&
      data.NEWOBS.push({
        ID_OPR: App.user.id_usr,
        DT_CN: pedido?.DT_CN ?? App.user.dt_sistema,
        NR_CN: pedido?.NR_CN,
        ID_LOGIN: App.user.id_login,
        DT_OPR: App.user.dt_sistema,
        DS_OBS: {
          ds_obs:
            tam === prds.length && prds.find((o) => o.ID_PRO === id)
              ? lang.alt_produto.replace("%d", App.user.nm_prc) +
                prodSelect.NM_PRO +
                lang.qnt_pedido.replace(
                  "%d",
                  prds.find((p) => p.ID_PRO === id).qnt
                ) +
                prodSelect.UNDMED
              : lang.remover_produto.replace("%d", App.user.nm_prc) +
                prodSelect.NM_PRO +
                lang.qnt_pedido.replace("%d", prodSelect.qnt) +
                prodSelect.UNDMED,
        },
        op: id,
        new: exclui,
      });

    const qtdObjetos = data.NEWOBS.reduce((c, o) => {
      if (o.op === id) {
        return c + 1;
      }
      return c;
    }, 0);

    qtdObjetos &&
      qtdObjetos > 1 &&
      data.NEWOBS.splice(
        data.NEWOBS.findIndex((o) => o.op === id),
        1
      );

    setData({ ...data, produtos: prds });
  }

  function submit() {
    setLoading(true);
    data?.DS_OBSs?.map((o) => {
      o.new = false;
    });

    return App.api("pedidos::save", {
      ...data,
      transportadoras: data?.lst_transp,
      ID_PRCRCB: data?.ID_PRCRCB ?? App.user.id_prc,
      TP_FRT: (representante && App.user.in_crt === "F" && !pedido) ? "F" : "C",
      ID_PRC: data?.ID_PRC ?? App.user.id_prc,
      ID_CRT: representante ? representante.ID_PES : App.user.id_prc,
      DT_EMS: App.moment(data.DT_EMS, "DD/MM/YYYY").format("DD/MM/YYYY"),
      DT_ENT: App.moment(data.DT_ENT, "DD/MM/YYYY").format("DD/MM/YYYY"),
      DT_FTR: App.moment(data.DT_FTR, "DD/MM/YYYY").format("DD/MM/YYYY"),
      DT_FN: App.moment(data.DT_ENT, "DD/MM/YYYY").format("DD/MM/YYYY"),
      ID_CC: cooperante ? '1' : '0'
    }).then((r) => {
      setLoading(false);
      onFinish();
      return true;
    });
  }

  useEffect(
    () =>
      setViewCarrinho({
        ...data,
        produtos: data?.produtos?.map((p, i) => ({
          ...p,
          INP_PRO: (
            <>
            <c.Input
              fit
              info={
                parseInt(p.STC) < p.qnt &&
                lang.sem_estoque
              }
              value={p.qnt}
              onBlur={(e) => {
                p.qnt = e.target.value > 1 ? e.target.value : 1;
                setData({ ...data });
              }}
              onChange={async (e) => {
                p.qnt = e.target.value < 10000000 ? e.target.value : p.qnt;
                p.OUT_STOCK =
                  parseInt(p.STC ?? 0) < parseInt(p.qnt);
                pedido &&
                  pedido?.NM_PES !== App.user.nm_prc &&
                  App.user.in_uorgadm === "T" &&
                  data.NEWOBS.push({
                    ID_OPR: App.user.id_usr,
                    DT_CN: pedido?.DT_CN ?? App.user.dt_sistema,
                    NR_CN: pedido?.NR_CN,
                    ID_LOGIN: App.user.id_login,
                    DT_OPR: App.user.dt_sistema,
                    DS_OBS: {
                      ds_obs: p.new
                        ? lang.add_produto.replace("%d", App.user.nm_prc) +
                          p.NM_PRO +
                          lang.qnt_pedido.replace("%d", p.qnt) +
                          p.UNDMED
                        : lang.alt_produto.replace("%d", App.user.nm_prc) +
                          p.NM_PRO +
                          lang.qnt_pedido.replace("%d", p.qnt) +
                          p.UNDMED,
                    },
                    op: p.ID_PRO,
                  });
                const qtdObjetos = data.NEWOBS.reduce((c, o) => {
                  if (o.op === p.ID_PRO) {
                    return c + 1;
                  }
                  return c;
                }, 0);

                qtdObjetos &&
                  qtdObjetos > 1 &&
                  data.NEWOBS.splice(
                    data.NEWOBS.findIndex((o) => o.op === p.ID_PRO),
                    1
                  );
                setData({ ...data });
              }}
              error={parseInt(p.STC) < p.qnt}
              type="number"
              className="w50"
            />   <span style={{minWidth: '50%',marginLeft: '5px', color: parseInt(p.STC) < p.qnt && 'var(--error)'}} title={"Estoque de " + p.NM_PRO + " em "}>{ '/' + p.STC}</span></>
          ),
          actions: (
            <c.IconButton onClick={(e) => removeProdutosCarrinho(p.ID_PRO)}>
              <icons.BsFillTrashFill />
            </c.IconButton>
          ),
        })),
      }),
    [data]
  );

  let steps = !representante
    ? [
        {
          label: lang.pedido,
          validate:
            !!data.NR_DCO &&
            !!data.DT_EMS &&
            !!data.DT_FTR
        },
        { label: lang.produtos, validate: !!data.produtos?.length >= 1 },
        { label: lang.observacao, validate: true },
        { label: lang.resumo, validate: true },
      ]
    : [
        {
          label: lang.pedido,
          validate: !!data.NR_DCO && !!data.DT_EMS && !!data.DT_FTR,
        },
        { label: lang.produtos, validate: !!data.produtos?.length >= 1 },
        { label: lang.transportadora, validate: true },
        { label: lang.observacao, validate: true },
        { label: lang.resumo, validate: true },
      ];

  const produtosPaginacao = useMemo(() => {
    const { produtos = [] } = data

    if (produtos.length > 10) return 'client-side'

    return 'no-pagination'
  }, [data])

  useEffect(() => {
    let prodNew = produtos?.map((p) => ({
      ...p,
      actions: (
        <>
          <c.IconButton
            onClick={(e) => {
              addProdutosCarrinho(
                p.ID_PRO,
                p.NM_PRO,
                p.TP_PRO,
                p.ID_UNDMQTD,
                p.STC,
                data.produtos.find((d) => d.ID_PRO === p.ID_PRO)?.selected
              );
            }}
          >
            <icons.MdAdd />
          </c.IconButton>
          {data.produtos.find((d) => d.ID_PRO === p.ID_PRO)?.selected && (
            <c.IconButton
              onClick={(e) => {
                removeProdutosCarrinho(p.ID_PRO, true);
              }}
            >
              <icons.FaMinus />
            </c.IconButton>
          )}
        </>
      ),
    }));
    setProdutos(prodNew);
  }, [viewCarrinho, step]);

  useEffect(() => {
    setLoading(true);

    App.api("clientes::buscaRepresentante", { id_prc: App.user.id_prc }).then(
      (rep) => {
        setRepresentante(rep.results ?? "");
      }
    );
    pedido &&
      App.api("Pedidos::getTrsp", {
        DT_CN: pedido?.DT_CN ?? "",
        NR_CN: pedido?.NR_CN ?? "",
      }).then((r) => {
        r.results.map((t) => {
          data?.lst_transp.push(t?.NR_CPFCNPJ);
        });
        setData({ ...data });
      });

    App.api("produtos::getByPrc").then((r) => {
      pedido && data.produtos.map((p) => {
        r.results.map((r) => {
          if (p.ID_PRO === r.ID_PRO) {
            p.STC = r.STC;
          }
        });
      })
      r = r.results.map((p) => ({
        ...p,
        actions: (
          <>
            <c.IconButton
              onClick={(e) => {
                addProdutosCarrinho(p.ID_PRO, p.NM_PRO, p.TP_PRO, p.ID_UNDMQTD, p.STC);
              }}
            >
              <icons.MdAdd />
            </c.IconButton>
          </>
        ),
        DS_TPPRO:
          [
            "",
            "PRODUTO INDUSTRIALIZADO",
            "MATÉRIA-PRIMA",
            "SERVIÇO",
            "INSUMO",
            "OUTROS",
          ][p.TP_PRO] ?? "NÃO DEFINIDO",
      }));
      setProdutos(r);
      setData({...data})
    });
    App.api("clientes::getByRepresentante", {
      id_prc: App.user.id_prc,
      in_crt: App.user.in_crt,
    }).then((clientes) => {
      App.api("produtos::getMedidas").then((m) => {
        App.api("pedidos::checaData").then((d) => {
          App.api("transportadoras::get").then((c) => {
            let trsp = c.results.filter((p) => p.IN_TRP === "T");
            App.api("clientes::getByCpfCnpj", {
              nr_cpfcnpj: App.user.nr_cpfcnpj,
            }).then((t) => {
              setListas({
                ...listas,
                parceiros: clientes.results.map((p) => ({
                  label: p.NM_PES,
                  value: p.ID_PES,
                })).concat({label: App.user.nm_prc, value: App.user.id_prc}),
                transportadoras: trsp.map((p) => ({
                  label: p.NM_TRP,
                  value: p.NR_CNPJTRP,
                })),
                medidas: m.results,
                repLb: t.results.NM_PES,
                repId: t.results.ID_PES,
                DS_OBS: JSON.parse(pedido?.OBSS?.DS_OBS ?? "[]"),
              });

              setData({
                ...data,
                DT_SUG:
                  App.moment(d.results, "YYYY-MM-DD").format("DD/MM/YYYY") ??
                  App.moment().format("DD/MM/YYYY"),
                DT_FTR: pedido
                  ? data.DT_FTR
                  : App.moment(d.results, "YYYY-MM-DD").format("DD/MM/YYYY"),
                DT_INICIAL: pedido
                  ? data.DT_FTR
                  : App.moment(d.results, "YYYY-MM-DD").format("DD/MM/YYYY"),
                DT_ENT:pedido ? data.DT_ENT : App.moment(d.results, "YYYY-MM-DD").add(1, "d").format("DD/MM/YYYY")
              });
              setLoading(false);
            });
          });
        });
      });
    });

  }, []);

  useEffect(() => {
    App.api("produtos::getGrupos").then((g) => {
      setGrupos(g.results);
    });
    setFiltered(
      produtos.filter(
        (p) => !grupo.length || grupo.some((g) => g?.ID_GRPPRO === p?.ID_GRPPRO)
      )
    );
    let c = colunas_default;
    if (grupo.length !== 1) c.unshift([lang.grupoproduto, "NM_GRPPRO"]);
    setColunas(c);
  }, [produtos, grupo]);
  return (
    <c.Modal
      fullWidth
      title={pedido ? lang.edicao_pedido : lang.inclusao_pedido}
      steps={steps}
      onStepChange={(s) => setStep(s)}
      onClose={onClose}
      onFinish={submit}
      loading={loading}
      contentHeight={400}
      validate={
        !!data.NR_DCO &&
        !!data.DT_FTR &&
        (!!App.moment(data?.DT_FTR, "DD/MM/YYYY").isSame(
          App.moment(data?.DT_INICIAL, "DD/MM/YYYY"),
          "day"
        ) ||
        !!App.moment(data?.DT_FTR, "DD/MM/YYYY").isSameOrAfter(
          App.moment(data?.DT_SUG, "DD/MM/YYYY"),
          "day"
        )) &&
        // !!data.ID_CN &&
        !!data.produtos?.length >= 1
      }
    >
      {/* ------------------------------------------------------------ {/ Pedido /} ----------------------------------------------------- */}

      {step === 0 && (
        <div className="f f-wrap g1 w100">
          {loading ? (
            <App.LoadingComponent />
          ) : (
            <>
              <c.Input
                required
                label={lang.numero_ped_cli}
                value={data.NR_DCO}
                onChange={(e) => {
                  pedido &&
                    pedido?.NM_PES !== App.user.nm_prc &&
                    App.user.in_uorgadm === "T" &&
                    insertObs(
                      pedido,
                      lang.num_pedido.replace("%d", pedido?.NM_PES) +
                        e.target.value,
                      1,
                      'M'
                    );
                  setData({ ...data, NR_DCO: e.target.value });
                }}
                type="number"
                className="w25"
              />
              <c.Input
                required
                label={lang.dt_criacao}
                type="Date"
                value={App.moment(data.DT_EMS, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )}
                disabled
              />
              <c.Input
                error={
                  !App.moment(data?.DT_FTR, "DD/MM/YYYY").isSame(
                    App.moment(data?.DT_INICIAL, "DD/MM/YYYY"),
                    "day"
                  ) &&
                  !App.moment(data?.DT_FTR, "DD/MM/YYYY").isSameOrAfter(
                    App.moment(data?.DT_SUG, "DD/MM/YYYY"),
                    "day"
                  )
                }
                label={lang.dt_crg}
                info={lang.disponibilidade}
                type="Date"
                onChange={(e) => {
                  pedido?.NM_PES !== App.user.nm_prc &&
                    App.user.in_uorgadm === "T" &&
                    insertObs(
                      pedido,
                      lang.dt_ent_pedido.replace("%d", App.user.nm_prc) +
                        App.moment(e.target.value, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        ),
                      2
                    );
                  setData({
                    ...data,
                    DT_FTR: App.moment(e.target.value, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    ),
                    DT_ENT: App.moment(e.target.value, "YYYY-MM-DD")
                      .add(1, "d")
                      .format("DD/MM/YYYY"),
                    DT_INI: App.moment(e.target.value, "YYYY-MM-DD")
                      .add(1, "d")
                      .format("DD/MM/YYYY"),
                    DT_FN: App.moment(e.target.value, "YYYY-MM-DD")
                      .add(7, "d")
                      .format("DD/MM/YYYY"),
                  });
                }}
                value={App.moment(data.DT_FTR, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )}
              />
              <c.Checkbox label={'Cooperante'} checked={cooperante} onChange={e=>setCooperante(e.target.checked)}  />

              <c.Select
                isDisabled={representante || pedido}
                required={!representante}
                label={lang.cliente}
                options={
                  !representante && App.user.in_uorgadm === "F"
                    ? listas.parceiros
                    : [
                        {
                          label: data.NM_PES ?? App.user.nm_prc,
                          value: data.ID_PRC ?? App.user.id_prc,
                        },
                      ]
                }
                value={data?.ID_PRC ?? App.user.id_prc}
                onChange={(e) => [
                  setData({
                    ...data,
                    ID_PRC: e.value,
                    ID_PRCRCB: App.user.id_prc,
                  }),
                  setCliente({
                    ...cliente,
                    labelC: listas.parceiros?.find((a) => a.value === e.value)
                      ?.label,
                    valueC: e.value,
                  }),
                ]}
                searchable
                className="w100"
              />
            </>
          )}

          {!representante.ID_PES && (
            <c.Select
              label={lang.recebedor}
              options={[
                {
                  label: representante.NM_PES ?? App.user.nm_prc,
                  value: representante.ID_PES ?? App.user.id_prc,
                },
                {
                  label: data?.NM_PES ?? cliente?.labelC,
                  value: data?.ID_PRC ?? cliente?.valueC,
                },
              ]}
              value={data.ID_PRCRCB ?? App.user.id_prc}
              onChange={(e) => setData({ ...data, ID_PRCRCB: e.value })}
              searchable
              isDisabled={
                (!data.ID_PRCRCB && !data.ID_PRC) || App.user.in_uorgadm === "T" || App.user.id_prc === cliente.valueC
              }
              className="w100"
            />
          )}
        </div>
      )}

      {/* ------------------------------------------------------------ {/ Pedido /} ----------------------------------------------------- */}

      {/* ------------------------------------------------------------ {/ Produto /} ----------------------------------------------------- */}
      {step === 1 && (
        <>
          <div className="w100">
            <c.Frame
              autoScroll={false}
              style={{
                position: "sticky",
                top: "-10px",
                zIndex: "1",
                margin: "-10px 0 10px",
              }}
            >
              {loading ? (
                <App.LoadingComponent />
              ) : (
                <div className="f center-v w100 g1">
                  <c.Select
                    value={grupo.map((g) => g.ID_GRPPRO)}
                    className="f4"
                    onChange={(v) =>
                      setGrupo(
                        grupos.filter((g) =>
                          v.some((v) => v.value === g.ID_GRPPRO)
                        )
                      )
                    }
                    options={grupos?.map((g) => ({
                      value: g.ID_GRPPRO,
                      label: g.NM_GRPPRO,
                    }))}
                    label={lang.grupo_produtos}
                    multi
                    searchable
                  />
                </div>
              )}
            </c.Frame>

            <c.Frame
              style={{ position: "relative" }}
              title={lang.produtos}
              autoScroll={false}
              actions={{
                filter: {
                  onClick: () => setProdutoShowFilters(!produtoShowFilters),
                  value: produtoShowFilters,
                },
              }}
              control={
                <c.Input
                  placeholder={lang.busca}
                  value={produtoBusca}
                  onChange={(e) => setProdutoBusca(e.target.value)}
                />
              }
            >
              <div className="container">
                <c.Table
                  selectColumns={false}
                  columns={colunas}
                  data={filtered}
                  search={produtoBusca}
                  showFilters={produtoShowFilters}
                  loading={loading.produtos}
                />
                <c.Frame
                  title={lang.carrinho}
                  style={{
                    position: "sticky",
                    top: "55px",
                    margin: "-10px 0 10px",
                    height: "24vw",
                  }}
                  autoScroll
                >
                  <c.Table
                    isSub
                    className="h100"
                    columns={[
                      [lang.NM_PRO, "NM_PRO"],
                      [
                        lang.qt_produto,
                        "INP_PRO",
                        { display: "flex", justifyContent: 'center', alignItems: "center", },
                      ],
                      [
                        lang.pedUndMed,
                        "UNDMED",
                        { display: "flex", justifyContent: "center" },
                      ],
                      [
                        "",
                        "actions",
                        { display: "flex", justifyContent: "center" },
                      ],
                    ]}
                    pagination="no-pagination"
                    data={viewCarrinho.produtos}
                    type="number"
                  />
                </c.Frame>
              </div>
            </c.Frame>
          </div>
        </>
      )}

      {/* ------------------------------------------------------------ {/ Produto /} ----------------------------------------------------- */}

      {/* ------------------------------------------------------------ {/ Transportadora /} ----------------------------------------------------- */}

      {representante && step === 2 && (
        <>
          <c.Select
            required
            multi
            label={lang.transportadora}
            options={listas.transportadoras}
            value={data?.lst_transp}
            className="w100"
            onChange={(e) =>
              setData({ ...data, lst_transp: e.map((t) => t.value) })
            }
            defaultValue={listas.transportadoras.filter(
              (t) => !!data.lst_transp.find((v) => v === t.value)
            )}
            clearable
            searchable
          />
        </>
      )}

      {/* ------------------------------------------------------------ {/ Transportadora /} ----------------------------------------------------- */}

      {/* ------------------------------------------------------------ {/ Observação /} ----------------------------------------------------- */}
      {(representante ? step === 3 : step === 2) && (
        <>
          {" "}
          <div className="f f-column g1 w100 contrato-editar">
            {/* OBS */}
            {
              <div className="f g1 f-column" style={{ width: "70vw" }}>
                <button
                  title={lang.tipo}
                  onClick={() => {
                    data.OBSS.push({
                      ID_OPR: App.user.id_usr,
                      DT_CN: pedido?.DT_CN,
                      NR_CN: pedido?.NR_CN,
                      ID_LOGIN: App.user.id_login,
                      DT_OPR: App.user.dt_sistema,
                      DS_OBS: {
                        ds_obs: "",
                      },
                      new: true,
                      TP_OBSS: 'M'
                    });
                    setData({ ...data });
                  }}
                >
                  <icons.MdAdd />
                </button>
                {data.OBSS?.map((o, i) => (
                  <div key={i} className="f g1">
                    <c.Span value={o.DT_OPR} label={lang.data_observacao} />
                    <c.Span value={o.ID_LOGIN} label={lang.relator} />

                    {!pedido || o.new ? (
                      <c.Select
                        label={lang.tipo}
                        className="f1"
                        options={[
                          {
                            value: "success",
                            label: lang.success,
                            color: "var(--success)",
                          },
                          {
                            value: "danger",
                            label: lang.danger,
                            color: "var(--danger)",
                          },
                          {
                            value: "warning",
                            label: lang.warning,
                            color: "var(--warning)",
                          },
                          {
                            value: "info",
                            label: lang.info,
                            color: "var(--info)",
                          },
                        ]}
                        styles={{
                          option: (s, { data }) => ({
                            ...s,
                            color: "var(--white)",
                            background: data.color,
                          }),
                        }}
                        onChange={(e) => {
                          o.DS_OBS.id_color = e.value;
                          setData({ ...data });
                        }}
                        value={o.DS_OBS.id_color}
                      />
                    ) : (
                      <c.Input
                        disabled
                        value={o.DS_OBS.ds_obs}
                        className="f4 w50"
                        inputStyle={{
                          borderLeft:
                            "10px solid var(--" +
                            (o.DS_OBS.id_color ?? "color") +
                            ")",
                          width: "90%",
                          cursor: "default",
                        }}
                      />
                    )}

                    {(!pedido || o.new) && (
                      <c.Input
                        value={o.DS_OBS.ds_obs}
                        label={lang.obs}
                        className="f4"
                        onChange={(e) => {
                          o.DS_OBS.ds_obs = e.target.value;
                          setData({ ...data });
                        }}
                        inputStyle={{
                          borderLeft:
                            "5px solid var(--" +
                            (o.DS_OBS.id_color ?? "color") +
                            ")",
                        }}
                      />
                    )}
                    {(!pedido || o.new) && (
                      <button
                        onClick={() => {
                          data?.OBSS.splice(i, 1);
                          setData({ ...data });
                        }}
                      >
                        <icons.BsFillTrashFill />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            }
          </div>
        </>
      )}

      {/* ------------------------------------------------------------ {/ Observação /} ----------------------------------------------------- */}

      {/* -------------------------------------------------------*🤠*Resumo*🤠*-----------------------------------------------------------*/}
      {(representante ? step === 4 : step === 3) && (
        <div className="f g2">
          <div className="f g2 f-column w50">
            {/*Resumo do pedido */}
            <c.Frame title={lang.detalhe_pedido}>
              <div
                className="f g2 f-row w100"
                style={{ justifyContent: "space-around" }}
              >
                <c.Span value={data.NR_DCO} label={lang.numero_ped_cli} />
                <c.Span value={data.DT_EMS} label={lang.dt_criacao} />
              </div>
              <c.Divider />
              <div
                className="f g2 f-row w100"
                style={{ justifyContent: "space-around" }}
              >
                <c.Span
                  value={data.DT_FTR}
                  label={lang.dt_crg}
                  info={lang.disponibilidade}
                />
                <c.Span
                  value={
                    !representante
                      ? listas.parceiros?.find((a) => a.value === data.ID_PRC)
                          ?.label
                      : App.user.nm_prc
                  }
                  label={lang.cliente}
                />
                {!representante && (
                  <c.Span
                    value={
                      data.ID_PRCRCB === cliente?.valueC
                        ? cliente?.labelC
                        : App.user.nm_prc
                    }
                    label={lang.recebedor}
                    isDisabled
                  />
                )}
              </div>
            </c.Frame>

            <c.Frame title={lang.produtos}>
              <c.Table
                title={lang.produtos}
                columns={[
                  ["", ""],
                  ["", "STOCK"],
                  [lang.produto, "NM_PRO"],
                  [
                    lang.qt_produto,
                    "QT_PROUND",
                    { display: "flex", justifyContent: "center" },
                  ],
                  [
                    lang.psg,
                    "PES_TOT",
                    { display: "flex", justifyContent: "center" },
                  ],
                ]}
                pagination={produtosPaginacao}
                data={data.produtos.map((p) => ({
                  ...p,
                  STOCK: p.OUT_STOCK ? (
                    <c.Dot color="var(--danger)" blink />
                  ) : (
                    ""
                  ),
                  QT_PROUND: p.qnt + " " + p.UNDMED,
                  PES_TOT:
                    parseInt(p.qnt) *
                    parseInt(
                      listas.medidas.find((u) => u.ID_UNDMDD === p.UNDMED)
                        .NR_UNDEQV
                    ),
                }))}
                type="number"
                className="w100"
              />
            </c.Frame>

            {/*Resumo das Observações */}
          </div>

          <div className="f g2 f-column w50">
            {representante && (
              <c.Frame title={lang.transportadoras} className="w100">
                {!!data.lst_transp.length
                  ? listas.transportadoras
                      .filter((t) => data.lst_transp.find((v) => v === t.value))
                      .map((r) => r.label)
                      .join(", ")
                  : lang.selecionar_transportadora}
              </c.Frame>
            )}
            <c.Frame title={lang.obs} className="w100">
              {!data.OBSS.length ? (
                lang.nothing_here
              ) : (
                <div className="f g1 f-column">
                  {data.OBSS?.map((obs, i) => (
                    <div
                      key={i}
                      style={{
                        borderLeftStyle: "solid",
                        borderWidth: 5,
                        borderColor: "var(--" + obs.DS_OBS.id_color + ")",
                      }}
                      className="p1"
                    >
                      {obs.DS_OBS.ds_obs}
                    </div>
                  ))}
                </div>
              )}
            </c.Frame>
          </div>
        </div>
      )}
    </c.Modal>
  );
}

export default function Pedidos({ setReloader }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.pedidos,
      ...App.lang.produtos,
      ...App.lang.menu,
    },
    icons = App.icons;

  const labelsPedido = {
    11: lang.stsEditando,
    "-7": lang.stsAprovado,
    "-8": lang.stsReprovado,
    13: lang.stsAnalise,
    "-3": lang.stsCancelado,
    "-2": lang.stsFaturadoParcial,
    "-1": lang.stsEncerrado,
    1: lang.stsOCgerada,
    2: lang.stsAgendado,
    3: lang.stsEmRota,
    4: lang.stsEmRota,
    5: lang.stsEntregaParcial,
  };

  const [pedidos, setPedidos] = useState([]),
    [obs, setObs] = useState(null),
    [editing, setEditing] = useState(null),
    [details, setDetails] = useState(null),
    [loading, setLoading] = useState(true),
    [incluir, setIncluir] = useState(null),
    [pedidoBusca, setPedidosBusca] = useState(""),
    [pedidoShowFilters, setPedidoShowFilters] = useState(),
    [toggleCardsList, setToggleCardsList] = useState(false),
    [filtroConcluidas, setFiltroConcluidas] = useState(false);

  function getPedidos() {
    setLoading(true);

    App.api("pedidos::get", {
      id_prc: App.user.id_prc,
      in_crt: App.user.in_crt,
      filtroConcluidas
    }).then((r) => {
      // VD-9847 Lucas
      const pros = r.results.map((pedidos) => ({
        ...pedidos,
        CD_EXT: (pedidos.CD_EXT ?? 0) === 0 ? '' : (pedidos.CD_EXT ?? 0),
        DT_AGENDADO: pedidos.DT_AGENDADO ?? '--',
        PROS: pedidos.PROS.map((p) => ({
          ...p,
          STOCK: p.OUT_STOCK ? <c.Dot color="var(--danger)" blink /> : "",
        })),
      }));

      r = pros.map((pedidos) => ({
        ...pedidos,
        PROS: pedidos.PROS.map((p) => ({
          ...p,
        })),
        actions: (
          <>
            <c.Badge value={pedidos?.OBSS.length ?? "0"}>
              <c.IconButton onClick={() => setObs({ pedidos })}>
                <icons.BsFillChatLeftTextFill />
              </c.IconButton>
            </c.Badge>
            <c.IconButton
            disabled={
                  !(pedidos.CD_STS === "-1" ||
                  pedidos.CD_STS === "-3" ||
                  (parseInt(pedidos.CD_STS) > 0 && parseInt(pedidos.CD_STS) < 6))
                } onClick={() => setDetails({ pedidos })}>
              <icons.BsFillEyeFill />
            </c.IconButton>
          </>
        ),
        OPTIONS: (
          <DropdownEditMenu
            pedido={pedidos}
            control={
              <c.IconButton
                disabled={
                  pedidos.CD_STS === "-1" ||
                  pedidos.CD_STS === "-3" ||
                  (parseInt(pedidos.CD_STS) > 0 && parseInt(pedidos.CD_STS) < 6)
                }
              >
                <icons.BsThreeDotsVertical />
              </c.IconButton>
            }
          />
        ),
        STOCK: pedidos.PROS.find((p) => p.OUT_STOCK) ? (
          <c.Dot color="var(--danger)" blink />
        ) : (
          <c.Dot color="var(--sucess)" />
        ),

        QT_TOTEXP: f.formatNumber(
          pedidos.PROS?.reduce((i, p) => {
            return i + parseInt(p?.QT_TEXP??0);
          }, 0) ?? 0),
        QT_TSDO: f.formatNumber(
          pedidos.PROS?.reduce((i, p) => {
            return i + parseInt(p?.QT_SDO??0);
          }, 0) ?? 0),
        QTD_ITEM: pedidos.PROS?.length.toString(),
        QTD_TOTAL:
          pedidos.PROS?.reduce((i, p) => {
            return i + parseInt(p?.QT_PRO);
          }, 0).toString() ?? 0,
        PES_TOTAL: f.formatNumber(
          pedidos.PROS?.reduce((i, p) => {
            return i + parseInt(p?.QT_PRO) * parseInt(p?.NR_UNDEQV);
          }, 0) ?? 0,
          0
        ),
        obs: JSON.parse(pedidos?.DS_OBS),

        sub: (
          <>
            <c.Table
            isSub={true}
              pagination="no-pagination"
              navigate={false}
              data={pedidos?.PROS}
              columns={[
                ["", ""],
                ["", "STOCK"],
                [lang.nome, "NM_PRO"],
                [
                  lang.pedUndMed,
                  "UNDMED",
                  { display: "flex", justifyContent: "center" },
                ],
                [
                  lang.qt_produto,
                  "QT_PRO",
                  { display: "flex", justifyContent: "center" },
                ],
                [
                  lang.psg_total,
                  "PES_TOT",
                  { display: "flex", justifyContent: "center" },
                ],
              ]}
            />
          </>
        ),
        cardSub: (
          <>
            <c.Table
              isSub={true}
              pagination="no-pagination"
              navigate={false}
              data={pedidos?.PROS}
              columns={[
                ["", "STOCK", {}, "force-fit"],
                [lang.produto, "NM_PRO"],
                [
                  lang.qt_produto,
                  "QT_PROUND",
                  {
                    display: "flex",
                    justifyContent: "center",
                    padding: "0 0 0 40px",
                    width: 150,
                  },
                  "force-fit",
                ],
                [
                  lang.psg,
                  "PES_TOT",
                  {
                    display: "flex",
                    justifyContent: "center",
                    padding: "0 0 0 40px",
                    width: 150,
                  },
                  "force-fit",
                ],
                [
                  lang.expedido,
                  "QT_TEXP",
                  {
                    display: "flex",
                    justifyContent: "center",
                    padding: "0 0 0 40px",
                    width: 150,
                  },
                  "force-fit",
                ],
                [
                  lang.saldo,
                  "QT_SDO",
                  {
                    display: "flex",
                    justifyContent: "center",
                    padding: "0 0 0 40px",
                    width: 150,
                  },
                  "force-fit",
                ],
              ]}
            />
          </>
        ),
      }));
      setPedidos(r);
      setLoading(false);
      return true;
    });
  }

  function DropdownEditMenu({ pedido, control }) {
    const App = useContext(AppContext),
      lang = {
        ...App.lang.global,
        ...App.lang.pedidos,
        ...App.lang.produtos,
        ...App.lang.menu,
        ...App.lang.Obs,
      },
      icons = App.icons;

    function insertObs(pedido, ds_obs, op,tp_obss='S') {
      data?.NEWOBS.find((o) => o.op === op) &&
        data.NEWOBS.splice(
          data.NEWOBS.findIndex((o) => o.op === op),
          1
        );

      data.NEWOBS.push({
        ID_OPR: App.user.id_usr,
        DT_CN: pedido?.DT_CN ?? App.user.dt_sistema,
        NR_CN: pedido?.NR_CN,
        ID_LOGIN: App.user.id_login,
        DT_OPR: App.user.dt_sistema,
        DS_OBS: {
          ds_obs: ds_obs,
          id_color:
            op === "-8" || op === "-3"
              ? "danger"
              : op === "13"
              ? "warning"
              : "success",
        },
        op: op,
        new: true,
        TP_OBSS: tp_obss
      });
    }

    const [data, setData] = useState({ ...pedido, NEWOBS: [] });

    return (
      <div className="f" style={{ justifyContent: "end", marginTop: "10px" }}>
        {App.user.in_uorgadm === "F" ? (
          <c.DropdownMenu
            itens={[
              {
                title: lang.solicitar_aprovacao,
                action: () => {
                  insertObs(
                    pedido,
                    lang.solic_pedido.replace("%d", App.user.nm_prc),
                    "11"
                  );
                  setData({ ...data });
                  App.api("pedidos::alterarObs", { ...data }).then(() => {
                    App.api("pedidos::alterarSts", {
                      ID_CN: pedido.ID_CN,
                      new: "13",
                    }).then(() => getPedidos());
                  });
                },
                disabled: pedido.CD_STS !== "11",
              },
              {
                title: lang.solicitar_alteracao,
                action: () => {
                  insertObs(
                    pedido,
                    lang.alterar_pedido.replace("%d", App.user.nm_prc),
                    "13"
                  );
                  setData({ ...data });
                  setObs({ pedidos: data });
                },
                disabled:
                  pedido.CD_STS === "13" ||
                  (pedido.CD_STS !== "-7" && pedido.CD_STS !== "-8"),
              },
              {
                title: lang.cancelar_pedido,
                action: () => {
                  insertObs(
                    pedido,
                    lang.cancelar_obs_pedido.replace("%d", App.user.nm_prc),
                    "-3"
                  );
                  setData({ ...data });
                  setObs({ pedidos: data });
                },
                disabled:
                  pedido.CD_STS !== "13" &&
                  (App.user.in_uorgadm === "T" || App.user.in_clt === "T"),
              },
              {
                title: lang.editar_texto,
                action: () => setEditing(pedido),
                disabled: pedido.CD_STS !== "11",
              },
              {
                title: lang.editoObs,
                action: () => setObs({ pedidos: pedido }),
              },
            ]}
            title={lang.editar}
          >
            {control}
          </c.DropdownMenu>
        ) : (
          <c.DropdownMenu
            itens={[
              {
                title: lang.devolver_pedido,
                action: () => {
                  insertObs(
                    pedido,
                    lang.retorna_pedido.replace("%d", App.user.nm_prc),
                    "13"
                  );
                  setData({ ...data });
                  App.api("pedidos::alterarObs", { ...data }).then(() => {
                    App.api("pedidos::alterarSts", {
                      ID_CN: pedido.ID_CN,
                      new: "11",
                    }).then(() => getPedidos());
                  });
                },
                disabled: pedido.CD_STS !== "-7" && pedido.CD_STS !== "-8",
              },
              {
                title: lang.aprovar,
                action: () => {
                  insertObs(
                    pedido,
                    lang.aprov_pedido.replace("%d", App.user.nm_prc),
                    "-7"
                  );
                  setData({ ...data });
                  App.api("pedidos::alterarObs", { ...data }).then(() => {
                    App.api("pedidos::alterarSts", {
                      ID_CN: pedido.ID_CN,
                      new: "-7",
                    }).then(() => getPedidos());
                  });
                },
                disabled: pedido.CD_STS !== "13",
              },
              {
                title: lang.reprovar,
                action: () => {
                  insertObs(
                    pedido,
                    lang.reprov_pedido.replace("%d", App.user.nm_prc),
                    "-8"
                  );
                  setData({ ...data });
                  setObs({ pedidos: data });
                },
                disabled: pedido.CD_STS !== "13",
              },
              {
                title: lang.cancelar_pedido,
                action: () => {
                  insertObs(
                    pedido,
                    lang.cancelar_obs_pedido.replace("%d", App.user.nm_prc),
                    "-3"
                  );
                  setData({ ...data });
                  setObs({ pedidos: data });
                },
                disabled:
                  pedido.CD_STS !== "11" &&
                  (App.user.in_uorgadm === "T" || App.user.in_clt === "T"),
              },
              {
                title: lang.editar_texto,
                action: () => setEditing(pedido),
                disabled: pedido.CD_STS !== "13",
              },
              {
                title: lang.editoObs,
                action: () => setObs({ pedidos: pedido }),
              },
            ]}
            title={lang.editar}
          >
            {control}
          </c.DropdownMenu>
        )}
      </div>
    );
  }

  useEffect(() => {
    getPedidos();
    setReloader(() => getPedidos);
  }, []);

  useEffect(() => getPedidos(), [filtroConcluidas])

  return (
    <>
      <c.Frame
        flex
        title={lang.pedidos}
        loading={loading}
        actions={{
          ...(App.user.in_uorgadm === "F" && { add: () => setIncluir(true) }),
          filter: {
            onClick: () => setPedidoShowFilters(!pedidoShowFilters),
            value: pedidoShowFilters,
          },
          toggleCardsList: {
            onClick: () => setToggleCardsList(!toggleCardsList),
            value: toggleCardsList,
          },
        }}
        control={
          <c.Input
            placeholder={lang.busca}
            value={pedidoBusca}
            clearable
            onChange={(e) => setPedidosBusca(e.target.value)}
          />
        }
      >
        <div className="f g1 w100">
          {'Em aberto'}
          <c.Switch checked={filtroConcluidas} onChange={e => setFiltroConcluidas(e.target.checked)} />
          {'Concluídas'}
        </div>

        {toggleCardsList ? (
          <c.Table
            hasSub={"cardSub"}
            data={pedidos.map((p) => ({
              ...p,
              DESTAQUE: (
                <div
                  className="destaque f center"
                  style={{
                    backgroundColor:
                      "var(--" +
                      (p.CD_STS === "-8"
                        ? "danger"
                        : p.CD_STS === "11" || p.CD_STS === "1"
                        ? "warning"
                        : p.CD_STS === "-7" || p.CD_STS === "-1"
                        ? "success"
                        : p.CD_STS === "-3"
                        ? "colorTextGray"
                        : "info") +
                      ")",
                  }}
                >
                  {p?.CD_STS && labelsPedido[p?.CD_STS]}
                </div>
              ),
            }))}
            // -- VD-9847 Lucas
            search={pedidoBusca}
            showFilters={pedidoShowFilters}
            columns={[
              ["", "STOCK", { justifyContent: "flex-end" }, "f g1"],
              ["", "OPTIONS", { justifyContent: "center" }, "f g1"],
              ["#", "ID_CN"],
              ["REF", "NR_DCO"],
              [lang.cliente, "NM_PES"],
              ["REF", "CD_EXT"],
              [lang.sts, "DESTAQUE", {}, "center f", "CD_STS"],
              [lang.dt_criacao, "DT_EMS"],
              [lang.dt_crg, "DT_FTR"],
              [lang.agendado_em, "DT_AGENDADO"],
              [lang.produtos, "QTD_ITEM"],
              [lang.qt_produto, "QTD_TOTAL"],
              [lang.psg, "PES_TOTAL"],
              [lang.expedido, "QT_TOTEXP"],
              [lang.saldo, "QT_TSDO"],
              ["", "actions", {}, "center f g1"],
            ]}
            loading={loading}
          />
        ) : pedidos?.length ? (
          pedidos
            .filter(
              (d) =>
                pedidoBusca === "" ||
                Object.keys(d).some(
                  (k) =>
                    ["string", "number"].indexOf(typeof d[k]) >= 0 &&
                    pedidoBusca
                      ?.toUpperCase()
                      .split(" ")
                      .every((si) => String(d[k])?.toUpperCase().includes(si))
                )
            )
            .map((d, i) => (
              <PedidosCard
                key={i}
                pedido={d}
                dropdown={
                  <DropdownEditMenu
                    pedido={d}
                    control={
                      <button
                        disabled={
                          d.CD_STS === "-3" ||
                          d.CD_STS === "-1" ||
                          (parseInt(d.CD_STS) > 0 && parseInt(d.CD_STS) < 6)
                        }
                      >
                        {lang.editar}
                      </button>
                    }
                  />
                }
              />
            ))
        ) : (
          lang.sem_dados
        )}
      </c.Frame>
      {obs && (
        <PedidoOBS
          pedido={obs.pedidos}
          op={obs.op}
          onFinish={() => getPedidos()}
          onClose={() => setObs(null)}
        />
      )}
      {editing && (
        <PedidoIncluir
          pedido={editing}
          onFinish={() => getPedidos()}
          onClose={() => setEditing(null)}
        />
      )}
      {incluir && (
        <PedidoIncluir
          onFinish={() => getPedidos()}
          onClose={() => setIncluir(null)}
        />
      )}
      {details && (
        <DetalhesOCModal
          pedido={details.pedidos}
          onClose={() => setDetails(null)}
          />
      )}
    </>
  );
}

function PedidoOBS({ pedido, onClose, onFinish }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.pedidos,
      ...App.lang.produtos,
      ...App.lang.menu,
      ...App.lang.Obs,
    },
    icons = App.icons;
  const [loading, setLoading] = useState(false),
    [data, setData] = useState({});

  function submit() {
    setLoading(true);
    data.OBSS.map((o) => {
      o.new = false;
    });

    pedido?.NEWOBS &&
      pedido?.NEWOBS.length > 0 &&
      pedido?.CD_STS !== "-7" &&
      pedido?.CD_STS !== "-8" &&
      App.api("pedidos::alterarSts", {
        ID_CN: pedido.ID_CN,
        new: pedido.NEWOBS[0].op,
      }).then(() => {});

    return App.api("pedidos::alterarObs", {
      ...data,
      // obs: data.DS_OBS?.filter((o) => !!o.obs),
    }).then((r) => {
      setLoading(false);
      onFinish();
      return true;
    });
  }

  useEffect(() => {
    setLoading(true);
    App.api("pedidos::getObs", { ID_CN: pedido?.ID_CN }).then((r) => {
      const obs = r.results.map((o) => ({
        ...o,
        DS_OBS: JSON.parse(o.DS_OBS),
      }));

      console.log(obs)
      setData({
        NEWOBS: pedido?.NEWOBS ?? [],
        OBSS: obs,
      });
    });
    setLoading(false);
  }, []);

  return (
    <c.Modal
      minHeight={800}
      title={
        lang.editar_pedido.replace("%d", pedido?.NR_DCO) +
        " - " +
        lang["observacao"]
      }
      loading={loading}
      onClose={onClose}
      onFinish={submit}
    >
      {loading ? (
        <App.LoadingComponent />
      ) : (
        <div className="f f-column g1 w100 contrato-editar">
          {/* OBS */}
          {
            <div className="f g1 f-column" style={{ width: "70vw" }}>
              <c.Chat user={App.user.id_usr} msg={data?.OBSS} height={500} />
              <button
                disabled={
                  pedido.CD_STS === "-3" ||
                  pedido.CD_STS === "-1" ||
                  (parseInt(pedido.CD_STS) > 0 && parseInt(pedido.CD_STS) < 6)
                }
                title={lang.tipo}
                onClick={() => {
                  data.NEWOBS.push({
                    ID_OPR: App.user.id_usr,
                    DT_CN: pedido?.DT_CN,
                    NR_CN: pedido?.NR_CN,
                    ID_LOGIN: App.user.id_login,
                    DT_OPR: App.user.dt_sistema,
                    DS_OBS: {
                      ds_obs: "",
                    },
                    new: true,
                    TP_OBSS: 'M'
                  });
                  setData({ ...data });
                }}
              >
                <icons.MdAdd />
              </button>
              <c.Divider />
              {data.NEWOBS?.map((o, i) => (
                <div key={i} className="f g1">
                  <c.Span value={o.DT_OPR} label={lang.data_observacao} />
                  <c.Span value={o.ID_LOGIN} label={lang.relator} />
                  {!pedido || o.new ? (
                    <c.Select
                      label={lang.tipo}
                      className="f1"
                      options={[
                        {
                          value: "success",
                          label: lang.success,
                          color: "var(--success)",
                        },
                        {
                          value: "danger",
                          label: lang.danger,
                          color: "var(--danger)",
                        },
                        {
                          value: "warning",
                          label: lang.warning,
                          color: "var(--warning)",
                        },
                        {
                          value: "info",
                          label: lang.info,
                          color: "var(--info)",
                        },
                      ]}
                      styles={{
                        option: (s, { data }) => ({
                          ...s,
                          color: "var(--white)",
                          background: data.color,
                        }),
                      }}
                      onChange={(e) => {
                        o.DS_OBS.id_color = e.value;
                        setData({ ...data });
                      }}
                      value={o.DS_OBS.id_color}
                    />
                  ) : (
                    <c.Input
                      disabled
                      value={o.DS_OBS.ds_obs}
                      className="f4 w100"
                      inputStyle={{
                        borderLeft:
                          "10px solid var(--" +
                          (o.DS_OBS.id_color ?? "color") +
                          ")",
                        cursor: "default",
                      }}
                    />
                  )}

                  {(!pedido || o.new) && (
                    <c.Input
                      value={o.DS_OBS.ds_obs}
                      label={lang.obs}
                      className="f4"
                      onChange={(e) => {
                        o.DS_OBS.ds_obs = e.target.value;
                        setData({ ...data });
                      }}
                      inputStyle={{
                        borderLeft:
                          "5px solid var(--" +
                          (o.DS_OBS.id_color ?? "color") +
                          ")",
                      }}
                    />
                  )}
                  {(!pedido || o.new) && (
                    <button
                      onClick={() => {
                        data?.NEWOBS.splice(i, 1);
                        setData({ ...data });
                      }}
                    >
                      <icons.BsFillTrashFill />
                    </button>
                  )}
                </div>
              ))}
            </div>
          }
        </div>
      )}
    </c.Modal>
  );
}

function PedidosCard({ pedido, dropdown }) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.pedidos,
      ...App.lang.produtos,
      ...App.lang.menu,
      ...App.lang.Obs,
    },
    labelsPedido = {
      11: lang.stsEditando,
      "-7": lang.stsAprovado,
      "-8": lang.stsReprovado,
      13: lang.stsAnalise,
      "-3": lang.stsCancelado,
      "-2": lang.stsFaturadoParcial,
      "-1": lang.stsEncerrado,
      1: lang.stsOCgerada,
      2: lang.stsAgendado,
      3: lang.stsEmColeta,
      4: lang.stsEmRota,
      5: lang.stsEntregaParcial,
    };

    const icons = App.icons

  const [loading, setLoading] = useState(false),
        [details, setDetails] = useState(false),
        [obs, setObs] = useState(
      pedido.OBSS.map((o) => ({ ...o, DS_OBS: JSON.parse(o.DS_OBS) }))
    );

  useEffect(()=>{
    setObs(pedido.OBSS.map((o) => ({ ...o, DS_OBS: JSON.parse(o.DS_OBS) })))
  },[pedido])

  return (
    <div className="contrato-card" style={{ flexBasis: "49.5%" }}>
      <div className="header" style={{ margin: "-10px -10px 0" }}>
        <div
          className="f w100 f-column "
          style={{
            backgroundColor: "var(--colorTitle)",
            paddingBottom: "5px",
          }}
        >
          {/* {App.moment().format("DD/MM/YYYY") === pedido.DT_EMS ? (
        <div style={{justifyContent: "flex-start"}} className="fita-novo">Novo</div>
      ) : null} */}

          <div className="f w100" style={{ justifyContent: "space-between" }}>
            <div>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "22px",
                  marginLeft: "10px",
                }}
              >
                {"# " + pedido.ID_CN}
              </span>
            </div>
            {/* -- VD-9847 Lucas */}
            <div className="f center" style={{flexDirection: "column"}}>
              <span style={{ fontWeight: "bold" }}>{pedido.NM_PES}</span>
              <span style={{ textAlign:"left", width: "100%"}}>{"REF: " + pedido.CD_EXT}</span>
            </div>
            <div
              className="status"
              style={{
                backgroundColor:
                  "var(--" +
                  (pedido.CD_STS === "-8"
                    ? "danger"
                    : pedido.CD_STS === "11" || pedido.CD_STS === "1"
                    ? "warning"
                    : pedido.CD_STS === "-7" || pedido.CD_STS === "-1"
                    ? "success"
                    : pedido.CD_STS === "-3"
                    ? "colorTextGray"
                    : "info") +
                  ")",
              }}
            >
              <c.Span
                style={{ padding: "0" }}
                value={
                  <span className="f g1 center-v">
                    {labelsPedido[pedido.CD_STS]}
                  </span>
                }
                className="center"
              />
            </div>
          </div>
          <div
            className="f f-between actions"
            style={{ margin: "0px 10px 0px 10px", flexDirection: "row" }}
          >
            <span style={{ fontSize: "16px" }}>{"REF: " + pedido.NR_DCO}</span>
            <div className="f">
            {!!dropdown && dropdown}
            <button style={{marginTop: "10px", marginLeft: "10px"}} onClick={() => setDetails(true)} disabled={
                          !(pedido.CD_STS === "-3" ||
                          pedido.CD_STS === "-1" ||
                          (parseInt(pedido.CD_STS) > 0 && parseInt(pedido.CD_STS) < 6))
                        }>
              {lang.detalhes}
            </button>
            </div>
          </div>
        </div>
      </div>
      <div className="infos">
        <c.Span value={pedido.DT_EMS} label={lang.dt_criacao} />
        <c.Span value={pedido.DT_FTR} label={lang.dt_crg} />
        <c.Span value={pedido.DT_AGENDADO} label={lang.agendado_em} />
        {pedido?.PROS.some((obj) => obj.OUT_STOCK) && (
          <div className="f center g1">
            <c.Dot color="var(--danger)" blink />
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
              {lang.sem_estoque}
            </span>
          </div>
        )}
      </div>
      <c.Container>
        <c.Table
          isSub={true}
          pagination="no-pagination"
          data={[pedido]}
          columns={[
            [
              lang.produtos,
              "QTD_ITEM",
              { display: "flex", justifyContent: "center" },
            ],
            [
              lang.qt_produto,
              "QTD_TOTAL",
              { display: "flex", justifyContent: "center" },
            ],
            [
              lang.psg,
              "PES_TOTAL",
              { display: "flex", justifyContent: "center" },
            ],
            [
              lang.expedido,
              "QT_TOTEXP",
              { display: "flex", justifyContent: "center" },
            ],
            [
              lang.saldo,
              "QT_TSDO",
              { display: "flex", justifyContent: "center" },
            ],
          ]}
          hasSub={"cardSub"}
          loading={loading}
        />
      </c.Container>
      <c.Chat user={App.user.id_usr} msg={obs} />
      {details && (
        <DetalhesOCModal
          pedido={pedido}
          onClose={() => setDetails(false)}
          />
      )}
    </div>
  );
}

function DetalhesOCModal({pedido, onClose}) {
  const App = useContext(AppContext),
    lang = {
      ...App.lang.global,
      ...App.lang.pedidos,
      ...App.lang.produtos,
      ...App.lang.menu,
      ...App.lang.Obs,
      ...App.lang.ordem_carregamento
    },
    icons = App.icons;

    const [loading, setLoading] = useState(false),
    [data, setData] = useState([])

    function get() {
      setLoading(true)
      App.api('ordemCarregamento::getbyPedido', {id_cn: pedido.ID_CN}).then(r => {
        setData(r.results.map(oc => ({...oc,
          TRANSPORTADORAS: oc.TRANSPORTADORAS?.filter((f,y) => f.CD_STS !== -3),
          ITENS: oc.ITENS?.filter((t,y) =>t.CD_STS != -3 ),
          PORTARIAS: oc.PORTARIAS?.map(portaria => ({...portaria,
            DS_STS: portaria.CD_STS === '-1' ? lang.encerrado :
                    portaria.CD_STS === '1'  ? lang.em_fila :
                    portaria.CD_STS === '2'  ? lang.recepcionado :
                    portaria.CD_STS === '3'  ? lang.em_operacao :
                    portaria.CD_STS === '4'  ? lang.agendado :
                    portaria.CD_STS === '5'  ? lang.transito : ''
          })),
          TURNOS: oc?.TURNOS?.map(turno => ({...turno,
            DS_STS_APR: turno.CD_STS_APR === '-1' ? lang.encerrado :
                        turno.CD_STS_APR === '1'  ? lang.em_fila :
                        turno.CD_STS_APR === '2'  ? lang.recepcionado :
                        turno.CD_STS_APR === '3'  ? lang.em_operacao :
                        turno.CD_STS_APR === '4'  ? lang.agendado :
                        turno.CD_STS_APR === '5'  ? lang.transito : 'Não agendado',
            PRODUTO_PREDOMINANTE:
                      oc.ITENS.find(item => item.ID_PRO ===
                        oc.ORIGENS.find(origem => origem.NR_CNPJUND === turno.NR_CNPJUND)?.ITENS[0].ID_PRO
                      )?.NM_PRO,
            DOWNLOADS: <c.PedidoDownloadButtons pedido={{...turno}} />, // VD-9853 - Thales - 12/04/2024
          }))
        }))??[] )
        setLoading(false)
      })
    }

    useEffect(() => {
      get()
    }, [])

    return (

      <c.Modal largeWidth title={lang.pedido_OC.replace("%d", pedido.ID_CN)} loading={loading}
      contentHeight={500} onClose={onClose} validate={true}
     >
        {loading ? (
          <App.LoadingComponent />
        ) : (
          <c.Frame title={lang.ordem_carregamento} loading={loading} flex>
          <c.Table isSub hasSub={'SUB'}
            columns={[
              ['#', 'ID_UOCC'],
              [lang.situacao, '_DS_STS', {}, 'force-fit', 'DS_STS'],
              [lang.data, 'DT_CDT'],
              [lang.tipo, 'TP_FRT'],
              [lang.locais_carregamento, 'LOCAIS_CARREGAMENTO'],
              [lang.peso_carga, 'PESO_CARGA'],
              [lang.capacidade_caminhao, 'CAPACIDADE_VCL'],
              ['', 'ACTION', {}, 'force-fit'],
              ['', '', {}, '', 'DS_ITENS']
            ]}
            data={
              data?.map(d=>({...d,
                DT_CDT: App.moment(d.DT_CDT).format('DD/MM/YYYY'),
                TP_FRT: d.TP_FRT === 'F' ? 'FOB' : 'CIF',
                LOCAIS_CARREGAMENTO:
                  [...new Set( d.ORIGENS?.map(l=>l.NM_PES) )].join(', '),
                CAPACIDADE_VCL: f.formatNumber(d.QT_CAPMAX) + ' ' + d.ID_UNDMOC,
                PESO_CARGA: f.formatNumber(
                              d.ITENS?.reduce((acc, i) =>
                                parseFloat(acc) + parseFloat(i.QT_EXP) * parseFloat(i.CONVERSION)
                              , 0)) + ' KG',
                ITENS: d.ITENS?.map(i=>({...i,
                          _QUANTIDADE: f.formatNumber(i.QT_EXP) + ' ' + i.ID_UNDMEXP
                        })),
              })).map(d=>{
                const ds_sts = d.CD_STS === -3 ? 'Cancelado'
                              :d.CD_STS ===  1 ? 'Pendente'
                              :d.CD_STS ===  2 ? 'Agendado'
                              :d.CD_STS ===  3 ? 'Em coleta'
                              :d.CD_STS === -1 ? 'Concluído' : ''

                return {...d,
                  DS_STS: ds_sts,
                  DS_ITENS: d.ITENS?.map(i=> i.ID_CN).join(', '),

                  _DS_STS: <div className={['destaque fit-content nowrap', d.CD_STS===-3?'danger':d.CD_STS===1?'warning':d.CD_STS===2||d.CD_STS===3?'info':'success'].join(' ')}>
                            {ds_sts}
                          </div>,
                  SUB:  <div className="f g1 f-column">
                          <c.Table isSub noFooter
                            columns={[
                              [lang.pedido, 'ID_CN'],
                              [lang.cliente, 'NM_CLIENTE'],
                              [lang.produto, 'NM_PRO'],
                              [lang.quantidade, '_QUANTIDADE'],
                            ]} data={d.ITENS} />

                          <c.Divider />

                          <c.Table isSub noFooter
                            columns={[
                              ['','ACTIONS',{},'force-fit'],
                              ['Portaria','_ID_PRT'],
                              ['Produto predominante', 'PRODUTO_PREDOMINANTE'],
                              ['Origem', 'NM_PES'],
                              ['Data e turno', 'DS_DATA_TURNO'],
                              ['Situação', 'DS_STS_APR'],
                              [<><icons.MdCloudDownload/>{lang.downloads}</>, 'DOWNLOADS', {}, 'f g1'], // VD-9853 - Thales - 12/04/2024
                            ]}
                            data={d.TURNOS?.map(turno => ({...turno,
                              DS_DATA_TURNO: App.moment(turno.DT_CRG).format('DD/MM/YYYY') + ' ' + turno.DS_TRN,
                              _ID_PRT: <div className="f g1 center-v">{turno.ID_PRT}</div>,
                            }))}
                          />
                        </div>,
                }
            })
          }
        />
        </c.Frame>
        )}

     </c.Modal>

    )

}
