import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { MdSave as SaveIcon } from "react-icons/md"

import { AppContext } from "../../../../../App"
import { Frame, Input, Table } from "../../../../../c"
import { formatDate, formatMoney, formatNumber, t, toFloat } from "../../../../../f"

const INITIAL_FORM = { amount: null, vehicleQuantity: null }

export const BidsFrame = ({
  lang,
  onItemAdded,
  quotation,
  shippingCompanyId,
}) => {
  const { api, toast, user } = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(INITIAL_FORM)
  const [list, setList] = useState([])

  const fetchList = useCallback(async () => {
    try {
      setLoading(true)

      const { status, results } = await api('ListQuotationDetails', {
        id: quotation.id,
        shippingCompanyId,
        allBids: true,
      })

      if (status) setList(results)
    } catch (error) {
      setList([])
    } finally {
      setLoading(false)
    }
  }, [api, quotation.id, shippingCompanyId])

  const handleChangeField = useCallback((field, type) => {
    return ({ target }) => {
      let value

      switch(type) {
        case 'number':
          value = target.value.replace(/\D/g, '')
          if(/^\d+$/.test(value)) {
            value = Number(value)
          } else {
            value = null
          }
          break
        case 'money':
          value = toFloat(target.value, 2)
          break
        default:
          value = ''
      }

      setForm((prevState) => ({ ...prevState, [field]: value }))
    }
  },[])

  const handleSubmit = useCallback(async (event) => {
    try {
      event.preventDefault()
      event.stopPropagation()
      setLoading(true)

      const { amount, vehicleQuantity } = form
      const { status, results } = await api('CreateQuotationBid', {
        amount,
        quotationId: quotation.id,
        shippingCompanyId,
        vehicleQuantity
      })

      if(!status) return

      if(results.message) {
        toast.error('Falha ao criar um lance!')

        throw new Error('Error')
      } else {
        setForm(INITIAL_FORM)

        if(onItemAdded) onItemAdded()

        await fetchList()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  },[api, fetchList, form, onItemAdded, quotation.id, shippingCompanyId, toast])

  const displayForm = useMemo(() => {
    return user.in_trp === 'T' && quotation?.situation?.code === 'started'
  }, [quotation?.situation?.code, user.in_trp])

  const columns = useMemo(() => [
    ['Data do lance', 'DT_OPR'],
    ['Valor', 'VL_LANCE'],
    ['Número de veículos', 'QT_VCL'],
  ], [])

  const bids = useMemo(() => {
    return list
      .sort((a, b) => a.createdAt <= b.createdAt ? 1 : -1)
      .map((item) => ({
        disabled: !item.active,
        DT_OPR: formatDate(item.createdAt),
        QT_VCL: formatNumber(item.vehicleQuantity),
        VL_LANCE: formatMoney(item.amount, 2),
      }))
  }, [list])

  useEffect(() => {
    (async () => {
      if(list.length < 1) await fetchList()
    })()
  }, [fetchList, list.length])

  return (
    <Frame title="Listagem" className="w50">
    {displayForm &&(
      <form onSubmit={handleSubmit}>
        <div className="f g1 mb3 center">
          <Input
            className="f4"
            label="Valor"
            error={form.amount <= 0}
            onChange={handleChangeField('amount', 'money')}
            value={formatNumber(form.amount, 2)}
          />
          <Input
            className="f4"
            label="Número de veículos"
            error={form.vehicleQuantity < quotation.minimumVehicleQuantity || form.vehicleQuantity <= 0}
            onChange={handleChangeField('vehicleQuantity', 'number')}
            value={formatNumber(form.vehicleQuantity)}
          />

          <button
            className="success"
            title={t(lang, 'add')}
            disabled={form.vehicleQuantity < quotation.minimumVehicleQuantity || loading || form.vehicleQuantity <= 0 || form.amount <= 0}
          >
            <SaveIcon size={32} title={t(lang, 'add')} />
          </button>
        </div>
      </form>
    )}

    <div className="f g1 f-column f1">
      <Table
        columns={columns}
        data={bids}
        disableLine="disabled"
        fixedPerPage={10}
        loading={loading}
      />
    </div>
  </Frame>
  )
}
