import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../../App";
import { IconButton, Input, Modal, Select, Skeleton, Table } from "../../../../c";
import { diaDaSemana } from "../../../../f";

export function ContratoCotasModal({ contrato, onFinish, onClose }) {
  const App = useContext(AppContext)
  const icons = App.icons

  const [cotas, setCotas] = useState([]),
    [cotaEdting, setCotaEdting] = useState({}),
    //VD-10678 por Hugo em 07/05/24
    [cotasOriginal, setCotasOriginal] = useState([]),
    [cotaSaving, setCotaSaving] = useState(null),
    [loading, setLoading] = useState(null),
    [turnos, setTurnos] = useState([])

  //VD-10678 por Hugo em 07/05/24
  const startEditing = (cota) => {
    setCotasOriginal(JSON.parse(JSON.stringify(cotas)));
    setCotaEdting(cota);
  }

  //VD-10678 por Hugo em 07/05/24
  const cancelEditing = () => {
    setCotas(cotasOriginal);
    setCotasOriginal([]);
    setCotaEdting({});
  };

  async function save(cota) {
    setCotaSaving(cota.ID_UOCC)

    const r = await App.api('contratos::cotaSave', {
      ID_CN: contrato.ID_CN,
      cota: cotaEdting
    })

    if (r.status) App.toast.success('Salvo com sucesso!')

    setCotas((await App.api('contratos::cotas', { ID_CN: contrato.ID_CN })).results ?? [])

    setCotaEdting({})
    setCotaSaving(null)
  }

  async function insertClick() {
    await getTurnos(App.moment().format('YYYY-MM-DD'))

    setCotas([{ ID_UOCC: 0 }, ...cotas])

    setCotaEdting({
      "ID_UOCC": 0,
      "DT_COTA": App.moment().format('YYYY-MM-DD'),
      "DIA_SEMANA": 0,
      "ID_TRN": 0,
      "DS_TRN": "",
      "QT_COTA": 0,
      "QT_CNS": 37,
      "QT_USO": 0
    })
  }

  async function getTurnos(date) {
    setTurnos((await App.api('contratos::getTurnos', { date })).results)
  }

  function dataTurnoRepetido() {
    return cotaEdting.ID_UOCC === 0
      && cotas.filter(c => c.DT_COTA === cotaEdting.DT_COTA && c.ID_TRN === cotaEdting.ID_TRN).length > 0
  }

  useEffect(() => {
    init()

    async function init() {
      setLoading(true)

      let _cotas = (await App.api('contratos::cotas', { ID_CN: contrato.ID_CN })).results ?? []

      if (cotaEdting.ID_UOCC !== undefined) {
        _cotas = [{ ID_UOCC: 0 }, ..._cotas]
      }

      setCotas(_cotas)

      setLoading(null)
    }
  }, [])

  //useEffect(() => console.log( cotaEdting ), [cotaEdting])

  return (
    <Modal title={'Cotas do contrato ' + contrato.ID_CN}
      onClose={onClose}
      loading={loading}
      cancelText={'Sair'}
      extraControls={
        cotaEdting.ID_UOCC === undefined && <button onClick={insertClick}><icons.MdAdd />Incluir</button>
      }
    >
      <Table isSub allowOrder={cotaEdting.ID_UOCC !== 0} pagination="no-pagination"
        columns={[
          ['Data', '_DT_COTA'],
          ['Semana', '_DIA_SEMANA', {}, 'DIA_SEMANA'],
          ['Turno', '_DS_TRN'],
          ['Volume', '_VOLUME'],
          ['Conversão', '_QT_CNS'],
          ['Cota', '_QT_COTA'],
          ['Utilizado', '_QT_USO'],
          ['Edição', '_ACTION'],
        ]}
        data={
          cotas.map(cota => ({
            ...cota,
            _DT_COTA: cotaEdting.ID_UOCC === cota.ID_UOCC && cotaEdting.ID_UOCC === 0
              ? <Input value={cotaEdting.DT_COTA} fit type="date" loading={cotaSaving !== null}
                min={App.moment().format('YYYY-MM-DD')}
                error={!cotaEdting.DT_COTA || dataTurnoRepetido()} style={{ width: 110 }}
                onChange={e => {
                  getTurnos(App.moment(e.target.value).format('YYYY-MM-DD'))
                  cotaEdting.DT_COTA = e.target.value
                  setCotaEdting({ ...cotaEdting })
                }}
              />
              : cotaSaving === cota.ID_UOCC ? <Skeleton /> : App.moment(cota.DT_COTA).format('DD/MM/YYYY'),
            _DIA_SEMANA: cotaSaving === cota.ID_UOCC ? <Skeleton />
              : diaDaSemana(App.moment(cotaEdting.DT_COTA ?? cota.DT_COTA).day() + 1),
            _DS_TRN: cotaEdting.ID_UOCC === cota.ID_UOCC && cotaEdting.ID_UOCC === 0
              ? <Select value={cotaEdting.ID_TRN} options={turnos} fit loading={cotaSaving !== null}
                error={!cotaEdting.ID_TRN || dataTurnoRepetido()}
                onChange={e => {
                  cotaEdting.ID_TRN = e.value
                  setCotaEdting({ ...cotaEdting })
                }}
              />
              : cotaSaving === cota.ID_UOCC ? <Skeleton /> : cota.DS_TRN,
            _VOLUME: cotaEdting.ID_UOCC !== cota.ID_UOCC
              ? parseInt(cota.QT_COTA ?? 0) * parseInt(cota.QT_CNS ?? 0)
              : <Input value={cotaEdting._VOLUME ?? parseInt(cotaEdting.QT_COTA) * parseInt(cotaEdting.QT_CNS)} fit
                loading={cotaSaving !== null} style={{ width: 50 }}
                onChange={e => {
                  cotaEdting._VOLUME = Math.max(parseInt(+e.target.value), 0)
                  setCotaEdting({ ...cotaEdting })
                }}
                onBlur={e => {
                  cotaEdting.QT_COTA = parseInt(parseInt(+e.target.value) / (cotaEdting.QT_CNS ? cotaEdting.QT_CNS : 1))
                  delete cotaEdting._VOLUME
                  setCotaEdting({ ...cotaEdting })
                }}
              />,
            _QT_CNS: cotaEdting.ID_UOCC !== cota.ID_UOCC
              ? parseInt(cota.QT_CNS ?? 0)
              : <Input value={cotaEdting.QT_CNS ?? 0} fit loading={cotaSaving !== null} style={{ width: 50 }}
                onChange={e => {
                  cotaEdting.QT_CNS = Math.max(parseInt(+e.target.value), 0)
                  setCotaEdting({ ...cotaEdting })
                }}
              />,
            _QT_COTA: cotaEdting.ID_UOCC !== cota.ID_UOCC
              ? parseInt(cota.QT_COTA ?? 0)
              : <Input value={cotaEdting.QT_COTA ?? 0} fit loading={cotaSaving !== null} style={{ width: 50 }}
                type="number"
                onChange={e => {
                  cotaEdting.QT_COTA = Math.max(parseInt(+e.target.value), cotaEdting.QT_USO, 0)
                  setCotaEdting({ ...cotaEdting })
                }}
              />,
            _QT_USO: cotaSaving === cota.ID_UOCC ? <Skeleton /> : cota.QT_USO,
            _ACTION:
              <div className="f center-v g1" style={{ minHeight: 32 }}>
                {cotaEdting.ID_UOCC !== cota.ID_UOCC
                  ? cotaEdting.ID_UOCC === undefined &&
                  <IconButton icon={icons.MdEdit} size={32} title="Editar"
                    onClick={() => {
                      startEditing(cota);
                    }}
                  />
                  : cotaSaving === cota.ID_UOCC ? <Skeleton />
                    : <>
                      {!!cotaEdting.DT_COTA && !!cotaEdting.ID_TRN && !dataTurnoRepetido() &&
                        <IconButton title="Salvar" icon={icons.MdSave} size={32}
                          onClick={() => save(cota)}
                        />
                      }
                      <IconButton title="Cancelar" icon={icons.MdCancel} size={32}
                        onClick={() => App.confirm('Cancelar edição/adição?', () => {
                          if (cota.ID_UOCC === 0) {
                            cotas.shift();
                          }
                          return !cancelEditing();
                        })}
                      />
                      {dataTurnoRepetido() && <span style={{ color: 'var(--danger)', fontSize: 12 }}>Já existe registro de cotas <br /> para esta data e turno</span>}
                    </>
                }
              </div>
          }))
        }
      />
    </Modal>
  )
}
