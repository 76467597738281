import { formatNumber, formatMoney } from "../../../f";

const valueWithDefault = (value) => value ?? 'N/A';
const defaultToZero = (value) =>
  typeof value === 'number' && !isNaN(value) ? value : 0;

const formatDistance = (distance) => {
  if ((distance ?? null) === null) return defaultToZero(distance);
  return `${formatNumber(distance, 0)} km`;
};

const formatDuration = (duration) => {
  if (!duration) return '0h0m';

  const [hours, minutes] = duration.split(':').map(Number);
  return `${hours}h${minutes}m`;
};

const formatPedagio = (pedagioValue) => {
  if ((pedagioValue ?? null) === null) return defaultToZero(pedagioValue);

  return formatMoney(pedagioValue, 2);
};

export class QuotationPresenter {
  constructor(record) {
    this.record = record;
  }

  valueOf(field) {
    if (this[field]) return this[field]();

    return valueWithDefault(this.record[field]);
  }

  addressOrigin() {
    return valueWithDefault(
      this.record?.itinerary?.origin?.cityName
      + ' ' + this.record?.itinerary?.origin?.street
      + ' ' + this.record?.itinerary?.origin?.neighbourhood
      + ' ' + this.record?.itinerary?.origin?.number
      + ' ' + this.record?.itinerary?.origin?.state
    );
  }

  addressDestination() {
    return valueWithDefault(
      this.record?.itinerary?.destination?.cityName
      + ' ' + this.record?.itinerary?.destination?.street
      + ' ' + this.record?.itinerary?.destination?.neighbourhood
      + ' ' + this.record?.itinerary?.destination?.number
      + ' ' + this.record?.itinerary?.destination?.state
    );  }

    distance() {
      return formatDistance(this.record?.itinerary?.distance);
    }

  duration() {
    return formatDuration(this.record?.itinerary?.duration);
  }

  toll() {
    return formatPedagio(this.record?.itinerary?.tollAmount);
  }

  observation() {
    return valueWithDefault(this.record?.observations?.length > 0
      ? this.record.observations.join(', ')
      : 'N/A'
    );
  }
}
