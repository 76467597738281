import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../../App'
import * as c from '../../c'
import * as f from '../../f'
import Filtros from "./filtros"
import CardClassificacao from "./cardsClassificacao"
import Graficos from "./graficos"
import CardStatus from "./cardStatus"
import TablePortalCliente from "./table"
export default function PortalCliente(props) {
    const App = useContext(AppContext),
        lang = { ...App.lang.gerencialQualidade, ...App.lang.global },
        icons = App.icons

    //const today = App.moment().format('yyyy-MM-DD')

    const [lstDados, setLstDados] = useState([]),
        [dtIni, setDtIni] = useState(App.moment().toDate()),
        [dtFim, setDtFim] = useState(App.moment().toDate()),
        [filtro, setFiltro] = useState({}),
        [lstFiltro, setLstFiltro] = useState({}),
        [loading, setLoading] = useState(null)

    async function get() {
        setLoading(true)
        // Tentar mostrar uma notificacao caso o id_rv contenha caracteres indevidos
        App.api('portalCliente::getAnalitic', {
            periodos: [{
                ini: App.moment(dtIni).format('YYYY-MM-DD'),
                fim: App.moment(dtFim).format('YYYY-MM-DD')
            }],
            cnpjund: filtro?.unidades, // Valida se eh um cliente, para trazer apenas as informacoes dele
            id_pro: filtro?.produtos,
            operacao: filtro?.operacoes,
            id_rv: filtro?.id_rv,
            aprovacoes: filtro?.aprovacoes,
            modal: filtro?.modais,
            contrato:filtro?.contrato,
            cnpjparceiro: App.user.in_uorgadm!=='T' ? [App.user.nr_cpfcnpj]: filtro?.parceiros
        })
            .then(r => {
                setLstDados(r.results)

                setLoading(false)
            })
    }

    useEffect(() => {
        if(dtIni&& dtFim){
            App.api('portalCliente::getFiltros', {
                periodos: [{
                    ini: App.moment(dtIni).format('YYYY-MM-DD'),
                    fim: App.moment(dtFim).format('YYYY-MM-DD')
                }]
            }).then((r) => {
                setLstFiltro(r.results)
                if (r.results.produtos.length > 0) {
                    setFiltro({ ...filtro, produtos: r.results.produtos[0]?.value,operacoes:'T',aprovacoes: 'TODOS' })
                }else{
                    setFiltro({...filtro,operacoes:'T',aprovacoes: 'TODOS'})
                }
            }
            )
        }
    }, [dtIni, dtFim])

    useEffect(() => {
        App.api('portalCliente::getFiltros', {
            periodos: [{
                ini: App.moment(dtIni).format('YYYY-MM-DD'),
                fim: App.moment(dtFim).format('YYYY-MM-DD')
            }]
        }).then((r) => {
            setLstFiltro(r.results)
            if (r.results.produtos.length > 0) {
                setFiltro({ ...filtro, produtos: r.results.produtos[0]?.value,operacoes:'T',aprovacoes: 'TODOS' })
                App.api('portalCliente::getAnalitic', {
                    periodos: [{
                        ini: App.moment(dtIni).format('YYYY-MM-DD'),
                        fim: App.moment(dtFim).format('YYYY-MM-DD')
                    }],
                    id_pro: r.results.produtos[0].value,
                })
                    .then(r => {
                        setLstDados(r.results)

                        setLoading(false)
                    })
            }

        }
        )
        const reloadInterval = setInterval(get, 1000 * 60 * 10)

        return () => clearInterval(reloadInterval)
    }, [])

    return (<>
        <Filtros get={get} dtIni={dtIni} dtFim={dtFim} setDtIni={setDtIni} setDtFim={setDtFim} lstFiltro={lstFiltro} setLstFiltro={setLstFiltro} setFiltro={setFiltro} filtro={filtro} />
        {loading ? <App.LoadingComponent /> :
            <>
                <CardStatus dados={lstDados}/>
                <CardClassificacao key={1} filtro={filtro} lstFiltro={lstFiltro} dados={lstDados} />
                <Graficos dados={lstDados}/>
                <TablePortalCliente dados={lstDados}/>

            </>
        }
    </>)
}

