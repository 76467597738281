import React, { useState, useEffect, useContext, useMemo } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'
import * as p from '../p'
import AnexarADEModal from "./anexarADEModal"

export default function Monitor({setReloader}){
  const App = useContext(AppContext),
  lang = { ...App.lang.global, ...App.lang.monitor, ...App.lang.transportador },
  icons = App.icons

  const [monitores, setMonitores] = useState([]),
        [selectedMonitor, setSelectedMonitor] = useState(parseInt(sessionStorage.getItem('monitorMonitor')??0)),
        [loading, setLoading] = useState(false),
        [localFisicoModal, setLocalFisicoModal] = useState(null),
        [motorista, setMotorista] = useState(null),
        [printTicketModal, setPrintTicketModal] = useState(null),
        [openTicketFracionado, setOpenTicketFracionado] = useState(null),
        [detalheModal, setDetalheModal] = useState(null),
        [viewFracionadaDetails, setViewFracionadaDetails] = useState(null),
        [openDfe, setOpenDfe] = useState(false),
        [selectedTab, setSelectedTab] = useState(parseInt(sessionStorage.getItem('monitorTab')??0)),
        [busca, setBusca] = useState(''),
        [showFilters, setShowFilters] = useState(false),
        [firstLoad, setFirstLoad] = useState(true)
  // VD-22513 - Samuel - 11/07/24
  const [openNfp, setOpenNfp] = useState(false);
  const [openADE, setOpenADE] = useState(false);

  const columns = useMemo(() => {
    const attachDocument = [["", "OPTIONS", {}, "force-fit"]] ;

    return {
      fila: [
        [lang.fila, 'ID_FILA'],
        [lang.nome, 'NM_FILA'],
        [lang.prioridade, 'NR_PRIORIDADE'],
        [lang.produto, 'NM_PRO'],
        [lang.parceiro, 'NM_PES'],
        [lang.veiculo, 'ID_VCL'],
        [lang.entrada, 'DT_EFILA'],
        [lang.tempo, 'QT_TEMPO'],
        [lang.agendado, 'QT_AGENDADO', {}, '', "QT_AGD"],
        [lang.recepcionado, 'QT_PATIO', {}, '', "QT_PTO"],
        [lang.operacao, 'QT_OPERACAO', {}, '', "QT_OPR"],
      ],
      agendado: [
        [lang.fila, 'ID_FILA'],
        [lang.nome, 'NM_FILA'],
        [lang.cod_itinerario, 'ID_OBJ'],
        [lang.operacao, 'DS_TPOPRPRT', {}, 'f center-h'],
        [lang.data, 'DT_PRV'],
        [lang.turno, 'DS_TRN'],
        [lang.local, '_DS_LFIS', {}, 'nowrap f g1 center-v'],
        [lang.veiculo, 'ID_VCL'],
        [lang.contrato, 'CN'],
        [lang.ordem, 'ID_OC'],
        [lang.pedido, 'PEDIDO'],
        [lang.ticket, '_TICKET', {}, '', 'ID_UOCC'],
        [lang.nfe, 'LST_NFE'],
        [lang.nr_nfe, 'NR_NFE'],
        [lang.origem, 'DS_ORG'],
        [lang.destino, 'DS_DST'],
        [lang.produto, 'DS_PRD'],
        [lang.situacao, 'DS_STS'],
        [lang.tempo, 'TEMPO_TRS'],
        [lang.whatsapp, 'whatsapp'],
        [lang.acao, 'ACTION'],
        [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
        [lang.cpf_motorista, 'NR_CPFMTR'],
        [lang.nome_motorista, 'NM_MTR'],
        [lang.transportadora, 'NM_TRP'],
        [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1'],
      ],
      recepcionado: [
        [lang.fila, 'ID_FILA'],
        [lang.nome, 'NM_FILA'],
        [lang.cod_itinerario, 'ID_OBJ'],
        [lang.p_f, 'NR_ORDEMFILA'],
        [lang.p_g, 'NR_ORDEM'],
        [lang.local, '_DS_LFIS', {}, 'nowrap f g1 center-v'],
        [lang.veiculo, 'ID_VCL'],
        [lang.contrato, 'ID_CN'],
        [lang.ordem, 'ID_OC'],
        [lang.pedido, 'PEDIDO'],
        [lang.ticket, 'ID_UOCC'],
        [lang.nfe, 'LST_NFE'],
        [lang.nr_nfe, 'NR_NFE'],
        [lang.data, 'DT_PRV'],
        [lang.turno, 'DS_TRN'],
        [lang.operacao, 'TPOPRPRT', {}, 'f center-h', 'DS_TPOPRPRT'],
        [lang.origem, 'DS_ORG'],
        [lang.destino, 'DS_DST'],
        [lang.produto, 'DS_PRD'],
        [lang.data_chegada, 'DT_CHG'],
        [lang.entrada_fila, 'DT_EFILA'],
        [lang.tempo, 'TEMPO_FILA'],
        [lang.acao, 'ACTION', {}, 'f center-h'],
        [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
        [lang.whatsapp, 'WHATSAPP', {}, 'f center-h'],
        [lang.checar, 'CHK', {}, 'f center-h'],
        [lang.detalhe, 'DETALHE', {}, 'f center-h'],
        [lang.cpf_motorista, 'NR_CPFMTR'],
        [lang.nome_motorista, 'NM_MTR'],
        [lang.transportadora, 'NM_TRP'],
        [lang.cliente, 'NM_CLT'],
        [lang.tipo_veiculo, 'DS_TPVCL'],
        [lang.classificacao, 'IN_CLS', {}, 'f center-h'],
        [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1'],
      ],
      operacao: [
        ...attachDocument,
        [lang.fila, 'ID_FILA'],
        [lang.cod_itinerario, 'ID_OBJ'],
        [lang.nome, 'NM_FILA'],
        [lang.veiculo, 'ID_VCL'],
        [lang.contrato, 'ID_CN'],
        [lang.ordem, 'ID_OC'],
        [lang.pedido, 'PEDIDO'],
        [lang.ticket, 'ID_UOCC'],
        [lang.nfe, 'LST_NFE'],
        [lang.nr_nfe, 'NR_NFE'],
        [lang.data, 'DT_PRV'],
        [lang.operacao, 'TPOPRPRT', {}, 'f center-h'],
        [lang.origem, 'DS_ORG'],
        [lang.destino, 'DS_DST'],
        [lang.produto, 'DS_PRD'],
        [lang.local, 'ID_LFIS'],
        [lang.entrada, 'DT_OPR1'],
        [lang.tempo, 'TEMPO_OPR'],
        [lang.situacao, 'DS_STS'],
        [lang.cpf_motorista, 'NR_CPFMTR'],
        [lang.nome_motorista, 'NM_MTR'],
        [lang.transportadora, 'NM_TRP'],
        [lang.acao, 'ACTION'],
        [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
        [lang.whatsapp, 'WHATSAPP', {}, 'f center-h'],
        [lang.checar, 'IN_CHK', {}, 'f center-h'],
        [lang.detalhe, 'DETALHE', {}, 'f center-h'],
        [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1'],
      ],
      finalizado: [
        ...attachDocument,
        [lang.fila, 'ID_FILA'],
        [lang.cod_itinerario, 'ID_OBJ'],
        [lang.nome, 'NM_FILA'],
        [lang.veiculo, 'ID_VCL'],
        [lang.contrato, 'ID_CN'],
        [lang.ordem, 'ID_OC'],
        [lang.pedido, 'PEDIDO'],
        [lang.ticket, 'ID_UOCC'],
        [lang.data, 'DT_PRV'],
        [lang.nfe, 'LST_NFE'],
        [lang.nr_nfe, 'NR_NFE'],
        [lang.operacao, 'TPOPRPRT', {}, 'f center-h'],
        [lang.origem, 'DS_ORG'],
        [lang.destino, 'DS_DST'],
        [lang.produto, 'DS_PRD'],
        [lang.local, 'ID_LFIS'],
        [lang.entrada, 'DT_OPR1'],
        [lang.saida, 'DT_OPR2'],
        [lang.tempo, 'TEMPO_OPR'],
        [lang.cpf_motorista, 'NR_CPFMTR'],
        [lang.nome_motorista, 'NM_MTR'],
        [lang.transportadora, 'NM_TRP'],
        [lang.imprimir, 'IMPRIMIR', {}, 'f center-h'],
        [lang.detalhe, 'DETALHE', {}, 'f center-h'],
        [<><icons.MdCloudDownload />{lang.downloads}</>, 'downloads', {}, 'f g1'],
      ]
    };
  }, [App.components?.monitor?.anexarDocOperacao]);

  async function get(){
    setMonitores([])

    //setLoading(true)
    let _monitores = (await App.api('monitor::consulta')).results
    //setLoading(false)

    if( selectedMonitor > _monitores.length ) {
      setSelectedMonitor(0)
      sessionStorage.setItem('monitorMonitor', 0)
    }

    setMonitores( _monitores )
  }

  useEffect(() => {
    if( monitores.length && !!firstLoad ) {
      consultaPorSituacao()
      setFirstLoad(false)
    }
  }, [monitores])

  async function loadFila(){
    if( !monitores[selectedMonitor] ) return;

    monitores[selectedMonitor].filas =
      (await App.api('monitor::consultaFila', {id_uorg: monitores[selectedMonitor]?.uorg?.ID_UORG})).results
        ?.map(fila => ({...fila,
          QT_AGENDADO: fila.QT_AGD,
          QT_PATIO: fila.QT_PTO,
          QT_OPERACAO: fila.QT_OPR,
        }))
    setMonitores( [...monitores] )
  }

  async function loadAgendados(){
    if( !monitores[selectedMonitor] ) return;

    let _agendado = (await App.api('monitor::veiculoAgendado', {id_uorg: monitores[selectedMonitor]?.uorg?.ID_UORG})).results

    let extras = (await App.api('portarias::getExtras',{ID_UOCCs: _agendado.map(obj => obj.ID_UOCC)})).results

    monitores[selectedMonitor].agendado = _agendado?.map(a => {
      const mdfes = extras.mdfes[a.ID_UOCC];

      const mdfe_status = !mdfes?.length ? 0 : (
        mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      );

      a = {...a,
        mdfe_status: mdfe_status,
        downloads: <c.PortariaDownloadButtons portaria={{...a,mdfe_status:mdfe_status}} />,
        DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
        DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
        _DS_LFIS: <><c.IconButton onClick={()=>setLocalFisicoModal(a.ID_UOCC)}><icons.BsPencilFill /></c.IconButton>{a.DS_LFIS}</>,
        DS_TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
        whatsapp: (
          <span
            onClick={() => setMotorista({cpf: a.NR_CPFMTR, tel: a.NR_TLFMTR})}
            className='f nowrap center-v'
          >
            <c.IconButton>
              <icons.BsPencilFill />
            </c.IconButton>
            {f.formatTel(a.NR_TLFMTR)}
          </span>
        ),
        NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
        LST_ORDEM: !a.LST_ORDEM?<icons.BsQuestionCircleFill size={16} />
          :<a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('ordem|' + a.ID_UOCC)} target='_blank' rel="noopener noreferrer">
            <icons.BsCheck2Circle size={16} />
          </a>,
        ACTION: <button className="success" disabled={a.CD_STS === '4' && a.IN_AUTO === 'F'} onClick={()=>alterarSituacao(a, 1)}>{lang.recepcionar}</button>,
        IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                    onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                    <icons.FaPrint />
                  </c.IconButton>,
        DACTE: !!a.LST_CTE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        DANFE: !!a.LST_NFE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,
        CN: a.ID_CN??'-----',
        ID_OC: a.ID_OC??'-----',
        _TICKET: <button onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}>{a.ID_UOCC}</button>,
        LST_NFE: (extras.dcos[a.ID_UOCC]?.filter(d => d.TP_DF === "55").map(d => d.NR_DCO).join(', ')) || '-----' // VD-22541, Lucas, 24/06/2024
      }
      return a
    })

    setMonitores( [...monitores] )
  }

  async function loadRecepcionados(){
    if( !monitores[selectedMonitor] ) return;

    let _recepcionado = (await App.api('monitor::veiculoRecepcionado', {id_uorg: monitores[selectedMonitor]?.uorg?.ID_UORG})).results

    let extras = (await App.api('portarias::getExtras',{ID_UOCCs: _recepcionado.map(obj => obj.ID_UOCC)})).results

    monitores[selectedMonitor].recepcionado = _recepcionado?.map((a, i)=>{
      const mdfes = extras.mdfes[a.ID_UOCC];
      const mdfe_status = !mdfes?.length ? 0 : (
        mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      );

      a = {...a,
        mdfe_status: mdfe_status,
        downloads: <c.PortariaDownloadButtons portaria={{...a,mdfe_status:mdfe_status}} />,
        _DS_LFIS: <><c.IconButton onClick={()=>setLocalFisicoModal(a.ID_UOCC)}><icons.BsPencilFill /></c.IconButton>{a.DS_LFIS}</>,
                  DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
        DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
        TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
        TEMPO_FILA: <span className={a.TEMPO_FILA_COLOR}>{a.TEMPO_FILA}</span>,
        IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                    onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                    <icons.FaPrint />
                  </c.IconButton>,
        DACTE: !!a.LST_CTE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        DANFE: !!a.LST_NFE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        WHATSAPP: <c.IconButton onClick={()=>enviarMensagem(a, 1)} title={'WhatsApp'}><icons.MdOutlineWhatsapp /></c.IconButton>,
        DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,
        NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
        IN_CLS: a.IN_CLS==='T' ? <icons.BsCheck2Circle size={16} /> : <icons.MdCancel size={16} />,
        LST_ORDEM: !a.LST_ORDEM?<icons.BsQuestionCircleFill size={16} />
          :<a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('ordem|' + a.ID_UOCC)} target='_blank' rel="noopener noreferrer">
            <icons.BsCheck2Circle size={16} />
          </a>,
        ACTION: <button className="success" disabled={a.IN_CHK==='F'} onClick={() => alterarSituacao(a, 3)}>{lang.liberar_operacao}</button>,
        CHK: a.IN_CHK === 'T'
              ? <icons.BsCheck2Circle size={16} />
              : <c.IconButton title={lang.checar_veiculo}
                  onClick={()=>App.confirm(lang.deseja_checar.replace('%d', a.ID_VCL).replace('%e', lang.entrar_operacao),
                    () => App.api('monitor::checarPortaria',{id_uocc: a.ID_UOCC}).then(r => {
                      if(r.status){ get() }
                      return r.status
                    })
                  )}><icons.MdCancel /></c.IconButton>,
        LST_NFE: (extras.dcos[a.ID_UOCC]?.filter(d => d.TP_DF === "55").map(d => d.NR_DCO).join(', ')) || '-----', // VD-22541, Lucas, 24/06/2024
        ID_OC: a.ID_OC??'-----'
      }
      return a
    })

    setMonitores( [...monitores] )
  }

  async function loadOperacao(){
    if( !monitores[selectedMonitor] ) return;

    let _operacao = (await App.api('monitor::VeiculoOperacao', {id_uorg: monitores[selectedMonitor]?.uorg?.ID_UORG})).results

    let extras = (await App.api('portarias::getExtras',{ID_UOCCs: _operacao.map(obj => obj.ID_UOCC)})).results

    monitores[selectedMonitor].operacao = _operacao?.map(a => {
      const mdfes = extras.mdfes[a.ID_UOCC];
      const mdfe_status = !mdfes?.length ? 0 : (
        mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      );

      a = {...a,
        // VD-22513 - Samuel - 11/07/24
        OPTIONS:
        <c.DropdownMenu itens={[
            {
              title: lang.anexar_nfe,
              icon: <icons.MdAttachFile />,
              disabled: !!a.LST_NFE,
              action: () => setOpenDfe(a),
            },
            {
              title: lang.anexar_nfp,
              icon: <icons.MdAttachFile />,
              disabled: !!a.LST_NFP,
              action: ()=>setOpenNfp(a)
            },
            {
              title: lang.anexar_ade,
              icon: <icons.MdAttachFile />,
              disabled: !!a.LST_ADE,
              action: ()=>setOpenADE(a)
            },

            App.diretivas.TranspCancelarAgendamento === 'N'

          ]}
        >
          <c.IconButton icon={icons.BsThreeDotsVertical} size={24} />
        </c.DropdownMenu>,
        mdfe_status: mdfe_status,
        downloads: <c.PortariaDownloadButtons portaria={{...a,mdfe_status:mdfe_status}} />,
        TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
                  DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
        DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
        NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
        ACTION:
          <button className="success" disabled={a.IN_CHK==='F'}
            onClick={()=>
              (a.DS_TPOPRPRT==='EXPEDICAO' && App.diretivas.ObrigatorioNFeVelog === '1')
              ? ( !a.LST_NFE && !a.LST_NFP ? setOpenDfe({...a,button: true}) : alterarSituacao(a, -1) )
              : alterarSituacao(a, -1)
            } >{lang.liberar_saida}
          </button>,
        IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                    onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                    <icons.FaPrint />
                  </c.IconButton>,
        DACTE: !!a.LST_CTE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        DANFE: !!a.LST_NFE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        WHATSAPP: <c.IconButton onClick={()=>enviarMensagem(a, 3)}><icons.MdOutlineWhatsapp /></c.IconButton>,
        IN_CHK: a.IN_CHK === 'T' ? <icons.BsCheck2Circle size={16} />
          : <c.IconButton title={lang.checar_veiculo}
              onClick={()=>App.confirm(lang.deseja_checar.replace('%d', a.ID_VCL),
                ()=>App.api('monitor::checarPortaria',{id_uocc: a.ID_UOCC}).then(r=>{r.status&&App.toast.success(lang.veiculo_checado);get(); return r})
              )}><icons.MdCancel /></c.IconButton>,
        DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,

        ID_OC: a.ID_OC??'-----'
      }
      return a
    })

    setMonitores( [...monitores] )
  }

  async function loadFinalizados(){
    if( !monitores[selectedMonitor] ) return;

    let _finalizado = (await App.api('monitor::veiculoFinalizado', {id_uorg: monitores[selectedMonitor]?.uorg?.ID_UORG})).results

    let extras = (await App.api('portarias::getExtras',{ID_UOCCs: _finalizado.map(obj => obj.ID_UOCC)})).results

    monitores[selectedMonitor].finalizado = _finalizado?.map(a => {
      const mdfes = extras.mdfes[a.ID_UOCC];
      const mdfe_status = !mdfes?.length ? 0 : (
        mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === 1).length) ? 2
        : (mdfes?.some(d=>d.validacoes?.filter(v=>parseInt(v.CD_STS) === -3).length) ? 3 : 1)
      );

      a = {...a,
        OPTIONS: (
          <c.DropdownMenu
            itens={[
              {
                title: lang.anexar_nfe,
                icon: <icons.MdAttachFile />,
                disabled: !!a.LST_NFE,
                action: () => setOpenDfe(a),
              },
              {
                title: lang.anexar_nfp,
                icon: <icons.MdAttachFile />,
                disabled: !!a.LST_NFP,
                action: () => setOpenNfp(a),
              },
              {
                title: lang.anexar_ade,
                icon: <icons.MdAttachFile />,
                disabled: !!a.LST_ADE,
                action: ()=>setOpenADE(a)
              },
              App.diretivas.TranspCancelarAgendamento === "N",
            ]}
          >
            <c.IconButton icon={icons.BsThreeDotsVertical} size={24} />
          </c.DropdownMenu>
        ),
        mdfe_status: mdfe_status,
        downloads: <c.PortariaDownloadButtons portaria={{...a,mdfe_status:mdfe_status}} />,
        TPOPRPRT: a.DS_TPOPRPRT==='EXPEDICAO'?<icons.CargaIcon title={lang.carga} size={24} />:<icons.DescargaIcon title={lang.descarga} size={24} />,
                  DS_ORG: Array.isArray(a.DS_ORG) ? a.DS_ORG.map(d=><div>{d.NM_PES}</div>) : a.DS_ORG,
        DS_DST: Array.isArray(a.DS_DST) ? a.DS_DST.map(d=><div>{d.NM_PES}</div>) : a.DS_DST,
        NR_CPFMTR: f.formatCpf(a.NR_CPFMTR),
        IMPRIMIR: <c.IconButton title={lang.imprimir_ticket}
                    onClick={() => !!a.ID_COTA ? setPrintTicketModal(a.ID_UOCC) : setOpenTicketFracionado(a.ID_UOCC)}>
                    <icons.FaPrint />
                  </c.IconButton>,
        DACTE: !!a.LST_CTE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('dacte|' + a.ID_UOCC)} title={lang.dacte} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        DANFE: !!a.LST_NFE &&
          <a href={App.baseURL + '/' + App.cliente.id.toLowerCase() + '/download/?k=' + btoa('danfe|' + a.ID_UOCC)} title={lang.danfe} target='_blank' rel="noopener noreferrer">
            <c.IconButton><icons.FaPrint /></c.IconButton>
          </a>,
        DETALHE: <c.IconButton onClick={()=>!!a.ID_COTA ? setDetalheModal(a.ID_UOCC) : setViewFracionadaDetails(a.ID_UOCC)}><icons.BsEye size={16} /></c.IconButton>,
        LST_NFE: (extras.dcos[a.ID_UOCC]?.filter(d => d.TP_DF === "55").map(d => d.NR_DCO).join(', ')) || '-----', // VD-22541, Lucas, 24/06/2024
        ID_OC: a.ID_OC??'-----'
      }
      return a
    })

    setMonitores( [...monitores] )
  }

  function enviarMensagem(portaria, situacao){
    App.confirm(lang.deseja_reenviar.replace('%d', portaria.ID_VCL),
      ()=>App.api('monitor::reenviarWhatsapp', {id_uocc: portaria.ID_UOCC, tp_env: situacao})
            .then(r=>{r.status&&App.toast.success(lang.mensagem_reenviada); return r})
    )
  }

  function alterarSituacao(portaria, situacao){
    App.confirm(
      (portaria.CD_STS === '4' || portaria.CD_STS === '5' ? lang.deseja_recepcionar
        : portaria.CD_STS === '1' ? lang.deseja_liberar_operacao
        : lang.deseja_liberar_saida).replace('%d', portaria.ID_VCL),
      ()=>App.api('monitor::alteraSituacaoPortaria',{idPrt: portaria.ID_UOCC, sts: situacao, sts_prt: portaria.CD_STS}).then(r=>{
          if (r.status){
            consultaPorSituacao();
            return r;
          } else {
            consultaPorSituacao (); // Recarrega o monitor atualizando as informações.
          }
      })
    )
  }

  async function consultaPorSituacao(){
    setLoading(true)

    switch(selectedTab){
      case 0: await loadFila(); break;
      case 1: await loadAgendados(); break;
      case 2: await loadRecepcionados(); break;
      case 3: await loadOperacao(); break;
      case 4: await loadFinalizados(); break;
      default:
    }

    setLoading(false)
  }

  useEffect(() => {
    if( monitores.length ) consultaPorSituacao()
  }, [selectedMonitor, selectedTab])

  useEffect(()=>{
    get()

    setReloader(()=>()=>{
      setFirstLoad(true)
      get()
    })

    const interval = setInterval(() => {
      setFirstLoad(true)
      get()
    }, 300000);

    return () => clearInterval(interval)
  }, [])

  return(<>
    {monitores.length > 1 &&
      <div className="react-tabs f g2">
        <ul className="react-tabs__tab-list">
          {monitores.map((monitor, id) =>
            monitor.total > 0
            ? (
                <li key={id} onClick={() => {setSelectedMonitor(id);sessionStorage.setItem('monitorMonitor', id)}}
                  className={["react-tabs__tab f g1 center-v", (id===selectedMonitor?'react-tabs__tab--selected':'')].join(' ')}
                >
                  {monitor.uorg.NM_UORG}
                  <span className='destaque info'>{monitor.total}</span>
                </li>
              ) : <></>
          )}
        </ul>
      </div>
    }

    <c.Frame title={"Monitor"} loading={loading}
      actions={{
        filter: {onClick:()=>setShowFilters(!showFilters), value: showFilters}
      }}
      control={<c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable />}
    >
      <c.Tabs selectedIndex={selectedTab} onSelect={i=>{setSelectedTab(i); sessionStorage.setItem('monitorTab', i)}}>
        <div className="f f-column g1">
          <c.TabList>
            <c.Tab>{lang.fila}</c.Tab>
            <c.Tab>{lang.agendado} <sup><b>
              {monitores[selectedMonitor]?.qt_agendado}
            </b></sup></c.Tab>
            <c.Tab>{lang.recepcionado} <sup><b>
              {monitores[selectedMonitor]?.qt_recepcionado}
            </b></sup></c.Tab>
            <c.Tab>{lang.em_operacao} <sup><b>
              {monitores[selectedMonitor]?.qt_operacao}
            </b></sup></c.Tab>
            <c.Tab>{lang.finalizado_recente}</c.Tab>
          </c.TabList>

          <c.Select value={monitores[selectedMonitor]?.fila} label={lang.fila} clearable
            onChange={v => {
              monitores[selectedMonitor].fila = v?.value||null
              setMonitores([...monitores])
            }}
            options={monitores[selectedMonitor]?.filas?.map(f=>({value: f.ID_FILA, label: f.ID_FILA + ' - ' + f.NM_FILA}))}
          />
        </div>

        {/* Fila */}
        <c.TabPanel>
          <c.Table columns={columns.fila} data={monitores[selectedMonitor]?.filas} search={busca}
            selectedRow={row => row.ID_FILA === monitores[selectedMonitor]?.fila} showFilters={showFilters}
            rowClick={row => {
              monitores[selectedMonitor].fila = row.ID_FILA||null
              setMonitores([...monitores])
            }} forceFullHeight={290}
          />
        </c.TabPanel>

        {/* Agendado */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          {/* // VD-22513 - Samuel - 11/07/24 */}
          <c.Table columns={columns.agendado} perPage={10} search={busca}
            data={monitores[selectedMonitor]?.agendado?.filter(t=>t.ID_FILA===(monitores[selectedMonitor]?.fila??t.ID_FILA))} showFilters={showFilters}
          />
        </c.TabPanel>

        {/* Recepcionado */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          {/* // VD-22513 - Samuel - 11/07/24 */}
          <c.Table columns={columns.recepcionado} perPage={10} search={busca}
            data={monitores[selectedMonitor]?.recepcionado?.filter(t=>t.ID_FILA===(monitores[selectedMonitor]?.fila??t.ID_FILA))} showFilters={showFilters}

          />
        </c.TabPanel>

        {/* Em operação */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          {/* // VD-22513 - Samuel - 11/07/24 */}
          <c.Table columns={columns.operacao} perPage={10} search={busca}
            data={monitores[selectedMonitor]?.operacao?.filter(t=>t.ID_FILA===(monitores[selectedMonitor]?.fila??t.ID_FILA))} showFilters={showFilters}
          />
        </c.TabPanel>

        {/* Finalizados */}
        <c.TabPanel style={{overflowX: 'auto'}}>
          <c.Table columns={columns.finalizado} perPage={10} forceFullHeight={290} search={busca}
            data={monitores[selectedMonitor]?.finalizado?.filter(t=>t.ID_FILA===(monitores[selectedMonitor]?.fila??t.ID_FILA))} showFilters={showFilters}
          />
        </c.TabPanel>
      </c.Tabs>
    </c.Frame>

    {localFisicoModal &&
      <LocalFisicoModal idPrt={localFisicoModal} onClose={()=>{get();setLocalFisicoModal(null)}}
        onFinish={()=>get()} />
    }

    {motorista && (
      <TelMotorista
        cpf={motorista.cpf}
        telefone={motorista.tel}
        onClose={()=>setMotorista(null)}
        onFinish={async () => {
          await get()
          await consultaPorSituacao()
        }}
      />
    )}

    {printTicketModal &&
      <p.PrintTicketModal idPrt={printTicketModal} onClose={()=>setPrintTicketModal(false)} />
    }

    {!!openTicketFracionado &&
      <p.PrintTicketFracionadoModal prtId={openTicketFracionado} onClose={()=>setOpenTicketFracionado(null)} />
    }

    {detalheModal &&
      <DetalheModal idPrt={detalheModal} onClose={()=>setDetalheModal(null)}/>
    }

    {viewFracionadaDetails &&
      <p.OrdemCarregamentoDetailsModal prtId={viewFracionadaDetails} onClose={()=>setViewFracionadaDetails(null)} />
    }
    {
      !!openADE && (
        <AnexarADEModal
          portaria={openADE}
          onClose={() => setOpenADE(null)}
          onFinish={async () => {
            await get();
            await consultaPorSituacao();
          }}
        />
      )
    }
    {/* // VD-22513 - Samuel - 11/07/24 */}
    {!!openDfe && (
        <p.DfeModal
          // tela="monitor"
          portaria={openDfe}
          mod={55}
          onClose={() => setOpenDfe(null)}
          onFinish={ async  () => {
            if(!!openDfe.button) {
              await alterarSituacao(openDfe, -1);
            }
            await get();
            await consultaPorSituacao();
          }}
        />
      )}
      {/* // VD-22513 - Samuel - 11/07/24 */}
      {!!openNfp && (
        <p.NfpModal
          onClose={() => setOpenNfp(false)}
          tela="agendado"
          portaria={openNfp}
          onFinish={ async () => {
            await get();
            await consultaPorSituacao();
          }}
        />
          )}
      </>)
    }

function DetalheModal({idPrt, onClose}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.monitor}
  const [loading, setLoading] = useState(false)
  const [p, setP] = useState({}) // Portaria

  useEffect(()=>{ setLoading(true)
    App.api('portarias::consultaPorId',{id: idPrt})
      .then(r=>{ setP(r.results); setLoading(false)})
  }, [])

  return(
    <c.Modal onClose={onClose} loading={loading} cancelText={lang.fechar} onFinish={false}
      title={!!p.ID_UOCC&&lang.agendamento_de_op_tiket?.replace('%d', p.DS_OPRPRT).replace('%e', p.ID_TICKET)}>
      <div className="f f-wrap g2" style={{width: 1000}} >
        <c.Input label={lang.data_turno} value={p.DT_PRV + ' ' + p.DS_TRN} disabled={true} className='f3' />
        <c.Input label={lang.situacao}   value={p.DS_STS}   disabled={true} className='f3' />
        <c.Input label={lang.cliente}    value={p.NM_PRC}   disabled={true} className='f6' />
        <c.Input label={lang.origemgem}     value={p.NM_ORI}   disabled={true} className='f6' />
        <c.Input label={lang.destinotino}    value={p.NM_DST}   disabled={true} className='f6' />
        <c.Input label={lang.produto}    value={p.NM_PRO}   disabled={true} className='f4' />
        <c.Input label={lang.veiculo}    value={p.DS_TPVCL} disabled={true} className='f4' />
        <c.Input label={lang.placa}      value={p.LST_VCL}  disabled={true} className='f4' />
        <c.Input label={lang.transportadora} value={p.NR_CNPJTRP + ' ' + p.NM_TRP} disabled={true} className='f12' />
        <c.Input label={lang.motorista} value={p.NR_CPFMTR + ' ' + p.NM_MTR} disabled={true} className='f6' />
        <c.Input label={lang.cnh_categ_vencto} value={p.NR_CNHMTR + ' ' + p.DS_CTGCNHMTR + ' ' + p.DT_VCTCNHMTR} disabled={true} className='f3' />
        <c.Input label={lang.telefone}   value={p.NR_TLFMTR} disabled={true} className='f3' />
        <c.Input label={lang.data_agendado} value={p.DT_AGD} disabled={true} className='f2' />
        <c.Input label={lang.tempo_agendado} value={p.TEMPO_AGD} disabled={true} className='f2' />
        <c.Input label={lang.data_transito}  value={p.DT_TRS} disabled={true} className='f2' />
        <c.Input label={lang.tempo_transito} value={p.TEMPO_TRS} disabled={true} className='f2' />
        <c.Input label={lang.data_fila}      value={p.DT_EFILA} disabled={true} className='f2' />
        <c.Input label={lang.tempo_fila}     value={p.TEMPO_FILA} disabled={true} className='f2' />
        <c.Input label={lang.data_operacao}  value={p.DT_OPR1} disabled={true} className='f2' />
        <c.Input label={lang.tempo_operacao} value={p.TEMPO_OPR} disabled={true} className='f2' />
        <c.Input label={lang.data_saida}     value={p.DT_OPR2} disabled={true} className='f2' />
        <c.Input label={lang.tempo_fila_operacao} value={p.TEMPO_PATIO} disabled={true} className='f2' />
        <c.Input label={lang.tempo_consumo}  value={p.TEMPO_CONSUMO} disabled={true} className='f2' />
      </div>

      <c.Divider />

      <c.Table isSub={true} columns={[
        [lang.data_envio, 'DT_ENV'],
        [lang.telefone, 'NR_TLF'],
        [lang.mensagem, 'DS_MSG'],
      ]} data={p.LST_MSG} style={{width: 1000}} />

      <div className="p1">{lang.visualizado_em}{f.formatDate()}</div>
    </c.Modal>
  )
}

function LocalFisicoModal({idPrt, onClose}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.monitor}
  const [options, setOptions] = useState([])
  const [idLfis, setIdLfis] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true)
    App.api('portarias::listaLocalFisico', {idPrt})
      .then(r=>{setOptions(r.results); setLoading(false)})
  }, [])

  return(
    <c.Modal onClose={onClose} loading={loading}
      onFinish={()=>{
        setLoading(true)
        return App.api('portarias::gravaLocalFisico',{idPrt, idLfis}).then(r=>{
          setLoading(false)
          return r.status
        })
      }}
      validate={!!idLfis} successMsg={lang.alterado_sucesso}
    >
      <c.Select label={lang.local} options={options} value={idLfis} onChange={v=>setIdLfis(v.value)}/>
    </c.Modal>
  )
}

function TelMotorista({cpf, telefone, onClose, onFinish}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.monitor}

  const [tel, setTel] = useState( f.formatTel(telefone) )

  return(
    <c.Modal
      onClose={onClose}
      title="Alterar telefone"
      onFinish={async () => {
        await App.api('parceiros::alteraTelefone', { cpfcnpj: cpf, tel })

        if(onFinish) onFinish();

        return true
      }}
      validate={String(tel)?.replace(/\D/g, '').length===13} successMsg={lang.alterado_sucesso}
    >
      <c.Input label={lang.telefone} value={tel} onChange={e=>setTel( f.formatTel(e.target.value) )} />
    </c.Modal>
  )
}
