import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../App';

export function useUserPreferencesVIA() {
  const App = useContext(AppContext);
  const [isVIAInfoModalDisplayed, setIsVIAInfoModalDisplayed] = useState(false);

  useEffect(() => {
    setIsVIAInfoModalDisplayed(App.prefs.displayVIAInfoModalAgain);
  }, [App.prefs.displayVIAInfoModalAgain]);

  const handleCheckboxChange = (checked) => {
    setIsVIAInfoModalDisplayed(checked);
    App.setPrefs({ displayVIAInfoModalAgain: checked });
  };

  return {
    isVIAInfoModalDisplayed,
    handleCheckboxChange,
  };
}
