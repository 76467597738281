import { useCallback } from "react"

import DaterangePicker from "../../daterangePicker"

export const DaterangePickerFilter = ({ value, onChange, settings, label }) => {
  const { startDate, endDate } = value

  const handleChange = useCallback((startDate, endDate) => {
    if(onChange) onChange({ startDate, endDate })
  }, [onChange])

  return (
    <DaterangePicker
      {...settings}
      className="f1"
      endDate={endDate}
      label={label}
      onChange={handleChange}
      startDate={startDate}
    />
  )
}
