import { fileToBase64 } from "../../../../../../f"

export const persistInvoice = async ({ fields, App, records }) => {
  const {
    amount,
    discount,
    dueDate,
    images: [file],
    invoiceNumber,
    pdf = '',
  } = fields

  const params = {
    DS_PDF: '',
    dt_fatura: dueDate,
    id_usuario: App.user.id_usr,
    lst_portaria: records.map(
      ({ id }) => ({ ID_PRT: id.idPrt, DT_PRT: id.dtPrt, ID_UPRT: id.idUprt })
    ),
    nr_fatura: invoiceNumber,
    vl_desc: discount,
    vl_fatura: amount,
  }

  const [contentType, doc ] = pdf.split(',');

  if (contentType === 'data:application/pdf;base64' && doc) {
    params.DS_PDF = doc
  } else {
    params.DS_PDF = await fileToBase64(file)
  }

  const { status } = await App.api('controllership::create_invoice', params)

  return status
}
