import { BsFileTextFill as SettledIcon } from "react-icons/bs"
import {
  IoShieldCheckmark as AuthorizedIcon,
  IoShieldCheckmarkSharp as AuditedControllershipIcon
} from "react-icons/io5";
import { TbCoinFilled as InvoicedIcon } from "react-icons/tb"
import moment from "moment"

import { Checkbox, PortariaDownloadButtons } from "../../../../c"
import { Icon as IconColumn } from "../../../../c/Icon"
import { formatMoney, t } from "../../../../f"

import SITUATIONS from "../contents/situations.json"

import { AuditIconColumn } from "../columns/AuditIconColumn"
import { DelayedIconColumn } from "../columns/DelayedIconColumn"
import { SubRowColumn } from "../columns/SubRowColumn"
import { ActionColumn } from "../columns/ActionColumn"
import { Badge } from "../../../../c/Badge";

export const transformToRow = (props) => {
  const { api, columns,  confirm,  lang,  onFetchRecords,  onToggle } = props
  const { record: { selected, situation }, record } = props

  const situationData = SITUATIONS.find(({value}) => value === situation)

  const situationClassName = `${situationData.className}`
  const situationLabel = t(lang.card_filters, situation)

  const settledColor = record.finished ? 'var(--success)' : 'var(--disabled)'
  const invoicedColor = record.invoiced ? 'var(--info)' : 'var(--disabled)'
  const authorizedColor = record.authorized
                                    ? 'var(--success)'
                                    : 'var(--disabled)'
  const approvedColor = record.approved
                                    ? 'var(--info)'
                                    : 'var(--disabled)'

  const row = {
    ...record,

    record,
    selectable: <Checkbox checked={selected} className="column-field" onToggle={onToggle} />,
    finished: <IconColumn icon={SettledIcon} color={settledColor} />,
    invoiced: <IconColumn icon={InvoicedIcon} color={invoicedColor} />,
    authorized: <IconColumn icon={AuthorizedIcon} color={authorizedColor} />,
    approved: <IconColumn icon={AuditedControllershipIcon} color={approvedColor} />,
    taxDocument: <AuditIconColumn value={record.taxDocument} />,
    operation: <AuditIconColumn value={record.operation} />,
    service: <AuditIconColumn value={record.service} />,
    scheduleOn: moment(record.scheduleOn, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    delayed: <DelayedIconColumn delayed={record.delayed} />,
    deadline: moment(record.deadline, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    situation: <Badge text={situationLabel} color={situationClassName} size={'md'}/>,
    mainAmount: formatMoney(record.mainAmount, 2),
    additionalAmount: formatMoney(record.additionalAmount, 2),
    amount: formatMoney(record.amount, 2),
    downloads: <PortariaDownloadButtons auditoria portaria={{ ...record, ...record.id }} />,
    action: '',
    details: <SubRowColumn portaria={record} methods={{ api, confirm, onFetchRecords }} />,

    key: `${record.id.dtPrt}-${record.id.idPrt}-${record.id.idUprt}-${record.ticketId}`,
  }

  row.action = <ActionColumn user={props.user} columns={columns} row={row} onFetchRecords={onFetchRecords} />

  return columns.reduce(
    (rowColumns, [, field]) => ({ ...rowColumns, [field]: row[field] }),
    { record, id: record.id, key: row.key, details: row.details, }
  )
}
