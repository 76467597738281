import { useState, useEffect, useContext, useCallback, useMemo } from "react"

import { AppContext } from '../../../App'
import { Frame, Input, Table } from '../../../c'

import { DistribuidaModal } from './modals/DistribuidaModal'
import { DistribuirModal } from './modals/DistribuirModal'

export const DistribuirCotas = ({setReloader}) => {
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.distribuir_cotas}
  const icons = App.icons

  const [loading, setLoading] = useState({})
  const [busca, setBusca] = useState('')
  const [openDistribuir, setOpenDistribuir] = useState(false)
  const [openDistribuida, setOpenDistribuida] = useState(false)
  const [data, setData] = useState([])

  const parsedData = useMemo(() => {
    return data.map((cota)=>({
      ...cota,
      DS_OPRPRT: (
        <span className="f center g1 destaque c2">
          {cota.TP_OPRPRT === 'E'
            ? (<>
                  <icons.MdRedo size={16} />
                  {lang.carga}
                </>
              )
            : (
                <>
                  {lang.descarga}
                  <icons.MdRedo size={16} />
                </>
              )
          }
        </span>
      ),
      DISPONIVEL: (
        <button
          disabled={parseInt(cota.QT_DISPONIVEL) === 0}
          onClick={() => setOpenDistribuir(cota)}
        >
          {cota.QT_DISPONIVEL}
        </button>
      ),
      DISTRIBUIDA: (
        <button
          disabled={parseInt(cota.QT_DISTRIBUIDA) === 0}
          onClick={() => setOpenDistribuida(cota)}
        >
          {cota.QT_DISTRIBUIDA}
        </button>
      ),
    }))
    }, [data, lang.carga, lang.descarga])

    const get = useCallback(async () => {
      setLoading(true)

      try {
        const { results } = await App.api('cotas::getDistribuir')

        setData(results);
      } finally {
        setLoading(false)
      }
    }, [App])

    useEffect(() => {
      get()
    }, [get])

    useEffect(() => {
      get()
      setReloader(() => get)
    }, [get, setReloader])

    return(
      <Frame
        title={lang.distribuirCotas}
        loading={loading}
        control={(
          <Input
            clearable
            onChange={({ target }) => setBusca(target.value)}
            placeholder={lang.busca}
            value={busca}
          />
        )}
      >
        <Table
          search={busca}
          columns={[
            [lang.data, 'DT_COTA'],
            [lang.contrato, 'ID_CN'],
            [lang.pedido_cliente,'NR_CLI'],
            [lang.operacao, 'DS_OPRPRT'],
            [lang.cliente, 'NM_PESPRC'],
            [lang.origem, 'NM_PESORI'],
            [lang.destino, 'NM_PESDST'],
            [lang.produto, 'NM_PRO'],
            [lang.turno, 'DS_TRN'],
            [lang.cota, 'QT_COTA',{},'f center'],
            [lang.distribuir, 'DISPONIVEL',{},'f center'],
            [lang.distribuida, 'DISTRIBUIDA',{},'f center'],
            [lang.consumida, 'QT_CONSUMO',{},'f center'],
          ]}
          data={parsedData}
        />

        {!!openDistribuida && (
          <DistribuidaModal
            cota={openDistribuida}
            onClose={() => {
              setOpenDistribuida(null);
              get()
            }}
            onFinish={() => get()}
          />
        )}
        {!!openDistribuir && (
          <DistribuirModal
            cota={openDistribuir}
            onClose={() => setOpenDistribuir(null)}
            onFinish={() => get()}
          />
        )}
      </Frame>
    )
  }
