import { useMemo } from 'react'
import {
  BsCircleFill as OkIcon,
  BsFillTriangleFill as FailIcon,
} from "react-icons/bs"

import { Icon } from '../../../../../c/Icon'

import './styles.sass'

export const AuditIconColumn = ({ value, size = 32 }) => {
  const [IconComponent, color, className] = useMemo(() => {
    if (value === 0) return [OkIcon, 'var(--success)', 'ok']

    return [FailIcon, 'var(--warning)', 'fail']
  }, [value])

  return (
    <Icon
      icon={IconComponent}
      size={size}
      color={color}
      label={value}
      className={`audit-icon ${className}`}
    />
  )
}
