import { useCallback, useContext, useMemo, useState } from 'react';
import { AppContext } from '../App';
import * as components from '../c';
import * as formUtils from '../f';

export default function AnexarCanhotoModal({ cota, onClose, onFinish }) {
  const { lang, api } = useContext(AppContext);
  const language = useMemo(() => ({
    ...lang.global,
    ...lang.transportador,
  }), [lang.global, lang.transportador]);

  const [loading, setLoading] = useState(false);
  const [nomeRecebedor, setNomeRecebedor] = useState('');
  const [documentoRecebedor, setDocumentoRecebedor] = useState ('');
  const [dataEntrega, setDataEntrega] = useState('');
  const [observacao, setObservacao] = useState('');
  const [images, setImages] = useState([]);

  const convertImageToBase64 = useCallback(async (file) => {
    return await formUtils.fileToBase64(file);
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const imagem = await convertImageToBase64(images[0].file);
    const idUocc = cota.ID_UOCC ?? cota.ticketId;
    const extensaoImagem = images[0].name.split('.').pop();

    const { status } = await api('recordDocuments::leftHandedRecording', {
      id_uocc: idUocc,
      nome_recebedor: nomeRecebedor,
      documento_recebedor: documentoRecebedor,
      data_entrega: dataEntrega,
      ds_obs: observacao,
      tp_ext: extensaoImagem,
      imagem,
      ds_tag: 'canhoto',
    });

    setLoading(false);
    if (onFinish) onFinish();

    return status;
  }, [convertImageToBase64, images, cota.ID_UOCC, cota.ticketId, api, nomeRecebedor, documentoRecebedor, dataEntrega, observacao, onFinish]);

  return (
    <components.Modal
      largeWidth
      loading={loading}
      onClose={onClose}
      onFinish={handleSubmit}
      title={language.anexar_canhoto}
      validate={!!nomeRecebedor && !!dataEntrega && !!documentoRecebedor && images.length > 0}
    >
      <div className='f g1'>
        <div className='w1-2 f g1 f-column w50'>
          <div className='f g1 f-column'>
            <div className='f g1'>
              <components.Input
                label={language.nome_recebedor}
                error={!nomeRecebedor}
                required
                value={nomeRecebedor}
                onChange={e => setNomeRecebedor(e.target.value)}
                className="w1-3"
              />
              <components.Input
                label={language.documento_recebedor}
                error={!documentoRecebedor}
                required
                type="number"
                value={documentoRecebedor}
                onChange={e => setDocumentoRecebedor(e.target.value)}
                className="w1-3"
              />
              <components.Input
                label={language.data_entrega}
                error={!dataEntrega}
                required
                type="date"
                value={dataEntrega}
                onChange={e => setDataEntrega(e.target.value)}
                className="w1-3"
              />
            </div>
            <components.Input
              label={language.observacao}
              value={observacao}
              onChange={e => setObservacao(e.target.value)}
            />
          </div>

          <components.Upload
            accept={{"image/png": ['.png'], "image/jpeg": ['.jpg']}}
            label="Arquivo aceito: JPG, e PNG. Máximo de 1 arquivo."
            maxFiles={1}
            files={images}
            onChange={setImages}
            className='w100'
          />
        </div>

        <div className='w50'>
          <components.Span
            label={"Pré-visualização"}
            className='center-v'
            value={images[0]
                    ? <img src={images[0].preview} alt="Preview" className='w100' style={{maxHeight: 400}} />
                    : <div className='p10'>Selecione um arquivo para visualizar</div>
                  }
          />
        </div>
      </div>
    </components.Modal>
  );
}
