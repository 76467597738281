export const saveAudit = async ({ api, records, action }) => {
  if (action !== 'approve' && action !== 'disapprove') return

  const { status } = await api(
    `controllership::${action}`,
    { tickets: records.map(({ ticketId }) => ticketId) }
  )

  return status
}
