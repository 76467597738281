import { parseFilters } from './parseFilters'

const parseTab = (tab) => {
  if(tab === 0) return 'travel_records'

  if(tab === 1) return 'payment_records'

  if(tab === 2) return 'invoice_records'

  return ''
}
export const fetchData = async ({ api, filters: rawFilters, user, tab: rawTab }) => {
  if (user.in_uorgadm !== 'T' && user.in_trp !== 'T') return []

  const filters  = parseFilters(rawFilters)
  const tab = parseTab(rawTab)

  const [tabsResponse, recordsResponse] = await Promise.all([
    api('controllership::tab_counters', { ...filters, tab }),
    api('controllership::records', { ...filters, tab }),
  ])

  const { results: { tabs = [] } } = tabsResponse
  const { results: { records = [] } } = recordsResponse

  return {
    records: records.map((record) => ({ ...record, selected: false })),
    tabs,
  }
}
