import moment from "moment";
import { t } from "../../../f"

export const fetchQuotationData = async (api, lang, { endQuotation, ...filters}) => {
  const { startDate: endQuotationFrom, endDate: endQuotationUntil } = endQuotation ?? {};
  const data = {
    ...filters,
    endQuotationFrom: endQuotationFrom ? moment(endQuotationFrom).format('YYYY-MM-DDTHH:mm') : undefined,
    endQuotationUntil: endQuotationUntil ? moment(endQuotationUntil).format('YYYY-MM-DDTHH:mm') : undefined,
  }

  const { results: quotations } = await api('ListQuotation', data);

  return quotations.map(({
    itinerary,
    situation,
    ...rest
  }) => ({
    ...rest,
    itinerary: {
      ...itinerary,
      name: `${itinerary.code} - ${itinerary.name}`,
      id: itinerary.id,
    },
    situation: {
      code: situation.name,
      name: t(lang.situations, situation.name),
      id: situation.id,
    },
  }))
}
