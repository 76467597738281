import React, { useMemo } from 'react'

import { Table } from '../../../../../c'

export const ServiceStep = ({ step, columns: initialColumns, rows }) => {
  const columns = useMemo(() => {
    return initialColumns.filter(([, field]) => {
      const ignoredColumns = [
        'action',
        'approved',
        'downloads',
        'authorized',
        'invoiced',
        'operation',
        'selectable',
        'finished',
        'taxDocument',
        'service',
      ]

      return !ignoredColumns.includes(field)
    })
  }, [initialColumns])

  if(step !== 0 ) return <></> //step 0 é serviço

  return (
    <div className="f g2 f-column">
      <div className="f g2 w100">
        <Table isSub columns={columns} data={rows} pagination="no-pagination" />
      </div>
    </div>
  )
}
