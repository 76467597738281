import React, { useState, useEffect, useCallback } from 'react';

import { ModalInput } from './modals/ModalInput';
import { InputDisplay } from './components/InputDisplay';

import './styles.sass';

const MIN_ITEMS = 1;
const MAX_ITEMS = 500;

export const InputMultiFilter = ({
  label,
  maxItems = 500,
  onChange,
  settings,
  value,
}) => {
  const [collection, setCollection] = useState(value ?? []);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleChange = useCallback((newCollection) => {
    setCollection(newCollection)
    if(onChange) onChange(newCollection)
  }, [onChange])

  const handleCollectionChange = useCallback((newValue, position) => {
    const lastChar = newValue.slice(-1);

    if([',', ';', ' '].includes(lastChar)) {
      throw new Error('Após os delimitadores ",", ";" e/ou " ", deve conter um valor para filtro')
    }

    const newItems = newValue ? newValue.split(/[,; ]/) : []
    const newCollection = [
      ...collection.slice(0, position),
      ...newItems,
      ...collection.slice(position + 1)
    ]

    if(newCollection.includes('')) {
      throw new Error(`
        Não é permitido ",", ";" e/ou " ", no inicio, no fim ou consecutivos no interior do Filtro.
        EX: " 123", "123 ", "123;,4a5"
        `.replace(/[ ]{3,}/g, '')
      )
    }

    if(newCollection.length > maxItems) {
      throw new Error(`A quantidade de itens pesquisados excedeu o valor maximo de ${maxItems} itens`)
    }

    const length = newCollection.reduce( // removendo valores duplicados
      (items, item) => {
        if(items.includes(item)) return items

        return [...items, item]
      },
      [],
    ).length

    if(newCollection.length > length) {
      throw new Error(`Existe um ou mais itens duplicados`)
    }

    handleChange(newCollection);
  }, [collection, handleChange, maxItems]);

  const handleClearCollection = useCallback(() => {
    handleChange([]);
  }, [handleChange]);

  useEffect(() => {
    if (maxItems < MIN_ITEMS || maxItems > MAX_ITEMS) {
      throw new Error(`Prop maxItem should be between ${MIN_ITEMS} and ${MAX_ITEMS}`);
    }
  }, [maxItems]);

  return (
    <div className="input-multi-filter">
      <InputDisplay
        collection={collection}
        label={label}
        onInputChange={handleCollectionChange}
        onOpenModal={handleOpenModal}
        settings={settings}
      />

      {modalOpen && (
        <ModalInput
          collection={collection}
          label={label}
          maxItems={maxItems}
          onClear={handleClearCollection}
          onClose={handleCloseModal}
          onCollectionChange={handleCollectionChange}
        />
      )}
    </div>
  );
};
