import { useContext, useMemo, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";

import { AppContext } from "../../../../App";
import { Frame, Table } from '../../../../c'

import { DropdownActionMenu } from "../DropDownMenu";
import { QuotationDetails } from "./QuotationDetails";
import { ManagerQuotationDetail } from "../ManagerQuotationDetail";
import { ShippingCompanyQuotationDetail } from "../ShippingCompanyQuotationDetail";

export const QuotationTable = ({ records, setEditing, onRelaod }) => {
  const { user: { in_trp } } = useContext(AppContext)
  const [loading, setLoading] = useState(false)

  const rows = useMemo(() => {
    try {
      setLoading(true)

      return records.map((record) => {
        const {
          itinerary,
          situation,
          product,
          vehicleTypes,
        } = record

        const QuotationDetail = in_trp === 'T'
          ? ShippingCompanyQuotationDetail
          : ManagerQuotationDetail

        if (situation.id === 5 && in_trp === 'T') {
          return null;
        }

        return ({
          ID_CN: record.id,
          ID_EXT: itinerary.name,
          CD_STS: situation.name,
          DT_EMS: moment(record.startQuotation).format('DD/MM/YYYY HH:mm:ss'),
          DT_FIM: moment(record.endQuotation).format('DD/MM/YYYY HH:mm:ss'),
          DS_PRO: product?.name ?? '',
          TP_VCL: vehicleTypes.map(({ name }) => name).join(', '),
          MIN_VCL: record.minimumVehicleQuantity,
          actions: (
            <DropdownActionMenu
              quotation={record}
              setEditing={setEditing}
              control={<BsThreeDotsVertical />}
            />
          ),
          sub: (
            <Frame>
              <QuotationDetails record={record} setEditing={setEditing} />
              <div style={{ marginTop: "1rem" }} />
              <QuotationDetail record={record} setEditing={setEditing} onRelaod={onRelaod} />
            </Frame>
          )
        })
      }).filter(Boolean);
    } finally {
      setLoading(false)
    }
  },[in_trp, onRelaod, records, setEditing])

  return(
    <Table loading={loading}
      columns={[
        ['#','ID_CN'],
        ['Itinerario','ID_EXT'],
        ['Situação', 'CD_STS'], // Situação: Programada, Em concorrência, Encerrada ou Cancelada
        ['Data abertura','DT_EMS'],
        ['Data encerramento','DT_FIM'],
        ['Produto','DS_PRO'],
        ['Tipo de veículo','TP_VCL'], // Tipo de veículo: (Necessita nova tabela, se aplicável).
        ['Minimo de veículos','MIN_VCL'],
        ['Ação', 'actions', {}, 'center f g1']
      ]}
      data={rows}
      hasSub = {'sub'}
    />
  );
}
