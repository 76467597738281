import ReactDOM from "react-dom"

import 'react-toastify/dist/ReactToastify.css'

import Application from "./App"
import env from "./.env.json"

// 0 = Produção
// 1 = Pré-deploy
// 2 = Homologação
// 3 = Homologação - QA
// 4 = Teste
// 5 = Dev local

ReactDOM.render(<Application env={Number(env ?? '0')} />,
  document.getElementById("root"),
);

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', async () => {
//     try {
//     const registration = await navigator.serviceWorker.register('/service-worker.js')
//       console.log('Service worker registrado com sucesso: ', registration.scope)

//       navigator.serviceWorker.addEventListener('message', (event) => {
//         if (event.data === 'reload') {
//           window.location.reload();
//         }
//       });

//       Notification.requestPermission()
//     } catch(error) {
//       console.error('Erro ao registrar o service worker: ', error)
//     }
//   })

//   navigator.serviceWorker.addEventListener('message', (event) => {
//     if (event.data && event.data.type === 'update_available') {
//       window.location.reload();
//     }
//   });
// }
