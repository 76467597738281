const getSelected = (records) => records.filter((record) => record.selected)
const getFiltered = (records, filter) => {
  const { field, value } = filter

  if(!field || !value) return records

  return records.filter((record) => record[field] === value)
}

export const filterRecords = ({ records, filter }) => {
  const filtered = getFiltered(records, filter)
  const selected = getSelected(filtered)

  return { filtered,  selected }
}
