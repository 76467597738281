import { ItineraryFields } from "./ItineraryFields"
import { ObservationsFields } from "./ObservationsFields"
import { QuotationFields } from "./QuotationFields"
import { ShippingCompaniesFields } from "./ShippingCompaniesFields"

export const SummaryStep = ({ form, lang }) => {
  return (
    <div className="f g1 f3 f-column">
      <div className="f g1 w100">
        <ItineraryFields form={form.itinerary ?? {}} lang={lang} />
        <QuotationFields form={form} lang={lang} />
        <ShippingCompaniesFields form={form} lang={lang} />
      </div>
      <div className="f g1 w100">
        <ObservationsFields observations={form.observations} lang={lang} />
      </div>
    </div>
  )
}
