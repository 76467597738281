import { useContext } from "react";
import Modal from "../../modal";
import { generateModalContent } from "./ModalContents";
import { AppContext } from "../../../App";

export function VIAInfoModal({ type, onClose }) {
  const App = useContext(AppContext);
  const modalContent = generateModalContent(type);

  if (!modalContent) return null;
  if (!App.components.ticket_ocr) return null;

  return (
    <div>
      <Modal
        title={modalContent.title}
        onClose={onClose}
        largeWidth
        validate={true}
        showCheckbox={true}
        cancelText='Sair'
      >
        {modalContent.content}
      </Modal>
    </div>
  );
}
