import { useCallback } from "react"

export function Switch({
  checked = false,
  onChange,
  onToggle,
  disabled = false,
  title,
  label = null
}) {
  const handleChange = useCallback((event) => {
    if(onChange) onChange(event)
    if(onToggle) onToggle(event.target.checked)
  }, [onChange, onToggle])

  return (
    <label
      className={[
        "switch",
        (disabled ? 'disabled' : ''),
        (label ? 'with-label' : ''),
        (checked ? 'checked' : ''),
      ].join(' ')}
      title={title}
    >
      <div className='switch-container'>
        <input
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          type="checkbox"
        />
        <span className="switch-slider"></span>
      </div>
      {label && <div className='label'>{label}</div>}
    </label>
  )
}
