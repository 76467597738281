export const AddressDetails = ({ items }) => (
  <div className='address-details'>
    {items.map(({ label, value }) => (
      <div key={label}>
        <h3>{label}:</h3>
        <div className="form-group">{value}</div>
      </div>
    ))}
  </div>
);
