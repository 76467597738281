import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as p from './'
import * as f from '../f'

export default function Estoques({setReloader}){
  const App = useContext(AppContext),
        //VD-9855 Por Hugo em 16/05/2024
        //lang = {...App.lang.global, ...App.lang.estoques},
        lang = {...App.lang.global, ...App.lang.estoques, ...App.lang.ordem_carregamento},
        //
        icons = App.icons

  const [data, setData] = useState([]),
        [selectedFile, setSelectedFile] = useState(null),
        [loading, setLoading] = useState(null),
        [busca, setBusca] = useState(''),
        [showFilters, setShowFilters] = useState(false)

  function zerarQuantidade(item){
    App.api('estoques::zerarQuantidade',{...item}).then(r =>{
      get()
    })
  }

  function get(){
    setLoading(true)

    App.api('estoques::list').then(r =>{
      setData(r.results.map(est =>({
        ...est,
        ACTION:
        <div className="center f g1">
          <c.IconButton title="Excluir"
            onClick={() => zerarQuantidade(est)} >
            <icons.BsTrashFill />
          </c.IconButton>
        </div>
      })))
    })

    setLoading(false)
  }

  useEffect(() => { get()
    setReloader(()=>get)
  }, [])

  useEffect(() => {
    if( selectedFile ) upload()

    async function upload(){
      setLoading(true)
      const reader = new FileReader()

      reader.onload = async e => {
        (await App.api('estoques::upload', { file: e.target.result.split(',')[1] })).status
          && App.toast.success('Lista carregada com sucesso!')
        get()
      }
      reader.readAsDataURL(selectedFile)

      setSelectedFile(null)
    }
  }, [selectedFile])

  return(
    <c.Frame title={"Estoques"} loading={loading}
      control={<c.Input placeholder={lang.busca} value={busca} onChange={e=>setBusca(e.target.value)} clearable />}
      actions = {{
        upload: () => document.getElementById('fileInput').click(),
        filter: {onClick: () => setShowFilters(!showFilters), value: showFilters},
      }}
    >
      <c.Table search={busca} showFilters={showFilters} hasSub={'_SUB'}
        columns={[
          ['#','ID_PRO'],
          ['Nome', 'NM_PRO'],
          ['Un. medida', 'NM_UNDMDD'],
          ['Equivalência', 'DS_EQV'],
          ['Unidade', 'ID_UORG'],
          ['Estoque', '_QT_MPRO'],
          ['Em pedidos', '_EM_PEDIDOS'],
          ['Em Ordem de Carregamento', "_EM_OC"],
          ['Saldo', 'SALDO'],
          ['', 'ACTION', {}, 'force-fit']
        ]}
        data={
          data.map(item => ({...item,
            _QT_MPRO: f.formatNumber( item.QT_MPRO ),
            _EM_PEDIDOS: f.formatNumber( item.EM_PEDIDOS ?? 0 ),
            _EM_OC: f.formatNumber( item.EM_OC ?? 0 ),
            SALDO: f.formatNumber( item.QT_MPRO - item.EM_PEDIDOS ),
            _SUB:
              <div className="f g2 w100">
                <div className="f g2 f-column w50">
                  <c.Table isSub noFooter
                  columns={[
                    [lang.pedido, 'ID_CN'],
                    [lang.cliente, 'NM_PES'],
                    [lang.quantidade, 'QT_SLC'],
                  ]} data={item.pedidos} />
                  </div>
                <div className="f g2 f-column w50">
                <c.Table isSub noFooter
                  columns={[
                    [lang.ordem_carregamento, 'ID_OC'],
                    [lang.data, 'DT_OC'],
                    [lang.quantidade, 'QT_EXP'],
                  ]} data={item.ocs} />
                  </div>
              </div>
          }))
        }
      />

      <input type="file" id="fileInput" accept=".xlsx" style={{display: 'none'}}
        onChange={(e) => setSelectedFile(e.target.files[0])}
      />
    </c.Frame>
  )
}
