import { useContext, useEffect, useState } from "react"
import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet"
import Leaflet from "leaflet"

import { AppContext } from "../../../../App"
import { Checkbox, Divider, Frame, Input, Modal, Select, Table } from "../../../../c"
import { formatCurrency, formatNumber, toFloat } from "../../../../f"

export function ContratoEditarModal({ contrato, op, onClose, onFinish }) {
  const App = useContext(AppContext),
    lang = { ...App.lang.global, ...App.lang.contratos },
    icons = App.icons

  const [pauses_lst, setPauses_lst] = useState([]),
    [transportadoras, setTransportadoras] = useState([]),
    [validate, setValidate] = useState(false),
    [loading, setLoading] = useState(false),
    [data, setData] = useState({ op, idCn: contrato.ID_CN }),
    [listas, setListas] = useState({}),
    [loadingMap, setLoadingMap] = useState(false),
    [keyMAP, setKeyMAP] = useState(Math.random()),
    [pedagios, setPedagios] = useState([]),
    [rotaOrigemDestino, setRotaOrigemDestino] = useState([]),
    [posOrigemDestino, setPosOrigemDestino] = useState({ inicio: [], fim: [] }),
    [position, setPosition] = useState([-23.415881526446956, -51.93903347350793]),
    [rota, setRota] = useState({ lat_lng_orig: [], lat_lng_dest: [] }),
    [edDest, setEdDest] = useState({
      NM_LGD: '',
      NR_LGD: '',
      NM_BRO: '',
      NM_LOC: '',
      CD_IBGE_LOC: '',
      ID_UF: '',
      NM_PAIS: '',
      ID_OBJUNC: ''
    }),
    [edRmt, setEdRmt] = useState({
      NM_LGD: '',
      NR_LGD: '',
      NM_BRO: '',
      NM_LOC: '',
      CD_IBGE_LOC: '',
      ID_UF: '',
      NM_PAIS: '',
      ID_OBJUNC: ''
    }),
    [listaDestino, setListaDestino] = useState([]),
    [listaOrigem, setListaOrigem] = useState([]) //Lucas - VD-22600 ou VD-10553, 10/07/2024

  const iconePedagio = new Leaflet.divIcon({
    className: "",
    iconSize: [32, 32], iconAnchor: [4, 10],
    labelAnchor: [-4, 0], popupAnchor: [0, -5],
    html: `<span style="color:#d04933;" class="fa fa-circle"></span>`,
  })

  function deletePause(id, ini, fim) {
    window.confirm(lang.confirm_pause_del.replace('%ini', ini).replace('%fim', (fim || lang.indeterminado))) &&
      App.api('contratos::setPauseStatus', { idCn: contrato.ID_CN, id, sts: -3 })
        .then(r => setPauses_lst(r.results))
  }

  function submit() {
    setLoading(true)
    return App.api('contratos::editar', {
      ...data,
      VL_FRETE: parseFloat(data.VL_FRETE),
      VL_PEDAGIO: parseFloat(data.VL_PEDAGIO),
      VL_FRETEMOTORISTA: parseFloat(data.VL_FRETEMOTORISTA),
      DS_OBS: data.DS_OBS.filter(o => !!o.ds_obs),
      ED_DEST: edDest,
      ED_ORIG: edRmt //Lucas - VD-22600 ou VD-10553, 10/07/2024
    }).then(r => {
      if (r.status) onFinish()
      setLoading(false)
      return r.status
    })
  }

  useEffect(() => {
    setLoading(true)
    get()
    setLoading(false)

    async function get() {
      const _contrato = (await App.api('contratos::consultaPorId', { idCn: contrato.ID_CN })).results
      setData({
        ...data, ..._contrato,
        qt_saldo: _contrato.qt_volume,
        qt_saldoatual: _contrato.qt_volume,
        qt_volume: _contrato.qt_volumetotal,
        VL_FRETE: _contrato.vl_frete,
        VL_FRETEMOTORISTA: _contrato.vl_fretemotorista,
        VL_PEDAGIO: _contrato.vl_pedagio,
        qt_cota: Math.ceil(_contrato.QT_CADENCIA / _contrato.QT_CONVERSAO),
        transportadoras: _contrato.lst_transp?.map(t => t.NR_CNPJTRP),
        lst_transp: _contrato.lst_transp?.map(t => ({ label: t.NM_TRP, value: t.NR_CNPJTRP })),
        DS_OBS: JSON.parse(_contrato.DS_OBS ?? '[]')
      })

      if (op === 'pausar') {
        App.api('contratos::getPauses', { idCn: contrato.ID_CN }).then(r => setPauses_lst(r.results))
      }

      if (op === 'transportadora') {
        App.api('transportadoras::get')
          .then(r => {
            setTransportadoras(r.results.map((v, i) => ({ value: v.NR_CNPJTRP, label: v.NM_TRP, key: i })))
          })
      }
    }
  }, [])

  useEffect(() => {
    setValidate(
      op === 'pausar'
        ? !!data.DT_INICIO
      : op==='tarifa'
        ? (!!data.VL_FRETE && !!data.VL_FRETEMOTORISTA && data.dtVldFrete) || (!!data.VL_PEDAGIO && data.dtVldPedagio)
      : op==='lote'
        ? !!data.QT_VOLUME && !!data.QT_CADENCIA && !!data.QT_CONVERSAO && !!data.QT_COTA && data.QT_CADENCIA|0 >= data.QT_CONVERSAO|0
      : op==='transportadora'
        ? !!data.transportadoras?.length
      : op==='encerrar'
        ? !!data.dt_termino
      : op==='reiniciar'
        ? !!data.DT_INICIO
      : op==='obs'
        ? true
      : op==='origem_destino'
        ? true
      : op==='vincular_fluxo'
        ? true
      : false
    )
  }, [data])

  useEffect(() => {
    if (!!data.NR_CNPJEXP && !!data.NR_CNPJRCB && op === 'origem_destino') {
      Promise.all([
        App.api('fob::getEmbarcadores', { adicionais: true, s: data.NR_CNPJEXP }),
        App.api('fob::getEmbarcadores', { adicionais: true, s: data.NR_CNPJRCB })
      ]).then((r) => {
        const exp = r[0];
        const rcb = r[1];
        const id_cli = rcb.results[0].adicional.id_pes;
        const id_rmt = exp.results[0].adicional.id_pes;
        //Lucas - VD-22600 ou VD-10553, 10/07/2024
        const ed_dest_cn = {
          label: data.DEST_NM_LGD ?? rcb.results[0].adicional.nm_lgd,
          value: -1,
          ID_OBJUNC: -1,
          NM_LOC: data.DEST_NM_LOC ?? rcb.results[0].adicional.nm_loc,
          ID_UF: data.DEST_UF ?? rcb.results[0].adicional.nm_uf,
          NM_PAIS: 'BR'
        };

        const ed_rmt_cn = {
          label: data.RMT_NM_LGD ?? exp.results[0].adicional.nm_lgd,
          value: -1,
          ID_OBJUNC: -1,
          NM_LOC: data.RMT_NM_LOC ?? exp.results[0].adicional.nm_loc,
          ID_UF: data.RMT_UF ?? exp.results[0].adicional.nm_uf,
          NM_PAIS: 'BR'
        };

        let newListaOrigem = [];
        let newListaDestino = [];

        App.api('contratos::getEndereco', { id_cli: id_cli }).then(ed => {
          newListaDestino = ed.results.map(e => ({
            ...e,
            value: e.ID_OBJUNC,
            label: e.NM_LGD ?? e.NM_LOC
          }));
          // Verifica se o novo endereço já existe na lista antes de adicionar
          if (!newListaDestino.some(item => item.label === ed_dest_cn.label && item.NM_LOC === ed_dest_cn.NM_LOC)) {
            newListaDestino.push(ed_dest_cn);
          }
          setListaDestino(newListaDestino);

          // Verifica se o endereço selecionado está na lista e o seleciona
          const selectedDestino = newListaDestino.find(item => item.label === ed_dest_cn.label && item.NM_LOC === ed_dest_cn.NM_LOC);
          if (selectedDestino) {
            setEdDest(selectedDestino);
          }
        });

        App.api('contratos::getEndereco', { id_cli: id_rmt }).then(ed => {
          newListaOrigem = ed.results.map(e => ({
            ...e,
            value: e.ID_OBJUNC,
            label: e.NM_LGD ?? e.NM_LOC
          }));
          // Verifica se o novo endereço já existe na lista antes de adicionar
          if (!newListaOrigem.some(item => item.label === ed_rmt_cn.label && item.NM_LOC === ed_rmt_cn.NM_LOC)) {
            newListaOrigem.push(ed_rmt_cn);
          }
          setListaOrigem(newListaOrigem);

          // Verifica se o endereço selecionado está na lista e o seleciona
          const selectedOrigem = newListaOrigem.find(item => item.label === ed_rmt_cn.label && item.NM_LOC === ed_rmt_cn.NM_LOC);
          if (selectedOrigem) {
            setEdRmt(selectedOrigem);
          }
        });

        setListas({ ...listas, origens: exp.results, destinos: rcb.results });
      });
    }
  }, [data.NR_CNPJEXP, data.NR_CNPJRCB]); //Lucas - VD-22600 ou VD-10553, 10/07/2024

  // Tarifas
  const [lstTarifas, setLstTarifas] = useState([])
  useEffect(() => getTarifas(), [])
  function getTarifas() {
    App.api('contratos::getLstTarifas', { idCn: contrato.ID_CN }).then(r => {
      let _tarifas = {}
      let x = ['frete', 'motorista', 'pedagio'].map(tipo => {
        _tarifas = {
          ..._tarifas,
          [tipo]: r.results?.[tipo].map((item, i) => ({
            ...item,
            //DT_FIMVLD: item.DT_FIMVLD??'Indeterminado',
            value: parseFloat(item.VL_UBRT).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            action: parseInt(item.CD_STS) === 1 && i === 0 &&
              <button onClick={() => deleteTarifa(item.ID_TPCO)} style={{ color: 'white', background: 'indianred' }}><icons.MdDelete style={{ fontSize: 16 }} /></button>,
            disable: item.CD_STS === '-3',
          }))
        }
        return true
      })
      setLstTarifas(_tarifas)
    })
  }

  function deleteTarifa(tipo) {
    App.confirm('Confirma a exclusão da tarifa?',
      () => App.api('contratos::delTarifa', { idCn: contrato.ID_CN, tipo }).then(r => { if (r.status) { getTarifas() } return r.status })
    )
  }

  //Pedagio
  useEffect(() => {
    if (op === 'origem_destino') {
      atualizaPedagios();
    }
  }, [listas, edDest, edRmt]) //Lucas - VD-22600 ou VD-10553, 10/07/2024

  //Pedagio
  useEffect(() => {
    if (op === 'origem_destino') {
      if (listaOrigem.length !== 0 && listaDestino.length !== 0) {
        atualizaPedagios();
      }
    }
  }, [listas, edDest])

  async function buscarLongitudeLatitude(objeto, posicao = '') {
    let r = '';
    let endereco = '';
    let enderecoOrigemSemLGD = '';

    if (posicao !== '') {
      endereco = `${objeto?.NM_LGD}, ${objeto?.NR_LGD}, ${objeto?.NM_LOC}, ${objeto?.ID_UF}, ${objeto?.NM_PAIS}`;
      enderecoOrigemSemLGD = `${objeto?.NM_LOC}, ${objeto?.ID_UF}, ${objeto?.NM_PAIS}`;
    }
    else {
      endereco = `${objeto?.adicional?.nm_lgd}, ${objeto?.adicional?.nr_lgd}, ${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`
      enderecoOrigemSemLGD = `${objeto?.adicional?.nm_loc}, ${objeto?.adicional?.nm_uf}, ${objeto?.adicional?.nm_pais}`
    }
    r = (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${endereco}&format=geojson`)).json()).features[0]?.geometry?.coordinates
      ?? (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${enderecoOrigemSemLGD}&format=geojson`)).json()).features[0]?.geometry?.coordinates
    return r;

  }

  function atualizarCustos(lat_lng_orig, lat_lng_dest) {
    fetch('https://velog.vertti.com.br/api/roteiro', {
      method: 'POST', headers: { 'content-type': 'application/json, text/plain, */*' },
      body: JSON.stringify({
        sn_lat_lng: true, sn_rota_alternativa: false, sn_pedagio: true, sn_balanca: true,
        tag: "TRUCK", sn_calcu_volta: false, qtd_eixos: 6, veiculo_km_litro: 0, valor_medio_combustivel: 0,
        rotas: [{ lat: lat_lng_orig[1], lng: lat_lng_orig[0] }, { lat: lat_lng_dest[1], lng: lat_lng_dest[0] }],
      })
    }).then(r => r.json()).then(r => {
      setPedagios(r[0].track.rotas[0].pedagios)
      setRotaOrigemDestino(r[0].track.rotas[0].lng_lat)
      setPosOrigemDestino({
        inicio: [
          r[0].track.rotas[0].lng_lat[0][1],
          r[0].track.rotas[0].lng_lat[0][0],
        ],
        fim: [
          r[0].track.rotas[0].lng_lat[r[0].track.rotas[0].lng_lat.length - 1][1],
          r[0].track.rotas[0].lng_lat[r[0].track.rotas[0].lng_lat.length - 1][0],
        ],
      })
      setKeyMAP(Math.random());
      setData({
        ...data,
        VL_PEDAGIO: (r[0].track.rotas[0].vl_total_pedagio_original / 6)?.toFixed(2) ?? 0,
        QT_KM: r[0].track.rotas[0].distancia,
        HR_DURACAO: r[0].track.rotas[0].duracao
      })
    })
  }

  async function atualizaPedagios() {
    if (listas.origens?.length > 0 && listaDestino?.length > 0) {
      if (!!data.NR_CNPJEXP && !!data.NR_CNPJRCB) {
        setLoadingMap(true)
        const origem = !!edRmt.ID_OBJUNC ? listaOrigem?.find(o => parseInt(o.value) === parseInt(edRmt.ID_OBJUNC)) : null //Lucas - VD-22600 ou VD-10553, 10/07/2024
        const destino = !!edDest.ID_OBJUNC ? listaDestino?.find(o => parseInt(o.value) === parseInt(edDest.ID_OBJUNC)) : null //Lucas - VD-22600 ou VD-10553, 10/07/2024
        if (origem !== null && destino !== null) {
          const posOrigem = await buscarLongitudeLatitude(origem, 'destino') //Lucas - VD-22600 ou VD-10553, 10/07/2024
          const posDestino = await buscarLongitudeLatitude(destino, 'destino') //Lucas - VD-22600 ou VD-10553, 10/07/2024
          if (posOrigem) {
            setRota({ ...rota, lat_lng_orig: posOrigem, lat_lng_dest: posDestino })
            setPosition([posOrigem[1], posOrigem[0]])
            atualizarCustos(posOrigem, posDestino)
          }

        }
        setLoadingMap(false)
      }
    }

  }

  return (
    <Modal largeWidth={op === 'origem_destino'} title={lang.editar_contrato.replace('%d', contrato.ID_CN) + ' - ' + lang[op]}
      validate={validate} loading={loading} onClose={onClose} onFinish={submit}
      extraControls={!(op === 'obs') ? null :
        <button title={lang.adicionar} onClick={() => { data.DS_OBS = data.DS_OBS.filter(o => !!o.ds_obs); data.DS_OBS.push({ ds_obs: '' }); setData({ ...data }) }}><icons.MdAdd />{lang.adicionar}</button>
      }
    >
      <div className="f f-column g1 w100 contrato-editar">
        {op === 'pausar' && <> {/* PAUSAR */}
          <Input type='date' label={lang.dtInicio} value={data.DT_INICIO ?? ''} onChange={e => setData({ ...data, DT_INICIO: e.target.value })} />
          <Input type='date' label={lang.dtTermino} value={data.DT_TERMINO ?? ''} onChange={e => setData({ ...data, DT_TERMINO: e.target.value })} />

          <Divider text={lang.pausas_cadastradas} />
          {pauses_lst.length ?
            <ul className="contrato-pausas">{pauses_lst?.map((p, i) => <li key={i} className={p.CD_STS === '-3' ? 'paused' : ''}>
              {p.DT_INI} - {p.DT_FIM ?? lang.indeterminado}
              {p.CD_STS !== '-3' && <button onClick={() => deletePause(p.ID_UOCC, p.DT_INI, p.DT_FIM)} title={lang.del_pausa}><icons.BsFillTrashFill /></button>}
            </li>)}</ul>
            : lang.sem_pausas}
        </>}

        {/* TARIFA */}
        {op === 'tarifa' && <div className="f g2">
          <div style={{ width: 450, minHeight: 255, overflowY: 'auto' }} className="f f-column g2">
            {['frete', 'motorista', 'pedagio'].map(tt =>
              <Frame key={tt} title={lang.historico + ' - Tarifas ' + tt}>
                <Table disableLine={'disable'}
                  columns={[
                    [lang.dtInicio, '_DT_INIVLD'],
                    [lang.dtTermino, '_DT_FIMVLD'],
                    [lang.tarifa, 'value'],
                    ['', 'action', {}, 'force-fit'],
                  ]} data={
                    (lstTarifas[tt] ?? []).map(t => ({
                      ...t,
                      _DT_INIVLD: App.moment(t.DT_INIVLD).format('DD/MM/YYYY'),
                      _DT_FIMVLD: !!t.DT_FIMVLD ? App.moment(t.DT_FIMVLD).format('DD/MM/YYYY') : lang.indeterminado,
                    }))
                  }
                />
              </Frame>
            )}
          </div>

          <div className="f f-column g1" style={{ width: 300 }}>
            <Input label={lang.valor_frete} value={formatNumber(data.VL_FRETE, 2)} onChange={e => setData({ ...data, VL_FRETE: toFloat(e.target.value, 2) })} />
            <Input label={lang.valor_frete_mot} value={formatNumber(data.VL_FRETEMOTORISTA, 2)} onChange={e => setData({ ...data, VL_FRETEMOTORISTA: toFloat(e.target.value, 2) })} />
            <Input label={lang.data_inicio} value={data.dtVldFrete} onChange={e => setData({ ...data, dtVldFrete: e.target.value })} type="date" />
            <Divider />
            <Input label={lang.pedagio} value={formatNumber(data.VL_PEDAGIO, 2)} onChange={e => setData({ ...data, VL_PEDAGIO: toFloat(e.target.value, 2) })} />
            <Input label={lang.data_inicio} value={data.dtVldPedagio} onChange={e => setData({ ...data, dtVldPedagio: e.target.value })} type="date" />
          </div>
        </div>}

        {op === 'lote' && <> {/* LOTE */}
          <Input disabled label={lang.volume_total} type="number" value={data.qt_volumetotal} />
          <Input disabled label={lang.consumida} type="number" value={data.qt_consumido} />
          <Input disabled label={lang.saldo} value={formatNumber(data.qt_saldoatual, 2)} />
          <Input error={!data.QT_VOLUME} label={lang.novo_volume} type="number" value={data.QT_VOLUME} onChange={e => setData({ ...data, QT_VOLUME: e.target.value })} />
          <Input disabled label={lang.novo_saldo} value={formatNumber(data.qt_saldo, 2)} />
          <Input error={!data.QT_CADENCIA || parseInt(data.QT_CADENCIA) < parseInt(data.QT_CONVERSAO)} label={lang.volume_cadencia} type="number" value={data.QT_CADENCIA}
            onChange={e => setData({ ...data, QT_CADENCIA: e.target.value, QT_COTA: Math.ceil(e.target.value / data.QT_CONVERSAO) })} />
          <Input error={!data.QT_CONVERSAO} label={lang.volume_conversao} type="number" value={data.QT_CONVERSAO}
            onChange={e => setData({ ...data, QT_CONVERSAO: e.target.value, QT_COTA: Math.ceil(data.QT_CADENCIA / e.target.value) })} />
          <Input error={!data.QT_COTA} label={lang.cota} type="number" value={Math.ceil(data.QT_CADENCIA / data.QT_CONVERSAO) ?? 0}
            onChange={e => setData({ ...data, QT_COTA: e.target.value, QT_CADENCIA: data.QT_CONVERSAO * e.target.value })} />
        </>}

        {op === 'transportadora' && <> {/* TRANSPORTADORA */}
          <div style={{ minHeight: 400 }}>
            <Select multi label={lang.transportadora} options={transportadoras} value={data.transportadoras} style={{ width: '70vw' }}
              onChange={e => setData({ ...data, transportadoras: e.map(t => t.value) })} defaultValue={data.lst_transp} clearable searchable />
            <button onClick={() => setData({ ...data, transportadoras: transportadoras.map(t => t.value) })}>{lang.selecionar_tudo}</button>
          </div>
        </>}

        {op === 'reiniciar' && <> {/* REINICIAR */}
          <Input error={!data.DT_INICIO} label={lang.dtInicio} type="date" value={data.DT_INICIO} onChange={e => setData({ ...data, DT_INICIO: e.target.value })} />
        </>}

        {op === 'encerrar' && <> {/* ENCERRAR */}
          <Input error={!data.dt_termino} label={lang.dtTermino} type="date" value={data.dt_termino} onChange={e => setData({ ...data, dt_termino: e.target.value })} />
        </>}

        {/* OBS */}
        {op === 'obs' &&
          <div className="f g1 f-column" style={{ width: '70vw' }}>
            {/* <button title={lang.adicionar} onClick={()=>{data.DS_OBS=data.DS_OBS.filter(o=>!!o.ds_obs);data.DS_OBS.push({ds_obs:''});setData({...data})}}><icons.MdAdd /></button> */}
            {data.DS_OBS?.map((o, i) =>
              <div key={i} className="f g1 center-v">
                <Checkbox checked={o.in_ticket}
                  label={'No ticket'}
                  onChange={e => { o.in_ticket = e.target.checked; setData({ ...data }) }}
                />

                <Select label={lang.tipo} className="f1" options={[
                  { value: 'success', label: lang.success, color: 'var(--success)' },
                  { value: 'danger', label: lang.danger, color: 'var(--danger)' },
                  { value: 'warning', label: lang.warning, color: 'var(--warning)' },
                  { value: 'info', label: lang.info, color: 'var(--info)' },
                ]} styles={{ option: (s, { data }) => ({ ...s, color: 'var(--white)', background: data.color }) }}
                  onChange={e => { data.DS_OBS[i].id_color = e.value; setData({ ...data, DS_OBS: data.DS_OBS }) }}
                  value={o.id_color}
                />

                <Input value={o.ds_obs} label={lang.observacao} className="f4"
                  onChange={e => { data.DS_OBS[i].ds_obs = e.target.value; setData({ ...data, DS_OBS: data.DS_OBS }) }}
                  inputStyle={{ borderLeft: '5px solid var(--' + (o.id_color ?? 'color') + ')' }}
                />

                <button onClick={() => { data.DS_OBS.splice(i, 1); setData({ ...data, DS_OBS: data.DS_OBS }) }}><icons.BsFillTrashFill /></button>
              </div>
            )}
          </div>
        }

        {op === 'origem_destino' && //Lucas - VD-22600 ou VD-10553, 10/07/2024
          <div className="f g2 w100">
            <div className="f g1 f-column f1">
              <Select
                label={'Cliente Origem'}
                options={listas.origens}
                value={data.NR_CNPJEXP}
                onChange={e => setData({ ...data, NR_CNPJEXP: e.value })}
                searchable
                isDisabled={true} // Cliente Origem desabilitado
                onInputChange={(v, { action }) => {
                  if (v.length >= 3 && action === 'input-change') {
                    App.api('fob::getEmbarcadores', { adicionais: true, s: v })
                      .then(r => {
                        setListas({ ...listas, origens: r.results })
                      })
                  }
                  return v
                }}
              />
              <Select
                label={'Endereço Origem'}
                options={listaOrigem}
                value={edRmt.ID_OBJUNC}
                onChange={e => setEdRmt(listaOrigem.find(obj => obj.ID_OBJUNC === e?.value))}
                searchable
              />

              <Select
                label={'Cliente Destino'}
                options={listas.destinos}
                value={data.NR_CNPJRCB}
                onChange={e => setData({ ...data, NR_CNPJRCB: e.value })}
                isDisabled={true} // Cliente Destino desabilitado
              />
              <Select
                label={'Endereço Destino'}
                options={listaDestino}
                value={edDest.ID_OBJUNC}
                onChange={e => setEdDest(listaDestino.find(obj => obj.ID_OBJUNC === e?.value))}
                searchable
              />
              {/* //Lucas - VD-22600 ou VD-10553, 10/07/2024 */}

              <Input disabled value={formatCurrency(data.VL_PEDAGIO ?? 0)} label={lang.pedagio} />
              <Input disabled value={data.QT_KM} label={lang.distancia} />
              <Input disabled value={data.HR_DURACAO} label={lang.duracao} />
            </div>
            <div className="f2 radius overflow-hidden box-shadow">
              {loadingMap ? <App.LoadingComponent /> :
                <Map key={keyMAP} center={position} zoom={8} style={{ position: "relative", width: "100%", height: 375 }}>
                  {posOrigemDestino.inicio.length > 0 && posOrigemDestino.fim.length > 0 && <>
                    <Marker position={posOrigemDestino.inicio}><Popup>Inicio da Rota</Popup></Marker>
                    <Marker position={posOrigemDestino.fim}><Popup>Final da Rota</Popup></Marker>
                    <GeoJSON attribution='&copy; <a href="https://velog.com.br">VELOG</a>'
                      data={{
                        type: "RotaCollection",
                        features: [{ type: "Feature", geometry: { type: "LineString", coordinates: rotaOrigemDestino } }],
                      }}
                    />
                  </>}
                  {pedagios.map((value, index) => (
                    <Marker icon={iconePedagio} key={`pedagio-${index}`} position={[value.id_lat, value.id_long]}>
                      <Popup>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                          <div style={{ backgroundImage: `url("${value.ds_logo}")`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", width: "100px", minHeight: "70px", maxHeight: "100px" }} />
                          <span>{value.ds_praca}</span> <span><strong>{formatCurrency(value.vl_tarifa / 6)}</strong></span>
                        </div>
                      </Popup>
                    </Marker>
                  ))}
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>' />
                </Map>
              }
            </div>

          </div>
        }

        {op === 'vincular_fluxo' &&
          <Input error={!data.VL_FLUXO} label={lang.descricao_fluxo} value={data.VL_FLUXO} onChange={e => setData({ ...data, VL_FLUXO: e.target.value })} />
        }
      </div>
    </Modal>
  )
}
