
import { useState } from 'react'
import UsersEditor from './usersEditor'
import PerfisEditor from './perfisEditor'
import * as c from '../c'

export default function UsersPerfisEditionModal({onClose}){

  const [tabActive, setTabActive] = useState(0)

  return(
    <c.Modal
      largeWidth
      cancelText="Fechar"
      contentHeight=""
      loading={false}
      onClose={onClose}
      title="Gestão de usuários e permissões"
    >
      <div className='f g2 w100'>
        <ul className='menu' style={{gap: 0, marginLeft: -10, minWidth: 150}}>
          {['Perfis e permissões','Usuários'].map((tab, tab_i)=>
            <li key={tab_i} className={tabActive === tab_i ? 'active' : false}>
              <span onClick={()=>setTabActive(tab_i)}>
              <span className='menu-name'>{tab}</span>
            </span></li>
          )}
        </ul>

        {tabActive===0 && <PerfisEditor />}
        {tabActive===1 && <UsersEditor />}
      </div>
    </c.Modal>
  )
}
