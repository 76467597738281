import { useContext, useMemo } from "react"
import { AppContext } from "../../../../App"
import { DropdownMenu } from "../../../../c"

export function DropdownEditMenu({ contrato, control, setEditing, setEditCotas }) {
  const App = useContext(AppContext)
  const lang = useMemo(() => ({
    ...App.lang.global,
    ...App.lang.contratos
}), [App.lang.contratos, App.lang.global])

  return (
    <DropdownMenu title={lang.editar}
      itens={
        [
          { title: lang.pausar, action: () => setEditing({ contrato, op: 'pausar' }), disabled: contrato.CD_STS !== '1' },
        ].concat(
          contrato.ID_NATOP === '1'
            ? [
              { title: lang.cotas, action: () => setEditCotas(contrato), disabled: contrato.CD_STS !== '1' },
              { title: lang.tarifa, action: () => setEditing({ contrato, op: 'tarifa' }), disabled: contrato.CD_STS !== '1' },
              { title: lang.lote, action: () => setEditing({ contrato, op: 'lote' }), disabled: contrato.CD_STS !== '1' },
              { title: lang.transp, action: () => setEditing({ contrato, op: 'transportadora' }), disabled: contrato.CD_STS !== '1' },
              { title: lang.encerrar, action: () => setEditing({ contrato, op: 'encerrar' }) },
              { title: lang.obs, action: () => setEditing({ contrato, op: 'obs' }), disabled: contrato.CD_STS !== '1' },
              { title: lang.origem_destino, action: () => setEditing({ contrato, op: 'origem_destino' }), disabled: contrato.CD_STS !== '1' },
              { title: lang.vincular_fluxo, action: () => setEditing({ contrato, op: 'vincular_fluxo' }), disabled: contrato.CD_STS !== '1' },
              parseInt(contrato.CD_STS) === -2 && (
                { title: lang.reiniciar, action: () => setEditing({ contrato, op: 'reiniciar' }) }
              )
            ]
            : []
        )
      }
    >
      {control}
    </DropdownMenu>
  )
}
