import { useMemo } from "react"

export function IconButton({
  children = null,
  className = '',
  icon = null,
  selected = false,
  size = 36,
  style = {},
  ...rest
}) {
  const buttonClassName = useMemo(() => {
    return ['iconButton', selected ? 'selected' : '', className].join(' ')
  }, [className, selected])

  const buttonStyle = useMemo(() => ({
    maxWidth: size,
    maxHeight: size,
    fontSize: parseInt(size / 2),
    ...style
  }), [size, style])

  return (
    <button className={buttonClassName} style={buttonStyle} {...rest}>
      {children} {!!icon && icon({size})}
    </button>
  )
}
