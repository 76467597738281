import { useMemo } from "react"
import { Frame, Input, Select } from "../../../../../../c"
import { formatDate, formatNumber, t } from "../../../../../../f"

export const QuotationFields = ({ form, lang }) => {
  const fields = useMemo(() => {
    const {
      startQuotation = '',
      endQuotation = '',
      product: { name: product = '' } = {},
      minimumVehicleQuantity = 0,
      vehicleTypes = [],
    } = form

    console.log(vehicleTypes)

    return [
      ['startQuotation', lang.quotationStep, formatDate(startQuotation, { default: '' }), 'input'],
      ['endQuotation', lang.quotationStep, formatDate(endQuotation, { default: '' }), 'input'],
      ['product', lang.quotationStep, product, 'input'],
      ['minimumVehicleQuantity', lang.quotationStep, formatNumber(minimumVehicleQuantity), 'input'],
      ['vehicleTypes', lang.quotationStep, vehicleTypes, 'select'],
    ]
  }, [form, lang.quotationStep])

  return (
    <Frame title={t(lang.steps, 'quotationStep')} className="w25">
      {fields.map(([field, fieldLang, value, type]) => {
        if(type === 'select') {
          return (
            <Select
              disabled
              multi
              key={field}
              className="f2"
              label={t(fieldLang, field)}
              value={value.map(({ id }) => id)}
              options={value.map(({ name, id }) => ({ label: name, value: id }))}
            />
          )
        }

        return (
          <Input
            disabled
            className="f2"
            key={field}
            label={t(fieldLang, field)}
            value={value}
          />
        )
    })}
    </Frame>
  )
}
