import { useContext, useState, useEffect} from 'react'
import { AppContext } from '../App'
import * as c from '../c'

export default function PerfisEditor(){
  const App = useContext(AppContext),
        icons = App.icons

  const [loading, setLoading] = useState(false),
        [perfis, setPerfis] = useState([]),
        [perfilSaving, setPerfilSaving] = useState({}),
        [perfilNameEditing, setPerfilNameEditing] = useState({}),
        [selected, setSelected] = useState({}),
        [search, setSearch] = useState('')

  const style = {
                  padding: 5,
                  borderRadius: 5,
                  background: 'var(--background)',
                  border: '1px dashed var(--color)',
                  overflowY: 'auto',
                  minWidth: '300px'
                }

  async function init(){
    setLoading(true)
    setPerfis( (await App.api('perfis::full')).results )
    setLoading(false)
  }

  async function perfilAdd(){
    setPerfis([{ID: -1}, ...perfis])
    setPerfilNameEditing({PERFIL_ID: -1})
    setSelected({ID: -1})
    setSearch('')
  }

  async function perfilEditingCancel(){
    if( perfis[0].ID === -1 ){
      perfis.shift()
      setPerfis([...perfis])
      setSelected({})
    }
    setPerfilNameEditing({})
  }

  async function perfilSave(){
    if( !!perfilSaving.PERFIL_ID ){
      const _perfis = await App.api('perfis::save', perfilSaving)
      if( _perfis.status ) {
        setPerfis( _perfis.results )
        App.toast.success('Salvo', { autoClose: 500 })
      }else{
        perfilEditingCancel()
      }
      setPerfilSaving({})
      setPerfilNameEditing({})
    }
  }

  async function usersByPerfil(){
    if( !selected.users ){
      setSelected({...selected,
        users: [],
        usersLoaging: true,
      })

      setSelected({...selected,
        users: (await App.api('perfis::usersByPerfil', {ID: selected.ID})).results,
        usersLoaging: false,
      })
    }
  }

  useEffect(() => init(), [])

  useEffect(() => perfilSave(), [perfilSaving])

  useEffect(() => usersByPerfil(), [selected])

  return(
    <div className='f f-column f1' style={{height: 500}}>
      {loading && <App.LoadingComponent className="h100"/>}

      {!loading &&
        <div className='f g1' style={{height: 500}}>
          <div className='f f-column g1' style={style}>
            {!loading && !perfilNameEditing.PERFIL_ID && !perfilSaving.PERFIL_ID &&
              <div className='f g1 f-between center-v'>
                <button onClick={() => perfilAdd()}>
                  <icons.MdCreateNewFolder />Novo
                </button>

                <c.Input fit clearable placeholder={'Busca'} value={search} onChange={e => setSearch( e.target.value )} />
              </div>
            }

            <ul className='menu'
              style={{
                overflowY: perfilNameEditing.PERFIL_ID ? 'hidden' : 'auto',
                display: 'block',
                minWidth: 270
              }}
            >
              {perfis
                .filter(perfil => !search || (perfil.NOME ?? '').toUpperCase().indexOf(search.toUpperCase()) >= 0)
                .map(perfil =>
                  <li key={perfil.ID}
                    className={[
                      selected.ID === perfil.ID
                        ? 'active'
                        : perfilNameEditing.PERFIL_ID ? 'hidden' : ''
                    ].join(' ')}
                  >
                    <span onClick={() =>
                      !perfilNameEditing.PERFIL_ID && selected.ID !== perfil.ID && setSelected({ID: perfil.ID})
                    }>
                      <span className='menu-name f center-v f-between w100'>
                        {perfilNameEditing.PERFIL_ID === perfil.ID
                          ? perfilSaving.PERFIL_ID === perfil.ID
                            ? <div className='f1'><c.Skeleton /></div>
                            : <c.Input value={perfilNameEditing.NOME} minLength={5} fit
                                title={'Não são permitidos caracteres acentuados ou especiais'}
                                onChange={e => setPerfilNameEditing({...perfilNameEditing,
                                  NOME: e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                })}
                              />
                          : perfil.NOME ?? '-- NOVO PERFIL --'
                        }

                        {perfilNameEditing.PERFIL_ID === perfil.ID
                          ? !perfilSaving.PERFIL_ID &&
                              <>
                                {perfilNameEditing.NOME?.length >= 5 &&
                                  <c.IconButton title={"Salvar"} icon={icons.MdSave} size={28}
                                    onClick={()=>setPerfilSaving({...perfilNameEditing})}
                                  />
                                }
                                <c.IconButton title={"Cancelar"} icon={icons.MdCancel} size={28}
                                  onClick={()=>perfilEditingCancel()}
                                />
                              </>
                          : !perfilNameEditing.PERFIL_ID &&
                              <c.IconButton title={"Editar"} icon={icons.MdEdit} size={28}
                                onClick={()=>setPerfilNameEditing({PERFIL_ID: perfil.ID, NOME: perfil.NOME})}
                              />
                        }
                      </span>
                    </span>
                  </li>
                )
              }
            </ul>
          </div>

          {selected.ID > 0 && !perfilNameEditing.PERFIL_ID
            ? <>
                <div className='f1 f f-column f-between g1' style={style}>
                  <div style={{fontSize: 18}} className='center-h'>
                    Permissões de <b>{perfis.find(perfil => perfil.ID === selected.ID)?.NOME}</b>
                  </div>

                  {perfis
                    .find(perfil => perfil.ID === selected.ID)
                    ?.COMPONENTES?.map(componente =>
                      <details key={componente.ID}>
                        <summary className='f g1 center-v'>
                          <c.Checkbox title={"Ativar/Desativar"} checked={componente.ATIVO}
                            disabled={!!perfilSaving.PERFIL_ID}
                            label={perfilSaving.COMPONENTE_ID !== componente.ID
                              ? componente.NAME
                              : <div className='f1'><c.Skeleton /></div>
                            }
                            onChange={() => setPerfilSaving({PERFIL_ID: selected.ID, COMPONENTE_ID: componente.ID, ATIVO: !componente.ATIVO})}
                          />
                        </summary>

                        {componente.FUNCOES.map(funcao =>
                          <div className='item f g1' key={funcao.ID}>
                            <c.Checkbox title={"Ativar/Desativar"} checked={funcao.ATIVO}
                              disabled={!!perfilSaving.PERFIL_ID}
                              label={perfilSaving.FUNCAO_ID !== funcao.ID
                                ? funcao.NOME
                                : <div className='f1'><c.Skeleton /></div>
                              }
                              onChange={() => setPerfilSaving({PERFIL_ID: selected.ID, FUNCAO_ID: funcao.ID, ATIVO: !funcao.ATIVO})}
                            />
                          </div>
                        )}
                      </details>
                    )
                  }
                </div>

                <div className='f1 f f-column g1' style={style}>
                  {selected.usersLoaging ? <App.LoadingComponent className="h100" />
                   : <>
                      <div style={{fontSize: 18}} className='center-h'>
                        Usuários com perfil <b>{perfis.find(perfil => perfil.ID === selected.ID)?.NOME}</b>
                      </div>
                      {selected.users?.length
                        ? <ul style={{paddingLeft: 25, margin: 0}}>
                            {selected.users?.map(user =>
                              <li key={user.ID} className='p1'><b>{user.LOGIN}</b> ({user.NOME})</li>
                            )}
                          </ul>
                        : <div className='bold p3 w100 center-h'>Ainda não há usuários para este perfil</div>
                      }
                    </>
                  }
                </div>
              </>
            : <div className='bold f center center-margin f-column g3 w100'>
                <icons.FaUsers size={60} />
                <h2>Selecione um perfil</h2>
              </div>
          }
        </div>
      }
    </div>
  )
}
