import { parseFilters } from './parseFilters'

export const fetchSummaries = async ({ api, filters: rawFilters, user }) => {
  if (user.in_uorgadm !== 'T' && user.in_trp !== 'T') return []

  const filters  = parseFilters(rawFilters)

  const records = await api('controllership::summaries', { ...filters })

  return { records }
}
