import { useContext, useMemo } from "react"
import { FaTruck } from "react-icons/fa"

import { AppContext } from "../../../../App"
import { Avatar, Divider, Span } from "../../../../c"
import { formatNumber } from "../../../../f"

import { DropdownEditMenu } from "../DropdownEditMenu"

export function ContratoCard({ contrato, setEditing, setDetalhe, setEditCotas, get, recalcular }){
  const App = useContext(AppContext)
  const lang = useMemo(
    () => ({ ...App.lang.global, ...App.lang.contratos }),
    [App.lang.contratos, App.lang.global],
  )

  const DS_OBS = useMemo(() => {
    const {
      CD_STS,
      DS_OBS,
      DT_FRETEMOTORISTANEW,
      DT_FRETENEW,
      DT_MAXCOTA,
      ID_NATOP,
      show_recalc,
      VL_FRETEMOTORISTANEW,
      VL_FRETENEW,
    } = contrato
    const {
      card_data_insuficiente,
      card_nova_tarifa,
      card_pausar,
      card_valor_maximo_motorista,
    } = lang

    return [
      ...JSON.parse(DS_OBS ? DS_OBS : '[]'),
      !!VL_FRETENEW
        ? { id_color: 'danger', ds_obs: `${card_nova_tarifa + DT_FRETENEW}: R$ ${VL_FRETENEW }` } : null,
      !!VL_FRETEMOTORISTANEW
        ? { id_color: 'danger', ds_obs: card_valor_maximo_motorista + DT_FRETEMOTORISTANEW + ': R$ ' + VL_FRETEMOTORISTANEW } : null,
      CD_STS === "-2"
        ? { id_color: 'warning', ds_obs: card_pausar + DT_MAXCOTA } : null,
      show_recalc && ID_NATOP === '1'
        ? { id_color: 'danger', ds_obs: card_data_insuficiente } : null,
    ].filter(o => !!o && !!o.ds_obs)
  }, [contrato, lang])

  return (
    <div className="contrato-card">
      {App.moment().format('DD/MM/YYYY') === contrato.DT_CDT
        ? <div className="fita-novo">Novo</div> : null
      }

      <div className="header">
        <div className="base">
          <div className="f g2 center-v">
            <Avatar size={48} />
            <h2 className="b">{contrato.NR_DCO}</h2>
            <h4 className="w100" style={{ textAlign: "center", fontWeight: "normal" }}>
              {contrato.ID_UORG + ' - ' + contrato.NM_PESUND}
            </h4>
          </div>
          <div><span>{contrato.DS_ORIGEM}</span></div>
          <div><span>{contrato.DS_DESTINO}</span></div>
          <div><span>{contrato.NM_PRO}</span></div>
          <Span value={contrato.CLIENTE} label={'Cliente'} />
          {!!contrato.TOMADOR && <Span value={contrato.TOMADOR} label={lang.tomador} />}
          {!!contrato.AREA_NEGOCIO && <Span value={contrato.AREA_NEGOCIO} label={lang.area_negocio} />}
          {/* Lucas, VD-22920, 15/07/2024 */}
        </div>

        <div className="actions">
          {parseInt(contrato.CD_STS) === 1 && // Contrato normal
            <DropdownEditMenu contrato={contrato} setEditCotas={setEditCotas} setEditing={setEditing} control={<button>{lang.editar}</button>} style={{ marginLeft: -50 }} />}

          {parseInt(contrato.CD_STS) === -2 && // Contrato pausado
            <button className="warning" onClick={() => setEditing({ contrato, op: 'reiniciar' })} >{lang.reiniciar}</button>}

          {contrato.show_recalc === true && contrato.ID_NATOP === '1' && ( // Recalcular
            <button className="warning" onClick={() => recalcular(contrato.ID_CN) } >
              {lang.recalcular}
            </button>
          )}

          {parseInt(contrato.CD_STS) === -1 && // Contrato encerrado
            <span className="encerrado">{lang.encerrado}</span>}

          <button onClick={() => setDetalhe(contrato)}>{lang.visualizar}</button>
        </div>
      </div>

      <Divider />

      <div className="infos">
        {(App.user.cliente.toUpperCase() !== "RUMO" && App.user.cliente.toUpperCase() !== "HUB") && <Span value={formatNumber(contrato.VL_FRETE ?? 0, 2)} sub={'(∅ ' + formatNumber(contrato.AVG_FRETE ?? 0, 2) + ')'} label={lang.frete} />}
        <Span value={formatNumber(contrato.VL_FRETEMOTORISTA ?? 0, 2)} sub={'(∅ ' + formatNumber(contrato.AVG_FRETEMOTORISTA ?? 0, 2) + ')'} label={lang.frete_motorista} />
        <Span value={formatNumber(contrato.VL_PEDAGIO, 2)} sub={'(∅ ' + formatNumber(contrato.AVG_PEDAGIO ?? 0, 2) + ')'} label={lang.pedagio} />
        <Span value={contrato.SLC} label={lang.volume_ton} />
        <Span value={contrato.SALDO} label={lang.saldo_ton} />
        <Span value={contrato.VOL_CADENCIA + '/' + formatNumber(contrato.QT_CAD)}
          label={lang.volume_cadencia} />
        <Span value={contrato.ID_CN} label={lang.contrato} />
        <Span value={contrato.DT_INICIO} label={lang.inicio} />
        <Span value={contrato.DT_MAXCOTA} label={lang.termino_previsto} />
        <Span value={contrato.calc_ontem} sub={<>({contrato.QT_ONTEM}  <FaTruck size={16} />)</>} label={lang.ontem} />
        <Span value={contrato.calc_hoje} sub={<>({contrato.QT_HOJE}   <FaTruck size={16} />)</>} label={lang.hoje} />
        <Span value={contrato.calc_amanha} sub={<>({contrato.QT_AMANHA} <FaTruck size={16} />)</>} label={lang.amanha} />
      </div>

      {!!DS_OBS.length && <>
        <Divider />
        <div className="observations">
          {DS_OBS.map((obs, i) => obs.ds_obs !== "" && <div key={"obs" + i} className={['item', obs.id_color].join(' ')}>{obs.ds_obs}</div>)}
        </div>
      </>}
    </div>
  )
}
