import { useState, useEffect, useContext } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'

export default function DashCarga(props){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.dashCargaDescarga},
        icons = App.icons

  const [data, setData] = useState([]),
        [activeIndex, setActiveIndexId] = useState(0),
        [loading, setLoading] = useState(null)

  function get(){ setData([])
    setLoading(true)
    App.api('dashboard::unidadeCarga')
      .then(r=>{
        setData( r.results??{} )
        setLoading(false)
      })
  }

  function renderActiveShape(props) {
    var RADIAN = Math.PI / 180;
    var { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    var sin = Math.sin(-RADIAN * midAngle);
    var cos = Math.cos(-RADIAN * midAngle);
    var sx = cx + (outerRadius + 10) * cos;
    var sy = cy + (outerRadius + 10) * sin;
    var mx = cx + (outerRadius + 30) * cos;
    var my = cy + (outerRadius + 30) * sin;
    var ex = mx + (cos >= 0 ? 1 : -1) * 22;
    var ey = my;
    var textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fontSize="11" fill={fill}>
          {payload.name}
        </text>
        <c.chart.Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
        <c.chart.Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" >
          {`Veículos: ${value}`}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" >
          {`(Taxa: ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    )
  }

  useEffect(()=>{ get()
    const interval = setInterval(() => get(), 60000)
    return () => clearInterval(interval)
  }, [])

  useEffect(()=>{
    props.setReloader(()=>get)
  }, [])

  return(<>
    <c.Frame title={lang.title_carga + ' ' + App.moment().format('DD/MM/YYYY')} flex>
      { !loading && !Object.entries(data).length
      ? lang.nothing_here
      : <>
        {/* QUANTIDADES */}
        <c.Card type='info'    icon={icons.BsCalendar3}     title={lang.consumida_dia}    value={data.qt_agd} />
        <c.Card type='warning' icon={icons.FaRoad}          title={lang.transito}         value={data.qt_trs} />
        <c.Card type='danger'  icon={icons.BsClock}         title={lang.aguardando_fila}  value={data.qt_fila} />
        <c.Card type='info'    icon={icons.FaBalanceScale}  title={lang.em_operacao}      value={data.qt_opr} />
        <c.Card type='info'    icon={icons.FaBalanceScale}  title={lang.veiculo_total}    value={data.qt_fila_opr} />
        <c.Card type='info'    icon={icons.MdLogout}        title={lang.encerrado}        value={data.qt_enc} />

        {/* VOLUMES */}
        <c.Card type='info'    icon={icons.BsCalendar3}     title={lang.volume_agendado}  value={data.qt_vtotal} />
        <c.Card type='warning' icon={icons.FaRoad}          title={lang.volume_transito}  value={data.qt_vtrs} />
        <c.Card type='danger'  icon={icons.BsClock}         title={lang.volume_fila}      value={data.qt_vfila} />
        <c.Card type='info'    icon={icons.FaBalanceScale}  title={lang.volume_operacao}  value={data.qt_vopr} />
        <c.Card type='info'    icon={icons.FaBalanceScale}  title={lang.volume_total}     value={data.qt_vfila_vopr} />
        <c.Card type='info'    icon={icons.MdLogout}        title={lang.volume_encerrado} value={data.qt_venc} />

        {/* TEMPOS */}
        <c.Card type='info'    icon={icons.BsClock}        title={lang.tempo_transito}    value={data.tempo_trs} />
        <c.Card type='danger'  icon={icons.BsClock}        title={lang.tempo_fila}        value={data.tempo_fila} />
        <c.Card type='info'    icon={icons.BsClock}        title={lang.tempo_operacao}    value={data.tempo_opr} />
        <c.Card type='normal'  icon={icons.BsClock}        title={lang.tempo_total}       value={data.tempo_estadia} />
      </>}
    </c.Frame>

    {!!data.lst_produto?.length && <c.Frame title={lang.resumo_produto}>
      <c.chart.PieChart width={600} height={300}>
        <c.chart.Pie cx={'50%'} cy={'50%'} innerRadius={60} outerRadius={80}
          activeIndex={activeIndex} activeShape={renderActiveShape}
          data={data.lst_produto.map(p=>{return {name: p.NM_PRO, value: parseInt(p.QT_VEICULO??0), color: 'black', backgroundColor: 'white'}}) }
          fill="var(--color2)" dataKey="value"
          onMouseEnter={(e, id) => setActiveIndexId(id)}
        />
      </c.chart.PieChart>
    </c.Frame>}
  </>)
}
