import { useMemo } from "react"
import { Select } from "../.."

export const SelectFilter = ({ kind, onChange, settings, label, options, value }) => {
  const multi = useMemo(() => kind === 'multi-select', [kind])

  const handleChange = (option) => {
    let filter = option?.value

    if(multi) filter = option.map(({ value }) => value )

    if(onChange) onChange(filter)
  }

  return (
    <Select
      {...settings}
      clearable
      searchable
      multi={multi}
      className="f1"
      label={label}
      onChange={handleChange}
      options={options}
      value={value}
    />
  )
}
