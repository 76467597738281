import { useContext, useState, useRef, useEffect, useMemo } from 'react'
import { AppContext } from '../../App'

import {VIAIcon} from '../VIAIcon/VIAIcon/VIAIcon'
import { useUserPreferencesVIA } from '../VIAIcon/hooks/useUserPreferencesVIA'
import * as c from '../'

export default function Modal({
  cancelText = '',
  children,
  contentHeight = null,
  closeOnFinish = true,
  control = null,
  diretiva = [],
  displayCancel = true,
  extraControls = null,
  fullWidth = null,
  largeWidth = null,
  loading = null,
  middleWidth = null,
  okText = '',
  onCancel = () => {},
  onClose = () => {},
  onFinish = null,
  onStepChange = () => {},
  showCheckbox = null,
  smallWidth = null,
  steps = null,
  successMsg = null,
  title,
  validate: rawValidate = null,
  VIAIconProps = null,
  width = null,
}){
  const App = useContext(AppContext),
        lang = App.lang.global,
        icons = App.icons

  const modalRef = useRef(null)
  const [closing, setClosing] = useState(false),
        [activeStep, setActiveStep] = useState(0),
        [isDragging, setIsDragging] = useState(false),
        [position, setPosition] = useState({x:null, y:null}),
        [offset, setOffset] = useState({ x: null, y: null })

  const { isVIAInfoModalDisplayed, handleCheckboxChange } = useUserPreferencesVIA();

  function realClose(){
    setClosing(true)
    setTimeout(() => {
      if(onClose) onClose()
    }, 600)
  }

  function realFinish(){
    const f = onFinish()
    //onFinish()
    const msg = successMsg===null ? App.lang.global.success : successMsg

    //realClose()
    // if(successMsg) App.toast.success( msg )

    if( f instanceof Promise ){
      f.then(r=>{
        if( r?.status || r instanceof Promise || r === true ) {
          if(closeOnFinish) realClose()

          if(msg) App.toast.success( msg )
        }
      })
    }else{
      if( !!f ) {
        if(closeOnFinish) realClose()
        if(msg) App.toast.success( msg )
      }
    }
  }

  function lockscreenClick(e){
    // if( modalRef.current && !modalRef.current.contains(e.target) )
    //   App.confirm('Alterações podem ter sido feitas. Deseja sair?', ()=>{realClose();return true})
      //realClose()
  }

  function handleKeyDown(e) {if (e.keyCode === 27) realClose()}

  function nextStep(step,diretiva){
    let next_step = step+1
    if(diretiva){
      while(diretiva.includes(next_step)){
        next_step = next_step + 1
      }
    }else{
      return next_step
    }
    return next_step
  }

  function backStep(step,diretiva){
    let back_step = step-1
    if(diretiva){
      while(diretiva.includes(back_step)){
        back_step = back_step - 1
      }
    }else{
      return back_step
    }
    return back_step
  }

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setOffset({ x: e.clientX - position.x??0, y: e.clientY - position.y??0 })
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({ x: e.clientX - offset.x??0, y: e.clientY - offset.y??0 });
    }
  }

  const handleMouseUp = () => setIsDragging(false)

  const viaContent = useMemo(() => {
    if (!VIAIconProps) return null;

    const iconClassName = App.components.ticket_ocr ? '' : 'via-svg-disabled';

    return <VIAIcon type={VIAIconProps.type} className={iconClassName} />;
  }, [VIAIconProps, App.components.ticket_ocr]);

  const validate = useMemo(() => {
    if((rawValidate ?? null) === null) {
      if((steps ?? null) === null || steps.length === 0) return true

      return steps.every((item) => item.validate)
    }

    return rawValidate
  }, [rawValidate, steps])

  useEffect(()=>{
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  useEffect(()=>onStepChange(activeStep),[activeStep])

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging])

  return (
    <div className={['lockscreen',(closing?'closing':'')].join(' ')}
      onClick={lockscreenClick}
    >
      <div className={['modal',(largeWidth?'large-width':''),(fullWidth?'full-width':''),(middleWidth?'middle-width':'')].join(' ')} ref={modalRef}
        style={{
          width: width,
          //top: position.y??'',
          //left: position.x??''
        }}
      >
        <div className='modal-header' style={{cursor: isDragging?'move':'auto'}}>
          <div className='f g4 center'>
            <span className='modal-title f g2 center-v'
              >{title}
            </span>
            <div>{viaContent}</div>
          </div>
            {!!control&&<div className='frame-title-control'>{control}</div>}
            <div>
              <c.IconButton title={lang.fechar} onClick={realClose}>{App.icons.MdClose({size: 16})}</c.IconButton>
            </div>
        </div>

        {!!steps && !loading &&
          <c.Stepper steps={steps} active={activeStep} setActiveStep={setActiveStep} className='modal-stepper' diretiva={diretiva}/>
        }

        <div className='modal-content' style={{minHeight: contentHeight?contentHeight:'auto'}}>
          {(loading && <App.LoadingComponent />) || children}
        </div>

        <div className='modal-buttons'>
          <div className='modal-buttons-extra'>
            <div>
              {showCheckbox && (
            <div >
              <label className='f'>
                <c.Checkbox label={"Não mostrar novamente"} checked={isVIAInfoModalDisplayed} onChange={e => handleCheckboxChange(e.target.checked)} />
              </label>
            </div>
          )}
            </div>
            {!!steps && !loading && <>
              <button onClick={()=>{setActiveStep(backStep(activeStep,diretiva))}} disabled={activeStep===0} className='warning'><icons.MdNavigateBefore />{lang.voltar}</button>
              <button onClick={()=>{setActiveStep(nextStep(activeStep,diretiva))}} disabled={activeStep===steps.length-1} className='warning'>{lang.proximo}<icons.MdNavigateNext /></button>
            </>}
            {extraControls}
          </div>
          <div className='modal-buttons-main'>
            {displayCancel && (
              <button
                onClick={()=>{
                  realClose();
                  if(onCancel) onCancel()
                }}
                className='danger display-cancel'
                disabled={!!loading}
              >
                {cancelText || App.lang.global.cancel}
              </button>
            )}
            {!!onFinish && (
              <button
                onClick={realFinish}
                className='success'
                disabled={!validate || !!loading}
              >
                {okText || App.lang.global.save}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
