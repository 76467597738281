import { useMemo } from "react"

import { Frame, Table } from "../../../../c"
import { formatDate, formatMoney, formatNumber } from "../../../../f";

import { handleDetatilsActions } from "../QuotationTable/useCases/handleDetails";

export const ShippingCompanyQuotationDetail = ({ record, setEditing }) => {
  const details = useMemo(() => {
    const { details: rawDetails, ...data } = record

    const result = rawDetails
      .sort((a, b) => a.createdAt <= b.createdAt ? 1 : -1)
      .map((detail) => {
        const key = `${data.id}-${detail.shippingCompany?.id}`

        return {
          disabled: !detail.active,
          VL_LANCE: detail.amount ? formatMoney(Number(detail.amount), 2) : '',
          DT_LANCE: detail.createdAt ? formatDate(detail.createdAt) : '',
          QT_VCL: detail.vehicleQuantity ? formatNumber(detail.vehicleQuantity) : '',
          actions: detail.active ? handleDetatilsActions(record, detail, setEditing) : '',
          record: { ...data, detail },
          key,
        }
    });

    return result;
  }, [record, setEditing]);

  const columns = useMemo(() => {
    return [
      ["Data do lance", "DT_LANCE", {}, "f"],
      ["Valor", "VL_LANCE", {}, "f"],
      ["Número de veículos", "QT_VCL", {}, "center f"],
      ["Ações", 'actions', {}, 'center f g1'],
    ]
  }, []);

  return (
    <div>
       <Frame title='Lances cadastrados' >
        <Table
          isSub
          columns={columns}
          data={details}
          disableLine="disabled"
          fixedPerPage={5}
        />
      </Frame>
    </div>
  )
}
