import { useMemo } from "react"
import { Frame, Input } from "../../../../../../c"
import { formatMoney, t } from "../../../../../../f"

export const ItineraryFields = ({ form, lang }) => {
  const fields = useMemo(() => {
    const {
      code = '',
      name = '',
      duration = '00:00',
      tollAmount = 0,
      distance = 0
    } = form

    return [
      ['itinerary', lang.itineraryStep, code && name ? `${code} - ${name}` : ''],
      ['duration', lang.itineraryStep, duration],
      ['tollAmount', lang.itineraryStep, formatMoney(tollAmount, 2)],
      ['distance', lang.itineraryStep, distance],
    ]
  }, [form, lang.itineraryStep])

  return (
    <Frame title={t(lang.steps, 'itineraryStep')} className="w25">
      {fields.map(([field, fieldLang, value]) => (
        <Input
          disabled
          className="f2"
          key={field}
          label={t(fieldLang, field)}
          value={value}
        />
      ))}
    </Frame>
  )
}
