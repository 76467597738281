import { useCallback, useContext, useMemo, useState } from "react";
import { AppContext } from "../../../../App";
import { Frame } from "../../../../c";
import { DashboardChart } from "../../components/DashboardChart";
import { NothingHere } from "../../../../c/NothingHere";
import { CardFilters } from "../../../../c/CardFilters";
import { getCardFilters } from "../../../../useCases/getCardFilters";
import { generateFiltersFromSummaries, adjustCardFiltersWithSummaries } from "./useCases/genereteCards";
import cardFiltersConfig from './contents/card_filters_config.json';
import './index.sass';

export const Summaries = ({ records = [], summaries, countTotalsDocuments }) => {
  const App = useContext(AppContext);

  const [selectedCardFilter, setCardFilter] = useState({});

  const lang = useMemo(() => {
    const { global, freight_controllership } = App.lang;
    return { ...global, ...freight_controllership.travel_records.summaries };
  }, [App.lang]);

  const tdProblems = summaries?.OP?.TD || { PROBLEMS: [] };
  const nfeProblems = summaries?.DFE?.NFE || { PROBLEMS: [] };
  const cteProblems = summaries?.DFE?.CTE || { PROBLEMS: [] };

  const countsProblems = { PROBLEMS :[tdProblems.COUNTS, nfeProblems.COUNTS, cteProblems.COUNTS]};

  const dataForFilters = tdProblems.PROBLEMS;

  const handleFilterCard = useCallback((newFilter) => {
    setCardFilter(newFilter);
  }, []);

  const adjustedCardFiltersConfig = useMemo(() => {
    return adjustCardFiltersWithSummaries(cardFiltersConfig, {
      DS_LBL: summaries?.OP?.TD,
      DS_VLD_NFE: summaries?.DFE?.NFE,
      DS_VLD_CTE: summaries?.DFE?.CTE
    });
  }, [summaries]);

  const cardFilters = useMemo(() => {
    return getCardFilters(adjustedCardFiltersConfig, {
      data: dataForFilters,
      lang: lang.card_summaries ?? lang,
      selectedFilter: selectedCardFilter,
      onFilter: handleFilterCard,
      ratio: true,
    });
  }, [adjustedCardFiltersConfig, handleFilterCard, lang, records, selectedCardFilter]);

  const filters = [];

  const isSummariesEmpty = !summaries ||
    (!tdProblems.PROBLEMS.length && !nfeProblems.PROBLEMS.length && !cteProblems.PROBLEMS.length);

  return (
    <>
      {isSummariesEmpty ? (
        <NothingHere message={lang.nothing_here} />
      ) : (
        <>
          <Frame className="summaries-cards">
            <CardFilters visible filters={cardFilters} />
          </Frame>

          <div className="summaries" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <DashboardChart
              title={lang.documentos}
              summaries={countsProblems}
              labelKey="LABEL"
              valueKey="TOTALS"
              chartWidth="49%"
            />
            {tdProblems.PROBLEMS.length > 0 && (
              <DashboardChart
                title={lang.ticket}
                summaries={tdProblems}
                labelKey="DS_LBL"
                valueKey="TOTALS"
                chartWidth="49%"
              />
            )}
            {nfeProblems.PROBLEMS.length > 0 && (
              <DashboardChart
                title={lang.nfe}
                summaries={nfeProblems}
                labelKey="DS_VLD_NFE"
                valueKey="TOTALS"
                chartWidth="49%"
              />
            )}
            {cteProblems.PROBLEMS.length > 0 && (
              <DashboardChart
                title={lang.cte}
                summaries={cteProblems}
                labelKey="DS_VLD_CTE"
                valueKey="TOTALS"
                chartWidth="49%"
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
