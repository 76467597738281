import moment from "moment";

export const ColumnsCache = {
  getCacheKey: (tableId) => `users::columns::${tableId}`,

  getData: (tableId) => {
    const cacheKey = ColumnsCache.getCacheKey(tableId);

    const cachedData = localStorage.getItem(cacheKey);

    if (!cachedData) return null

    const { data, expiresIn } = JSON.parse(cachedData);

    if (!expiresIn || !moment().isBefore(moment(expiresIn)))  return null;

    return data;
  },

  setData: (tableId, data) => {
    const cacheKey = ColumnsCache.getCacheKey(tableId);
    const expiresIn = moment().add(5, 'minutes').toISOString()

    localStorage.setItem(cacheKey, JSON.stringify({ expiresIn, data}));
  },

  clearData: (tableId) => {
    const cacheKey = ColumnsCache.getCacheKey(tableId);

    localStorage.removeItem(cacheKey);
  }
};
