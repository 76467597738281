import { useState, useEffect, useContext } from "react";
import { AppContext, log } from "../../App";
import * as c from "../../c";
import * as p from "../";
import * as f from "../../f";

export default function Turnos({ setReloader }) {
  const App = useContext(AppContext),
    lang = { ...App.lang.global, ...App.lang.transportadoras, ...App.lang.turno },
    icons = App.icons;

    const semana = [
        {NR_DSM: 0, NM_DIA:lang.domingo},
        {NR_DSM: 1, NM_DIA: lang.segunda},
        {NR_DSM: 2, NM_DIA: lang.terca},
        {NR_DSM: 3, NM_DIA: lang.quarta},
        {NR_DSM: 4, NM_DIA: lang.quinta},
        {NR_DSM: 5, NM_DIA: lang.sexta},
        {NR_DSM: 6, NM_DIA: lang.sabado},
    ]
    const labels = {
      "1": lang.ativo,
      "-3": lang.inativo
    };

  const [loading, setLoading] = useState({}),
    [busca, setBusca] = useState(""),
    [incluir, setIncluir] = useState(null),
    [editing, setEditing] = useState(null),
    [unidade, setUnidade] = useState(null),
    [data, setData] = useState([]);

  function getData(){
    setLoading(true)
    App.api('turno::get').then(r=>{
      setData(r.results);
      setLoading(false);
      })
  }

  useEffect(() => {
    getData();
    setReloader(() => getData)
  }, [])

  return (
    <>
      <c.Frame
        title={"Turnos"}
        loading={loading}
        actions={{
          add: () => setIncluir(true),
        }}
        control={
          <c.Input
            placeholder={lang.busca}
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
            clearable
          />
        }
      >
        <c.Table
        hasSub={"sub"}
          data={data.map((d, i) => ({
            ...d,
            DIAS_FILTRO: <> <div className="f row w50" style={{justifyContent: 'space-around', width:'100%'}}>{semana.map((s, i) => {
                return d.DIAS.find((dia) => dia.NR_DSM == s.NR_DSM) ?
                  <div style={{
                    width: '20px',
                    height:'20px',
                    borderRadius: '50%',
                    fontSize:'10px',
                    lineHeight:'20px',
                    textAlign:'center',
                    border: '1.8px solid #000',
                    justifyContent:'center',
                    flexWrap:'nowrap',
                  }}>
                    <div style={{color:'green',fontSize:'10px'}}>{s.NM_DIA[0]}</div>

                  </div> :
                  <div style={{
                    width: '20px',
                    height:'20px',
                    borderRadius: '50%',
                    fontSize:'10px',
                    lineHeight:'20px',
                    textAlign:'center',
                    justifyContent:'center',
                    flexWrap:'nowrap'
                  }}>
                   <div style={{color:'grey',fontSize:'10px'}}>{s.NM_DIA[0]}</div>
                  </div>

            })}</div></>,
            HORASTOTAL: f.formatTime(d?.DIAS.reduce((acc, dia) => {
                const horaIni = App.moment(dia.HR_INI, "HH:mm");
                const horaFim = App.moment(dia.HR_FIM, "HH:mm");

                // Calcula a diferença entre as horas de início e fim
                const diferencaHoras = horaFim.diff(horaIni, "minutes");
                // Soma a diferença de horas ao acumulador
                return acc + diferencaHoras;
            }, 0)),
            ACTIONS: <c.IconButton onClick={(e) => setEditing(d)}><icons.BsPencilFill/></c.IconButton>,
            sub:<>
                <c.Table pagination="no-pagination"
                isSub={true}
                navigate={false}
                data = {d.DIAS.map((dia, i) => ({...dia,
                  NM_DIA: semana.find((s) => dia.NR_DSM == s.NR_DSM).NM_DIA}))}
                columns={[
                    [lang.dia, "NM_DIA", {}, "f center"],
                    [lang.horario_inicial, "HR_INI", {}, "f center"],
                    [lang.horario_fim, "HR_FIM", {}, "f center"],
                    ["", "ACTIONS"]
                ]}

                />
            </>,
            UNIDADES:( f.unidadeVerify(d?.ORGS) ??
              <div style={{display: 'inline',
              maxWidth:'50ch',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'}}>
                <a onClick={() => setUnidade(d)} style={{cursor:'pointer'}}>{d.ORGS.map(org => org.LABEL).join(', ')}</a>
              </div>
            ),
            UNIDADES_ORDER:(
            d?.ORGS.length === 0
              ? "0"
              : d?.ORGS.length === 1
              ? d?.ORGS[0].LABEL
              : "-1"
              ),
            SITUACAO_ : (
              <div
              className="destaque f center"
                      style={{
                        backgroundColor:
                          "var(--" +
                          (d.CD_STS === "1"
                          ? "info"
                          : "colorTextGray"
                          ) +
                          ")",
                      }}
              >
                {labels[d.CD_STS]}
              </div>
            ),
            STATUS: d.CD_STS === '1' ? lang.ativo : lang.inativo,
            UNIDADES_NOME:
            d?.ORGS.length === 0 ? '' : d.ORGS.map(org => org.LABEL).join(' ')
          }))}
          columns={[
            ["#", "ID_TRN", {}, "f center"],
            [lang.descricao, "DS_TRN"],
            ["Status", "SITUACAO_", {}, "f center","STATUS"],
            [lang.unidades, "UNIDADES", {}, "f center","UNIDADES_ORDER"],
            [lang.horas_totais, "HORASTOTAL", {}, "f center"],
            [lang.dias_cadastrados, "DIAS_FILTRO", { display: "flex", justifyContent: "space-around" }],
            ["", "ACTIONS"]
          ]}
          search={busca}
        />
      </c.Frame>
      {incluir && (
        <TurnosModal
          tipo={"TRP"}
          onClose={() => {
            setIncluir(null);
          }}
          onFinish={() => {
            setIncluir(null)
            getData()
          }}
        />
      )}
      {editing && (
        <TurnosModal
          tipo={"TRP"}
          onClose={() => {
            setEditing(null)
          }}
          onFinish={() => {
            setEditing(null)
            getData()
          }}
          turno={editing}
        />
      )}
      {unidade && (
        <UnidadesModal
        turno = {unidade}
        onClose={() => setUnidade(null)}
        onFinish={() => getData()}
         />)}
    </>
  );
}

function UnidadesModal({turno, onClose}) {
  const App = useContext(AppContext),
  lang = {...App.lang.global, ...App.lang.transportadoras, ...App.lang.turno},
  icons = App.icons

const [loading, setLoading] = useState({}),
  [busca, setBusca] = useState(''),
  [incluir, setIncluir] = useState(null),
  [data, setData] = useState(turno.ORGS),
  [_data, set_Data] = useState([])

return(
<c.Modal contentHeight={150}
title={'Unidades'}
onClose={onClose}>
  <c.Table
    data={data}
    columns={[
      ["ID", "ID_UTRN", {}, "f center"],
      [lang.nome_unidade, "LABEL", {}, "f center"],
      ["CPF/CNPJ", "VALUE", {}, "f center"],
    ]}
    style={{overflowY: 'auto', width: '25vw', maxHeight:'70vh'}}
  />
  </c.Modal>)
}

function TurnosModal({
  turno = null,
  tipo = null,
  onClose,
  onFinish,
}) {
  const App = useContext(AppContext),
  [disableButton, setdisableButton] = useState (true), // VD-13106, Lucas, 11/06/2024
    lang = { ...App.lang.global, ...App.lang.menu, ...App.lang.turno },
    icons = App.icons;

  const semana = [
    { NR_DSM: "0", NM_DIA: lang.domingo, CD_STS: "1", HR_INI: "", HR_FIM: "" },
    { NR_DSM: "1", NM_DIA: lang.segunda, CD_STS: "1", HR_INI: "", HR_FIM: "" },
    { NR_DSM: "2", NM_DIA: lang.terca, CD_STS: "1", HR_INI: "", HR_FIM: "" },
    { NR_DSM: "3", NM_DIA: lang.quarta, CD_STS: "1", HR_INI: "", HR_FIM: "" },
    { NR_DSM: "4", NM_DIA: lang.quinta, CD_STS: "1", HR_INI: "", HR_FIM: "" },
    { NR_DSM: "5", NM_DIA: lang.sexta, CD_STS: "1", HR_INI: "", HR_FIM: "" },
    { NR_DSM: "6", NM_DIA: lang.sabado, CD_STS: "1", HR_INI: "", HR_FIM: "" },
  ];

  const [data, setData] = useState({
      ...turno,
      tipo,
      DIAS: turno
        ? semana.map((d) => ({
            ...d,
            HR_INI: turno.DIAS.find((t) => t.NR_DSM === d.NR_DSM)?.HR_INI??'',
            HR_FIM: turno.DIAS.find((t) => t.NR_DSM === d.NR_DSM)?.HR_FIM??'',
          }))
        : semana,
      ORGS: turno ? turno?.ORGS.map((o) => ({ ...o, IN_INSERT: "T" })) : [],
    }),
    [loading, setLoading] = useState(false),
    [busca, setBusca] = useState(""),
    [unidades, setUnidades] = useState([]),
    [_data, set_Data] = useState([]),
    [step, setStep] = useState(0)

  useEffect(() => {
    setLoading(true);
    App.api("turno::getUnidades").then((r) => {
      r = r.results.map((u, i) => ({
        ...u,
        IN_INSERT:
          turno && (data.ORGS.find((o) => o.VALUE === u.VALUE) ? "T" : "F"),
      }));
      setUnidades(r);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    set_Data(
      unidades.map((d, i) => ({
        ...d,
        CNPJ: f.formatCnpj(d.VALUE),
        SELECTION:
          <c.Switch
            checked={unidades[i].IN_INSERT === "T"}
            onChange={(e) => {
              unidades[i].IN_INSERT = e.target.checked ? "T" : "F";
              setUnidades(unidades);
              data.ORGS.find((u) => u.VALUE === d.VALUE) &&
                data.ORGS.splice(
                  data.ORGS.findIndex((u) => u.VALUE === d.VALUE),
                  1
                );
              data.ORGS.push(unidades[i]);
              setData({ ...data, ORGS: data.ORGS });
            }}
          />,
      }))
    );
  }, [unidades, data]);

  let steps = [
    {
      label: lang.descricao_horarios,
      validate: !!data?.CD_STS &&
      !!data.DS_TRN,
    },
    {
      label: lang.unidades,
      validate: true,
    },
    {
      label: lang.resumo,
      validate: true,
    },
  ];

  // VD-13106, Lucas, 11/06/2024
  function submit() {
    setLoading(true);
    return App.api("turno::save", {
      ...data,
    }).then((r) => {
      setLoading(false);
      if (r.status) {
        onFinish();
        return r.status;
      } else {
        return r.status;
      }
    });
  }

  // VD-13106, Lucas, 11/06/2024
  useEffect (()=>{
    return App.api ("turno::validaTurno", {
      ...data,
    }).then((r) => {
      if (!r.status) {
        setdisableButton (false);
        return r.status;
      } else {
        setdisableButton (true);
      }
    })
  },[data.CD_STS])

  return (
    <c.Modal
      fullWidth
      title={(turno  ? lang.editar :lang.cadastro) + " - " + lang.turnos}
      onClose={onClose}
      onFinish={submit}
      middleWidth
      steps={steps}
      contentHeight={400}
      onStepChange={(s) => setStep(s)}
      validate={
        !!data.DS_TRN &&
        !!data.CD_STS &&
        !!data.DIAS.every((d) => d.CD_STS === "1") &&
        disableButton // VD-13106, Lucas, 11/06/2024
      }
    >
      {step === 0 && (
        <div className="f f-wrap g1 w100">
          <c.Input
            required={!data?.DS_TRN}
            className="w50"
            label= {lang.descricao_turno}
            value={data.DS_TRN}
            onChange={(e) => setData({ ...data, DS_TRN: e.target.value })}
          />
          <c.Select
            required={!data?.CD_STS}
            className="w1-3"
            onChange={(e) => setData({ ...data, CD_STS: e.value })}
            value={data?.CD_STS}
            label={lang.situacao_turno}
            options={[
              {
                label: lang.ativo,
                value: "1",
              },
              {
                label: lang.inativo,
                value: "-3",
              },
            ]}
          />
          <div className="w100">
            <c.Divider />
          </div>
          <div
            className="f row w100"
            style={{ justifyContent: "space-evenly" }}
          >
            <c.Table
              data={data.DIAS.map((d,i) => ({
                ...d,
                LABEL_DIA: (
                  <p
                    style={{
                      fontWeight: "bold",
                      color:
                        d.HR_INI && d.HR_FIM && d.CD_STS === "1"
                          ? "var(--success)"
                          : "var(--danger)",
                    }}
                  >
                    {d.NM_DIA}
                  </p>
                ),
                HR_INI: (
                  <c.Input
                    className="w1-3"
                    type="time"
                    value={d.HR_INI}
                    onChange={(e) =>(
                      d.HR_INI = e.target.value,
                      d.CD_STS = App.moment(d.HR_FIM, "HH:mm").isAfter(App.moment(e.target.value, "HH:mm")) ? "1" : "-3",
                      setData({...data}))}
                  />
                ),
                HR_FIM: (
                  <c.Input
                    className="w1-3"
                    type="time"
                    value={d.HR_FIM}
                    onChange={(e) =>(
                      d.HR_FIM = e.target.value,
                      d.CD_STS = App.moment(e.target.value, "HH:mm").isAfter(App.moment(d.HR_INI, "HH:mm")) ? "1" : "-3",
                      setData({...data}))}
                  />
                ),
                ACTIONS: (
                  <c.IconButton disabled={!data.DIAS[i].HR_INI && !data.DIAS[i].HR_FIM} onClick={() => (data.DIAS[i].HR_INI = '', data.DIAS[i].HR_FIM = '', data.DIAS[i].CD_STS = '1', setData({...data,}))}><icons.BsFillEraserFill /></c.IconButton>
                )
              }))}
              columns={[
                [lang.dia, "LABEL_DIA", {}, "f center"],
                [lang.comeco_turno, "HR_INI", {}, "f center"],
                [lang.final_turno, "HR_FIM", {}, "f center"],
                ["", "ACTIONS", {}, "f center"]
              ]}
            />
          </div>
        </div>
      )}

      {step === 1 && (
        <c.Frame
          title={lang.unidades}
          loading={loading}
          control={
            <c.Input
              placeholder={lang.busca}
              value={busca}
              onChange={(e) => setBusca(e.target.value)}
              clearable
            />
          }
        >
          <c.Table
            data={_data}
            columns={[
              [<c.Switch
                checked={unidades.every(u => u.IN_INSERT === "T")}
                onChange={(e) =>{
                  let todos = unidades.map((d, i) => ({
                    ...d,
                    IN_INSERT : e.target.checked ? "T" : "F"
                  }))
                  setUnidades(todos)

                }}
              />, "SELECTION"],
              [lang.cnpj, "CNPJ"],
              [lang.nome, "LABEL"],
            ]}
            search={busca}
          />
        </c.Frame>
      )}

      {step === 2 && (
        <div className="f g2">
          <div className="f g2 f-column w50">
            <c.Frame title={lang.detalhe_pedido}>
              <div
                className="f g2 f-row w100"
                style={{ justifyContent: "space-around" }}
              >
                <c.Span value={data?.DS_TRN} label={lang.descricao_turno} />
                <c.Span
                  value={data?.CD_STS === "1" ? lang.ativo :  lang.inativo}
                  label={lang.situacao_turno}
                />
              </div>
            </c.Frame>
            <c.Table
              data={data.DIAS}
              columns={[
                [lang.dia, "NM_DIA"],
                [lang.comeco_turno, "HR_INI"],
                [lang.final_turno, "HR_FIM"],
              ]}
            />
          </div>
          <div className="f g2 f-column w50">
            <c.Frame title={lang.unidades} className="w100">
              {data.ORGS.every(d => d.IN_INSERT === "F")  ? lang.sem_unidades : data.ORGS.filter(u => u.IN_INSERT !== "F").map((o) => o.LABEL).join(", ")}
            </c.Frame>
            </div>
        </div>
      )}
    </c.Modal>
  );
}

