import { ColumnsCache } from '../models/ColumnsCache'

export const fetchColumns = async ({ api, tableId }) => {
  const cachedData = ColumnsCache.getData(tableId);

  if (cachedData) return cachedData;

  let { results: prefs = [] } = await api("users::getColunas", { ID_TABLE: tableId });

  if(JSON.stringify(prefs) === '{}') prefs = []

  ColumnsCache.setData(tableId, prefs);

  return prefs;
};
