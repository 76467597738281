import { useContext, useEffect, useMemo, useState } from "react"

import { AppContext } from "../../../../App"
import { Frame, Input, Modal, Table, chart as Chart } from "../../../../c"
import GerencialOperacaoCards from "../../../gerencial/gerencialOperacaoCards"

export function ContratoDetalheModal({ detalhe, onClose }) {
  const App = useContext(AppContext)
  const lang = useMemo(() => ({
    ...App.lang.global,
    ...App.lang.contratos
  }), [App.lang.contratos, App.lang.global])

  const today = useMemo(() => App.moment().format('yyyy-MM-DD'), [App])

  const [dtini, setDtini] = useState(today),
    [dtfim, setDtfim] = useState(today),
    [dataDetalhe, setDataDetalhe] = useState({}),
    [veiculoBusca, setVeiculoBusca] = useState(''),
    [loading, setLoading] = useState(false)

  const buscaVeiculoPaginacao = useMemo(() => {
    const { lst_veiculo = [] } = dataDetalhe

    if (lst_veiculo.length > 10) return 'client-side'

    return 'no-pagination'
  }, [dataDetalhe])

  function getDataDetalhe() {
    setLoading(true)
    App.api('gerencial::consulta', { ini: dtini, fim: dtfim, id_cn: detalhe.ID_CN })
      .then(r => {
        setDataDetalhe(r.results)
      }).finally(() => setLoading(false))
  }

  useEffect(() => getDataDetalhe(), [])
  useEffect(() =>
    App.moment(dtini, 'YYYY-MM-DD', true).isValid()
    && App.moment(dtini, 'YYYY-MM-DD', true).format('YYYY') >= 1900
    && App.moment(dtfim, 'YYYY-MM-DD', true).isValid()
    && App.moment(dtfim, 'YYYY-MM-DD', true).format('YYYY') >= 1900
    && getDataDetalhe()
    , [dtini, dtfim])

  return (
    <Modal title={lang.detalhe_contrato_titulo + ' ' + detalhe.ID_CN + ' :: ' + detalhe.DS_ORIGEM + ' > ' + detalhe.DS_DESTINO + ' :: ' + detalhe.NM_PRO}
      onClose={onClose} loading={loading}
      control={<div className="f g1">
        <Input type="date" label={lang.data_inicial} value={dtini} onChange={e => setDtini(e.target.value)} />
        <Input type="date" label={lang.data_final} value={dtfim} onChange={e => setDtfim(e.target.value)} />
      </div>}
    >
      <div className="f g2 f-column">
        <div className="f f-wrap g2">
          <GerencialOperacaoCards data={dataDetalhe} />
        </div>

        {!!dataDetalhe?.grafico &&
          <Frame title={lang.variacao_frete}>
            <Chart.ResponsiveContainer width="100%" minWidth={500} height={350}>
              <Chart.ComposedChart data={(() => {
                let FRETE_media_aux = parseInt(dataDetalhe?.grafico.reduce((acc, cur) => acc + parseFloat(cur.FRETE), 0) / dataDetalhe?.grafico.length * 100) / 100
                let FRETE_media = Number.isNaN(FRETE_media_aux) ? '-' : FRETE_media_aux
                let MOTORISTA_media_aux = parseInt(dataDetalhe?.grafico.reduce((acc, cur) => acc + parseFloat(cur.MOTORISTA), 0) / dataDetalhe?.grafico.length * 100) / 100
                let MOTORISTA_media = Number.isNaN(MOTORISTA_media_aux) ? '-' : MOTORISTA_media_aux
                return dataDetalhe?.grafico.map(g => ({
                  ...g,
                  FRETE_media, MOTORISTA_media
                }))
              })()} >
                <Chart.CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Chart.Tooltip />
                <Chart.YAxis domain={[0, parseInt(Math.max(...dataDetalhe.grafico?.map(g => parseFloat(g.FRETE) > parseFloat(g.MOTORISTA) ? parseFloat(g.FRETE) : parseFloat(g.MOTORISTA))) * 1.2)]} />
                <Chart.XAxis dataKey="DT" />
                <Chart.Line type="natural" dataKey="FRETE" stroke="#00dac2" strokeWidth={2} name="Frete" />
                <Chart.Line type="natural" dataKey="MOTORISTA" stroke="#f0a82d" strokeWidth={2} name="Motorista" />
                <Chart.Line type="natural" dataKey="FRETE_media" stroke="#00dac2" strokeWidth={1} strokeDasharray={5} name="Media Frete" />
                <Chart.Line type="natural" dataKey="MOTORISTA_media" stroke="#f0a82d" strokeWidth={1} strokeDasharray={5} name="Media Motorista" />
              </Chart.ComposedChart>
            </Chart.ResponsiveContainer>
          </Frame>
        }

        <Frame title={lang.veiculos}
          control={<Input placeholder={lang.busca} value={veiculoBusca} onChange={e => setVeiculoBusca(e.target.value)} />}
        >
          <Table
            search={veiculoBusca}
            pagination={buscaVeiculoPaginacao}
            columns={[
              [lang.unidade, 'ID_UORG'],
              [lang.data_prevista, '_DT_PRV', {}, '', 'DT_PRV'],
              [lang.turno, 'DS_TRN'],
              [lang.operacao, 'DS_TPOPRPRT'],
              [lang.cliente, 'NM_CLT'],
              [lang.origem_destino, 'DS_ORGDST'],
              [lang.produto, 'DS_PRD'],
              [lang.contrato, 'ID_CN'],
              [lang.transportadora, 'NM_TRP'],
              [lang.veiculo, 'ID_VCL'],
              [lang.ticket, 'ID_UOCC'],
            ]}
            data={
              dataDetalhe.lst_veiculo?.map(veiculo => ({
                ...veiculo,
                _DT_PRV: App.moment(veiculo.DT_PRV).format(lang.formatoData)
              }))
            }
          />
        </Frame>
      </div>
    </Modal>
  )
}
