export const handleCancel = async ({api,data}) =>{
  // return api('contratos::cancelar',{...data,sub: '',actions:''})

  const { status, results } = await api(
    'CancelQuotation',
    {
      ...data,sub: '',actions:''
    }
  )

  return results
}
