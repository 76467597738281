import { useState, useContext, useCallback } from "react"

import { AppContext } from "../../../App"
import { Chat, Divider, Input, Modal, Select, Span } from "../../../c"

import SITUATIONS from './contents/situations.json';

export const FaturamentoObs = ({ faturamento, onClose, onFinish }) => {
  const App = useContext(AppContext)
  const lang = {
      ...App.lang.global,
      ...App.lang.pedidos,
      ...App.lang.produtos,
      ...App.lang.menu,
      ...App.lang.Obs,
    }
  const icons = App.icons;

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    NEWOBS: faturamento?.NEWOBS ?? [],
    OBSS: faturamento.OBSS,
  });
  const options = [
    { value: "success", label: lang.success, color: "var(--success)" },
    { value: "danger", label: lang.danger, color: "var(--danger)" },
    { value: "warning", label: lang.warning, color: "var(--warning)" },
    { value: "info", label: lang.info, color: "var(--info)" },
  ];

  const submit = useCallback(async () => {
    setLoading(true);

    if (data?.OBSS?.length > 0) {
      data?.OBSS?.forEach((OBS) => {
        OBS.new = false;
      });
    }

    const situation = Number(data?.NEWOBS?.[0]?.op ?? '0')

    if (situation === SITUATIONS.CANCELED) {
      await App.api("controllership::cancel_invoice", { invoice_id: faturamento.ID_UOCC })
      App.toast.success(`Solicitação do faturamento ${faturamento.ID_UOCC} cancelada`)
    } else if (situation === SITUATIONS.DISAPPROVED) {
      await App.api("faturamento::reprovado", { ID_UOCC: faturamento.ID_UOCC })
      App.toast.success(`Solicitação do faturamento ${faturamento.ID_UOCC} reprovada`)
    }

    await App.api(
      "faturamento::faturamentoAlterarObs",
      { ...data, id_dc: faturamento.ID_UOCC }
    )

    setLoading(false);
    onFinish();

    return true;
  }, [App, data, faturamento.ID_UOCC, onFinish])

  return (
    <Modal
      minHeight={800}
      title="Editar Observação"
      loading={loading}
      onClose={onClose}
      onFinish={submit}
    >
      <div className="f g1 f-column" style={{ width: "70vw" }}>
        <Chat user={App.user.id_usr} msg={data?.OBSS ?? []} height={500} />
        <button
          title={'Adicionar'}
          onClick={() => {
            data.NEWOBS.push({
              ID_OPR: App.user.id_usr,
              ID_LOGIN: App.user.id_login,
              DT_OPR: App.user.dt_sistema,
              DS_OBS: { ds_obs: "", },
              new: true,
              TP_OBSS: 'M'
            });
            setData({ ...data });
          }}
        >
          <icons.MdAdd />
        </button>
        <Divider />
        {data.NEWOBS?.map((o, i) => (
          <div key={i} className="f g1">
            <Span value={o.DT_OPR} label={lang.data_observacao} />
            <Span value={o.ID_LOGIN} label={lang.relator} />
            {!faturamento || o.new ? (
              <Select
                label={'Tipo'}
                className="f1"
                options={options}
                styles={{
                  option: (s, { data }) => {
                    if (!data) return s

                    const background = `var(--${data.value})`

                    return ({ ...s, color: 'var(--white)', background })
                  },
                }}
                onChange={(e) => {
                  o.DS_OBS.id_color = e.value;
                  setData({ ...data });
                }}
                value={o.DS_OBS.id_color}
              />
            ) : (
              <Input
                disabled
                value={o.DS_OBS.ds_obs}
                className="f4 w100"
                inputStyle={{
                  borderLeft: `10px solid var(--${o.DS_OBS.id_color ?? "color"})`,
                  cursor: "default",
                }}
              />
            )}
            {(!faturamento || o.new) && (
              <Input
                value={o.DS_OBS.ds_obs}
                label={'Observação'}
                className="f4"
                onChange={(e) => {
                  o.DS_OBS.ds_obs = e.target.value;
                  setData({ ...data });
                }}
                inputStyle={{
                  borderLeft: `5px solid var(--${o.DS_OBS.id_color ?? "color"})`,
                }}
              />
            )}
            {(!faturamento || o.new) && (
              <button
                onClick={() => {
                  data?.NEWOBS.splice(i, 1);
                  setData({ ...data });
                }}
              >
                <icons.BsFillTrashFill />
              </button>
            )}
          </div>
        ))}
      </div>
    </Modal>)
}
