import React from 'react'
import "./style.sass";
import { Card } from './Card'


export const CardFilters = ({ filters, visible = false }) => {
  if(!visible) return <></>

  return (
    <div className="f g2 f-wrap container">
      {filters.map(({type, className, key, filters, label }) => {
        if(type === 'divisor') return <div className='divider-vertical'></div>;

        return (
          <Card className={className} key={key} filters={filters} label={label} />
        )
      })}
    </div>
  )
}
