import { useContext } from 'react';
import { getDetails } from '../../../useCases/getDetails';
import { ObservationsFields } from '../../Modals/CreateModal/SummaryStep/ObservationsFields';

import { AppContext } from '../../../../../App';
import { Frame } from '../../../../../c';

import { AddressDetails } from './AddressDetails';
import { TransportInformation } from './TransportInformation';

import './styles.sass';

export const QuotationDetails = ({ record }) => {

const App = useContext(AppContext)
  const presenter = getDetails(record);

  return (
    <div className='f g2'>
      <Frame title="Itinerário" className='w100'>
        <div className='container'>
          <div className='details-group'>
            <AddressDetails items={presenter.address.items} />
            <TransportInformation items={presenter.transportInformation.items} />
          </div>
        </div>
      </Frame>

      <div className='w100'>
        <ObservationsFields observations={record.observations} lang={App.lang.bid.createModal}/>
      </div>
    </div>
  );
};
