import { QuotationPresenter } from "../presenters/QuotationPresenter";

export const getDetails = (record) => {
  const presenter = new QuotationPresenter(record);

  if (!record) {
    throw new Error("Error Bid Details");
  }

  const details = {
    address: {
      items: [
        { label: 'Endereço de Origem', value: presenter.valueOf('addressOrigin') },
        { label: 'Endereço de Destino', value: presenter.valueOf('addressDestination') },
      ],
    },
    transportInformation: {
      items: [
        { label: 'Distância', value: presenter.valueOf('distance') },
        { label: 'Duração', value: presenter.valueOf('duration') },
        { label: 'Pedágio', value: presenter.valueOf('toll') },
      ],
    },
    observations: {
      items: [
        { label: 'Observações', value: presenter.valueOf('observation').label },
      ],
    },
  };

  return details;
};
