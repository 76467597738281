import { useContext, useEffect, useState } from "react"

import { AppContext } from '../../App'
import * as c from '../../c'
import * as f from '../../f'

import { DropdownEditMenu } from "./components/DropdownEditMenu"
import { ContratoCard } from "./components/ContratoCard"
import { ContratoCotasModal } from "./modals/ContratoCotasModal"
import { ContratoEditarModal } from "./modals/ContratoEditarModal"
import { ContratoDetalheModal } from "./modals/ContratoDetalheModal"
import { ContratoIncluirModal } from "./modals/ContratoIncluirModal"

export default function Contratos({ setReloader }) {
  const App = useContext(AppContext),
    lang = { ...App.lang.global, ...App.lang.contratos },
    icons = App.icons

  const [data, setData] = useState([]),
    [editing, setEditing] = useState(null),
    [editCotas, setEditCotas] = useState(null),
    [detalhe, setDetalhe] = useState(null),
    [incluir, setIncluir] = useState(null),
    [toggleCardsList, setToggleCardsList] = useState(true),
    [loading, setLoading] = useState([]),
    [busca, setBusca] = useState(''),
    [showFilters, setShowFilters] = useState(false),
    [ufFilter, setUfFilter] = useState(null),
    [filtroEncerradas, setFiltroEncerradas] = useState('Todos'), /* Lucas, VD-22920, 17/07/2024 */
    [areasDeNegocio, setAreasDeNegocio] = useState([]), /* Lucas, VD-22920, 17/07/2024 */
    [filtroAreaNegocio, setFiltroAreaNegocio] = useState(null) /* Lucas, VD-22920, 17/07/2024 */

  const [sourceTypeFilter, setSourceTypeFilter] = useState('');

  const opcoes = [
    { value: 'todos', label: 'Todos' },
    { value: 'andamento', label: 'Em andamento' },
    { value: 'encerrados', label: 'Encerrados' }
  ];

  const sourceTypeOptions = [
    { value: '', label: 'Todos' },
    { value: 'Proprio', label: 'Proprios' },
    { value: 'Terceiro', label: 'Terceiros' }
  ];

  function recalcular(contrato){
    setLoading(true);
    App.api("contratos::recalcular", {idCn: contrato}).then(()=>{
      setLoading(false);
      get();
    })
  }

  function getAreasDeNegocio() { /* Lucas, VD-22920, 17/07/2024 */
    App.api('fob::getAreasdeNegocio').then(res => {
      setAreasDeNegocio(res.results);
    });
  }

  function get() {
    setLoading(true)
    App.api('contratos::consultaTudo', { filtroEncerradas, filtroAreaNegocio, sourceTypeFilter }).then(r => { /* Lucas, VD-22920, 17/07/2024 */

      r = r.results.map(contrato => ({
        ...contrato,
        UND_NGC: (contrato.ID_UORG),
        ORI_DES: <>{contrato.DS_ORIGEM}<br />{contrato.DS_DESTINO}</>,
        SLC: f.formatNumber(contrato.QT_SLC, 0),
        SALDO: f.formatNumber(contrato.QT_SALDO, 0),
        CAD: f.formatNumber(contrato.QT_CAD, 0),
        PROPRIO_TERCEIRO: (contrato.PROPRIO_TERCEIRO),
        actions: <>
          {parseInt(contrato.CD_STS) !== -1
            && <DropdownEditMenu contrato={contrato} setEditing={setEditing} setEditCotas={setEditCotas} control={<icons.BsPencilFill />} />
          }
          <c.IconButton onClick={() => setDetalhe(contrato)} ><icons.BsEye /></c.IconButton>
        </>,
        VOL_CADENCIA: f.formatNumber(parseFloat(contrato.QT_ORG_HOJE ?? 0) + parseFloat(contrato.QT_VOLUMECOTA_HOJE ?? 0), 0),
        AREA_NEGOCIO: (contrato.AREA_NEGOCIO), /* Lucas, VD-22920, 15/07/2024 */
        calc_ontem: f.formatNumber(parseFloat(contrato.QT_ORG_ONTEM ?? 0) + parseFloat(contrato.QT_VOLUMECOTA_ONTEM ?? 0), 2),
        calc_hoje: f.formatNumber(parseFloat(contrato.QT_ORG_HOJE ?? 0) + parseFloat(contrato.QT_VOLUMECOTA_HOJE ?? 0), 2),
        calc_amanha: f.formatNumber(parseFloat(contrato.QT_ORG_AMANHA ?? 0) + parseFloat(contrato.QT_VOLUMECOTA_AMANHA ?? 0), 2)
      }))
      setData(r)
      setLoading(false)
      return true;
    })
  }

  useEffect(() => {get(); setReloader(() => get); getAreasDeNegocio();}, []); /* Lucas, VD-22920, 17/07/2024 */

  useEffect(() => get(), [filtroEncerradas, filtroAreaNegocio, sourceTypeFilter]) /* Lucas, VD-22920, 17/07/2024 */

  return (<>
    <div className="react-tabs f g2">
      {!loading &&
        <ul className="react-tabs__tab-list">
          <li onClick={() => setUfFilter(null)} className={["react-tabs__tab", (!ufFilter ? 'react-tabs__tab--selected' : '')].join(' ')}>
            Todas as UFs <sup>{data.length}</sup>
          </li>
          {Object.values(data
            .reduce((acc, d) => ({
              ...acc,
              [d.UF_UCN]: {
                uf: d.UF_UCN,
                qnt: (acc[d.UF_UCN]?.qnt ?? 0) + 1
              }
            }), {}))
            .map((d, di) =>
              <li key={di} onClick={() => setUfFilter(d.uf)} className={["react-tabs__tab", (ufFilter === d.uf ? 'react-tabs__tab--selected' : '')].join(' ')}>
                {d.uf} <sup>{d.qnt}</sup>
              </li>
            )
          }
        </ul>
      }
    </div>

    <c.Frame title={lang.comunicacao_negocio} loading={loading} flex
      actions={{
        add: () => setIncluir(true),
        filter: toggleCardsList ? { onClick: () => setShowFilters(!showFilters), value: showFilters } : null,
        toggleCardsList: { onClick: () => setToggleCardsList(!toggleCardsList), value: toggleCardsList }
      }}
      control={<div className="f g1">
        <c.Input placeholder={lang.busca} value={busca} onChange={e => setBusca(e.target.value)} clearable className={'f5'} />
        <c.Select value={sourceTypeFilter} onChange={v => setSourceTypeFilter(v.value)} options={sourceTypeOptions} label={"Tipo de Origem"} searchable className='f1' />
        <c.Select value={filtroAreaNegocio} onChange={v => setFiltroAreaNegocio(v.value)} options={areasDeNegocio} label={lang.area_negocio} searchable className='f1' /> {/* Lucas, VD-22920, 17/07/2024 */}
        <c.Select value={filtroEncerradas} onChange={v => setFiltroEncerradas(v.value)} options={opcoes} label={'Status Contratos'} searchable className='f1' />
      </div>}

    >
      {toggleCardsList ?
        <c.Table loading={loading} search={busca} showFilters={showFilters}
          columns={[
            [lang.und_ngc, 'UND_NGC'],
            [lang.ori_des, 'ORI_DES'],
            [lang.produto, 'NM_PRO'],
            [lang.area_negocio, 'AREA_NEGOCIO'], /* Lucas, VD-22920, 15/07/2024 */
            [lang.volume, 'SLC'],
            [lang.saldo, 'SALDO'],
            [lang.cadencia_dia, 'CAD'],
            [lang.valor_frete, 'VL_FRETE'],
            [lang.valor_pedagio, 'VL_PEDAGIO'],
            [lang.ontem, 'QT_ONTEM'],
            [lang.hoje, 'QT_HOJE'],
            [lang.amanha, 'QT_AMANHA'],
            [lang.situacao, 'situacao'],
            [lang.acao, 'actions', {}, 'center f g1'],
          ]}
          data={data.filter(d => !ufFilter || d.UF_UCN === ufFilter).map(item => ({
            ...item,
            situacao: item.CD_STS === '-1' ? lang.encerrado : (item.CD_STS === '-2' ? lang.pausado : lang.em_andamento)
          }))}
        />

        :

        data.filter(d => !ufFilter || d.UF_UCN === ufFilter).length
          ? (() => {
            let df = data?.filter(d => !ufFilter || d.UF_UCN === ufFilter).filter(d => busca === '' || Object.keys(d).some(k =>
              ['string', 'number'].indexOf(typeof d[k]) >= 0
              && busca?.toUpperCase().split(' ').every(si => String(d[k])?.toUpperCase().includes(si)))) ?? []
            return df.length
              ? df?.map((d, i) =>
                  <ContratoCard key={i} contrato={d} setEditing={setEditing} setDetalhe={setDetalhe} get={get} setEditCotas={setEditCotas} recalcular={recalcular} />
                )
              : <b>{lang.nothing_here}</b>
          })()
          : <b>{lang.nothing_here}</b>
      }
    </c.Frame>

    {incluir && <ContratoIncluirModal onFinish={() => get()} onClose={() => setIncluir(null)} />}
    {detalhe && <ContratoDetalheModal detalhe={detalhe} onClose={() => setDetalhe(null)} />}
    {editing && <ContratoEditarModal contrato={editing.contrato} op={editing.op} onFinish={() => get()} onClose={() => setEditing(null)} />}

    {editCotas && <ContratoCotasModal contrato={editCotas} onClose={() => { setEditCotas(null); get() }} />}
  </>)
}
