import React, { useCallback, useContext, useMemo } from 'react'

import { buildFields } from './useCases/buildFields'
import { PreviewInvoice } from './components/PreviewInvoice'
import { changeField } from './useCases/changeField'
import { AppContext } from '../../../../../App'
import { Input, Span, Upload } from '../../../../../c'

export const InvoiceStep = ({ step, dataFields, onChange }) => {
  const App = useContext(AppContext)
  const lang = useMemo(() => {
    const {
      errors,
      freight_controllership: { invoicing_modal },
      global
    } = App.lang

    return { ...global, ...errors, ...invoicing_modal }
  }, [App.lang])

  const handleChangeField = useCallback((field, type) => {
    return changeField({ field, type, onChange })
  }, [onChange])

  const groupedFields = useMemo(() => {
    return buildFields(dataFields, lang, handleChangeField)
  }, [dataFields, handleChangeField, lang])

  if(step !== 1) return <></> // step 1 é Fatura

  return (
    <div className='f g1'>
      <div className='w1-3 f g1 f-column'>
        <div className='f g1 f-column'>
          {groupedFields.map(([key, fields]) => (
            <div key={key} className='f g1'>
              {fields.map((field) => (
                <Input
                  required
                  className={`${field.className} h-field ${field.type}`}
                  error={field.error}
                  key={field.key}
                  label={field.label}
                  disabled={field.disabled ?? false}
                  onChange={field.onChange}
                  type={field.type === 'float' ? 'text' : field.type}
                  value={field.value}
                />
            ))}
            </div>
          ))}
        </div>

        <Upload
          accept={{"application/pdf": ['.pdf'],}}
          className='w100'
          files={dataFields.images ?? []}
          label={lang.invoice_file}
          maxFiles={1}
          onChange={handleChangeField('invoice_file', 'file')}
        />
      </div>

      <div className='w2-3'>
        <Span
          label={lang.preview}
          className='center-v'
          value={<PreviewInvoice data={dataFields} lang={lang} />}
        />
      </div>
    </div>
  )
}
