import { BsFillTrashFill } from 'react-icons/bs'
import { MdAdd, MdSend } from "react-icons/md"

import { Input, Select, Chat } from "../../../../c"

export const ObservacaoPartial = ({ data, setData }) => {
  return (
    <div className="f f-column g1 w100 contrato-editar">
      <div className="f g1 f-column" style={{ width: '70vw' }}>
        {data.observations?.map((o, i) =>
          <div key={i} className="f g1 center-v">
            <Select label={'Tipo'} className="f1" options={[
              { value: 'success', label: 'Sucesso', color: 'var(--success)' },
              { value: 'danger', label: 'Cuidado', color: 'var(--danger)' },
              { value: 'warning', label: 'Atenção', color: 'var(--warning)' },
              { value: 'info', label: 'Informação', color: 'var(--info)' },
              { value: 'primary', label: 'Normal', color: 'var(--primary)' }
            ]} styles={{ option: (s, { data }) => ({ ...s, color: 'var(--white)', background: data.color }) }}
              onChange={e => { data.observations[i].type = e.value; setData({ ...data, observations: data.observations }) }}
              value={o.type}
            />

            <Input value={o.description} label={'Observação'} className="f4"
              onChange={e => { data.observations[i].description = e.target.value; setData({ ...data, observations: data.observations }) }}
              inputStyle={{ borderLeft: '5px solid var(--' + (o.type ?? 'color') + ')' }}
            />

            <button onClick={() => { data.observations.splice(i, 1); setData({ ...data, observations: data.observations }) }}><BsFillTrashFill /></button>
          </div>
        )}
        <button title={'Adicionar observação'} onClick={() => { data.observations = data.observations.filter(o => !!o.description); data.observations.push({ description: '' }); setData({ ...data }) }}><MdAdd />{'Adicionar observação'}</button>
      </div>
    </div>
  )
}

export const ObservacaoChat = ({ data, App, newObs, setNewObs, submit, loading = false }) => {
  const { user } = App

  return (
    <div className="f f-column g1 w100 h100 contrato-editar" id="parente">
      <div className="f f1 w100">
        {loading && <App.LoadingComponent />}
        {!loading && <Chat user={user.id_usr} msg={data ?? []} height={500} />}
      </div>

      <form onSubmit={submit}>
        <div className="f g1 f-end" id="ONE">
          <Input
            value={newObs.descricao}
            className="f4"
            onChange={(e) => {
              setNewObs({ descricao: e.target.value });
            }}
          />

          <button
            style={{ height: "39px" }}
            disabled={loading || !newObs.descricao}
          >
            <MdSend />
          </button>
        </div>
      </form>
    </div>
  )
}
