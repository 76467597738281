import moment from "moment"

export const parseFilters = (filters) => {
  return filters.reduce(
    (memo, { field, value }) => {
      if(field === 'period') {
        const { startDate, endDate } = value

        return {
          ...memo,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        }
      }

      if(value) return { ...memo, [field]: value }

      return memo
    },
    {}
  )
}
