import { useCallback } from "react"

import { Input } from "../.."

export const InputFilter = ({ label, onChange, settings, value }) => {
  const handleChange = useCallback(({ target }) => {
    if(onChange) onChange(target.value)
  }, [onChange])

  return (
    <Input
      {...settings}
      clearable
      className="f1"
      label={label}
      onChange={handleChange}
      value={value}
    />
  )
}
