import { useCallback, useContext, useMemo, useState } from 'react';
import { AppContext } from '../App';
import * as components from '../c';
import * as formUtils from '../f';

export default function AnexarADEModal({ portaria, onClose, onFinish }) {
  const { lang, api } = useContext(AppContext);
  const language = useMemo(() => ({
    ...lang.global,
    ...lang.monitor,
  }), [lang.global, lang.monitor]);

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const convertImageToBase64 = useCallback(async (file) => {
    return await formUtils.fileToBase64(file);
  }, []);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const imagem = await convertImageToBase64(images[0].file);
    const idUocc = portaria.ID_UOCC ?? portaria.ticketId;
    const extensaoImagem = images[0].name.split('.').pop();
    const { status } = await api('RegisterADEDocument::recordADE', {
      id_uocc: idUocc,
      tp_ext: extensaoImagem,
      imagem,
      ds_tag: 'ade',
    });

    setLoading(false);
    if (onFinish) onFinish();

    return status;
  }, [convertImageToBase64, images, portaria.ID_UOCC, portaria.ticketId, api, onFinish]);

  return (
    <components.Modal
      largeWidth
      loading={loading}
      onClose={onClose}
      onFinish={handleSubmit}
      title={language.anexar_agendamento_destino}
      validate={images.length > 0}
    >
      <div className='f w100'>
        <components.Upload
          accept={{"application/pdf": ['.pdf'], "image/png": ['.png'], "image/jpeg": ['.jpg']}}
          label="Arquivo aceito: PDF, JPG e PNG. Máximo de 1 arquivo."
          maxFiles={1}
          files={images}
          onChange={setImages}
          className='w100'
        />

        <div className='w50'>
          <components.Span
          label={"Pré-visualização"}
          className='center-v'
          value={images[0]
                  ? <img src={images[0].preview} alt="Preview" className='w100' style={{maxHeight: 400}} />
                  : <div className='p10'>Selecione um arquivo para visualizar</div>
                  }
          />
      </div>
     </div>
    </components.Modal>
  );
}
