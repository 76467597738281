import React, { useCallback, useContext, useMemo } from 'react';
import { MdSearch as SearchIcon } from 'react-icons/md';

import { AppContext } from '../../App';
import { FieldFilter } from './FieldFilter';

export const Filters = ({ additionalButtons = [], filters, loading = false, onChangeFilter, onSubmitFilter }) => {
  const { lang } = useContext(AppContext);
  const globalLang = useMemo(() => lang?.global ?? {}, [lang?.global]);

  const handleFilter = useCallback(() => {
    if (onSubmitFilter) onSubmitFilter();
  }, [onSubmitFilter]);

  const buttons = useMemo(() => {
    const searchConfig = {
      key: 'search',
      disabled: loading,
      onClick: handleFilter,
      label: (
        <>
          <SearchIcon size={24} />
          {globalLang.pesquisar}
        </>
      ),
    };

    return [searchConfig, ...additionalButtons].filter(({ display = true }) => display);
  }, [additionalButtons, handleFilter, globalLang.pesquisar, loading]);

  return (
    <div className="filters f f-wrap g1 center-v">
      {filters.map(({key, ...filter}) => (
        <FieldFilter
          key={key}
          filter={filter}
          onChange={onChangeFilter(filter.field)}
        />
      ))}

      <div className="buttons">
        {buttons.map(({ key, disabled, onClick, label }) => (
          <button
            key={key}
            className="f1 submit"
            disabled={disabled}
            onClick={onClick}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};
