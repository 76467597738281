import React from 'react'

import { Frame, PreviewPDF } from '../../../../../../c'

export const PreviewInvoice = ({ data, lang }) => {
  const { images, pdf, } = data ?? {}
  const [ image ] = images ?? []
  const { file: { type: contentType } = {}, preview } = image ?? {}

  if(!contentType) { // será undefined se o arquivo da imagem não existir
    return (
      <div className='p10'>
        {lang.select_invoice_file}
      </div>
    )
  }

  if( contentType === 'application/pdf') {
    return (
      <Frame alt={lang.preview} className="w100" >
        <PreviewPDF file={pdf} />
      </Frame>
    )
  }

  return (
    <img
      src={preview}
      alt={lang.preview}
      className='w100'
      style={{ maxHeight: 400 }}
    />
  )
}
