import React, { useContext, useState } from "react"
import { AppContext } from '../App'
import * as c from '../c'
import * as f from '../f'

export default function AnexarDcoAuxModal({portaria, onClose, onFinish}){
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.dfe, ...App.lang.transportador}

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [tipo, setTipo] = useState(0)

  const shipment = (portaria.TP_OPRPRT === 'E')
                || (portaria.operationType === 'shipment')
  const ticketId = portaria.ID_UOCC ?? portaria.ticketId

  // VD-12707 - Samuel - 21/05/2024
  const tipos = [
    {value: 1, label: shipment ? lang.anexar_ordem_carga : lang.anexar_ordem_descarga},
    {value: 3, label: lang.nota_de_venda},
    {value: 11, label: lang.nfe_devolvida},
    {value: 12, label: lang.aet}
  ]

  async function submit(){
    setLoading(true)

    const extension = files[0].file.name.split('.').pop().toUpperCase()
    let ds_dco = ['XML'].includes( extension )
      ? await f.getFileString( files[0].file )
      : await f.fileToBase64( files[0].file )

    try {
      const { status } = await App.api('portarias::anexarDcoAux', {
        ID_UOCC: ticketId,
        NM_DCO: files[0].name,
        tipo: tipo, //VD-12707 - Samuel - 22/05/2024 --> retirei o "+ 1" do "tipo + 1"
        DS_DCO: ds_dco
      })

      onFinish()

      return status
    } finally {
      setLoading(false)
    }
  }

  return(
    <c.Modal
      middleWidth
      loading={loading}
      onClose={onClose}
      onFinish={submit}
      successMsg={lang.vinculada_com_sucesso}
      title={`Anexar documento auxiliar - ${ticketId}`}
      validate={files.length === 1}
    >
      {/* VD-12707 - Samuel - 21/05/2024 */}
      <c.Select
        label={'Tipo do documento'}
        onChange={e=>setTipo(e.value)}
        options={tipos}
        value={tipos.find(option => option.value === tipo)}
      />

      {tipo === 11 && (
        <div className='f g1 w100'>
          <div className='f g1'>
            <c.Span
              label={"ATENÇÃO: Vincular uma nota devolvida irá encerrar o agendamento, e considerar a viagem concluída."}
            />
          </div>
        </div>
      )}

      <c.Upload
        accept={{
          "application/pdf": ['.pdf'],
          "image/png": ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
          'image/jfif': ['.jfif'],
        }}
        label="Arquivo aceito: PDF, JPG, PNG e JFIF. Máximo de 1 arquivo."
        maxFiles={1}
        files={files}
        onChange={f=>setFiles(f)}
        className='w100'
      />
    </c.Modal>
  )
}
