import moment from "moment"
import { formatNumber } from "../../../f"

const valueWithDefault = (value) => value ?? ''

const dateTo = (date) => {
  if((date ?? '') === '') return valueWithDefault(date)

  return  moment(date).format('DD/MM/YYYY')
}

const kilogramTo = (value) => {
  if((value ?? null) === null) return valueWithDefault(value)

  return formatNumber(value, 2)
}

const priceTo = (value) => {
  if((value ?? '') === '') return valueWithDefault(value)

  return formatNumber(value, 2)
}

export class TravelRecordCsvPresenter  {
  constructor(record, lang) {
    this.record = record
    this.lang = lang
  }

  valueOf (field) {
    if(this[field]) return this[field]()

    return valueWithDefault(this.record[field])
  }

  translateEnumTo (value, field) {
    if((value ?? null) === null) return valueWithDefault(value)

    return this.lang.enums[field][value] ?? value
  }

  compliance () {
    return this.translateEnumTo(this.record.compliance, 'compliance')
  }

  situation () {
    return this.translateEnumTo(this.record.situation, 'situation')
  }

  deadline () {
    return dateTo(this.record?.deadline)
  }

  shippingDateCte () {
    return dateTo(this.record?.shippingDateCte)
  }

  nfeWeight () {
    return kilogramTo(this.record?.nfeWeight)
  }

  amount () {
    return priceTo(this.record?.amount)
  }

  mainAmount () {
    return priceTo(this.record?.mainAmount)
  }

  additionalAmount () {
    return priceTo(this.record?.additionalAmount)
  }

  amountCte () {
    return priceTo(this.record?.amountCte)
  }

  cteNumber () {
    return valueWithDefault(`${this.record?.cteNumber}`)
  }
}
