import { useContext } from "react";
import { IconButton } from "../../../../c";
import { AppContext } from "../../../../App";

export const ToggleViewButtons = ({
  toggle,
  lang,
  displayContent,
}) => {
  const App = useContext(AppContext);

  const buttonsConfig = [
    {
      isActive: displayContent === 'list',
      setActive: () => toggle('list'),
      icon: App.icons.FaList({ size: '34' }),
      title: lang.buttonView.buttonList.title,
    },
    {
      isActive: displayContent === 'summaries',
      setActive: () => toggle('summaries'),
      icon: App.icons.FaChartPie({ size: '34' }),
      title: lang.buttonView.buttonDashboard.title,
    }
  ];

  return (
    <div className="frame-buttons-view">
      {buttonsConfig.map(({ isActive, setActive, icon, title }, index) => (
        <IconButton
          key={index}
          onClick={setActive}
          size={32}
          title={title}
          disabled={isActive}
        >
          {icon}
        </IconButton>
      ))}
    </div>
  );
};
