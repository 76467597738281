import { useContext, useMemo } from "react"

import { AppContext } from "../../../../../../App"
import { Badge } from "../../../../../../c/Badge"
import { Frame, Table } from "../../../../../../c"
import { t } from "../../../../../../f"

export const ObservationsFields = ({ observations, lang }) => {
  const App = useContext(AppContext)

  const columns = useMemo(() => {
    return [
      [t(lang.observationsStep, 'type'), 'type', {}, 'force-fit'],
      [t(lang.observationsStep, 'description'), 'description'],
    ]
  },[lang])

  const data = useMemo(() => {
    return observations.map(({ id, type = 'primary', description }) => ({
      description,
      key: id,
      type: <Badge color={type} text={t(App.lang.global, type)} size="md" />,
    }))
  }, [App.lang.global, observations])

  return (
    <Frame title={t(lang.steps, 'observationsStep')} className="w100 h100">
      <Table isSub columns={columns} data={data} fixedPerPage={5} />
    </Frame>
  )
}
