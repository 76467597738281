import { useContext, useEffect } from "react"
import { AppContext } from '../../App'
import * as c from '../../c'
import * as p from '..'
import * as f from '../../f'

export default function GerencialFracionadaCards({data}){
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.contratos},
        icons = App.icons

    useEffect(()=>{
    console.log(data)
    },[])

return(<>
    <c.Card icon={icons.BsArrowsMove}     title={lang.cota}           value={Object.keys(data.lst_portaria.reduce((acc, lista_interna) => {
  lista_interna.LST_VCL.flat().forEach(obj => {
    acc[obj.ID_OC] = true;
  });
  return acc;
}, {})).length < 1 ? 0 : Object.keys(data.lst_portaria.reduce((acc, lista_interna) => {
    lista_interna.LST_VCL.flat().forEach(obj => {
      acc[obj.ID_OC] = true;
    });
    return acc;
  }, {})).length - 1}         percent={data.pr_cota} />
    <c.Card icon={icons.BsCurrencyDollar} title={lang.cif}            value={Object.keys(data.lst_portaria.reduce((acc, lista) => {
  lista?.LST_VCL.filter(obj => obj.TP_FRT === "C").forEach(obj => {
    acc[obj.ID_OC] = (acc[obj.ID_OC] || 0) + 1;
  });
  return acc;
}, {})).length}          percent={data.pr_cif} />
    <c.Card icon={icons.FaHandshake}      title={lang.fob}            value={Object.keys(data.lst_portaria.reduce((acc, lista) => {
  lista?.LST_VCL.filter(obj => obj.TP_FRT === "F").forEach(obj => {
    acc[obj.ID_OC] = (acc[obj.ID_OC] || 0) + 1;
  });
  return acc;
}, {})).length}          percent={data.pr_fob} />

    <c.Card icon={icons.BsCalendar3}      title={lang.agendado}       value={data.lst_portaria?.[2]?.LST_VCL.length??0}  type='info' />
    <c.Card icon={icons.FaRoad}           title={lang.transito}       value={data.lst_portaria?.[3]?.LST_VCL.length??0}  type='warning' />
    <c.Card icon={icons.BsClock}          title={lang.em_fila}        value={data.lst_portaria?.[4]?.LST_VCL.length??0}  type='warning' />
    <c.Card icon={icons.BsClock}          title={lang.em_operacao}    value={data.lst_portaria?.[5]?.LST_VCL.length??0}  type='info' />

    <c.Card icon={icons.BsClock}          title={lang.encerrado}      value={data.lst_portaria?.[6]?.LST_VCL.length??0}  percent={data.lst_portaria?.[7]?.PR_VCL??0}  type='success' />
    <c.Card icon={icons.FaEyeSlash}       title={lang.no_show}        value={data.lst_portaria?.[7]?.LST_VCL.length??0}  percent={data.lst_portaria?.[8]?.PR_VCL??0}  type='success' />

    <c.Card icon={icons.FaBalanceScale}   title={lang.peso}           value={data.lst_portaria?.[0].LST_VCL.reduce((acc, c) => {
        const qtOrg = parseFloat(c.QT_ORG||0)

        return acc + qtOrg

    }, 0)}  percent={data.pr_volume} />
  </>)
}
