import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Input } from '../../../../../c'; // Assumindo que seu componente Input está disponível aqui
import { AppContext } from '../../../../../App';
import { t } from '../../../../../f';

export const InputDisplay = ({
  collection,
  onInputChange,
  onOpenModal,
  label,
  settings,
}) => {
  const { toast, lang  } = useContext(AppContext)

  const [inputProps, setInputProps] = useState({
    value: collection[0] ?? '',
    cursor:'text',
    readOnly: false
  });
  const [timer, setTimer] = useState(null);

  const handleInputChange = useCallback(({ target }) => {
    const { value } = target;

    setInputProps((state) => ({ ...state, value })); // aplicando o valor editável

    // Limpa o timeout anterior
    if (timer) clearTimeout(timer);

    // Configura um novo timeout para enviar o valor após 500ms
    const newTimer = setTimeout(() => {
      try {
        if(onInputChange) onInputChange(value, 0);
      } catch(error) {
        toast.error(error.message)
      }
    }, 500)

    // aplica o timer no estado
    setTimer(newTimer);
  }, [timer, onInputChange, toast]);

  const handleOpenModal = useCallback(({ forceOpen = false }) => {
    return () => {
      if (!forceOpen && collection.length <= 1) return;

      if (onOpenModal) onOpenModal();
    };
  }, [collection.length, onOpenModal]);


  useEffect(() => {
    let props = {
      cursor:'text',
      readOnly: false,
      value: collection[0] ?? '',
    }

    if (collection.length > 1) {
      props = {
        cursor:'pointer',
        readOnly: true,
        value: t(
          lang.inputMultiFilter.inputdisplay, 
          'title', 
          { count: collection.length }
        ),
      }
    }

    setInputProps(props);
  }, [collection, lang]);

  return (
    <div className="input-display">
      <Input
        {...settings}
        {...inputProps}
        label={label}
        icon="filter"
        iconClick={handleOpenModal({ forceOpen: true })}
        onClick={handleOpenModal({ forceOpen: false })}
        onFocus={handleOpenModal({ forceOpen: false })}
        onChange={handleInputChange}
      />
    </div>
  );
};
