import React from 'react';

export const Field = ({ label, value }) => {
  return (
    <div className="details-column">
      <h3>{label}:</h3>
      <div className='form-group'>{value}</div>
    </div>
  )
};
