import React, { Fragment, useState, useEffect, useContext, useRef } from "react"
import { AppContext, log } from '../App'
import * as c from '../c'
import * as f from '../f'

import { useReactToPrint } from 'react-to-print'
import QRCode from 'qrcode.react'

export const PrintTicketModal = ({idPrt, onClose}) => {
  const App = useContext(AppContext)
  const lang = {...App.lang.global, ...App.lang.monitor}
  const component = useRef()
  const [loading, setLoading] = useState(false)
  const [p, setP] = useState({}) // Portaria

  useEffect(() => {
    load()

    async function load(){
      setLoading(true)

      let _p = (await App.api('portarias::consultaPorId', {id: idPrt})).results

      _p.OBSS = !!_p.ID_CN
        ? (await App.api('contratos::getObsByCnId', {id: _p.ID_CN})).results
            .filter(obs => !!obs.in_ticket)
        : []

      setP( _p )
      setLoading(false)
      console.log(_p)
    }
  }, [])

  const printGo = useReactToPrint({content: () => component.current,})

  return(
    <c.Modal okText={lang.imprimir} onClose={onClose} loading={loading}
      onFinish={printGo} successMsg={false}
    >
      <TicketPortaria p={p} lang={lang} user={App.user.nm_usr} baseURL={App.baseURL} ref={component} />
    </c.Modal>
  )
}

class TicketPortaria extends React.Component { // p = portaria
  render(){
    const style = { width: 540, border: "1px solid black", margin: '0 auto' }

    const ticket_url = this.props.baseURL+'ticket/?key='+btoa(this.props.p.ID_UOCC)

    return (
      <div className="f g1 f-column" style={{background: 'white', color: 'black', padding: 5}}>
        <table style={style}>
          <tbody>
            <tr style={{ fontSize:18, fontWeight: 'bold' }}>
              <td colSpan={3}>LIBERAÇÃO {this.props.p.DS_OPRPRT}</td>
              <td style={{ textAlign: "right" }}>{f.formatNumber(this.props.p.ID_UOCC)}</td>
            </tr>
          </tbody>
        </table>

        <table style={style}>
          <tbody>
            <tr><td>{this.props.lang.data}:</td><td>{this.props.p.DT_PRV} {this.props.p.DS_TRN}</td></tr>
            <tr><td>{this.props.lang.cliente}:</td><td>{this.props.p.NM_PRC}</td></tr>
            <tr><td>{this.props.lang.origem}:</td><td>{this.props.p.NM_ORI}</td></tr>
            <tr><td>{this.props.lang.destino}:</td><td>{this.props.p.NM_DST}</td></tr>
            <tr><td>{this.props.lang.produto}:</td><td>{this.props.p.NM_PRO}</td></tr>
            <tr><td>{this.props.lang.nota}:</td><td>{this.props.p.LST_NFE}</td></tr>
            <tr><td>{this.props.lang.tipo_veiculo}:</td><td>{this.props.p.DS_TPVCL + " " + (this.props.p.QT_EIXO? this.props.p.QT_EIXO + ' EIXOS' : '' ) + ' ' + (this.props.p.QT_CAPMAX?this.props.p.QT_CAPMAX + ' TON' : '')}</td></tr>
            <tr><td>{this.props.lang.veiculo}:</td><td>{this.props.p.ID_VCL} {this.props.p.LST_VCL}</td></tr>
            <tr><td>{this.props.lang.renavam}:</td><td>{this.props.p.ID_RNV}</td></tr>
            <tr><td>{this.props.lang.liberacao}:</td><td>{this.props.p.DT_TRS}</td></tr>
          </tbody>
        </table>

        <div style={{...style, width: 538}}>
          <table style={{width: '100%'}}><tbody>
            <tr><td>{this.props.lang.motorista}:</td>
              <td>{this.props.p.NM_MTR}</td>
              <td>{f.formatCpf(this.props.p.NR_CPFMTR)}</td>
            </tr>
          </tbody></table>

          <table style={{width: '100%'}}><tbody>
            <tr>
              <td>{this.props.lang.cnh}:</td><td>{this.props.p.NR_CNHMTR} {this.props.p.DS_CTGCNHMTR?.toUpperCase()}</td>
              <td>{this.props.lang.validade}:</td><td>{this.props.p.DT_VCTCNHMTR}</td>
            </tr>
            <tr>
              <td>{this.props.lang.telefone}:</td><td><c.TelefoneView value={this.props.p.NR_TLFMTR} /></td>
              <td>{this.props.lang.rg}:</td><td>{this.props.p.NR_RGMTR}</td>
            </tr>
          </tbody></table>
        </div>

        {/* Observações */}
        {!!this.props.p.OBSS?.length &&
          <table style={{...style, width: '100%'}} cellSpacing={0} cellPadding={5}>
            <tbody>
              <tr><td colSpan={4} style={{fontWeight: 'bold'}}>Observações</td></tr>
              {this.props.p.OBSS?.map((o, i) =>
                <Fragment key={i}>
                  <tr style={{display: 'flex'}}>
                    <td style={{borderLeft: '5px solid var(--' + o.id_color + ')', padding: 5 }}>
                      {o.ds_obs}
                    </td>
                  </tr>
                </Fragment>
              )}
            </tbody>
          </table>
      }

        {/* Assinatura */}
        <table style={{...style, textAlign: "center" }}>
          <tbody>
            <tr style={{ height: "100px" }}></tr>
            <tr>
              <td style={{ width:"50%" }}>______________________________</td>
              <td style={{ width:"50%" }}>______________________________</td>
            </tr>
            <tr>
              <td style={{ width:"50%" }}>{this.props.p.NM_UND}</td>
              <td style={{ width:"50%" }}>{this.props.p.NM_TRP}</td>
            </tr>
            <tr>
              <td style={{ width:"50%" }}>{f.formatCnpj(this.props.p.NR_CNPJUND)}</td>
              <td style={{ width:"50%" }}>{f.formatCnpj(this.props.p.NR_CNPJTRP)}</td>
            </tr>
            <tr>
              <td>{this.props.lang.ticket_carimbo_assinatura}</td>
              <td>{this.props.lang.ticket_carimbo_assinatura}</td>
            </tr>
          </tbody>
        </table>

        {/* Código de barras */}
        <table style={{...style}}><tbody>
          <tr>
            <td style={{ textAlign: "left", width: "50%" }}>
              <c.Barcode value={this.props.p.ID_UOCC} displayValue={false} />
            </td>
            <td style={{ textAlign: "right", width: "50%" }}>
              <a href={ticket_url} target="_blank" rel="noreferrer">
                <QRCode value={ticket_url} level={"M"} includeMargin={true}
                  imageSettings={{x: undefined, y: undefined, height: 19, width: 25, excavate: true,
                  src: this.props.baseURL + '/assets/logo_vertti.png',
                }}/>
              </a>
            </td>
          </tr>
        </tbody></table>

        <table style={{ width: 540, padding: 2, margin: '0 auto' }}><tbody>
          <tr>
            <td>{this.props.lang.emissao}: {f.formatDate()}</td>
            <td style={{ textAlign: "right" }}>
              {this.props.lang.usuario}: {this.props.user}
            </td>
          </tr>
        </tbody></table>
      </div>
    )
  }
}

export const PrintTicketFracionadoModal = ({ocId=null, prtId=null, onClose}) => {
  const App = useContext(AppContext),
        lang = {...App.lang.global, ...App.lang.monitor},
        component = useRef(),
        [loading, setLoading] = useState(false),
        [oc, setOc] = useState(null)

  useEffect(() => {
    async function fetchData(){
      setLoading(true)
      let id = ocId ?? (await App.api('ordemCarregamento::getByPortaria', {prtId})).results

      if( !id ){
        App.toast.error('Falha ao buscar OC')
        onClose()
      }else{
        App.api('ordemCarregamento::getById',{id})
        .then(r=>{ setOc(r.results); setLoading(false)})
      }
    }
    fetchData()
  }, [])

  const printGo = useReactToPrint({
    content: () => component.current,
    pageStyle: {
      padding: 40,
      fontFamily: 'monospace',
    },
  })

  return(
    <c.Modal okText={lang.imprimir} onClose={onClose} loading={loading}
      onFinish={printGo} successMsg={false}
    >
      {!!oc&&<TicketOC oc={oc} lang={lang} user={App.user.nm_usr} baseURL={App.baseURL} ref={component} app={App} />}
    </c.Modal>
  )
}

class TicketOC extends React.Component {
  render(){
    const stylePapel = {
      //padding: 40,
      background: 'white',
      color: '#333',
      display: 'flex',
      gap: 10,
      flexDirection: 'column',
      fontFamily: 'monospace',
    }

    const styleTable = {
      width: '100%',
      minWidth: 540,
      border: "1px solid #333",
      borderRadius: 5,
      overflow: 'hidden',
      textTransform: 'uppercase',
      //margin: 5,
    }

    const styleTableHead = {
      background: '#f0f0f0',
      padding: 5,
      fontWeight: 'bold',
      fontSize: 14,
      color: '#333',
      textTransform: 'uppercase',
    }

    const ticket_url = this.props.baseURL+'ticket/?tp=oc&key='+btoa(this.props.oc.ID_UOCC)

    return (
      <div className="f g1 f-column ticketForPrint" style={stylePapel}>
        <div className="center-h"><img style={{height: 50}} src={require('../a/velog-hub-b.png')} alt='Logo' /></div>

        <table style={styleTable} cellSpacing={0} cellPadding={5}>
          <tbody>
            <tr>
              <td style={styleTableHead}>LIBERAÇÃO {this.props.oc.TP_OPRPRT === 'E' ? 'CARGA' : 'DESCARGA'}</td>
              <td style={{...styleTableHead, textAlign: "right" }}>#{f.formatNumber(this.props.oc.ID_UOCC)}</td>
            </tr>
            <tr>
              <td>{this.props.lang.data} INICIAL:</td>
              <td>
                {this.props.app.moment(this.props.oc.TURNO_INICIAL.DT_CRG).format('DD/MM/YYYY')}
                {' - '}{this.props.oc.TURNO_INICIAL.DS_TRN}
              </td>
            </tr>
            <tr><td>{this.props.lang.veiculo}:</td><td>{this.props.oc.ID_VCL}</td></tr>
            <tr><td>{this.props.lang.renavam}:</td><td>{this.props.oc.PORTARIAS[0].ID_RNV}</td></tr>
            {/* <tr><td>{this.props.lang.tipo_veiculo}:</td><td>{this.props.oc.PORTARIAS[0].DS_TPVCL}</td></tr> */}
            <tr><td>{this.props.lang.tipo_veiculo}:</td><td>{this.props.oc.PORTARIAS[0].DS_TPVCL + " " + (this.props.oc.PORTARIAS[0].QT_EIXO ? this.props.oc.PORTARIAS[0].QT_EIXO + ' EIXOS' : '' ) + ' ' + (this.props.oc.PORTARIAS[0].QT_CAPMAX?this.props.oc.PORTARIAS[0].QT_CAPMAX + ' TON' : '')}</td></tr>
          </tbody>
        </table>

        <table style={styleTable} cellSpacing={0} cellPadding={5}><tbody>
          <tr><td colSpan={4} style={styleTableHead}>Dados do motorista</td></tr>
          <tr>
            <td colSpan={3}>{this.props.oc.NM_MOTORISTA}</td>
            <td colSpan={1}>{f.formatCpf(this.props.oc.PORTARIAS[0].NR_CPFMTR)}</td>
          </tr>
          <tr>
            <td>{this.props.lang.cnh}:</td>
            <td>{this.props.oc.PORTARIAS[0].NR_CNHMTR} {this.props.oc.PORTARIAS[0].DS_CTGCNHMTR?.toUpperCase()}</td>
            <td>{this.props.lang.validade}:</td>
            <td>{this.props.oc.PORTARIAS[0].DT_VCTCNHMTR}</td>
          </tr>
          <tr>
            <td>{this.props.lang.telefone}:</td>
            <td><c.TelefoneView value={this.props.oc.PORTARIAS[0].NR_TLFMTR} /></td>
            <td>{this.props.lang.rg}:</td>
            <td>{this.props.oc.PORTARIAS[0].NR_RGMTR}</td>
          </tr>
        </tbody></table>

        {/* Origens */}
        <table style={styleTable} cellSpacing={0} cellPadding={5}>
          <tbody>
            <tr><td colSpan={4} style={styleTableHead}>Roteiro de coleta</td></tr>

            <tr><td className="f f-column g1">
              {this.props.oc.ORIGENS.map(o=>
                <table style={{...styleTable}} cellSpacing={0} cellPadding={5}>
                  <tr>
                    <td colSpan={5} style={{...styleTableHead}}>{o.NM_PES}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} style={{background: '#f0f0f0'}}>
                      {f.formatCnpj(o.NR_CNPJUND)}
                    </td>
                    <td style={{background: '#f0f0f0'}}>
                      {this.props.app.moment(o.DT_CRG).format('DD/MM/YYYY')}
                    </td>
                    <td style={{background: '#f0f0f0'}}>{o.DS_TRN}</td>
                  </tr>
                  {o.ITENS
                    .map(i => ({...i,
                      NM_PRO: this.props.oc.ITENS.find(ii=>ii.ID_PRO === i.ID_PRO)?.NM_PRO,
                    }))
                    .map((item, id) =>
                      <tr key={id}>
                        <td style={{width: 70}}>{f.formatNumber(item.QT_EXP)} {item.ID_UNDMEXP}</td>
                        <td colSpan={3}>{item.NM_PRO} {"(COD: " + item.ID_PRO + ")"}</td>
                        <td>{item.PALETES?.join('|')}</td>
                      </tr>
                    )}
                </table>
              )}
            </td></tr>
          </tbody>
        </table>

        {/* Entregas */}
        <table style={styleTable} cellSpacing={0} cellPadding={5}>
          <tbody>
            <tr><td colSpan={4} style={styleTableHead}>Entregas</td></tr>

            <tr><td className="f f-column g1">
              {this.props.oc.DESTINOS.map(o=>
                <table style={{...styleTable}} cellSpacing={0} cellPadding={5}>
                  <tr>
                    <td colSpan={2} style={{...styleTableHead}}>{o.NM_PES}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{background: '#f0f0f0'}}>
                      {f.formatCnpj(o.NR_CPFCNPJ)}
                    </td>
                  </tr>
                  {o.ITENS
                    .map(i => ({...i,
                      NM_PRO: this.props.oc.ITENS.find(ii=>ii.ID_PRO === i.ID_PRO)?.NM_PRO,
                    }))
                    .map((item, id) =>
                      <tr key={id}>
                        <td style={{width: 70}}>{f.formatNumber(item.QT_EXP)} {item.ID_UNDMEXP}</td>
                        <td colSpan={3}>{item.NM_PRO} {"(COD: " + item.ID_PRO + ")"}</td>
                      </tr>
                    )}
                </table>
              )}
            </td></tr>
          </tbody>
        </table>

        {/* Observações */}
        <table style={styleTable} cellSpacing={0} cellPadding={5}><tbody>
          <tr><td colSpan={4} style={styleTableHead}>Observações</td></tr>
          {this.props.oc.OBSS.map((o,i)=>
          <>
          <tr style={{display: 'flex', marginBottom: i < this.props.oc.OBSS.length-1 ? '-5px' : '', marginTop: i > 0 ? '-5px' : ''}}>
              <td style={{borderLeft: '10px solid var(--' + o.id_color + ')' }}>{o.ds_obs}</td>
          </tr>
          {
            i < this.props.oc.OBSS.length-1 && <c.Divider style={{borderLeft: '10px solid var(--' + o.id_color + ')'}}/>
          }
          </>
            )}
        </tbody></table>

        {/* Códigos de barra */}
        <table style={styleTable}><tbody>
          <tr>
            <td style={{ textAlign: "left", width: "50%" }}>
              <c.Barcode value={this.props.oc.ID_UOCC} displayValue={false} />
            </td>

            <td style={{ textAlign: "right", width: "50%" }}>
              <a href={ticket_url} target="_blank" rel="noreferrer">
                <QRCode value={ticket_url} level={"M"} includeMargin={true}
                  imageSettings={{x: undefined, y: undefined, height: 19, width: 25, excavate: true,
                  src: this.props.baseURL + '/assets/logo_vertti.png',
                }}/>
              </a>
            </td>
          </tr>
        </tbody></table>

        {/* Rodapé */}
        <table style={{ width: '100%', padding: 2, margin: '0 auto' }}><tbody>
          <tr>
            <td>{this.props.lang.emissao}: {f.formatDate()}</td>
            <td style={{ textAlign: "right" }}>
              {this.props.lang.usuario}: {this.props.user}
            </td>
          </tr>
        </tbody></table>
      </div>
    )
  }
}

